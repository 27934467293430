<template>
  <div>
    <section class="fixed w-full top-0 bg-white z-50 shadow-lg">
      <div class="bg-white shadow-lg py-4">
        <div
          class="max-w-7xl mx-auto px-4 flex gap-5 justify-between place-items-center"
        >
          <div class="flex gap-6 items-center text-center">
            <a href="/">
              <img src="@/assets/logo.svg" class="w-[250px]" alt />
            </a>
          </div>
          <div class="flex items-center">
            <div>
              <div class="flex justify-center items-center gap-2">
                <a href="tel:+918877661755">
                  <div
                    class="flex justify-center items-center bg-[#004271] text-white lg:w-full w-[150px] lg:pr-5 rounded-l-full"
                  >
                    <img
                      src="@/assets/lpmba/icon-call.png"
                      class="w-[35px] lg:w-[50px]"
                    />
                    <span
                      class="text-[13px] lg:text-[18px] xl:text-[26px] leading-5"
                      >+91 8877661755</span
                    >
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="w-full flex flex-col md:flex-row justify-between">
      <div class="w-full mt-[90px] md:hidden">
        <h1 class="text-[#d02829] font-bold pt-2 text-[28px]">
          ONLINE DEGREE PROGRAMS
        </h1>
        <h2 class="font-extrabold text-black pb-1 text-[25px]">
          MBA | MCA | BBA | BCA
        </h2>
        <h2 class="text-white text-[18px] font-bold bg-[#d02829] py-1 px-3">
          NAAC A+ Accredited University
        </h2>
      </div>
      <div
        class="w-full flex justify-center md:w-[35%] xl:w-[40%] md:pt-10 -z-10"
      >
        <img
          src="@/assets/landingpage/Banner-emi.webp"
          class="h-full md:w-full w-[195px] md:mt-6"
        />
        <!-- <img
          src="@/assets/landingpage/Program/ug-mobile.webp"
          class="h-full w-full md:hidden"
        /> -->
      </div>

      <div
        class="w-full flex flex-col md:flex-row justify-end items-center mr-[4%] gap-[10%] md:w-[70%] 2xl:w-[80%]"
      >
        <div
          class="text-left flex flex-col justify-center items-center md:mt-[-5%]"
        >
          <h1
            class="text-[#d02829] font-bold text-center py-2 text-[30px] hidden md:block"
          >
            ONLINE DEGREE PROGRAMS
          </h1>
          <h2 class="font-extrabold text-black text-[27px] hidden md:block">
            MBA | MCA | BBA | BCA
          </h2>
          <h2
            class="text-white text-[18px] font-bold bg-[#d02829] py-1 px-3 mt-[24px] hidden md:block"
          >
            NAAC A+ Accredited University
          </h2>
          <h2
            class="text-[#d02829] font-bold text-[18px] md:text-[25px] md:pt-[24px]"
          >
            <span class="text-[25px] drop-shadow-xl">0</span> COST EMI OPTION
          </h2>
          <!-- <h2 class="font-bold text-center">
            Batch Starts on <span
              class="text-[#ce3b32] font-semibold text-lg" >24<sup>th</sup> December</span><br>
         <span
            class="" >Application closes in : <span
              id="countdown"
              class="text-[#ce3b32] font-semibold text-lg"
              >{{ countdown }}</span
            ></span
          >
      
        </h2> -->
        </div>
        <div
          class="bg-white shadow-md py-3 xl:py-8 md:mt-20 md:w-[60%] xl:w-[40%] 2xl:w-[35%]"
        >
          <p class="p-5">
            Thank you! Your Enquiry has been submitted successfully. Our
            admissions team will get in touch with you soon. A verification
            email has been sent to your registered email ID.<br />
            Please ignore if already verified.
          </p>
        </div>
      </div>
    </div>

    <!--Section 2-->
    <div class="w-full pt-6 bg-[#f7f9fb] z-50">
      <div class="max-w-7xl mx-auto w-full px-8 pb-5">
        <div class="col-lg-10 offset-lg-1 wow fadeInLeft text-center">
          <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
            ABOUT
            <span class="text-[#e33237] font-bold">VIGNAN ONLINE</span>
          </h2>

          <p class="pt-6 ld:px-10 px-2 text-black text-justify md:text-center">
            We at Vignan Online have advanced the standards for the delivery and
            reception of education. Our commitment goes beyond ethical values
            and social consciousness, encompassing various online UG and PG
            programs. We prioritize delivering high-quality education through
            innovation, a global curriculum, and a team of experienced faculty.
          </p>

          <!-- <p class="py-3 font-bold">We offer online UG and PG programs:</p>
          <h3 class="font-bold texl-[18px]">
            Online MBA | Online MCA | Online BBA | Online BCA
          </h3> -->
        </div>
      </div>
      <img src="@/assets/lpmba/partner-main.webp" class="w-full" />
    </div>

    <!--YEARS OF GLORIOUS LEGACY-->
    <div class="w-full pb-10 bg-[#f7f9fb]">
      <div
        class="xl:max-w-3xl mx-auto w-full bg-[#d02829] p-5 flex flex-col md:flex-row justify-center items-start text-white"
      >
        <div class="md:w-[37%] w-full justify-center">
          <h2 class="font-bold text-[100px] md:text-[150px]">45</h2>
          <p class="text-[16px] md:text-[25px] font-bold">
            YEARS OF GLORIOUS LEGACY
          </p>
        </div>
        <div
          class="md:w-[63%] text-white text-left py-5 text-sm px-5 text-justify"
        >
          <p>
            Celebrating a prestigious 45-year legacy in education, we are fully
            equipped to impart the knowledge and skills necessary for learners
            to embrace leadership responsibilities in various industries,
            enabling them to forge their path to success through our online UG
            and PG programs.
          </p>

          <p class="pt-5">
            We aim to provide high-quality educational programs and facilitate
            training & research activities with our multiple online UG and PG
            electives. We also focus on increasing industrial interactions &
            promoting entrepreneurial and leadership skills among the students.
            Our affordable Online UG and PG programs entail the right exposure
            for the learners to take on leadership roles in the business and
            corporate world.
          </p>
        </div>
      </div>
    </div>

    <!--Rankings and Recognitions-->
    <!--Rankings and Recognitions-->
    <section>
      <div class="w-full p-5">
        <div class="max-w-6xl mx-auto w-full">
          <h2 class="font-bold text-[22px] md:text-[28px] py-3 uppercase">
            Rankings and
            <span class="text-[#e33237] font-bold">Recognitions</span>
          </h2>
          <div
            class="flex flex-wrap justify-center items-center gap-3 xl:gap-5 mt-5"
          >
            <div class="">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/home-icon-1.png" alt="" />
                <span class="text-center md:text-left"
                  >Amongst top 100 <br />in NIRF ranking</span
                >
              </div>
            </div>
            <div class="">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/home-icon-2.png" alt="" />
                <span class="text-center md:text-left"
                  >NAAC A+,<br />UGC 12B</span
                >
              </div>
            </div>
            <div class="">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/home-icon-3.png" alt="" />
                <span class="text-center md:text-left"
                  >Approved<br />BY AICTE</span
                >
              </div>
            </div>
            <div class="">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/home-icon-4.png" alt="" />
                <span class="text-center md:text-left">ISO<br />9001:2015</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ONLINE MBA PROGRAM-->
    <div class="w-full p-5 bg-[#f7f9fb]">
      <div class="max-w-5xl mx-auto w-full">
        <h1 class="font-bold text-black text-[28px] pt-5">
          ONLINE DEGREE
          <span class="text-[#d02829]">PROGRAMS</span>
        </h1>
        <ul
          class="flex justify-evenly items-center mt-10 font-bold text-black mb-5"
        >
          <li class="tab" @click="programdetail('detail1')">MBA</li>
          <li class="tab" @click="programdetail('detail2')">MCA</li>
          <li class="tab" @click="programdetail('detail3')">BBA</li>
          <li class="tab" @click="programdetail('detail4')">BCA</li>
        </ul>

        <!--tab detail-->
        <div class>
          <!--tab1 detail-->
          <div class="tabdetail" id="detail1">
            <h4 class="font-bold text-black text-[18px] text-left px-5">
              PROGRAM OVERVIEW
            </h4>
            <div class="text-left px-5 text-black">
              <p class="mt-5 text-[15px] font-normal">
                The Online Degree is a 2-year program with various in-demand
                electives offered by Vignan Online, preparing future managers
                with a qualification equivalent to a traditional 2-year program.
                Our Online UG and PG courses equip you with the required
                knowledge and help you learn quickly at your convenience. With
                Vignan’s Online UG and PG Degree program, you can learn
                independently. Our Online UG and PG Courses offer online weekend
                classes, pre-recorded video lessons, and job placement
                assistance in MNCs.
              </p>
            </div>
<div class="flex lg:flex-row flex-col">
  <div class="lg:w-[50%] w-full">
            <h2 class="font-bold text-black text-[18px] text-left mt-5">
              PROGRAM ELECTIVES
            </h2>
            <div
              class="flex flex-col md:flex-row justify-between items-center font-semibold text-[15px] pl-5"
            >
              <div class="w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Finance</li>
                  <li class="relative ml-[5%]">Marketing</li>
                  <li class="relative ml-[5%]">Human Resource Management</li>
                  <!-- <li class="relative ml-[5%]">
                    Finance and Human Resource Management
                  </li> -->
                  <li class="relative ml-[5%]">Operations Management</li>
                </ul>
              </div>
              </div>

              <!-- <div class="lg:w-[50%] w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Information Technology</li>
                  <li class="relative ml-[5%]">Business Analytics</li>
                  <li class="relative ml-[5%]">
                    Healthcare &amp; Hospital Management
                  </li>
                  <li class="relative ml-[5%]">
                    Logistics and Supply Chain Management
                  </li>
                </ul>
              </div> -->
            </div>
            <div class="lg:w-[50%] w-full">
            <h2 class="font-bold text-black text-[18px] text-left mt-7">
              MINOR  ELECTIVES 
            </h2>
            <div
              class="flex flex-col md:flex-row justify-between items-center font-semibold text-[15px] pl-5"
            >
              <div class="w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                 
                  <li class="relative ml-[5%]">Data Science & Analytics</li>
                  <li class="relative ml-[5%]">
                    Logistics & Supply Chain Management
                  </li>
                  <li class="relative ml-[5%]">
                    Digital Marketing & E-commerce
                  </li>
                  <li class="relative ml-[5%]">Operations Management</li>
                </ul>
              </div>

              <!-- <div class="lg:w-[50%] w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Finance</li>
                  <li class="relative ml-[5%]">Marketing</li>
                  <li class="relative ml-[5%]">Human Resource Management</li>
                  <li class="relative ml-[5%]">Banking & Fintech</li>
                 
                  <li class="relative ml-[5%]">
                    Healthcare & Hospital Management
                  </li>
                  <li class="relative ml-[5%]">Project Management</li>
                  
                </ul>
              </div> -->
            </div>
          </div>
            </div>
            <div
              class="flex justify-center gap-[10px] items-center uppercase mt-10 font-bold pb-5"
            >
              <div class="course-features-box">
                <img src="@/assets/lpmba/icon-tie-red.svg" class="p-2" alt />
                <span class="leading-5">
                  2 Year
                  <br />Program
                </span>
              </div>

              <div class="course-features-box">
                <img src="@/assets/lpmba/icon-goal-red.svg" class="p-2" alt />
                <span class="leading-5">
                  Live Online
                  <br />Sessions
                </span>
              </div>
            </div>
          </div>

          <!--tab2 detail-->
          <div class="tabdetail" id="detail2">
            <h4 class="font-bold text-black text-[18px] text-left px-5">
              PROGRAM OVERVIEW
            </h4>
            <div class="text-left px-5 text-black">
              <p class="mt-5 text-[15px] font-normal">
                The Online Degree is a 2-year program with various in-demand
                electives offered by Vignan Online, preparing future managers
                with a qualification equivalent to a traditional 2-year program.
                Our Online UG and PG courses equip you with the required
                knowledge and help you learn quickly at your convenience. With
                Vignan’s Online UG and PG Degree program, you can learn
                independently. Our Online UG and PG Courses offer online weekend
                classes, pre-recorded video lessons, and job placement
                assistance in MNCs.
              </p>
            </div>
<div class="flex lg:flex-row flex-col">
  <div class="lg:w-[50%] w-full">
            <h4 class="font-bold text-black text-[18px] text-left mt-7">
              PROGRAM ELECTIVES
            </h4>
            <div
              class="flex flex-col md:flex-row justify-between items-center font-semibold text-[15px] pl-5"
            >
              <div class="w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Computer Science and IT</li>
                  <li class="relative ml-[5%]">Data Science</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="lg:w-[50%] w-full">
            <h4 class="font-bold text-black text-[18px] text-left mt-7">
              MINOR  ELECTIVES 
            </h4>
            <div
              class="flex flex-col md:flex-row justify-between items-center font-semibold text-[15px] pl-5"
            >
              <div class="w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Data Science</li>
                  <li class="relative ml-[5%]">Cyber Security</li>
                  <li class="relative ml-[5%]">Full stack Development</li>
                  <li class="relative ml-[5%]">
                    Machine Learning & Artificial Intelligence
                  </li>
                </ul>
              </div>
              </div>

              <!-- <div class="lg:w-[50%] w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
               
                  <li class="relative ml-[5%]">Full stack Development</li>
                  <li class="relative ml-[5%]">Cloud Computing</li>
                </ul>
              </div> -->
            </div>
          </div>
            <div
              class="flex justify-center gap-[10px] items-center uppercase mt-10 font-bold pb-5"
            >
              <div class="course-features-box">
                <img src="@/assets/lpmba/icon-tie-red.svg" class="p-2" alt />
                <span class="leading-5">
                  2 Year
                  <br />Program
                </span>
              </div>

              <div class="course-features-box">
                <img src="@/assets/lpmba/icon-goal-red.svg" class="p-2" alt />
                <span class="leading-5">
                  Live Online
                  <br />Sessions
                </span>
              </div>
            </div>
          </div>

          <!-- tab3 detail -->
          <div class="tabdetail" id="detail3">
            <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
              PROGRAM OVERVIEW
            </h2>
            <div class="text-left px-5 text-black">
              <p class="mt-5 text-[15px] font-normal">
                The Online Degree is a 2-year program with various in-demand
                electives offered by Vignan Online, preparing future managers
                with a qualification equivalent to a traditional 2-year program.
                Our Online UG and PG courses equip you with the required
                knowledge and help you learn quickly at your convenience. With
                Vignan’s Online UG and PG Degree program, you can learn
                independently. Our Online UG and PG Courses offer online weekend
                classes, pre-recorded video lessons, and job placement
                assistance in MNCs.
              </p>
            </div>

            <h2 class="font-bold text-black text-[18px] text-left ml-5 mt-7">
              PROGRAM ELECTIVES
            </h2>
            <div
              class="flex flex-col md:flex-row justify-between items-center font-semibold text-[15px] pl-5"
            >
              <div class="lg:w-[50%] w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">General Management</li>
                </ul>
              </div>
            </div>

            <!-- <h2 class="font-bold text-black text-[18px] text-left ml-5 mt-7">
              MINOR  ELECTIVES 
            </h2>
            <div
              class="flex flex-col md:flex-row justify-between items-center font-semibold text-[15px] pl-5"
            >
              <div class="lg:w-[50%] w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Marketing</li>
                  <li class="relative ml-[5%]">Human Resource Management</li>
                  <li class="relative ml-[5%]">Banking & Fintech</li>
                  <li class="relative ml-[5%]">Data Science & Analytics</li>
                  <li class="relative ml-[5%]">
                    Logistics & Supply Chain Management
                  </li>
                </ul>
              </div>

              <div class="lg:w-[50%] w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Finance</li>
                  <li class="relative ml-[5%]">
                    Digital Marketing & E-commerce
                  </li>
                  <li class="relative ml-[5%]">
                    Healthcare & Hospital Management
                  </li>
                  <li class="relative ml-[5%]">Project Management</li>
                  <li class="relative ml-[5%]">Operations Management</li>
                </ul>
              </div>
            </div> -->
            <div
              class="flex justify-center gap-[10px] items-center uppercase mt-10 font-bold pb-5"
            >
              <div class="course-features-box">
                <img src="@/assets/lpmba/icon-tie-red.svg" class="p-2" alt />
                <span class="leading-5">
                  3 Year
                  <br />Program
                </span>
              </div>

              <div class="course-features-box">
                <img src="@/assets/lpmba/icon-goal-red.svg" class="p-2" alt />
                <span class="leading-5">
                  Live Online
                  <br />Sessions
                </span>
              </div>
            </div>
          </div>

          <!-- tab4 detail -->
          <div class="tabdetail" id="detail4">
            <h4 class="font-bold text-black text-[18px] text-left px-5">
              PROGRAM OVERVIEW
            </h4>
            <div class="text-left px-5 text-black">
              <p class="mt-5 text-[15px] font-normal">
                The Online Degree is a 2-year program with various in-demand
                electives offered by Vignan Online, preparing future managers
                with a qualification equivalent to a traditional 2-year program.
                Our Online UG and PG courses equip you with the required
                knowledge and help you learn quickly at your convenience. With
                Vignan’s Online UG and PG Degree program, you can learn
                independently. Our Online UG and PG Courses offer online weekend
                classes, pre-recorded video lessons, and job placement
                assistance in MNCs.
              </p>
            </div>

            <h2 class="font-bold text-black text-[18px] text-left ml-5 mt-7">
              PROGRAM ELECTIVES
            </h2>
            <div
              class="flex flex-col md:flex-row justify-between items-center font-semibold text-[15px] pl-5"
            >
              <div class="lg:w-[50%] w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Computer Science and IT</li>
                </ul>
              </div>
            </div>

            <!-- <h2 class="font-bold text-black text-[18px] text-left ml-5 mt-7">
              MINOR  ELECTIVES 
            </h2>
            <div
              class="flex flex-col md:flex-row justify-between items-center font-semibold text-[15px] pl-5"
            >
              <div class="lg:w-[50%] w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Cloud Computing</li>
                  <li class="relative ml-[5%]">Cyber Security</li>
                  <li class="relative ml-[5%]">
                    Machine Learning & Artificial Intelligence
                  </li>
                </ul>
              </div>

              <div class="lg:w-[50%] w-full">
                <ul class="red-dot-list text-black text-left leading-[45px]">
                  <li class="relative ml-[5%]">Data Science</li>
                  <li class="relative ml-[5%]">Full stack Development</li>
                </ul>
              </div>
            </div> -->
            <div
              class="flex justify-center gap-[10px] items-center uppercase mt-10 font-bold pb-5"
            >
              <div class="course-features-box">
                <img src="@/assets/lpmba/icon-tie-red.svg" class="p-2" alt />
                <span class="leading-5">
                  3 Year
                  <br />Program
                </span>
              </div>

              <div class="course-features-box">
                <img src="@/assets/lpmba/icon-goal-red.svg" class="p-2" alt />
                <span class="leading-5">
                  Live Online
                  <br />Sessions
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="w-full p-5">
        <div class="max-w-7xl mx-auto w-full">
          <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
            PROGRAM
            <span class="text-[#d02829]">HIGHLIGHTS</span>
          </h2>
          <div
            class="flex flex-wrap justify-center items-center gap-3 xl:gap-5 mt-5"
          >
            <div class>
              <div
                class="banner-feature-box flex flex-col lg:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img
                  src="@/assets/lpmba/Updated_Industry_Relevant_Syllabus.svg"
                  alt
                />
                <span class="text-center md:text-left">
                  Updated Industry
                  <br />Relevant Syllabus
                </span>
              </div>
            </div>
            <div class>
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Experienced_Faculty-02.svg" alt />
                <span class="text-center md:text-left">
                  Experienced &amp;
                  <br />Professional Faculty
                </span>
              </div>
            </div>
            <div class>
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Flexible-Learning.svg" alt />
                <span class="text-center md:text-left">
                  Flexible Learning
                  <br />Environment
                </span>
              </div>
            </div>
            <div class>
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Flexible-Learning.svg" alt />
                <span class="text-center md:text-left">
                  Multiple electives
                  <br />to choose from
                </span>
              </div>
            </div>
            <div class>
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Management_Thesis.svg" alt />
                <span class="text-center md:text-left">
                  Management
                  <br />Thesis
                </span>
              </div>
            </div>
            <div class>
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Live_Online_Session.svg" alt />
                <span class="text-center md:text-left">
                  Live Online
                  <br />Session
                </span>
              </div>
            </div>
            <div class>
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Practical_Exposure.svg" alt />
                <span class="text-center md:text-left">
                  Practical
                  <br />Exposure
                </span>
              </div>
            </div>

            <div class>
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Project_Reports.svg" alt />
                <span class="text-center md:text-left">
                  Project
                  <br />Reports
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--LEARNING METHODOLOGY-->
    <div class="w-full p-5">
      <div class="max-w-7xl mx-auto w-full">
        <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
          LEARNING
          <span class="text-[#d02829]">METHODOLOGY</span>
        </h2>
        <div class="flex flex-wrap justify-center items-center gap-4">
          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/lpmba/icon-clock.svg" alt class="w-[50px]" />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>120 learning hours in each program</strong>
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img
                src="@/assets/lpmba/icon-projetor.svg"
                alt
                class="w-[50px]"
              />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>Interactive audio-video lectures</strong>
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/lpmba/icon-id.svg" alt class="w-[50px]" />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>Pre-recorded video lectures</strong>
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/lpmba/icon-monitor.svg" alt class="w-[50px]" />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>Discussion forum</strong>
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/lpmba/icon-edit.svg" alt class="w-[50px]" />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>Assignments, quizzes, MCQ, etc.for reinforcement</strong>
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/lpmba/icon-tub.svg" alt class="w-[50px]" />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>
                Independent and
                <br />group projects
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full p-5 mt-5">
      <h2 class="font-bold text-black text-[22px] md:text-[28px]">
        HIRING
        <span class="text-[#d02829]">COMPANIES</span>
      </h2>
      <HiringComp />
    </div>

    <!--FAQ's-->
    <div class="w-full p-5 mt-5">
      <div class="max-w-5xl mx-auto w-full">
        <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
          FAQ<span class="text-[#d02829]">s</span>
        </h2>
        <div>
          <div
            class="border-b border-gray-400 pb-3 text-left text-black font-medium text-[14px]"
            v-for="(faq, index) in OnlineFaq.slice(start, end)"
            :key="index"
          >
            <div class="question my-4" @click="toggleAccordion(index)">
              <h3 class="text-[15px] font-semibold">
                {{ faq.title }}
              </h3>
              <span class="faq-qus-arrow"></span>
            </div>
            <p
              :id="`answer-${faq.id}`"
              class="answer-list mr-[50px]"
              :class="{ hidden: activeIndex !== index }"
              v-html="faq.content"
            ></p>
          </div>
        </div>
      </div>
    </div>

    <!--Footer-->
    <div class="w-full p-5 bg-[#d02829]">
      <div
        class="max-w-7xl mx-auto w-full text-white font-semibold text-sm flex flex-col md:flex-row justify-center items-center gap-5"
      >
        <div
          class="flex flex-col justify-center items-center md:w-[250px] gap-3"
        >
          <a href="tel:+918877661755">
            <img src="@/assets/lpmba/icon-call.svg" alt=""
          /></a>
          <div class="min-h-[10px]">
            <a href="tel:+918877661755">
              <p class="mb-0">+91 8877661755</p>
            </a>
          </div>
        </div>

        <!-- <div
          class="flex flex-col justify-center items-center md:w-[250px] gap-3"
        >
          <img
            src="@/assets/lpmba/icon-whatsapp.png"
            style="width: 30px"
            alt=""
          />
          <div class="min-h-[90px]">
            <a
              href="https://api.whatsapp.com/send?phone=918877661755&amp;text=Hi!%20I%27d%20like%20to%20know%20more%20details."
              target="_blank"
              tabindex="-1"
            >
              <p class="mb-0">+91 8877661755</p>
            </a>
          </div>
        </div>-->

        <a
          href="https://www.google.com/maps/place/Vignan's+Foundation+for+Science,+Technology+%26+Research+(Deemed+to+be+University)/@16.2333746,80.5509079,17z/data=!3m1!4b1!4m6!3m5!1s0x3a4a0953a362f945:0x11aa0de9063844ab!8m2!3d16.2333746!4d80.5509079!16s%2Fm%2F05q97tw?entry=ttu"
          target="_blank"
        >
          <div
            class="flex flex-col justify-center items-center md:w-[250px] gap-3"
          >
            <img src="@/assets/lpmba/icon-map.svg" alt="" />
            <div class="min-h-[90px]">
              <p class="mb-0">
                Vignan's Foundation for Science, Technology and Research (Deemed
                to be University), Vadlamudi, Guntur-522213
              </p>
            </div>
          </div></a
        >
      </div>
    </div>
    <div class="md:hidden">
      <div class="w-full bg-[#c82228]">
        <div class="flex justify-center items-center py-3 px-20">
          <a href="tel:+918877661755">
            <div class="flex flex-col justify-center items-center gap-3">
              <img src="@/assets/home/sticky/call-icon.webp" />
              <p class="text-white font-semibold text-[11px]">CALL NOW</p>
            </div>
          </a>
          <!-- <div class="border border-r-2 border-white h-14"></div> -->
          <!-- <a
            href="https://api.whatsapp.com/send?phone=918877661755&amp;text=Hi!%20I%27d%20like%20to%20know%20more%20details."
            target="_blank"
            tabindex="-1"
            ><div class="flex flex-col justify-center items-center gap-3">
              <img
                src="@/assets/home/sticky/icon-whatsapp-white.svg"
                class="w-7"
              />
              <p class="text-white font-semibold text-[11px]">WHATSAPP</p>
            </div></a
          >-->
        </div>
      </div>
    </div>

    <div class="enquire-s md:block" id="enqure">
      <a href="#">Enquire Now</a>
    </div>
  </div>
</template>

<script>
import HiringComp from "@/components/HiringComp.vue";
// import EnquireViewDG from "@/components/LPEnquire/EnquireFormDG.vue";
export default {
  name: "LP_Online",
  components: {
    // EnquireViewDG,
    HiringComp,
  },

  data() {
    return {
      activeIndex: null,
      start: 0,
      end: 7,
      countdown: "Loading...", // Initial value before the timer starts
      OnlineFaq: [
        // Your FAQ data goes here
        {
          id: 1,
          title: "What's the benefit of the ZERO cost EMI option?",
          content:
            "A Zero Cost EMI option includes zero processing fee, zero interest, and zero initial payment.",
        },
        {
          id: 2,
          title:
            "Why are online degree programs a better option than regular, correspondence, part-time, open, or distance programs?",
          content:
            "1. Flexible learning schedules make it easy to learn while you earn<br />2. Affordable fee when compared to a regular program<br />3. Our Online degree is equivalent to an on-campus one",
        },
        {
          id: 3,
          title:
            "Is this an Open and Distance Learning (ODL) or Correspondence Program?",
          content:
            "This is an online learning mode program. In an online learning mode, there are regular live online sessions on weekends, online proctored exams, learner engagement activities and support, and no territorial jurisdiction.",
        },
        {
          id: 4,
          title: "Will the recording of live sessions be available?",
          content:
            "Yes, the online sessions will be recorded and available on the LMS.",
        },
        {
          id: 5,
          title: "Will study material be provided?",
          content:
            "Yes, the learner is provided with access to an exhaustive and customized E-Learning Material (ELM) on the Learning Management System (LMS).",
        },
        {
          id: 6,
          title:
            "Is the Degree acquired through an online mode equivalent to an on-campus mode of learning?",
          content:
            "Yes, the below notification UGC endorses this.<br/><br/>UGC Notification No. F. 1-1/2020(DEB-I) dated 4th September 2020 – Part VI, Page 79, Point. 22 - Equivalence of qualification acquired through Conventional or Online Learning:<br/><br/>Degrees at undergraduate and postgraduate level in conformity with UGC notification on Specification of Degrees, 2014 and postgraduate diplomas awarded through Online mode by Higher Educational Institutions, recognized by the Commission under these regulations, shall be treated as equivalent to the corresponding awards of the Degrees at undergraduate and postgraduate level and postgraduate diplomas offered through conventional mode.</br><br/>Our Online Degree Programs are recognized by UGC and is equivalent to an on-campus degree.",
        },
        // Add more FAQ items as needed
      ],
    };
  },

  mounted() {
    const firstTabId = document.getElementsByClassName("tabdetail")[0].id;
    this.programdetail(firstTabId);

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = this.countDownDate - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = `${hours}h ${minutes}m ${seconds}s`;

      if (distance < 0) {
        this.countDownDate = this.getNeverEndingDate();
      }
    };

    this.countDownDate = this.getNeverEndingDate();
    updateCountdown();
    setInterval(updateCountdown, 1000);
  },

  methods: {
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null; // Collapse if the clicked FAQ is already open
      } else {
        this.activeIndex = index; // Expand the clicked FAQ
      }
    },

    programdetail(tabId) {
      const tabdetails = document.getElementsByClassName("tabdetail");
      const tabs = document.getElementsByClassName("tab");

      for (var i = 0; i < tabdetails.length; i++) {
        if (tabdetails[i].id === tabId) {
          tabdetails[i].style.display = "block";
          tabs[i].classList.add("active");
        } else {
          tabdetails[i].style.display = "none";
          tabs[i].classList.remove("active");
        }
      }
    },
    getNeverEndingDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.setHours(0, 0, 0, 0);
    },
  },
};
</script>

<style scoped>
.tabdetail {
  display: none;
}

.tab {
  width: 300px;
  padding-bottom: 10px;
}
.active {
  border-bottom: 5px solid #d02829;
}
.tab:hover {
  border-bottom: 5px solid #d02829;
  animation-name: fadeInUp;
  transition: all 0.3s;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 767.98px) {
  .question {
    margin-bottom: 8px;
    width: 83%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .faq-qus-arrow {
    position: absolute;
    float: right;
    top: 10px;
    right: -30px !important;
    width: 30px;
    height: 30px;
    border: 1px solid #c9c9c9;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    margin-right: -25px;
  }
}
.question {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.faq-qus-arrow {
  position: absolute;
  float: right;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  margin-right: -25px;
}

.faq-qus-arrow:after {
  content: "";
  border: solid #c9c9c9;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 2px);
  transition: all 0.2s ease;
}

.enquire-s {
  height: 0px;
  width: 65px;
  position: fixed;
  right: 0;
  top: 55%;
  z-index: 1000;
  transform: rotate(-90deg);
  color: black;
  background-color: #d02829;
}
.enquire-s a {
  display: block;
  background: #d02829;
  height: 30px;
  width: 140px;
  border-radius: 6px 6px 0 0;
  padding: 8px 16px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
  border-bottom: solid 1px #333;
  border-left: solid 1px #333;
  border-right: solid 1px #fff;
}
.methodology-box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.banner-feature-box {
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  margin: 0 5px;
  border-radius: 5px;
  line-height: 18px;
  margin-bottom: 10px;
  font-size: 14px;
}

.course-features-box {
  min-width: 22%;
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  line-height: 18px;
  text-align: left;
  font-size: 14px;
}
.red-dot-list li::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 18px;
  left: -40px;
  background-color: #d02829;
  margin-left: 10px;
}
.boxshadow {
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  margin: 0 5px;
  padding: 10px;
  border-radius: 5px;
  line-height: 18px;
  margin-bottom: 30px;
}
</style>
