<template lang="">
    <div>
      <section class="bg-[#f7f9fb]">
        <!-- FAQ -->
        <div id="faq-content" class="px-4 py-10">
          <div class="max-w-screen-2xl mx-auto">
            <div class="flex flex-wrap justify-center text-center">
              <h2 class="text-lg md:text-2xl font-bold">
                FAQ<span class="text-primary">s</span>
              </h2>
            </div>
            <div
              class="accordion mt-10 bg-trasparent max-w-6xl mx-auto"
              id="faqs"
            >
              <div
                v-for="(el, index) in this.OnlineFaQ.slice(this.start, this.end)"
                :key="el"
                class="accordion-item border-t-0 border-x-0 text-left"
              >
                <h3 class="accordion-header mb-0">
                  <button
                    @click="faqCollapse"
                    class="accordion-button font-semibold relative flex items-center justify-between w-full py-4 px-5 text-sm text-[#004271] hover:text-[#0a58ca] text-left border-0 rounded-none transition focus:outline-none"
                    type="button"
                    :data-target="'#text-' + index"
                  >
                    {{ el.title }}
                    <font-awesome-icon
                      :id="'text-' + index + '-img'"
                      icon="fa-solid fa-circle-chevron-down"
                      alt="down-arrow"
                      class="down-arrow text-[#e01b24] text-xl"
                    />
                    <!-- <img
                      src="../assets/home/Untitled.png"
                      alt="down-arrow"
                      class="down-arrow"
                    />  -->
                  </button>
                </h3>
                <div
                  :id="'text-' + index"
                  class=" accordion-collapse border-0 hidden  text-sm  text-black"
                >
                  <div class="accordion-body pb-4 px-5 flex text-[28px] flex-col gap-3">
                    <!-- <p v-html="el.answer_text"></p> -->
                    <div class="font-montserrat text-[14px]"  v-html="el.content"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <button class="font-medium">Load more >></button> -->
            <button
              v-if="this.OnlineFaQ.length >= this.end"
              @click="this.end = this.end + 4"
              class="bg-[#CA2526] text-white font-semibold px-8 py-5 rounded-[50px] mt-5"
            >
              View More
            </button>
            <button
              v-if="this.OnlineFaQ.length <= this.end"
              @click="this.end = 5"
              class="bg-[#CA2526] text-white font-semibold px-8 py-5 rounded-[50px] mt-5"
            >
              View Less
            </button>
          </div>
        </div>
      </section>
    </div>
  </template>
  <script>
  import $ from "jquery";
  import axios from "axios";
  export default {
    name: "ProFaqview",
  
    data() {
      return {
        start: 0,
        end: 5,
        OnlineFaQ:[]
      };
    },


  created() {
    this.getForumPost()
  },
    methods: {
      faqCollapse(e) {
        var accordionItem = $(e.target).parents(".accordion-item");
        var dataTarget = accordionItem
          .find(".accordion-button")
          .attr("data-target");
  
        var icon = accordionItem.find(".accordion-button .down-arrow");
  
        if (icon.hasClass('rotate-180')) {
          icon.removeClass("rotate-180");
          $(dataTarget).hide("slow");
        } else {
          $(".accordion-item .accordion-button .down-arrow").removeClass('rotate-180');
          $(".accordion-item .accordion-collapse").hide("slow");
          icon.addClass("rotate-180");
          $(dataTarget).show("slow");
        }
      },
  
      getForumPost() {
        // let url = `${process.env.VUE_APP_API}/faq/faq-list/?category__id=2`;
        axios.get(`${process.env.VUE_APP_API}/faq/faq-list/?category__id=2`)
        .then((response) => {
          // console.log(response);
          if (response.data) {
            this.OnlineFaQ = response.data.data;
            console.log(this.OnlineFaQ)
          }
        })
      },
    },
  };
  </script>
  <style lang="">
  
  </style>