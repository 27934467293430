<template lang="">
  <div>
    <!-- Modal -->
    <div class="text-[13px] bg-white" id="enqu">
      <h1 class="text-black font-bold text-2xl mt-4 pt-5 mb-3">Enquire Now</h1>
      <div>
        <form
          class="space-y-3 p-4 font-normal"
          @submit="$emit('closeWidget')"
          @submit.prevent="enquireSubmit"
        >
          <input
            type="text"
            placeholder="Enter Name *"
            id="name"
            class="p-2 rounded-md w-full border border-[#ccc]"
            required
          />
          <input
            type="email"
            placeholder="Enter Email Address *"
            id="email"
            class="p-2 rounded-md w-full border border-[#ccc]"
            required
          />
          <input
            type="tel"
            placeholder="Enter Mobile Number *"
            id="phone"
            maxlength="10"
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
            class="p-2 rounded-md w-full border border-[#ccc]"
            required
          />
          <div class="flex flex-row gap-4">
            <select
              id="qualification"
              class="p-2 rounded-md w-full border border-[#ccc] bg-white"
              required
            >
              <option value="" disabled selected>
                Select Your Highest Qualification*
              </option>
              <option value="MASTERS">12th/PUC/Equivalent</option>
              <option value="MASTERS">B.A</option>
              <option value="MASTERS">B.Arch</option>
              <option value="MASTERS">B.Com</option>
              <option value="MASTERS">B.Pharm</option>
              <option value="MASTERS">B.Sc</option>
              <option value="MASTERS">BBA</option>
              <option value="MASTERS">BCA</option>
              <option value="MASTERS">BE/B.Tech</option>
              <option value="MASTERS">BHMS</option>
              <option value="MASTERS">M.A</option>
              <option value="MASTERS">M.Arch</option>
              <option value="MASTERS">M.Com</option>
              <option value="MASTERS">M.Sc</option>
              <option value="MASTERS">MBA</option>
              <option value="MASTERS">MBBS</option>
              <option value="MASTERS">MCA</option>
              <option value="MASTERS">MS/M.Tech</option>
              <option value="MASTERS">ITI</option>
              <option value="MASTERS">Diploma</option>
              <!-- Add more program options as needed -->
            </select>
          </div>
          <div class="flex gap-4">
            <select
              name="state"
              id
              @change="this.selectDistricts"
              class="p-2 rounded-md w-full border border-[#ccc] bg-white"
              required
            >
              <option value="">Select State*</option>
              <option v-for="i in this.state_list" :key="i" :value="i.state">
                {{ i.state }}
              </option>
            </select>
            <select
              id="city"
              aria-placeholder="Select State"
              class="p-2 rounded-md w-full border border-[#ccc] bg-white"
              required
            >
              <option value="">Select city*</option>
              <option
                v-for="i in this.selected_city.districts"
                :key="i"
                :value="i"
              >
                {{ i }}
              </option>
            </select>
          </div>
          <div class="flex flex-row gap-4">
            <select
              id="college"
              v-model="selectedCollege"
              class="p-2 rounded-md w-full border border-[#ccc] bg-white"
              required
            >
              <option value="" disabled selected>
                Select Your Current College*
              </option>
              <option
                v-for="college in colleges"
                :value="college.id"
                :key="college.id"
              >
                {{ college.name }}
              </option>
            </select>
          </div>
          <div class="flex flex-row gap-4">
            <select
              id="program"
              class="p-2 rounded-md w-full border border-[#ccc] bg-white"
              required
            >
              <option value="" disabled selected>Select Program*</option>
              <option value="MASTERS">MBA</option>
              <option value="MASTERS">MCA</option>
              <!-- Add more program options as needed -->
            </select>

            <!-- <select
              id="elective"
              v-model="selectedElective"
              class="p-2 rounded-md w-full border border-[#ccc] bg-white"
            >
              <option value="" disabled selected>Elective*</option>
              <option
                v-for="option in electiveOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.label }}
              </option>
            </select> -->
          </div>
          <div class="flex gap-4 text-[11px] items-start text-left text-black">
            <input type="checkbox" checked />
            <span class="text-justify"
              >I authorize Vignan Online and its associates to contact me with
              updates & notifications via email, SMS, WhatsApp, and voice call.
              This consent will override any registration for DNC/NDNC.</span
            >
          </div>
          <button
            type="submit"
            class="w-full text-white font-semibold bg-[#d02829] hover:bg-[#ca9216] rounded-lg px-4 py-2 text-center"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "EnquireViewOR",

  props: ["widget_prop_id"],

  data: () => ({
    selectedProgram: "",
    selectedElective: "",
    electiveOptions: [],

    utmSource: "",
    utmSourceCampaign: "",
    utmSourceMedium: "",
    utmAdgroupid: "",
    utmCampaignid: "",
    utmCreativeid: "",
    utmDevice: "",
    utmKeyword: "",
    utmMatchtype: "",
    utmNetwork: "",
    utmPlacement: "",
    utmGclid: "",
    selectedCollege: "", // To store the selected value
    colleges: [], // To store the list of colleges

    widget_id: "",
    selectedState: "TamilNadu",
    activeTab: "tab-1",
    selected_city: [],
    state_list: [
      {
        state: "ANDAMAN & NICOBAR ISLANDS",
        districts: [
          "Carnicobar",
          "Nancorie",
          "Nancowrie",
          "Diglipur",
          "Mayabander",
          "Port Blair",
          "Rangat",
          "Ferrargunj",
          "Hut Bay",
          "Port Blair",
          "Portblair",
        ],
      },
      {
        state: "Andhra Pradesh",
        districts: [
          "Anantapur",
          "Chittoor",
          "East Godavari",
          "Guntur",
          "Krishna",
          "Kurnool",
          "Nellore",
          "Prakasam",
          "Srikakulam",
          "Visakhapatnam",
          "Vizianagaram",
          "West Godavari",
          "YSR Kadapa",
        ],
      },
      {
        state: "Arunachal Pradesh",
        districts: [
          "Tawang",
          "West Kameng",
          "East Kameng",
          "Papum Pare",
          "Kurung Kumey",
          "Kra Daadi",
          "Lower Subansiri",
          "Upper Subansiri",
          "West Siang",
          "East Siang",
          "Siang",
          "Upper Siang",
          "Lower Siang",
          "Lower Dibang Valley",
          "Dibang Valley",
          "Anjaw",
          "Lohit",
          "Namsai",
          "Changlang",
          "Tirap",
          "Longding",
        ],
      },
      {
        state: "Assam",
        districts: [
          "Baksa",
          "Barpeta",
          "Biswanath",
          "Bongaigaon",
          "Cachar",
          "Charaideo",
          "Chirang",
          "Darrang",
          "Dhemaji",
          "Dhubri",
          "Dibrugarh",
          "Goalpara",
          "Golaghat",
          "Hailakandi",
          "Hojai",
          "Jorhat",
          "Kamrup Metropolitan",
          "Kamrup",
          "Karbi Anglong",
          "Karimganj",
          "Kokrajhar",
          "Lakhimpur",
          "Majuli",
          "Morigaon",
          "Nagaon",
          "Nalbari",
          "Dima Hasao",
          "Sivasagar",
          "Sonitpur",
          "South Salmara-Mankachar",
          "Tinsukia",
          "Udalguri",
          "West Karbi Anglong",
        ],
      },
      {
        state: "Bihar",
        districts: [
          "Araria",
          "Arwal",
          "Aurangabad",
          "Banka",
          "Begusarai",
          "Bhagalpur",
          "Bhojpur",
          "Buxar",
          "Darbhanga",
          "East Champaran (Motihari)",
          "Gaya",
          "Gopalganj",
          "Jamui",
          "Jehanabad",
          "Kaimur (Bhabua)",
          "Katihar",
          "Khagaria",
          "Kishanganj",
          "Lakhisarai",
          "Madhepura",
          "Madhubani",
          "Munger (Monghyr)",
          "Muzaffarpur",
          "Nalanda",
          "Nawada",
          "Patna",
          "Purnia (Purnea)",
          "Rohtas",
          "Saharsa",
          "Samastipur",
          "Saran",
          "Sheikhpura",
          "Sheohar",
          "Sitamarhi",
          "Siwan",
          "Supaul",
          "Vaishali",
          "West Champaran",
        ],
      },
      {
        state: "Chandigarh (UT)",
        districts: ["Chandigarh"],
      },
      {
        state: "Chhattisgarh",
        districts: [
          "Balod",
          "Baloda Bazar",
          "Balrampur",
          "Bastar",
          "Bemetara",
          "Bijapur",
          "Bilaspur",
          "Dantewada (South Bastar)",
          "Dhamtari",
          "Durg",
          "Gariyaband",
          "Janjgir-Champa",
          "Jashpur",
          "Kabirdham (Kawardha)",
          "Kanker (North Bastar)",
          "Kondagaon",
          "Korba",
          "Korea (Koriya)",
          "Mahasamund",
          "Mungeli",
          "Narayanpur",
          "Raigarh",
          "Raipur",
          "Rajnandgaon",
          "Sukma",
          "Surajpur  ",
          "Surguja",
        ],
      },
      {
        state: "Dadra and Nagar Haveli (UT)",
        districts: ["Dadra & Nagar Haveli"],
      },
      {
        state: "Daman and Diu (UT)",
        districts: ["Daman", "Diu"],
      },
      {
        state: "Delhi (NCT)",
        districts: [
          "Central Delhi",
          "East Delhi",
          "New Delhi",
          "North Delhi",
          "North East  Delhi",
          "North West  Delhi",
          "Shahdara",
          "South Delhi",
          "South East Delhi",
          "South West  Delhi",
          "West Delhi",
        ],
      },
      {
        state: "Goa",
        districts: ["North Goa", "South Goa"],
      },
      {
        state: "Gujarat",
        districts: [
          "Ahmedabad",
          "Amreli",
          "Anand",
          "Aravalli",
          "Banaskantha (Palanpur)",
          "Bharuch",
          "Bhavnagar",
          "Botad",
          "Chhota Udepur",
          "Dahod",
          "Dangs (Ahwa)",
          "Devbhoomi Dwarka",
          "Gandhinagar",
          "Gir Somnath",
          "Jamnagar",
          "Junagadh",
          "Kachchh",
          "Kheda (Nadiad)",
          "Mahisagar",
          "Mehsana",
          "Morbi",
          "Narmada (Rajpipla)",
          "Navsari",
          "Panchmahal (Godhra)",
          "Patan",
          "Porbandar",
          "Rajkot",
          "Sabarkantha (Himmatnagar)",
          "Surat",
          "Surendranagar",
          "Tapi (Vyara)",
          "Vadodara",
          "Valsad",
        ],
      },
      {
        state: "Haryana",
        districts: [
          "Ambala",
          "Bhiwani",
          "Charkhi Dadri",
          "Faridabad",
          "Fatehabad",
          "Gurgaon",
          "Hisar",
          "Jhajjar",
          "Jind",
          "Kaithal",
          "Karnal",
          "Kurukshetra",
          "Mahendragarh",
          "Mewat",
          "Palwal",
          "Panchkula",
          "Panipat",
          "Rewari",
          "Rohtak",
          "Sirsa",
          "Sonipat",
          "Yamunanagar",
        ],
      },
      {
        state: "Himachal Pradesh",
        districts: [
          "Bilaspur",
          "Chamba",
          "Hamirpur",
          "Kangra",
          "Kinnaur",
          "Kullu",
          "Lahaul &amp; Spiti",
          "Mandi",
          "Shimla",
          "Sirmaur (Sirmour)",
          "Solan",
          "Una",
        ],
      },
      {
        state: "Jammu and Kashmir",
        districts: [
          "Anantnag",
          "Bandipore",
          "Baramulla",
          "Budgam",
          "Doda",
          "Ganderbal",
          "Jammu",
          "Kargil",
          "Kathua",
          "Kishtwar",
          "Kulgam",
          "Kupwara",
          "Leh",
          "Poonch",
          "Pulwama",
          "Rajouri",
          "Ramban",
          "Reasi",
          "Samba",
          "Shopian",
          "Srinagar",
          "Udhampur",
        ],
      },
      {
        state: "Jharkhand",
        districts: [
          "Bokaro",
          "Chatra",
          "Deoghar",
          "Dhanbad",
          "Dumka",
          "East Singhbhum",
          "Garhwa",
          "Giridih",
          "Godda",
          "Gumla",
          "Hazaribag",
          "Jamtara",
          "Khunti",
          "Koderma",
          "Latehar",
          "Lohardaga",
          "Pakur",
          "Palamu",
          "Ramgarh",
          "Ranchi",
          "Sahibganj",
          "Seraikela-Kharsawan",
          "Simdega",
          "West Singhbhum",
        ],
      },
      {
        state: "Karnataka",
        districts: [
          "Bagalkot",
          "Ballari (Bellary)",
          "Belagavi (Belgaum)",
          "Bengaluru (Bangalore) Rural",
          "Bengaluru (Bangalore) Urban",
          "Bidar",
          "Chamarajanagar",
          "Chikballapur",
          "Chikkamagaluru (Chikmagalur)",
          "Chitradurga",
          "Dakshina Kannada",
          "Davangere",
          "Dharwad",
          "Gadag",
          "Hassan",
          "Haveri",
          "Kalaburagi (Gulbarga)",
          "Kodagu",
          "Kolar",
          "Koppal",
          "Mandya",
          "Mysuru (Mysore)",
          "Raichur",
          "Ramanagara",
          "Shivamogga (Shimoga)",
          "Tumakuru (Tumkur)",
          "Udupi",
          "Uttara Kannada (Karwar)",
          "Vijayapura (Bijapur)",
          "Yadgir",
        ],
      },
      {
        state: "Kerala",
        districts: [
          "Alappuzha",
          "Ernakulam",
          "Idukki",
          "Kannur",
          "Kasaragod",
          "Kollam",
          "Kottayam",
          "Kozhikode",
          "Malappuram",
          "Palakkad",
          "Pathanamthitta",
          "Thiruvananthapuram",
          "Thrissur",
          "Wayanad",
        ],
      },
      {
        state: "Lakshadweep (UT)",
        districts: [
          "Agatti",
          "Amini",
          "Androth",
          "Bithra",
          "Chethlath",
          "Kavaratti",
          "Kadmath",
          "Kalpeni",
          "Kilthan",
          "Minicoy",
        ],
      },
      {
        state: "Madhya Pradesh",
        districts: [
          "Agar Malwa",
          "Alirajpur",
          "Anuppur",
          "Ashoknagar",
          "Balaghat",
          "Barwani",
          "Betul",
          "Bhind",
          "Bhopal",
          "Burhanpur",
          "Chhatarpur",
          "Chhindwara",
          "Damoh",
          "Datia",
          "Dewas",
          "Dhar",
          "Dindori",
          "Guna",
          "Gwalior",
          "Harda",
          "Hoshangabad",
          "Indore",
          "Jabalpur",
          "Jhabua",
          "Katni",
          "Khandwa",
          "Khargone",
          "Mandla",
          "Mandsaur",
          "Morena",
          "Narsinghpur",
          "Neemuch",
          "Panna",
          "Raisen",
          "Rajgarh",
          "Ratlam",
          "Rewa",
          "Sagar",
          "Satna",
          "Sehore",
          "Seoni",
          "Shahdol",
          "Shajapur",
          "Sheopur",
          "Shivpuri",
          "Sidhi",
          "Singrauli",
          "Tikamgarh",
          "Ujjain",
          "Umaria",
          "Vidisha",
        ],
      },
      {
        state: "Maharashtra",
        districts: [
          "Ahmednagar",
          "Akola",
          "Amravati",
          "Aurangabad",
          "Beed",
          "Bhandara",
          "Buldhana",
          "Chandrapur",
          "Dhule",
          "Gadchiroli",
          "Gondia",
          "Hingoli",
          "Jalgaon",
          "Jalna",
          "Kolhapur",
          "Latur",
          "Mumbai City",
          "Mumbai Suburban",
          "Nagpur",
          "Nanded",
          "Nandurbar",
          "Nashik",
          "Osmanabad",
          "Palghar",
          "Parbhani",
          "Pune",
          "Raigad",
          "Ratnagiri",
          "Sangli",
          "Satara",
          "Sindhudurg",
          "Solapur",
          "Thane",
          "Wardha",
          "Washim",
          "Yavatmal",
        ],
      },
      {
        state: "Manipur",
        districts: [
          "Bishnupur",
          "Chandel",
          "Churachandpur",
          "Imphal East",
          "Imphal West",
          "Jiribam",
          "Kakching",
          "Kamjong",
          "Kangpokpi",
          "Noney",
          "Pherzawl",
          "Senapati",
          "Tamenglong",
          "Tengnoupal",
          "Thoubal",
          "Ukhrul",
        ],
      },
      {
        state: "Meghalaya",
        districts: [
          "East Garo Hills",
          "East Jaintia Hills",
          "East Khasi Hills",
          "North Garo Hills",
          "Ri Bhoi",
          "South Garo Hills",
          "South West Garo Hills ",
          "South West Khasi Hills",
          "West Garo Hills",
          "West Jaintia Hills",
          "West Khasi Hills",
        ],
      },
      {
        state: "Mizoram",
        districts: [
          "Aizawl",
          "Champhai",
          "Kolasib",
          "Lawngtlai",
          "Lunglei",
          "Mamit",
          "Saiha",
          "Serchhip",
        ],
      },
      {
        state: "Nagaland",
        districts: [
          "Dimapur",
          "Kiphire",
          "Kohima",
          "Longleng",
          "Mokokchung",
          "Mon",
          "Peren",
          "Phek",
          "Tuensang",
          "Wokha",
          "Zunheboto",
        ],
      },
      {
        state: "Odisha",
        districts: [
          "Angul",
          "Balangir",
          "Balasore",
          "Bargarh",
          "Bhadrak",
          "Boudh",
          "Cuttack",
          "Deogarh",
          "Dhenkanal",
          "Gajapati",
          "Ganjam",
          "Jagatsinghapur",
          "Jajpur",
          "Jharsuguda",
          "Kalahandi",
          "Kandhamal",
          "Kendrapara",
          "Kendujhar (Keonjhar)",
          "Khordha",
          "Koraput",
          "Malkangiri",
          "Mayurbhanj",
          "Nabarangpur",
          "Nayagarh",
          "Nuapada",
          "Puri",
          "Rayagada",
          "Sambalpur",
          "Sonepur",
          "Sundargarh",
        ],
      },
      {
        state: "Puducherry (UT)",
        districts: ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
      },
      {
        state: "Punjab",
        districts: [
          "Amritsar",
          "Barnala",
          "Bathinda",
          "Faridkot",
          "Fatehgarh Sahib",
          "Fazilka",
          "Ferozepur",
          "Gurdaspur",
          "Hoshiarpur",
          "Jalandhar",
          "Kapurthala",
          "Ludhiana",
          "Mansa",
          "Moga",
          "Muktsar",
          "Nawanshahr (Shahid Bhagat Singh Nagar)",
          "Pathankot",
          "Patiala",
          "Rupnagar",
          "Sahibzada Ajit Singh Nagar (Mohali)",
          "Sangrur",
          "Tarn Taran",
        ],
      },
      {
        state: "Rajasthan",
        districts: [
          "Ajmer",
          "Alwar",
          "Banswara",
          "Baran",
          "Barmer",
          "Bharatpur",
          "Bhilwara",
          "Bikaner",
          "Bundi",
          "Chittorgarh",
          "Churu",
          "Dausa",
          "Dholpur",
          "Dungarpur",
          "Hanumangarh",
          "Jaipur",
          "Jaisalmer",
          "Jalore",
          "Jhalawar",
          "Jhunjhunu",
          "Jodhpur",
          "Karauli",
          "Kota",
          "Nagaur",
          "Pali",
          "Pratapgarh",
          "Rajsamand",
          "Sawai Madhopur",
          "Sikar",
          "Sirohi",
          "Sri Ganganagar",
          "Tonk",
          "Udaipur",
        ],
      },
      {
        state: "Sikkim",
        districts: [
          "East Sikkim",
          "North Sikkim",
          "South Sikkim",
          "West Sikkim",
        ],
      },
      {
        state: "Tamil Nadu",
        districts: [
          "Ariyalur",
          "Chennai",
          "Coimbatore",
          "Cuddalore",
          "Dharmapuri",
          "Dindigul",
          "Erode",
          "Kanchipuram",
          "Kanyakumari",
          "Karur",
          "Krishnagiri",
          "Madurai",
          "Nagapattinam",
          "Namakkal",
          "Nilgiris",
          "Perambalur",
          "Pudukkottai",
          "Ramanathapuram",
          "Salem",
          "Sivaganga",
          "Thanjavur",
          "Theni",
          "Thoothukudi (Tuticorin)",
          "Tiruchirappalli",
          "Tirunelveli",
          "Tiruppur",
          "Tiruvallur",
          "Tiruvannamalai",
          "Tiruvarur",
          "Vellore",
          "Viluppuram",
          "Virudhunagar",
        ],
      },
      {
        state: "Telangana",
        districts: [
          "Adilabad",
          "Bhadradri Kothagudem",
          "Hyderabad",
          "Jagtial",
          "Jangaon",
          "Jayashankar Bhoopalpally",
          "Jogulamba Gadwal",
          "Kamareddy",
          "Karimnagar",
          "Khammam",
          "Komaram Bheem Asifabad",
          "Mahabubabad",
          "Mahabubnagar",
          "Mancherial",
          "Medak",
          "Medchal",
          "Nagarkurnool",
          "Nalgonda",
          "Nirmal",
          "Nizamabad",
          "Peddapalli",
          "Rajanna Sircilla",
          "Rangareddy",
          "Sangareddy",
          "Siddipet",
          "Suryapet",
          "Vikarabad",
          "Wanaparthy",
          "Warangal (Rural)",
          "Warangal (Urban)",
          "Yadadri Bhuvanagiri",
        ],
      },
      {
        state: "Tripura",
        districts: [
          "Dhalai",
          "Gomati",
          "Khowai",
          "North Tripura",
          "Sepahijala",
          "South Tripura",
          "Unakoti",
          "West Tripura",
        ],
      },
      {
        state: "Uttarakhand",
        districts: [
          "Almora",
          "Bageshwar",
          "Chamoli",
          "Champawat",
          "Dehradun",
          "Haridwar",
          "Nainital",
          "Pauri Garhwal",
          "Pithoragarh",
          "Rudraprayag",
          "Tehri Garhwal",
          "Udham Singh Nagar",
          "Uttarkashi",
        ],
      },
      {
        state: "Uttar Pradesh",
        districts: [
          "Agra",
          "Aligarh",
          "Allahabad",
          "Ambedkar Nagar",
          "Amethi (Chatrapati Sahuji Mahraj Nagar)",
          "Amroha (J.P. Nagar)",
          "Auraiya",
          "Azamgarh",
          "Baghpat",
          "Bahraich",
          "Ballia",
          "Balrampur",
          "Banda",
          "Barabanki",
          "Bareilly",
          "Basti",
          "Bhadohi",
          "Bijnor",
          "Budaun",
          "Bulandshahr",
          "Chandauli",
          "Chitrakoot",
          "Deoria",
          "Etah",
          "Etawah",
          "Faizabad",
          "Farrukhabad",
          "Fatehpur",
          "Firozabad",
          "Gautam Buddha Nagar",
          "Ghaziabad",
          "Ghazipur",
          "Gonda",
          "Gorakhpur",
          "Hamirpur",
          "Hapur (Panchsheel Nagar)",
          "Hardoi",
          "Hathras",
          "Jalaun",
          "Jaunpur",
          "Jhansi",
          "Kannauj",
          "Kanpur Dehat",
          "Kanpur Nagar",
          "Kanshiram Nagar (Kasganj)",
          "Kaushambi",
          "Kushinagar (Padrauna)",
          "Lakhimpur - Kheri",
          "Lalitpur",
          "Lucknow",
          "Maharajganj",
          "Mahoba",
          "Mainpuri",
          "Mathura",
          "Mau",
          "Meerut",
          "Mirzapur",
          "Moradabad",
          "Muzaffarnagar",
          "Pilibhit",
          "Pratapgarh",
          "RaeBareli",
          "Rampur",
          "Saharanpur",
          "Sambhal (Bhim Nagar)",
          "Sant Kabir Nagar",
          "Shahjahanpur",
          "Shamali (Prabuddh Nagar)",
          "Shravasti",
          "Siddharth Nagar",
          "Sitapur",
          "Sonbhadra",
          "Sultanpur",
          "Unnao",
          "Varanasi",
        ],
      },
      {
        state: "West Bengal",
        districts: [
          "Alipurduar",
          "Bankura",
          "Birbhum",
          "Burdwan (Bardhaman)",
          "Cooch Behar",
          "Dakshin Dinajpur (South Dinajpur)",
          "Darjeeling",
          "Hooghly",
          "Howrah",
          "Jalpaiguri",
          "Kalimpong",
          "Kolkata",
          "Malda",
          "Murshidabad",
          "Nadia",
          "North 24 Parganas",
          "Paschim Medinipur (West Medinipur)",
          "Purba Medinipur (East Medinipur)",
          "Purulia",
          "South 24 Parganas",
          "Uttar Dinajpur (North Dinajpur)",
        ],
      },
    ],
  }),

  mounted() {
    // Simulating fetching data from an API or any source
    this.fetchColleges();
  },

  created() {
    this.widget_id = this.widget_prop_id;
    this.utmSource = this.$route.query.utm_source || "Organic Search";
    this.utmSourceCampaign = this.$route.query.utm_campaign || "website";
    this.utmSourceMedium = this.$route.query.utm_medium || "";
    this.utmAdgroupid = this.$route.query.utm_adgroupid || "";
    this.utmCampaignid = this.$route.query.utm_campaignid || "";
    this.utmCreativeid = this.$route.query.utm_creativeid || "";
    this.utmDevice = this.$route.query.utm_device || "";
    this.utmKeyword = this.$route.query.utm_keyword || "";
    this.utmMatchtype = this.$route.query.utm_matchtype || "";
    this.utmNetwork = this.$route.query.utm_network || "";
    this.utmPlacement = this.$route.query.utm_placement || "";
    this.Gclid = this.$route.query.gclid || "";
  },

  methods: {
    selectDistricts(el) {
      console.log(el.target.value);
      let select_state = el.target.value;
      this.state_list.forEach((el) => {
        if (el.state == select_state) {
          this.selected_city = el;
        }
      });
    },

    enquireSubmit(el) {
      let dataJson = [
        {
          Attribute: "FirstName",
          Value: el.target.elements.name.value,
        },
        {
          Attribute: "EmailAddress",
          Value: el.target.elements.email.value,
        },
        {
          Attribute: "Phone",
          Value: el.target.elements.phone.value,
        },
        {
          Attribute: "mx_State",
          Value: el.target.elements.state.value,
        },
        {
          Attribute: "mx_City",
          Value: el.target.elements.city.value,
        },
        {
          Attribute: "mx_Program",
          Value: el.target.elements.program.value,
        },
                {
          Attribute: "mx_Highest_Qualification",
          Value: el.target.elements.qualification.value,
        },
                {
          Attribute: "mx_Current_College",
          Value: el.target.elements.college.value,
        },
        {
          Attribute: "mx_utm_Source",
          Value: "Outreach",
        },
        {
          Attribute: "SourceCampaign",
          Value: "July 2024",
        },
        {
          Attribute: "SourceMedium",
          Value: "LP",
        },
        {
          Attribute: "mx_utm_Adgroupid",
          Value: this.utmAdgroupid,
        },
        {
          Attribute: "mx_utm_Campaignid",
          Value: this.utmCampaignid,
        },
        {
          Attribute: "mx_utm_Creativeid",
          Value: this.utmCreativeid,
        },
        {
          Attribute: "mx_utm_Device",
          Value: this.utmDevice,
        },
        {
          Attribute: "mx_utm_Keyword",
          Value: this.utmKeyword,
        },
        {
          Attribute: "mx_utm_Matchtype",
          Value: this.utmMatchtype,
        },
        {
          Attribute: "mx_utm_Network",
          Value: this.utmNetwork,
        },
        {
          Attribute: "mx_utm_Placement",
          Value: this.utmPlacement,
        },
        {
          Attribute: "mx_Gclid",
          Value: this.Gclid,
        },
      ];
      console.log(dataJson);
      axios
        .post(
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r822806c3d4d4cb1c22e55fc36ca478bf&secretKey=95b5dad573299c9d675384a7e9afe0476d44e0bb",
          dataJson
        )
        .then((resp) => {
          console.log(resp);

          // Redirect to the "thank you" page
          window.location.href = "/lp/thankyou/online-degree";
        })
        .catch((error) => {
          // Handle any errors that occur during the POST request
          console.error("Error:", error);
        });
    },

    updateElectiveOptions() {
      // Define elective options based on the selected program
      if (this.selectedProgram === "MASTERS") {
        this.electiveOptions = [
          {
            value: "MBA",
            label: "MBA",
          },
          { value: "MCA", label: "MCA" },
        ];
      }
    },

    fetchColleges() {
      // Simulated data fetching (replace with your data-fetching mechanism)
      // Here, colleges array is populated with some sample data
      // Replace this with your actual data-fetching code
      setTimeout(() => {
        this.colleges = [
          { id: 1, name: "Sai Vidya Institute of Technology" },
          { id: 2, name: "Abhaya Group of Institutions" },
          { id: 3, name: "A.P.S. College Of Engineering" },
          { id: 4, name: "City Engineering College" },
          { id: 5, name: "Dayananda Sagar Academy of Technology and Management" },
          { id: 6, name: "T.John Group of Institutions" },
          { id: 7, name: "Brindavan College of Engineering" },
          { id: 8, name: "Atria Institute of Technology" },
          { id: 9, name: "Rajiv Gandhi Institute of Technology" },
          { id: 10, name: "Impact College of Engineering and Applied Sciences" },
          { id: 11, name: "HKBK College of Engineering" },
          { id: 12, name: "Garden City University" },
          { id: 13, name: "Cambridge Institute of Technology - CIT" },
          { id: 14, name: "SEA Group of Institution" },
          { id: 15, name: "Gopalan College of Engineering and Management" },
          { id: 16, name: "Sri Revana Siddeshwara Institute of Technology" },
          { id: 17, name: "Bangalore College Of Engineering And Technology" },
          { id: 18, name: "BTL Institute of Technology and Management" },
          { id: 19, name: "Bangalore Technological Institute" },
          { id: 20, name: "The Oxford College of Engineering" },
          { id: 21, name: "Sir M. Visvesvaraya Institute of Technology" },
          { id: 22, name: "Nitte Meenakshi Institute of Technology" },
          { id: 23, name: "BMS Institute of Technology and Management" },
          { id: 24, name: "Sri VenkateshwaraCollege of Engineering" },
          { id: 25, name: "Dr. Ambedkar Institute Of Technology" },
          { id: 26, name: "K.S Institute of Technology" },
          { id: 27, name: "K S School of Engineering and Management" },
          { id: 28, name: "City Engineering College" },
          { id: 29, name: "Shakuntala Devi International Institute of Management Sciences and PreUniversity" },
          { id: 30, name: "The Oxford College of Science" },
          { id: 31, name: "Sri Sairam College of Engineering Bangalore" },
          { id: 32, name: "SVR College" },
          { id: 33, name: "BTM First Grade College" },
          { id: 34, name: "Govt. First Grade College" },
          { id: 35, name: "NMKRV College for Women" },
          { id: 36, name: "Rani Sarala Devi College" },
          { id: 37, name: "Agragami Degree College" },
          { id: 38, name: "Jnana Jyothi Degree College" },
          { id: 39, name: "Brite Educational Institute" },
          { id: 40, name: "Nagarjuna Degree College" },
          { id: 41, name: "Dr. NSAM First Grade College" },
          { id: 42, name: "BGS College of Engineering and Technology" },
          { id: 43, name: "East West Institute of Technology" },
          { id: 44, name: "Om Sai College" },
          { id: 45, name: "GT Educational Institutions" },
          { id: 46, name: "M. Basavaiah Institute Of Management Studies" },
          { id: 47, name: "Jyothy Institute of Commerce and Management" },
          { id: 48, name: "Imperial College" },
          { id: 49, name: "Kengeri First Grade College" },
          { id: 50, name: "NIMS Management Institutions" },
          { id: 51, name: "Seshadripuram College" },
          { id: 52, name: "Padmashree Institute of Management and Sciences" },
          { id: 53, name: "M C Varghese College Of Arts And Science" },
          { id: 54, name: "St. Thomas College (Autonomous) " },
          { id: 55, name: "KE College" },
          { id: 56, name: "MES Institutions" },
          { id: 57, name: "MLA Academy of Higher Learning " },
          { id: 58, name: "Bapu Institutions" },
          { id: 59, name: "Govt. First Grade College" },
          { id: 60, name: "ASC Silver Valley Degree College" },
          { id: 61, name: "Maharani Lakshmi Ammanni College for Women" },
          { id: 62, name: "Jesse College" },
          { id: 63, name: "Don Bosco College" },
          { id: 64, name: "Tapasya Degree College" },
          { id: 65, name: "Silicon City College of Management and Commerce" },
          { id: 66, name: "Indus Valley PU & Degree College" },
          { id: 67, name: "East Point College of Engineering And Technology" },
          { id: 68, name: "Gopalan College of Engineering and Management" },
          { id: 69, name: "Columbia College " },
          { id: 70, name: "Silicon City College" },
          { id: 71, name: "Hillside academy college" },
          { id: 72, name: "Suvarna Institution" },
          { id: 73, name: "Transcend Group of institutions" },
          { id: 74, name: "Loyola Degree College" },
          { id: 75, name: "Noble Institution" },
          { id: 76, name: "Sri Sairam Pu And Degree College" },
          { id: 77, name: "Sharada Vikas P.U. & Degree College" },
          { id: 78, name: "Govt. First Grade College" },
          { id: 79, name: "St. Dominic's School" },
          { id: 80, name: "Kristu Jyoti College of Management & Technology" },
          { id: 81, name: "St. George College of Management" },
          { id: 82, name: "KIET College Of Education" },
          { id: 83, name: "Kempegowda Institute of Management Studies" },
          { id: 84, name: "Sri Adichunchanagiri College of Arts and Commerce" },
          { id: 85, name: "Spurthy College of Science and Management Studies" },
          { id: 86, name: "V V Puram College Of Arts & Commerce" },
          { id: 87, name: "Gopalan College of Engineering and Management" },
          { id: 88, name: "Government First Grade College, Peenya" },

          // Add more colleges as needed
        ];
      }, 100); // Simulating an asynchronous data-fetching process
    },
  },
};
</script>
<style lang=""></style>
