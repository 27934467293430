<template lang="">
  <div>
    <!-- Modal -->
    <div class="text-[13px]">
      <h2 class="text-black font-bold text-2xl mt-5 mb-3">Enquire Now</h2>
      <div>
        <form
          class="space-y-3 p-4 font-normal relative"
          @submit="$emit('closeWidget')"
          @submit.prevent="enquireSubmit"
        >
          <input
            type="text"
            placeholder="Enter Name *"
            id="name"
            class="p-2 rounded-md w-full border border-[#ccc]"
            required
          />
          <input
            type="email"
            placeholder="Enter Email Address *"
            id="email"
            class="p-2 rounded-md w-full border border-[#ccc]"
            required
          />
          <div class="flex flex-col gap-2">
            <div class="flex items-center justify-center">
              <select
                v-model="selectedCountryCode"
                name="country_id"
                class="text-sm w-[83px] bg-white p-2 border border-[#ccc] rounded-md"
                required
              >
                <option
                  v-for="country in countryCodes"
                  :key="country.code"
                  :value="country.code"
                >
                  {{
                    selectedCountryCode === country.code
                      ? country.code
                      : country.name + " (" + country.code + ")"
                  }}
                </option>
              </select>
              <input
                type="tel"
                placeholder="Mobile Number *"
                id="phone"
                maxlength="10"
                @input="onPhoneNumberChange"
                v-model="phoneNumber"
                class="p-2 rounded-md w-full border border-[#ccc]"
                ref="phone"
                required
              />
              <button
                type="button"
                class="flex p-2 md:w-[130px] text-center justify-center w-[150px]"
                :class="{
                  'bg-[#d02829]': !otpSent,
                  'bg-green-500': otpSent,
                  'text-white': true,
                  'font-semibold': true,
                  'rounded-r-md': true,
                  'cursor-pointer': !otpSent && phoneNumber.length === 10,
                  'opacity-50 cursor-not-allowed':
                    otpSent || phoneNumber.length !== 10,
                }"
                @click="getOtp"
                :disabled="otpSent || phoneNumber.length !== 10"
              >
                {{ otpSent ? "OTP Sent" : "Get OTP" }}
              </button>
            </div>
            <span class="text-red-500 text-[9px] text-left pl-0 font-bold">{{
              message
            }}</span>
            <input
              type="text"
              placeholder="Enter OTP *"
              id="otp"
              maxlength="4"
              class="p-2 rounded-md w-full border mt-[-5px] border-[#ccc]"
              v-model="enteredOtp"
              :class="{ border: true, 'border-red-500': errorStatus }"
              ref="otp"
              pattern="[0-9]*"
              required
              @input="sanitizeInput"
            />
            <span
              v-if="errorStatus"
              class="text-red-500 text-[9px] text-left pl-0 font-bold"
            >
              Invalid OTP
            </span>
          </div>
          <div class="flex gap-4">
            <div
              v-if="selectedCountryCode === '+91-'"
              class="box-border text-[14px] leading-normal text-[#333] border border-gray-300 p-1 flex-1 focus-within:border-blue-500"
            >
              <select
                name="state"
                id="SelectState"
                class="form-control widget_input w-full bg-white border-none focus:outline-none focus:ring-0"
                v-model="selectedState"
                @change="onStateChange"
                required
              >
                <option value="" disabled selected>Select State *</option>
                <option
                  v-for="state in stateList"
                  :key="state.state"
                  :value="state.state"
                >
                  {{ state.state }}
                </option>
              </select>
            </div>
            <div
              v-if="selectedCountryCode === '+91-'"
              class="box-border text-[14px] leading-normal text-[#333] border border-gray-300 p-1 flex-1 focus-within:border-blue-500"
            >
              <select
                name="city"
                id="SelectCity"
                class="form-control widget_input w-full bg-white border-none focus:outline-none focus:ring-0"
                v-model="selectedCity"
                :disabled="!cities.length"
                required
              >
                <option value="" disabled selected>Select City *</option>
                <option v-for="city in cities" :key="city" :value="city">
                  {{ city }}
                </option>
              </select>
            </div>
          </div>
          <div class="flex flex-row">
            <select
              id="elective"
              @click="updateElectiveOptions"
              class="p-2 rounded-md w-full border border-[#ccc] bg-white"
              required
            >
              <option value="" disabled selected>Select Elective*</option>
              <option
                v-for="option in electiveOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="flex gap-4 text-[11px] items-start text-left text-black">
            <input type="checkbox" checked />
            <span class="text-justify"
              >I authorize Vignan Online and its associates to contact me with
              updates & notifications via email, SMS, WhatsApp, and voice call.
              This consent will override any registration for DNC/NDNC.</span
            >
          </div>
          <button
            type="submit"
            class="w-full text-white font-semibold bg-[#d02829] hover:bg-[#ca9216] rounded-lg px-4 py-2 text-center"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
import stateMixins from "@/mixins/stateMixins";
import countryCodes from "@/mixins/countryCodeMixins";
export default {
  name: "EnquireView",
  mixins:[stateMixins,countryCodes],
  props: ["widget_prop_id", "Electives"],

  data: () => ({
    selectedProgram: "MBA",
    // selectedElective: "mba",
    electiveOptions: [],
    enteredOtp: "",
    utmSource: "",
    utmSourceCampaign: "",
    utmSourceMedium: "",
    utmAdgroupid: "",
    utmCampaignid: "",
    utmCreativeid: "",
    utmDevice: "",
    utmKeyword: "",
    utmMatchtype: "",
    utmNetwork: "",
    utmPlacement: "",
    utmGclid: "",
    widget_id: "",
    selectedState: "",
    activeTab: "tab-1",
    selectedCity: [],
    cities: [],
    otpChecking: false,
    errorStatus: false,
    message: "",
    phoneNumber: "",
    otpSent: false,
    selectedCountryCode: "+91-",
  }),

  created() {
    console.log(this.$route.params.slug, "router");
    this.widget_id = this.widget_prop_id;
    this.utmSource = this.$route.query.utm_source || "Organic Search";
    this.utmSourceCampaign = this.$route.query.utm_campaign || "website";
    this.utmSourceMedium = this.$route.query.utm_medium || "";
    this.utmAdgroupid = this.$route.query.utm_adgroupid || "";
    this.utmCampaignid = this.$route.query.utm_campaignid || "";
    this.utmCreativeid = this.$route.query.utm_creativeid || "";
    this.utmDevice = this.$route.query.utm_device || "";
    this.utmKeyword = this.$route.query.utm_keyword || "";
    this.utmMatchtype = this.$route.query.utm_matchtype || "";
    this.utmNetwork = this.$route.query.utm_network || "";
    this.utmPlacement = this.$route.query.utm_placement || "";
    this.Gclid = this.$route.query.gclid || "";
  },

  methods: {
    onStateChange() {
      const state = this.stateList.find((s) => s.state === this.selectedState);
      this.cities = state ? state.districts : [];
      this.selectedCity = "";
    },
    sanitizeInput() {
      // Remove non-numeric characters
      this.enteredOtp = this.enteredOtp.replace(/[^0-9]/g, "");
    },

    onPhoneNumberChange() {
      // Reset the error message when the phone number changes
      this.message = "";

      // Reset otpSent when the phone number changes
      this.otpSent = false;

      // Remove non-numeric characters from the phone number
      this.phoneNumber = this.phoneNumber.replace(/\D/g, "");

      // Limit the phone number to a maximum length of 10 digits
      if (this.phoneNumber.length > 10) {
        this.phoneNumber = this.phoneNumber.slice(0, 10);
      }
    },

    handleKeyPress(event) {
      // Allow only numeric input and backspace
      if (
        !(event.charCode >= 48 && event.charCode <= 57) &&
        event.charCode !== 8
      ) {
        event.preventDefault();
      }
    },
    async getOtp() {
      try {
        // Call the API to get OTP
        let json_data = {
          phone: this.$refs.phone.value,
        };
        axios
          .post(`${process.env.VUE_APP_API}/mob_otp_send/`, json_data)
          .then((resp) => {
            console.log(resp);
          });
        this.otpSent = true;
      } catch (error) {
        // Handle error, for example, show an error message
        console.error("Error getting OTP:", error);
      }
    },

    async enquireSubmit(el) {
      try {
        // Call the API to verify OTP
        let json_data = {
          phone: this.$refs.phone.value,
          otp: this.enteredOtp,
        };
        axios
          .post(`${process.env.VUE_APP_API}/mob_otp_check/`, json_data)
          .then(async (resp) => {
            console.log(resp, "respresprespresp");
            if (resp.data.status == 200) {
              this.otpChecking = true;
              const dataJson = [
                {
                  Attribute: "FirstName",
                  Value: el.target.elements.name.value,
                },
                {
                  Attribute: "EmailAddress",
                  Value: el.target.elements.email.value,
                },
                {
                  Attribute: "Phone",
                  Value: this.selectedCountryCode + this.phoneNumber,
                },
                {
                  Attribute: "mx_State",
                  Value: el.target.elements.state.value,
                },
                {
                  Attribute: "mx_City",
                  Value: el.target.elements.city.value,
                },
                {
                  Attribute: "mx_Program",
                  Value: this.Electives,
                },
                {
                  Attribute: "mx_Elective",
                  Value: el.target.elements.elective.value,
                },
                {
                  Attribute: "mx_utm_Source",
                  Value: this.utmSource,
                },
                {
                  Attribute: "SourceCampaign",
                  Value: this.utmSourceCampaign,
                },
                {
                  Attribute: "SourceMedium",
                  Value: this.utmSourceMedium,
                },
                {
                  Attribute: "mx_utm_Adgroupid",
                  Value: this.utmAdgroupid,
                },
                {
                  Attribute: "mx_utm_Campaignid",
                  Value: this.utmCampaignid,
                },
                {
                  Attribute: "mx_utm_Creativeid",
                  Value: this.utmCreativeid,
                },
                {
                  Attribute: "mx_utm_Device",
                  Value: this.utmDevice,
                },
                {
                  Attribute: "mx_utm_Keyword",
                  Value: this.utmKeyword,
                },
                {
                  Attribute: "mx_utm_Matchtype",
                  Value: this.utmMatchtype,
                },
                {
                  Attribute: "mx_utm_Network",
                  Value: this.utmNetwork,
                },
                {
                  Attribute: "mx_utm_Placement",
                  Value: this.utmPlacement,
                },
                {
                  Attribute: "mx_Gclid",
                  Value: this.Gclid,
                },
              ];

              // Call the API to capture lead
              await axios
                .post(
                  "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r822806c3d4d4cb1c22e55fc36ca478bf&secretKey=95b5dad573299c9d675384a7e9afe0476d44e0bb",
                  dataJson
                )
                .then((resp) => {
                  // Handle lead capture response
                  console.log("Lead capture successful:", resp.data);
                  this.errorStatus = false;
                  window.dataLayer.push({
                    event: "Loginformsubmission",
                    enhanced_conversion_data: {
                      email: "el.target.elements.email.value",
                      phone_number: "yourPhoneVariable",
                      first_name: "yourFirstNameVariable",
                      last_name: "yourLastNameVariable",
                      street: "yourStreetAddressVariable",
                      city: "yourCityVariable",
                      region: "yourRegionVariable",
                      postal_code: "yourPostalCodeVariable",
                      country: "yourCountryVariable",
                    },
                  });
                  // Redirect or perform other actions after successful lead capture
                  window.location.href = `/lp/thankyou/${this.$route.params.slug}`;
                })
                .catch((error) => {
                  console.log(error.response.data.ExceptionMessage);
                  this.message = error.response.data.ExceptionMessage;
                });
            } else {
              this.otpChecking = false;
              this.errorMessage = "Incorrect OTP. Please try again.";
              this.errorStatus = true;
            }
          });

        // if (this.otpChecking) {

        // } else {
        //   // Handle OTP verification failure, for example, show an error message

        // }
      } catch (error) {
        // Handle error, for example, show a generic error message
        console.error("Error:", error);
        this.errorMessage = "An error occurred. Please try again later.";
      }
    },

    updateElectiveOptions() {
      // Define elective options based on the selected program
      if (this.$route.path == "/lp/online-mba-program.html") {
        this.electiveOptions = [
          { value: "Finance", label: "Finance" },
          { value: "Marketing", label: "Marketing" },
          {
            value: "Human Resource Management",
            label: "Human Resource Management",
          },
          { value: " Business Analytics", label: "Business Analytics" },

          // {
          //   value: "Human Resource Management",
          //   label: "Human Resource Management",
          // },
          // { value: "Finance", label: "Finance" },
          // { value: "Marketing", label: "Marketing" },
          {
            value: "Finance and Human Resource Management",
            label: "Finance and Human Resource Management",
          },
          {
            value: " Information Technology",
            label: " Information Technology",
          },
          // { value: "Business Analytics", label: "Business Analytics" },
          {
            value: "Healthcare and Hospital Management",
            label: "Healthcare and Hospital Management",
          },
          // {
          //   value: "Logistics and Supply Chain Management",
          //   label: "Logistics and Supply Chain Management",
          // },
          // { value: "Operations Management", label: "Operations Management" },
        ];
      } else if (this.$route.path == "/lp/online-mca-program.html") {
        this.electiveOptions = [
          {
            value: "Computer Science and IT",
            label: "Computer Science and IT",
          },
          {
            value: "Data Science",
            label: "Data Science",
          },
        ];
      } else if (this.$route.path == "/lp/online-bba-program.html") {
        this.electiveOptions = [
          {
            value: "General",
            label: "General",
          },
        ];
      } else if (this.$route.path == "/lp/online-bca-degree-program.html") {
        this.electiveOptions = [
          {
            value: " Computer Science and IT",
            label: " Computer Science and IT",
          },
        ];
      } else if (this.$route.path == "/lp/online-mca-degree-program.html") {
        this.electiveOptions = [
          {
            value: "Computer Science and IT",
            label: "Computer Science and IT",
          },
          {
            value: "Data Science",
            label: "Data Science",
          },
        ];
      } else if (this.$route.path == "/lp/online-mba-marketing") {
        this.electiveOptions = [
          {
            value: "Online MBA Marketing",
            label: "Online MBA Marketing",
          },
        ];
      } else if (this.$route.path == "/lp/online-mba-human-resource-management") {
        this.electiveOptions = [
          {
            value: "Online MBA Human Resource Management",
            label: "Online MBA Human Resource Management",
          },
        ];
      } else if (this.$route.path == "/lp/online-mca-computer-science-and-information-technology") {
        this.electiveOptions = [
          {
            value: "Online MCA Computer Science and Information Technology",
            label: "Online MCA Computer Science and Information Technology",
          },
        ];
      } else if (this.$route.path == "/lp/online-mba-operations-management") {
        this.electiveOptions = [
          {
            value: "Online MBA in Operations Management",
            label: "Online MBA in Operations Management",
          },
        ];
      } else if (this.$route.path == "/lp/online-mba-healthcare-and-hospital-management") {
        this.electiveOptions = [
          {
            value: "Online MBA in Healthcare and Hospital Management",
            label: "Online MBA in Healthcare and Hospital Management",
          },
        ];
      } else if (this.$route.path == "/lp/online-mba-business-analytics") {
        this.electiveOptions = [
          {
            value: "Online MBA in Business Analytics",
            label: "Online MBA in Business Analytics",
          },
        ];
      } else if (this.$route.path == "/lp/online-mba-information-technology") {
        this.electiveOptions = [
          {
            value: "Online MBA in Information Technology",
            label: "Online MBA in Information Technology",
          },
        ];
      } else if (this.$route.path == "/lp/online-mba-finance") {
        this.electiveOptions = [
          {
            value: "Online MBA in Finance",
            label: "Online MBA in Finance",
          },
        ];
      }
       else if (this.$route.path == "/lp/online-mba-finance-hrm") {
        this.electiveOptions = [
          {
            value: "Online MBA in Finance and HRM",
            label: "Online MBA in Finance and HRM",
          },
        ];
      } else if (this.$route.path == "/lp/online-bba-general") {
        this.electiveOptions = [
          {
            value: "Online BBA in General Management",
            label: "Online BBA in General Management",
          },
        ];
      } else if (this.$route.path == "/lp/online-mba-logistics-and-supply-chain-management") {
        this.electiveOptions = [
          {
            value: "Online MBA in Logistics and Supply Chain Management",
            label: "Online MBA in Logistics and Supply Chain Management",
          },
        ];
      } else if (this.$route.path == "/lp/online-bca-computer-science-and-information-technology") {
        this.electiveOptions = [
          {
            value: "Online BCA in Computer Science and IT",
            label: "Online BCA in Computer Science and IT",
          },
        ];
      } else if (this.$route.path == "/lp/online-mca-data-science") {
        this.electiveOptions = [
          {
            value: "Online MCA in Data Science",
            label: "Online MCA in Data Science",
          },
        ];
      }
      else if (this.$route.path == "/lp/vignanodl") {
        this.electiveOptions = [
          {
            value: "Finance",
            label: "Finance",
          },
          {
            value: "Human Resource Management",
            label: "Human Resource Management",
          },
          {
            value: "Marketing",
            label: "Marketing",
          },
        ];
      }
      
    },
  },
};
</script>
<style lang=""></style>
