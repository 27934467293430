<template>
  <div class="m-auto max-w-7xl lg:mt-[4rem]">
    <div
      class="flex flex-col justify-center items-center lg:flex-row-reverse lg:items-start lg:gap-x-12 lg:w-max lg:justify-between lg:m-auto lg:my-12 gap-y-4 my-4"
    >
      <div class="md:my-4 lg:my-0 w-max lg:mt-10">
        <div class="text-xl font-bold lg:text-4xl md:text-[#c82228] ">
          Refer and Earn Rewards
        </div>
        <div
          class="text-xl text-left text-black w-[25rem] my-4 hidden lg:block font-semibold"
        >
          You're also eligible to refer your friends to our online degree
          programs, earn 3000 worth of points on every successful referral, and
          redeem them on our exclusive rewards marketplace!
        </div>
        <a href="#" target="_blank">
          <div
            class="bg-[#c82228] text-white w-max py-2 px-12 rounded-full font-semibold text-xl cursor-pointer hidden lg:block leading-8"
          >
            Refer Now
          </div>
        </a>
      </div>

      <div class="m-auto flex flex-col justify-center items-center lg:w-max">
        <div class="md:hidden justify-center items-center m-auto w-[80%]">
          <img src="@/assets/alumni-lp/refer-&-Earn.webp" alt="referalMob" class="" />
        </div>
        <div class="hidden md:block">
          <img src="@/assets/alumni-lp/refer-&-Earn.webp" alt="referalDes" />
        </div>
        <div
          class="lg:hidden text-md text-center px-4 my-4 font-normal text-black"
        >
          You're also eligible to refer your friends to our online degree
          programs, earn 3000 worth of points on every successful referral, and
          redeem them on our exclusive rewards marketplace!
        </div>
        <a href="#" target="_blank">
          <div
            class="bg-[#c82228] text-white w-max py-1 px-12  rounded-full font-bold text-xl cursor-pointer lg:hidden leading-8 m-auto"
          >
            Refer Now
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "referReward",
};
</script>

<style scoped></style>
