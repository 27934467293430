<template>
    <div class="w-full mt-5">
        <div class="max-w-7xl mx-auto w-full px-3 pb-8">

            <!--desktop design-->
            <div class="hidden md:block border-2 border-gray-200 rounded-lg">
                <div class="alltabs flex justify-center ">
                    <div class="tabbutton w-full px-[10px] py-[15px] md:min-h-[70px] font-bold relative" id="tab1">CAREER SUPPORT</div>
                    <div class="tabbutton w-full px-[10px] py-[15px] md:min-h-[70px] font-bold relative" id="tab2">LEARNER SUPPORT</div>
                    <div class="tabbutton w-full px-[10px] py-[15px] md:min-h-[70px] font-bold relative" id="tab3">MENTORSHIP </div>
                    <div class="tabbutton w-full px-[10px] py-[15px] md:min-h-[70px] font-bold relative" id="tab4">INDUSTRY INTERFACE</div>
                </div>

                <div class="tabbutton-content mt-5 pb-4">

                    <div class="flex " id="content1">
                        <div class="md:flex justify-center items-center gap-3 w-full hidden md:block py-3">
                            <span class="font-bold text-black ">POWERED BY</span>
                            <img src="@/assets/Program/foundit_logo.webp" alt="found-it-logo">
                        </div>
                        <div class="flex flex-col md:flex-row md:gap-3">
                            <div>
                                <div class="flex justify-center items-center gap-3 w-full md:hidden">
                                    <span class="font-bold text-black ">POWERED BY</span>
                                    <img src="@/assets/Program/foundit_logo.webp" alt="found-it-logo">
                                </div>
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3  mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/career-support-1.png" alt="world-icon" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center px-4">Guidance on further studies through expert counseling sessions</span>
                                </div>
                            </div>

                            <div>
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3  mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/career-support-2.png" alt="people-icon" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center px-4">Helps our students find exciting and challenging job opportunities</span>
                                </div>
                            </div>

                            <div>
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3  mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/career-support-3.png" alt="peoplegather-icon" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center px-4">Our students get placements in reputed companies through a structured framework</span>
                                </div>
                            </div>

                            <div>
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3  mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/career-support-4.webp" alt="support-icon" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center px-4">Experience in training, networking and human resourcing skills</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="py-5" id="content2">
                        <div class="flex flex-col md:flex-row md:gap-1 justify-center items-center">
                            <div class="md:w-1/2 xl:w-[30%]">
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/ls1.png" alt="support-icon" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center md:min-h-[80px]">Dedicated learner support team to answer your queries and guide you throughout</span>
                                </div>
                            </div>

                            <div class="md:w-1/2 xl:w-[30%]">
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/ls2.png" alt="mentorship-icon" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center md:min-h-[80px]">Academic mentorship to give you expert advice on a wide range of academic topics</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="py-5" id="content3">
                        <div class="flex flex-col md:flex-row md:gap-1 justify-center items-center">
                            <div class="md:w-1/2 xl:w-[30%]">
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/mentorship-1.png" alt="online-mentoring" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center md:min-h-[80px]">Online mentoring sessions on weekends</span>
                                </div>
                            </div>

                            <div class="md:w-1/2 xl:w-[30%]">
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/mentorship-2.png" alt="doubt-clearing-icon" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center md:min-h-[80px]">Doubt-clearing sessions with faculty during live classes and discussion forums
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="py-5" id="content4">
                        <div class="flex flex-col md:flex-row md:gap-1 justify-center items-center">
                            <div class="md:w-1/2 xl:w-[30%]">
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/industry-interface-1.png" alt="guest-lectures" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center md:min-h-[80px]">Online and on-campus guest lectures from corporate leaders to enrich yourself with industry insights </span>
                                </div>
                            </div>

                            <div class="md:w-1/2 xl:w-[30%]">
                                <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                    <img src="@/assets/Program/industry-interface-2.png" alt="industry-icon" class="w-[50px] xl:w-[80px]">
                                    <span class="text-left md:text-center md:min-h-[80px]">Get practical exposure through industry visits
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--desktop design completed-->

            <!--mobile design-->
            <div class="md:hidden">            
                <div class=" tabbutton-content1 ">
                    <div class="tab-button  px-[10px] py-[15px] font-bold" id="tab1Button">CAREER SUPPORT 
                        <span class="arrow down"></span></div>
                        <div class="tab-panel" id="tab1Content">
                            <div class="flex flex-col gap-3 mt-4">
                                <div>
                                    <div class="flex justify-center items-center gap-3 w-full">
                                        <span class="font-bold text-black ">POWERED BY</span>
                                        <img src="@/assets/Program/foundit_logo.webp">
                                    </div>
                                    <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3  mt-3 border-2 border-gray-200 rounded-md">
                                        <img src="@/assets/Program/career-support-1.png" class="w-[50px] xl:w-[80px]">
                                        <span class="text-left md:text-center">Guidance on further studies through expert counseling sessions</span>
                                    </div>
                                </div>

                                <div>
                                    <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3  mt-3 border-2 border-gray-200 rounded-md">
                                        <img src="@/assets/Program/career-support-2.png" class="w-[50px] xl:w-[80px]">
                                        <span class="text-left md:text-center">Helps our students find exciting and challenging job opportunities</span>
                                    </div>
                                </div>

                                <div>
                                    <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3  mt-3 border-2 border-gray-200 rounded-md">
                                        <img src="@/assets/Program/career-support-3.png" class="w-[50px] xl:w-[80px]">
                                        <span class="text-left md:text-center">Get placements in reputed companies through a structured framework</span>
                                    </div>
                                </div>

                                <div>
                                    <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3  mt-3 border-2 border-gray-200 rounded-md">
                                        <img src="@/assets/Program/career-support-4.webp" class="w-[50px] xl:w-[80px]">
                                        <span class="text-left md:text-center">Experience in training, networking and human resourcing skills</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div class="tab-button  px-[10px] py-[15px] font-bold" id="tab2Button">LEARNER SUPPORT
                        <span class="arrow down"></span>
                    </div>
                        <div class="tab-panel" id="tab2Content">
                            <div class="flex flex-col gap-3 justify-center items-center">
                                <div class="md:w-1/2 xl:w-[30%]">
                                    <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                        <img src="@/assets/Program/ls1.png" class="w-[50px] xl:w-[80px]">
                                        <span class="text-left md:text-center md:min-h-[80px]">Dedicated learner support team to answer your queries and guide you throughout</span>
                                    </div>
                                </div>

                                <div class="md:w-1/2 xl:w-[30%]">
                                    <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                        <img src="@/assets/Program/ls2.png" class="w-[50px] xl:w-[80px]">
                                        <span class="text-left md:text-center md:min-h-[80px]">Academic mentorship to give you expert advice on a wide range of academic topics</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div class="tab-button  px-[10px] py-[15px] font-bold" id="tab3Button">MENTORSHIP
                        <span class="arrow down"></span>
                    </div>
                        <div class="tab-panel" id="tab3Content">
                            <div class="flex flex-col gap-3 justify-center items-center">
                                    <div class="md:w-1/2 xl:w-[30%]">
                                        <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                            <img src="@/assets/Program/mentorship-1.png" class="w-[50px] xl:w-[80px]">
                                            <span class="text-left md:text-center md:min-h-[80px]">Online mentoring sessions on weekends</span>
                                        </div>
                                    </div>

                                    <div class="md:w-1/2 xl:w-[30%]">
                                        <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                            <img src="@/assets/Program/mentorship-2.png" class="w-[50px] xl:w-[80px]">
                                            <span class="text-left md:text-center md:min-h-[80px]">Doubt-clearing sessions with faculty during live classes and discussion forums
                                        </span>
                                        </div>
                                    </div>
                                </div>
                        </div>

                    <div class="tab-button  px-[10px] py-[15px] font-bold" id="tab4Button">INDUSTRY INTERFACE
                        <span class="arrow down"></span>
                    </div>
                        <div class="tab-panel" id="tab4Content">
                            <div class="flex flex-col md:flex-row md:gap-3 justify-center items-center">
                                <div class="md:w-1/2 xl:w-[30%]">
                                    <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                        <img src="@/assets/Program/industry-interface-1.png" class="w-[50px] xl:w-[80px]">
                                        <span class="text-left md:text-center md:min-h-[80px]">Online and on-campus guest lectures from corporate leaders to enrich yourself with industry insights </span>
                                    </div>
                                </div>

                                <div class="md:w-1/2 xl:w-[30%]">
                                    <div class="flex justify-center items-center text-sm bg-white text-black font-medium gap-5 md:flex-col py-3 px-2 mt-3 border-2 border-gray-200 rounded-md">
                                        <img src="@/assets/Program/industry-interface-2.png" class="w-[50px] xl:w-[80px]">
                                        <span class="text-left md:text-center md:min-h-[80px]">Get practical exposure through industry visits
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <!--mobile design-->
        </div>
    </div>
</template>

<script>

export default {
    name: "CasLp",

    data() {
        return { activetabbutton: 0,   activeTab: null,}
    },
    async created() { },

    mounted() {
        this.initializeTabs();
        this.dropdownTabs();
    },

    methods: {
            // Function to switch tabs
            
    switchTab(tabId) {
        this.activeTab = tabId;
    },

        changeTab(index) {
            this.activetabbutton = index;
        },
        initializeTabs() {
            const tabs = document.querySelectorAll('.tabbutton');
            const tabContents = document.querySelectorAll('.tabbutton-content > div');

            tabs.forEach((tabbutton, index) => {
                tabbutton.addEventListener('click', () => {

                    tabContents.forEach(content => {
                        content.classList.remove('show');
                    });

                    tabContents[index].classList.add('show');

                    tabs.forEach(t => {
                        t.classList.remove('active');
                    });

                    tabbutton.classList.add('active');
                });
            });

            tabs[0].click();
        },

        dropdownTabs() {
        const tabButtons = document.querySelectorAll('.tab-button');
        const tabPanels = document.querySelectorAll('.tab-panel');

        tabPanels.forEach(panel => {
        panel.style.display = 'none';
      });

      tabButtons.forEach(button => {
        button.addEventListener('click', () => {
          tabPanels.forEach(panel => {
            panel.style.display = 'none';
          });

          const tabContentId = button.id.replace('Button', 'Content');
          const isActive = this.activeTab === tabContentId;

          if (!isActive) {
            document.getElementById(tabContentId).style.display = 'block';
            this.activeTab = tabContentId;

         
            tabButtons.forEach(tabButton => {
              tabButton.classList.remove('active');
            });

          
            button.classList.add('active');
          } else {
            this.activeTab = null;
            button.classList.remove('active');
          }
        });
      });

        },

    },
}
</script>

<style scoped>
.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
}
.arrow.down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(245, 242, 242);
  transition: transform 0.3s ease-in-out;
}
.arrow.up {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgb(250, 247, 247);
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.tab-button.active {
  background-color: #d02829;
}
.tab-button{
display: flex;
justify-content: space-between;
align-items: center;
font-size: 14px;
transition: 0.1s, color 0.1s;
font-weight: 600;
color: #fff;
width: 100%;
text-align: left;
position: relative;
height: 100%;
background: #2a2a2a;
box-shadow: 0 2px 5px 0 rgb(17 121 239 / 15%);
border: 1px solid #e6ecef;
border-radius: 5px;
line-height: 18px;
padding: 10px
}
.tabs {
display: flex;
justify-content: center;
align-items: center;
width:100%;
}
.tabbutton-content1{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 1px;
}
.tabbutton {
cursor: pointer;
user-select: none;
box-shadow: 0 2px 5px 0 rgb(17 121 239 / 15%);
border-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
color:#fff;
font-size: 15px;
background-color: #2a2a2a;
font-weight: bold;
border: 1px solid #e6ecef;
}
.tabbutton::after{
content: "";
background: red;
height: 0;
bottom: 0;
width: 100%;
position: absolute;
left: 0;
display: inline-block;
transition: all 0.2s ease;
}

.tabbutton-content > div {
display: none;
padding: 20px 20px;
}
.tabbutton-content > div.show {
display: block;
border-radius: 10px;
}
.active{
color: white;
background-color: #d0393b;
}

.tabbutton:hover{
    background-color: #d0393b;
    color: white;
}

</style>