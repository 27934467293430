<template>
  <div class="w-full px-5 pt-10 pb-2">
    <div class="max-w-7xl mx-auto w-full p-2 relative">
      <div
        class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
      >
        <h2 class="uppercase text-2xl font-bold mb-4">
          Rankings and <span class="text-primary">Recognition</span>
        </h2>
        <div class="p-4"><img src="@/assets/Heading-underline.png" /></div>
      </div>
      <div class="prev-rank absolute top-[50%] md:hidden cursor-pointer">
        <img
          src="../assets/icon-slider-prev.svg"
          width="40px"
          height="40px"
          alt=""
          class="md: hidden"
        />
      </div>

      <div class="flex justify-center items-center" :class="ranking_compo">
        <div class="relative h-[320px]">
          <div class="flex h-full items-center">
            <div
              class="border border-slate-200 rounded-lg lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-[#f7f9fb]"
            >
              <p>
                Entitled by the University Grants Commission (UGC) to offer
                online degree programs
              </p>
              <div
                class="rounded-lg bg-white absolute p-1 lg:top-4 top-4 m-auto shadow-lg mb-8"
              >
                <img
                  class="lg:w-[70%] w-[50%] m-auto"
                  src="../assets/rr1.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="relative h-[320px]">
          <div class="flex h-full items-center">
            <div
              class="border border-slate-200 rounded-lg lg:h-[180px] h-[220px] flex flex-col justify-center items-center py-6 px-4 bg-[#f7f9fb]"
            >
              <p class="pt-12">
                Ranked 75th in India by National Institutional Ranking
                Framework (NIRF), Ministry of Education, Government of India
              </p>
              <div
                class="rounded-lg bg-white absolute lg:top-4 top-4 m-auto shadow-lg"
              >
                <img
                  class="lg:w-[70%] w-[50%] m-auto"
                  src="../assets/rr2.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="relative h-[320px]">
          <div class="flex h-full items-center">
            <div
              class="border border-slate-200 rounded-lg lg:h-[180px] h-[220px] flex flex-col justify-center items-center py-6 px-4 bg-[#f7f9fb]"
            >
              <p class="pt-12">
                Accredited by the National Assessment and Accreditation Council
                (NAAC) with an A+ Grade
              </p>

              <div
                class="rounded-lg bg-white absolute lg:top-4 top-4 m-auto shadow-lg"
              >
                <img
                  class="lg:w-[70%] w-[50%] m-auto"
                  src="../assets/rr3.webp"
                  alt=""
                />
                
              </div>
            </div>
          </div>
        </div>
        <div class="relative h-[320px]">
          <div class="flex h-full items-center">
            <div
              class="border border-slate-200 rounded-lg lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-[#f7f9fb]"
            >
              <p class="pt-12">
                MBA & MCA programs approved by All India Council for Technical
                Education (AICTE)
              </p>

              <div
                class="rounded-lg bg-white absolute lg:top-4 top-4 m-auto shadow-lg"
              >
                <img
                  class="lg:w-[70%] w-[50%] m-auto"
                  src="../assets/rr4.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="next-rank absolute top-[50%] md:hidden -right-1 xl:-right-16 cursor-pointer"
      >
        <img
          src="../assets/icon-slider-next.svg"
          width="40px"
          height="40px"
          alt=""
          class="md: hidden"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "RankingComp",
  data() {
    return {
      ranking_compo: "ranking_components",
    };
  },

  async mounted() {
    await this.rankingCompo();
  },

  methods: {
    rankingCompo() {
      $(`.${this.ranking_compo}`).slick({
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              nextArrow: $(".next-rank"),
              prevArrow: $(".prev-rank"),
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              nextArrow: $(".next-rank"),
              prevArrow: $(".prev-rank"),
            },
          },
        ],
      });
    },
  },
};
</script>

<style>
.slick-slider .slick-track {
  display: flex;
  gap: 12px;
}
</style>
