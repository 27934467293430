<template>
  <div>
    <NavBar />
    <!--banner part-->
    <div class="w-full mx-auto bg-[#f7f9f8] pt-1 relative lg:mt-20">
      <div class="object-cover bg-center bg-no-repeat pt-20 lg:pt-1">
        <img
          src="@/assets/Program/Online-Degree-Programs.webp"
          class="hidden md:block h-full w-full"
        />
        <img
          src="@/assets/Program/Online-Degree-Programs-mab.webp"
          class="md:hidden"
        />
      </div>
      <div class="w-full max-w-screen-2xl mx-auto">
        <div class="absolute top-3 left-3">
          <p class="lg:pt-2 pt-20"><router-link to="/">Home</router-link> > Online Degree Programs</p>
        </div>

        <div
          class="text-black md:text-left absolute lg:top-[30%] top-[20%] lg:text-left text-center bottom-[10%] lg:bottom-[2%] xl:bottom-[24%] 2xl:bottom-[35%] md:left-[5%] lg:left-[10%] xl:left-[14%] 2xl:left-[20%]"
        >
        <div>
          <h2 class="text-xl font-semibold lg:mb-4 lg:text-[28px] text-md lg:text-left lg:leading-10">
            Be every employer's top choice with our
          </h2>
          <h1
            class="lg:mb-4 text-[#d02829] md:pt-2 font-semibold text-xl lg:text-[28px]"
          >
            Online Degree Programs
          </h1>
          <h2 class="lg:text-[28px] pt-2 text-xl lg:text-left text-center font-semibold md:pb-4 pb-1">
            <a href="/masters-business-administration-online-degree" target="_blank">MBA</a> | <a href="/masters-computer-applications-online-degree" target="_blank">MCA</a> | <a href="/bachelors-business-administration-online-degree" target="_blank">BBA</a> | <a href="/bachelor-computer-application-online-degree" target="_blank">BCA</a>
          </h2>
          </div>
          <div
          class="text-black md:text-left mb:mt-4 md:left-[5%] lg:left-[8%] xl:left-[12%]"
        >
          <img
            src="@/assets/Accreditions.webp"
            class="md:hidden w-[95%] "
          />
          <img
            src="@/assets/Accreditions.webp"
            class="hidden md:block w-[70%]"
          />
          
          <h6 class="text-[15px] font-semibold md:text-xl md:mt-20 mt-1 lg:text-2xl">
            #Driving <span class="text-[#cf4030]">YourFuture</span>
          </h6>
        </div>
        </div>
      </div>
    </div>
    <!--banner part completed-->

    <div class="grad w-full p-3 md:py-[45px] md:px-[30px]">
      <div class="w-full max-w-7xl mx-auto justify-center">
        <div
          class="flex flex-wrap md:justify-center items-center gap-3 xl:gap-5 mx-auto my-0 md:w-[95%] "
        >
          <div
            class="text-[16px] float-left leading-5 bg-[#e3dade] flex flex-col md:flex-row justify-center items-center gap-3 min-h-[120px] md:w-[200px] md:min-h-[100px] lg:w-[230px] lg:min-h-[120px] rounded-md gradbox"
          >
            <img src="@/assets/Program/icon-time-red.png" />
            <span class="text-center md:text-left"
              >Duration <br />
              <span class="font-bold text-black text-md lg:text-lg"
                >2 & 3 YEARS</span
              >
            </span>
          </div>

          <div
            class="text-[16px] float-left leading-5 bg-[#e3dade] flex flex-col md:flex-row justify-center items-center gap-3 min-h-[120px] md:w-[200px] md:min-h-[100px] lg:w-[230px] lg:min-h-[120px] rounded-md gradbox"
          >
            <img src="@/assets/Program/icon-calendar-red.svg" />
            <span class="text-center md:text-left"
              >Live Online <br />Sessions <br />
              <span class="font-bold text-black text-md lg:text-lg"
                >WEEKEND</span
              >
            </span>
          </div>

          <div
            class="text-[16px] leading-5 bg-[#e3dade] flex flex-col md:flex-row justify-center items-center gap-3 min-h-[120px] md:w-[200px] md:min-h-[100px] lg:w-[230px] lg:min-h-[120px] rounded-md gradbox"
          >
            <img src="@/assets/Program/icon-precent.svg" />
            <span class="font-bold text-black text-md lg:text-lg md:text-left"
              >FLEXIBLE EMI<br />OPTIONS</span
            >
          </div>
        </div>
      </div>
    </div>

    <!--Quick Introduction-->
    <div class="w-full px-5 py-5">
      <div class="max-w-6xl mx-auto w-full md:text-left">
        <h2 class="text-black font-bold text-[18px] px-5 text-left">
          QUICK <span class="text-[#d02829]">INTRO</span>
        </h2>
        <p class="py-3 text-justify px-5 text-[15px]">
          Online degree programs offer the perfect combination of flexibility,
          convenience, and affordability. You can study from anywhere, at any
          time, and experience quality education. As a working professional,
          enrolling in an online degree program allows you to earn and learn new
          skills, at the same time enabling you to boost your career to another
          level.
        </p>
        <p class="py-3 text-justify px-5 text-[15px]">
          Online degree programs are cost-effective compared to traditional
          programs and provide opportunities to network with peers and engage in
          collaborative learning. These programs offer the same curriculum and
          learning outcomes as traditional university programs, ensuring that
          your education is on par with that of the traditional ones.
        </p>
        <h2 class="text-black font-bold text-[18px] px-5 text-left">
          ONLINE DEGREE PROGRAMS <span class="text-[#d02829]">WE OFFER</span>
        </h2>
        <p class="py-3 text-justify px-5 text-[15px]">
          The online degree programs are available in four major streams across
          the Bachelor's and Master's categories. Within these categories, you
          can choose from various electives in BBA, BCA, MBA, and MCA online
          degree programs that align with your career goals.
        </p>
      </div>
    </div>
    <!--Quick Introduction end-->

    <!--tabs-->
    <div class="w-full px-5 py-5 mb-5">
      <div class="max-w-6xl mx-auto w-full px-5">
        <div class="tabs gap-[5px] sm:gap-[10px] lg:gap-[20px]">
          <div
            class="tab lg:w-[250px] px-[20px] py-[10px] lg:py-[20px] relative text-[15px]"
            id="tab1"
          >
            Online MBA
          </div>
          <div
            class="tab lg:w-[250px] px-[20px] py-[10px] lg:py-[20px] relative text-[15px]"
            id="tab2"
          >
            Online MCA
          </div>
          <div
            class="tab lg:w-[250px] px-[20px] py-[10px] lg:py-[20px] relative text-[15px]"
            id="tab3"
          >
            Online BBA
          </div>
          <div
            class="tab lg:w-[250px] px-[20px] py-[10px] lg:py-[20px] relative text-[15px]"
            id="tab4"
          >
            Online BCA
          </div>
        </div>
        <div class="tab-content mt-5">
          <div id="content1 py-5">
            <h2 class="text-left pt-3 font-bold text-black text-lg lg:text-2xl">
              Online MBA Degree Program
            </h2>
            <p class="text-justify pt-4 text-[15px]">
              An Online MBA is a great way to gain the skills and knowledge
              necessary to advance in the business world. It offers the
              flexibility of taking electives online, allowing learners to
              manage their studies around a full-time job or other commitments
              Learners in an Online MBA Program can access different resources,
              such as online lectures, multimedia presentations, course
              materials ...
              <a
                href="https://vignanonline.com/online-mba-degree"
                target="_blank"
                class="text-[#004271]"
                ><strong>Read more</strong></a
              >
            </p>
          </div>
          <div id="content2 py-5">
            <h2 class="text-left pt-3 font-bold text-black text-lg lg:text-2xl">
              Online MCA Degree Program
            </h2>
            <p class="text-justify pt-4 text-[15px]">
              An Online MCA is an ideal program for individuals looking to
              pursue a career in computer science, information technology, and
              software engineering. This program provides the necessary
              knowledge and skills to design, develop, and implement computer
              applications. Learners get an in-depth understanding on how to
              develop algorithms, create databases, and build systems from the
              ground up. The program also provides an understanding of the
              fundamentals of computer programming and software engineering...
              <a
                href="https://vignanonline.com/online-mca-degree"
                target="_blank"
                class="text-[#004271]"
                ><strong>Read more</strong></a
              >
            </p>
          </div>
          <div id="content3 py-5">
            <h2 class="text-left pt-3 font-bold text-black text-lg lg:text-2xl">
              Online BBA Degree Program
            </h2>
            <p class="text-justify pt-4 text-[15px]">
              An Online BBA is an excellent way to gain the business knowledge
              and skills necessary to succeed in today's competitive job market.
              With an Online BBA, learners understand the core business
              principles, such as accounting, economics, and marketing. They
              also gain the skills to think strategically, analyze data, and
              develop effective solutions. Additionally, Online BBA learners can
              access resources, such as online lectures, multimedia
              presentations, course materials...
              <a
                href="https://vignanonline.com/online-bba-degree"
                target="_blank"
                class="text-[#004271]"
                ><strong>Read more</strong></a
              >
            </p>
          </div>
          <div id="content4 py-5">
            <h2 class="text-left pt-3 font-bold text-black text-lg lg:text-2xl">
              Online BCA Degree Program
            </h2>
            <p class="text-justify pt-4 text-[15px]">
              An Online BCA is an ideal program for individuals looking to
              pursue a career in computing. This program provides the necessary
              knowledge and skills to design, develop, and implement computer
              applications. Learners can expect to understand how to develop
              algorithms, create databases, and build systems from the ground
              up. This online degree program also provides an understanding of
              computer programming fundamentals and software engineering...
              <a
                href="https://vignanonline.com/online-bca-degree"
                target="_blank"
                class="text-[#004271]"
                ><strong>Read more</strong></a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--tabs end-->

    <!--Program highlights-->
    <div class="w-full px-5 py-5">
      <div class="max-w-6xl mx-auto w-full">
        <h2
          class="font-bold text-black text-lg lg:text-xl pb-5 text-left lg:ml-6"
        >
          PROGRAM <span class="text-[#d0393b]">HIGHLIGHTS</span>
        </h2>
        <div
          class="flex flex-col md:flex-row justify-start lg:gap-[100px] w-full lg:ml-10"
        >
          <div class="flex flex-col justify-center items-start">
            <div
              class="flex justify-center gap-4 md:gap-8 items-center px-3 py-5"
            >
              <img src="@/assets/Program/icon-bulb.svg" />
              <span class="text-left text-[15px]"
                >Industry-aligned curriculum</span
              >
            </div>
            <div
              class="flex justify-center gap-4 md:gap-8 items-center px-3 py-5"
            >
              <img src="@/assets/Program/icon-bulb.svg" />
              <span class="text-left text-[15px]"
                >Learn at your own time and pace</span
              >
            </div>
            <div
              class="flex justify-center gap-4 md:gap-8 items-center px-3 py-5"
            >
              <img src="@/assets/Program/icon-bulb.svg" />
              <span class="text-left text-[15px]"
                >World-class and professional faculties</span
              >
            </div>
          </div>

          <div class="flex flex-col justify-center items-start">
            <div
              class="flex justify-center gap-4 md:gap-8 items-center px-3 py-5"
            >
              <img src="@/assets/Program/icon-bulb.svg" />
              <span class="text-left text-[15px]"
                >Practical experience with group projects</span
              >
            </div>
            <div
              class="flex justify-center gap-4 md:gap-8 items-center px-3 py-5"
            >
              <img src="@/assets/Program/icon-bulb.svg" />
              <span class="text-left text-[15px]"
                >Live Interactive sessions</span
              >
            </div>
            <div
              class="flex justify-center gap-4 md:gap-8 items-center px-3 py-5"
            >
              <img src="@/assets/Program/icon-bulb.svg" />
              <span class="text-left text-[15px]"
                >Modern learning methodology</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Program highlights end-->
    <section>
      <div>
        <EleDegree />
      </div>
    </section>

    <!--Learning Methodology-->
    <div class="w-full py-5 px-5">
      <div class="max-w-6xl mx-auto w-full">
        <h2 class="font-bold text-black text-lg lg:2xl pb-5 text-left lg:ml-6">
          LEARNING <span class="text-[#d0393b]">METHODOLOGY</span>
        </h2>
        <div
          class="flex flex-wrap justify-around items-center gap-3 sm:gap-5 xl:gap-10"
        >
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-clock.svg"
                class="w-[70px] h-[83px]"
              />
            </div>
            <p class="font-medium text-[15px]">
              120 study hours in each course
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-projetor.svg"
                class="w-[70px] h-[83px]"
              />
            </div>
            <p class="font-medium text-[15px]">
              Interactive audio-video lectures
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-id.svg"
                class="w-[70px] h-[83px]"
              />
            </div>
            <p class="font-medium text-[15px]">Pre-recorded video lectures</p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-monitor.svg"
                class="w-[70px] h-[83px]"
              />
            </div>
            <p class="font-medium text-[15px]">Discussion forum</p>
          </div>

          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-printer.svg"
                class="w-[70px] h-[83px]"
              />
            </div>
            <p class="font-medium text-[15px]">Self e-learning material</p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-edit.svg"
                class="w-[70px] h-[83px]"
              />
            </div>
            <p class="font-medium text-[15px]">
              Assignments, quizzes, MCQ, etc. for reinforcement
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-tub.svg"
                class="w-[70px] h-[83px]"
              />
            </div>
            <p class="font-medium text-[15px]">
              Independent and group projects
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/live-online.png"
                class="w-[70px] h-[83px]"
              />
            </div>
            <p class="font-medium text-[15px]">
              8 to 12 hours of live online sessions on weekends
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img src="@/assets/Program/lm9.png" class="w-[70px] h-[83px]" />
            </div>
            <p class="font-medium text-[15px]">
              Live Q&A sessions with faculty using tech-enabled tools for an
              optimum learning experience
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img src="@/assets/Program/lm10.png" class="w-[70px] h-[83px]" />
            </div>
            <p class="font-medium text-[15px]">
              Short videos in the form of learning chunks for comprehensive
              understanding
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img src="@/assets/Program/lm11.png" class="w-[70px] h-[83px]" />
            </div>
            <p class="font-medium text-[15px]">
              Technology Enabled Online Proctored Examinations, twice a year
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img src="@/assets/Program/lm12.png" class="w-[70px] h-[83px]" />
            </div>
            <p class="font-medium text-[15px]">
              Hands-on practical and lab exercises to supplement theory for tech
              based courses
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--Learning Methodology end-->

    <!--How to Enroll-->
    <div class="w-full py-5 px-5">
      <div class="max-w-6xl mx-auto w-full">
        <h2 class="font-bold text-black text-lg lg:2xl pb-5 text-left lg:ml-6">
          HOW <span class="text-[#d0393b]">TO ENROLL</span>
        </h2>
        <div
          class="w-full flex flex-col md:flex-row justify-center items-center gap-5"
        >
          <div class="shadow-[0_0px_5px_3px_rgba(227,224,224,0.3)]">
            <div class="text-left py-2 bg-[#d0393b] text-white pl-5 font-bold">
              <h3 class="text-sm">Step</h3>
              <h2 class="pl-2 text-lg">1</h2>
            </div>
            <div class="px-3 h-[100px] pt-[20px] text-left">
              <a
                href="https://apply.vignanonline.com/"
                class="underline text-[15px] text-[#19325a]"
                target="_blank"
                >Click here</a
              >
              <Span class="">
                to visit the Vignan Online Enrollment Form</Span
              >
            </div>
          </div>

          <div class="shadow-[0_0px_5px_3px_rgba(227,224,224,0.3)]">
            <div class="text-left py-2 bg-[#d0393b] text-white pl-5 font-bold">
              <h3 class="text-sm">Step</h3>
              <h2 class="pl-2 text-lg">2</h2>
            </div>
            <div class="px-3 h-[100px] pt-[20px] text-[14px] text-left">
                <Span
                  >Sign up, complete the 4 essential steps to enroll for the
                  Program of your choice</Span
                >
              </div>
          </div>

          <div class="shadow-[0_0px_5px_3px_rgba(227,224,224,0.3)]">
            <div class="text-left py-2 bg-[#d0393b] text-white pl-5 font-bold">
              <h3 class="text-sm">Step</h3>
              <h2 class="pl-2 text-lg">3</h2>
            </div>
            <div class="px-3 h-[100px] pt-[20px] text-[14px] text-left">
                <Span
                  >Pay fee through Net banking/ Debit card/ Credit card or using
                  easy EMI with 0% interest</Span
                >
              </div>
          </div>
        </div>
      </div>
    </div>
    <!--How to Enroll end-->

    <!--Career Advancement Services-->
    <div class="w-full py-5 px-5 mb-5 bg-[#f7f9fb]">
      <div class="max-w-6xl mx-auto w-full">
        <h2
          class="font-bold text-black text-lg md:text-2xl pb-5 text-left md:px-5"
        >
          CAREER <span class="text-[#d0393b]">ADVANCEMENT SERVICES</span>
        </h2>
        <p class="text-justify px-5 pb-5 text-[15px]">
          The career advancement services team works tirelessly to connect our
          students with the right job opportunities. Our years of experience in
          training, networking, and human resourcing skills help our students
          find exciting and challenging job opportunities. Our students get
          placements in reputed companies through a structured framework.
        </p>

        <CAS />
      </div>
    </div>
    <!--Career Advancement Services end-->
    <div class="mb-[6%]">
      <TechNology />
    </div>

    <div class="mb-[6%]">
      <ProFaq />
    </div>

    <FooterView />
    <StickyView />
  </div>
</template>

<script>
import FooterView from "@/components/FooterView.vue";
import TechNology from "@/components/TechNology.vue";
import CAS from "@/components/CAServices.vue";
import NavBar from "@/components/NavBar.vue";
import StickyView from "@/components/StickyView.vue";
import EleDegree from "@/components/EleDegree.vue";
import ProFaq from "@/components/ProFaq.vue";
export default {
  name: "DegreeProgramView",
  components: {
    CAS,
    TechNology,
    FooterView,
    StickyView,
    NavBar,
    EleDegree,
    ProFaq,
  },
  
  data() {
    return { activeTab: 0 };
  },

  mounted() {
    this.initializeTabs();
  },

  created() {},

  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
    initializeTabs() {
      const tabs = document.querySelectorAll(".tab");
      const tabContents = document.querySelectorAll(".tab-content > div");

      tabs.forEach((tab, index) => {
        tab.addEventListener("click", () => {
          tabContents.forEach((content) => {
            content.classList.remove("show");
          });

          tabContents[index].classList.add("show");

          tabs.forEach((t) => {
            t.classList.remove("active");
          });

          tab.classList.add("active");
        });
      });

      tabs[0].click();
    },
  },
};
</script>

<style scoped>
.active::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #d0393b;
  bottom: -10px;
  left: 40%;
  transform: rotate(45deg);
}
@media (max-width: 425px) {
  .gradbox {
    width: 180px;
  }
}
@media (max-width: 375px) {
  .gradbox {
    width: 158px;
  }
}
@media (max-width: 320px) {
  .gradbox {
    width: 133px;
  }
}
.grad {
  background-color: #364c70;
  background-image: linear-gradient(to right, #364c70, #d0393b);
  /* padding: 45px 30px; */
}
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tab {
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 0px 5px 1px rgba(227, 224, 227, 1);
  border-radius: 8px;
}

.tab-content > div {
  display: none;
  padding: 20px 30px;
}

.tab-content > div.show {
  display: block;
  box-shadow: 0px 0px 5px 1px rgba(227, 224, 227, 1);
  border-radius: 10px;
}
.active {
  color: white;
  background-color: #d0393b;
}
.abt ul li:before {
    content: '';
    display: inline-block;
    height: 22px;
    width: 17px;
    background-size: cover;
    list-style-image: url('https://api.vignanonline.com/media/uploads/2023/12/04/icon-bulb.svg');
    background-repeat: no-repeat;
    margin-right: 10px;
    background-position: center center;
    vertical-align: middle;
}
</style>
