<template>
  <div class="bg-[#c82228]">
    <!--mob version-->
    <div class="flex justify-center items-center flex-col lg:hidden">
      <div class="flex justify-center flex-col items-center">
        <div
          class="py-4 px-4 text-white text-left text-xl font-bold"
        >
        Announcing Vignan Online's <br />Empowering Initiative-
        </div>
        <div class="text-xl p-2 text-[#c82228] bg-white font-bold">
        A 20% Scholarship 
        </div>
        <div
          class="p-2 mt-4 text-[#c82228] bg-white text-xl font-bold"
        >
        for  5000 Alumni
        </div>
      </div>
      <div>
        <img src="@/assets/alumni-lp/Mobile-banner.webp" alt="mobilebanner" />
      </div>
      <div class="justify-center">
        <EnquireAlumni />
      </div>
    
    </div>
    <!--mob version-->
    <!--desktopVersion-->
    <div class="hidden lg:block m-auto relative justify-center">
      <div
        class="banner h-[38rem] xl:h-[35rem] flex justify-between 2xl:justify-around"
      >
        <div class="ml-8 text-left">
          <div
            class="text-xl md:text-3xl w-max leading-36 pt-16 xl:pt-[6rem] leading-8 text-white font-semibold"
          >
          <h1 class="text-white leading-[3rem]">Announcing Vignan Online's <br>Empowering Initiative</h1>
          </div>
          <div
            class="text-4xl text-center bg-white mt-8 font-bold w-[90%] p-2"
          >
         <h2 class="text-[#c82228]">A 20% Scholarship </h2> 
          </div>
          <div
            class="text-4xl text-center bg-white mt-8 font-bold w-[80%] p-2"
          >
         <h2 class="text-[#c82228]">for 5000 Alumni </h2> 
          </div>
        </div>



        <div class="mt-8">
          <EnquireAlumni />
        </div>
      </div>
    </div>
    <!--desktopVersion-->
  </div>
</template>

<script>
import EnquireAlumni from "@/components/LPEnquire/EnquireAlumni.vue";
export default {
  components: {
    EnquireAlumni,

  },

};
</script>

<style scoped>
.banner {
  background-image: url("@/assets/alumni-lp/Alumni-LP-Banner.webp"); /* Path to your image */
  background-size: cover;
  background-position: center;
}
</style>
