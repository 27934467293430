<template>
  <div class="home">
    <!-- <popupView ref="popup" />
    <span class="close" @click="closeModal">&times;</span> -->
    <div class="Emodal" >
      <div
        class="Emodal-content z-10 rounded-md relative bottom-24 md:w-[35%] 2xl:w-[20%]"
      >
        <span class="close z-50" @click="closeModal">&times;</span>
        <popupView ref="popup" />
      </div>
    </div>
    <NavBar />
    <section>
      <BannerView />
    </section>

    <section>
      <DegreeProgram />
    </section>

    <section>
      <div class="w-full mx-auto max-w-7xl py-8">
        <div>
          <h2 class="text-center font-bold pb-6 text-lg md:text-2xl uppercase">
            Rankings & <span class="text-[#e33237]">Recognitions</span>
          </h2>
        </div>
        <div class="w-full relative justify-center m-auto">
          <div
            class="next-ranking absolute bottom-0 right-32 md:top-[40%] md:-right-10 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-next.svg"
              class=""
              width="40px"
              height="40px"
              alt="slider-next"
            />
          </div>
          <div
            class="prev-ranking absolute bottom-0 left-32 md:top-[40%] md:-left-9 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-prev.svg"
              width="40px"
              height="40px"
              class=""
              alt="slider prev"
            />
          </div>
          <div
            class="slick-ranking flex justify-center m-auto items-center gap-10"
          >
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p>
                    Accredited by National Assessment and Accreditation Council
                    (NAAC) with an A+ Grade
                  </p>
                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg mb-8"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr1.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-center items-center py-6 px-4 bg-white"
                >
                  <p class="pt-12">
                    Ranked amongst the top 75th in India under National
                    Institutional Ranking Framework (NIRF), Ministry of
                    Education, Government of India
                  </p>
                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr2.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p class="pt-16">
                    University Grants Commission (UGC) Entitled Online Degree
                    Programs of Vignan Online are equivalent to on-campus mode
                    of learning
                  </p>

                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr3.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p class="pt-12">
                    Vignan's Foundation for Science, Technology, and Research is
                    Awarded ISO 9001:2015 certification
                  </p>

                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr4.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] md:w-[400px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p class="pt-12">
                    India Today 2021 - Best Universities in India Rank: 25
                  </p>

                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr5.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="w-full p-10 bg-[#f7f9fb]">
      <div class="max-w-7xl mx-auto rounded-xl lg:px-5">
        <div class="text-center font-bold text-lg md:text-2xl uppercase">
          <h2>
            SKILLS THAT ENHANCE
            <span class="text-primary">YOUR LEARNING CURVE</span>
          </h2>
        </div>
        <p class="text-sm font-normal pt-4 text-black">
          BECOME MORE THAN JUST A DEGREE HOLDER WITH SKILLS THAT MAKE YOU STAND
          OUT FROM THE CROWD
        </p>
        <div
          class="flex md:flex-row flex-col justify-center pt-10 gap-10 items-center"
        >
          <div class="lg:hidden">
            <img
              class="object-contain"
              src="../assets/home//skills/banner-element-girl.webp"
              height="301"
              width="275"
              alt
            />
          </div>
          <div class="hidden md:block">
            <img
              src="../assets/home//skills/banner-element-girl.webp"
              class="object-contain"
              height="301"
              width="275"
              alt=""
            />
          </div>
          <div class="flex flex-col md:flex-row justify-center gap-7">
            <div
              class="lg:px-5 lg:px-0 flex flex-col wrap items-center gap-5 w-full"
            >
              <div
                class="w-full relative"
                v-for="(item, index) in accordionUpdate1"
                :key="index"
              >
                <button
                  @click="toggleAccordion(index)"
                  :class="[
                    'accordion-header flex justify-between  items-center gap-4 w-full shadow-md  px-4 py-2 min-h-[60px]',
                    {
                      'active bg-[#CA2526] text-white rounded-t-lg': item.open,
                      'bg-[#ffffff] text-black': !item.open,
                    },
                  ]"
                >
                  <div
                    class="pl-1 text-sm flex justify-start items-center gap-2"
                  >
                    <img
                      :src="item.image"
                      :title="item.alt"
                      :alt="item.alt"
                      :width="26"
                      :height="26"
                      class="h-[26px] w-[26px]"
                    />
                    <div class="pl-1 text-[14px] text-left">
                      {{ item.title }}
                    </div>
                  </div>
                  <div class="">
                    <font-awesome-icon
                      icon="fa-solid fa-angle-down"
                      size="lg"
                    />
                  </div>
                </button>

                <div
                  v-if="item.open"
                  class="absolute z-40 accordion-content w-full bg-[#fff] rounded-b-lg shadow-xl"
                >
                  <div>
                    <div
                      v-for="(subItem, subIndex) in item.subItems"
                      :key="subIndex"
                    >
                      <button
                        @click="toggleSubAccordion(index, subIndex)"
                        class="sub-accordion-header flex justify-between py-2 w-full px-4 focus:outline-none border-b"
                      >
                        <div>
                          <a href="/skills">{{ subItem.title }}</a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="lg:px-4 lg:px-0 flex flex-col wrap items-center gap-5 w-full"
            >
              <div
                class="w-full relative"
                v-for="(item, index) in accordionUpdate2"
                :key="index"
              >
                <button
                  @click="toggleAccordion2(index)"
                  :class="[
                    'accordion-header flex justify-between items-center  gap-4 w-full shadow-md  px-4 py-2 min-h-[60px]',
                    {
                      'active bg-[#CA2526] text-white rounded-t-lg': item.open,
                      'bg-[#ffffff] text-black': !item.open,
                    },
                  ]"
                >
                  <div
                    class="pl-1 text-sm flex justify-start items-center gap-2"
                  >
                    <img :src="item.image" :title="item.alt" :alt="item.alt" />
                    <div class="pl-1 text-[14px]">{{ item.title }}</div>
                  </div>
                  <div class="">
                    <font-awesome-icon
                      icon="fa-solid fa-angle-down"
                      size="lg"
                    />
                  </div>
                </button>
                <div
                  v-if="item.open"
                  class="absolute z-40 accordion-content w-full bg-[#fff] rounded-b-lg shadow-xl"
                >
                  <div>
                    <div
                      v-for="(subItem, subIndex) in item.subItems"
                      :key="subIndex"
                    >
                      <button
                        @click="toggleSubAccordion2(index, subIndex)"
                        class="sub-accordion-header flex justify-between py-2 w-full px-4 focus:outline-none border-b"
                      >
                        <div>
                          <a href="/skills">{{ subItem.title }}</a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            v-if="this.accordionItems1.length > this.accordionUpdate1.length"
            class="flex justify-center mb-5"
          >
            <button
              @click="showAllItems()"
              class="bg-[#81C8DC] border rounded-md px-6 pt-3 pb-2 text-white"
            >
              View All
            </button>
          </div> -->
        </div>
      </div>
    </section>

    <section>
      <div class="w-full mx-auto max-w-7xl pt-8">
        <div class="py-4">
          <h2 class="uppercase text-lg md:text-2xl font-bold pb-4 text-center">
            RE-DEFINING YOUR<span class="text-primary"> RECOGNITIONS</span>
          </h2>
          <p>GET THE ADDED ADVANTAGE OF KICKSTARTING YOUR CAREER</p>
        </div>
        <div
          class="flex flex-col md:flex-row justify-between items-center gap-10"
        >
          <div
            class="md:w-[60%] lg:p-10 md:p-0 w-full flex flex-col justify-between lg:gap-5 gap-2"
          >
            <div class="flex lg:gap-5 gap-3 px-3">
              <div
                class="flex flex-col md:flex-row justify-center items-center gap-10 bg-white border-[#e6ecef] rounded-xl w-[300px] py-5 px-4 md:p-[20px] md:w-[550px] min-h-[180px]"
                style="box-shadow: 0px 1px 10px 2px rgb(238, 237, 237)"
              >
                <img
                  src="../assets/home/red-1.png"
                  title="3000 Additional skills"
                  alt="3000 Additional skills"
                  class="w-[117px] h-[81px]"
                  defer
                />
                <p class="text-center md:text-left">
                  <span class="text-primary font-semibold"
                    >3,000+ <br />additional courses</span
                  >
                  <br />
                  SWAYAM and NPTEL
                </p>
              </div>
              <div
                class="flex flex-col md:flex-row justify-center items-center gap-10 bg-white border-[#e6ecef] rounded-xl w-[300px] py-5 px-4 md:p-[20px] md:w-[550px] min-h-[180px]"
                style="box-shadow: 0px 1px 10px 2px rgb(238, 237, 237)"
              >
                <img
                  src="../assets/home/red-4.png"
                  title="Learning Management System Icon"
                  alt="Learning Management System Icon"
                  class="w-[90px] h-[91px]"
                  defer
                />
                <p class="text-center md:text-left">
                  <span class="text-primary font-semibold"
                    >Learning Management <br />System</span
                  >
                  <br />User friendly interactive <br />web & mobile app
                </p>
              </div>
            </div>

            <div class="flex lg:gap-5 gap-3 px-3">
              <div
                class="flex flex-col md:flex-row justify-center items-center gap-10 bg-white border-[#e6ecef] rounded-xl w-[300px] py-5 px-4 md:p-[20px] md:w-[550px] min-h-[180px]"
                style="box-shadow: 0px 1px 10px 2px rgb(238, 237, 237)"
              >
                <img
                  src="../assets/home/red-2.png"
                  title="Elective Numbers Icon"
                  alt="Elective Numbers Icon"
                  class="w-[88px] h-[110px]"
                  defer
                />
                <p class="text-center md:text-left">
                  <span class="text-primary font-semibold">10+ <br /></span
                  >In-demand electives <br />
                  <span class="text-primary font-semibold">10+ <br /></span
                  >Advanced certifications
                </p>
              </div>
              <div
                class="flex flex-col md:flex-row justify-center items-center gap-10 bg-white border-[#e6ecef] rounded-xl w-[300px] py-5 px-4 md:p-[20px] md:w-[550px] min-h-[180px]"
                style="box-shadow: 0px 1px 10px 2px rgb(238, 237, 237)"
              >
                <img
                  src="../assets/home/red-3.png"
                  title="Learners Support System"
                  alt="Learners Support System"
                  class="w-[116px] h-[98px]"
                  defer
                />
                <p class="text-center md:text-left">
                  <span class="text-primary font-semibold"
                    >Learners Support <br />System</span
                  >
                  <br />Dedicated team to answer your queries & guide you
                  throughout
                </p>
              </div>
            </div>
          </div>

          <div class="md:w-[40%] p-10">
            <img
              src="../assets/home/Online-Education.webp"
              alt=""
              width="355px"
              height="556px"
              class="-mt-[22%]"
              defer
            />
          </div>
        </div>
      </div>
    </section>

    <section class="w-full bg-[#f7f9fb]">
      <div class="w-full mx-auto max-w-7xl py-8">
        <div>
          <h2 class="uppercase text-lg md:text-2xl font-bold pt-8">
            Meet our<span class="text-primary"> Faculty - CDOE</span>
          </h2>
        </div>
        <div class="w-full relative justify-center m-auto">
          <div
            class="next-faculty absolute bottom-0 right-32 md:top-[40%] md:-right-10 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-next.svg"
              class=""
              width="40px"
              height="40px"
              alt="slider-next"
            />
          </div>
          <div
            class="prev-faculty absolute bottom-0 left-32 md:top-[40%] md:-left-9 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-prev.svg"
              width="40px"
              height="40px"
              class=""
              alt="slider prev"
            />
          </div>
          <div class="">
            <div
              class="slick-faculty flex justify-center m-auto items-center lg:gap-5"
            >
              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[335px] md:w-[350px] h-[180px] flex flex-col justify-end py-6 bg-white"
                  >
                    <p class="font-semibold">Dr. N. Veeranjaneyulu</p>
                    <p>M. Tech, Ph.D.</p>
                    <p>Director - CDOE</p>

                    <div
                      class="rounded-full p-2 bg-white absolute top-4 left-[37%] shadow-lg"
                    >
                      <img
                        class="h-[90px] w-[90px] rounded-full"
                        src="../assets/home/faculty/Veeranjaneyulu.webp"
                        width="78px"
                        height="90px"
                        alt="Veeranjaneyulu"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[335px] md:w-[350px] h-[180px] flex flex-col justify-end py-6 bg-white"
                  >
                    <p class="font-semibold">Dr. B. Hari Babu</p>
                    <p>MBA, Ph.D.</p>
                    <p>Asst. Controller of Examination CDOE</p>

                    <div
                      class="rounded-full p-2 bg-white absolute top-4 left-[37%] shadow-lg"
                    >
                      <img
                        class="h-[90px] w-[90px] rounded-full"
                        src="../assets/home/faculty/Dr-B-Hari-Babu.webp"
                        width="76px"
                        height="90px"
                        alt="Dr B Hari Babu"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[335px] md:w-[350px] h-[180px] flex flex-col justify-end py-6 bg-white"
                  >
                    <p class="font-semibold">Prof. K. Kalpana</p>
                    <p>MBA. LLB. Ph.D</p>
                    <p>Additional Director - CDOE</p>

                    <div
                      class="rounded-full p-2 bg-white absolute top-4 left-[37%] shadow-lg"
                    >
                      <img
                        class="h-[90px] w-[90px] rounded-full"
                        src="../assets/home/faculty/Prof-Kalpana.webp"
                        width="776px"
                        height="854px"
                        alt="Prof K Kalpana"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[335px] md:w-[350px] h-[180px] flex flex-col justify-end py-6 bg-white"
                  >
                    <p class="font-semibold">Dr. Ch. Hymavathi</p>
                    <p>MBA Finance & Ph. D.</p>
                    <p>Deputy Director - CDOE</p>

                    <div
                      class="rounded-full p-2 bg-white absolute top-4 left-[37%] shadow-lg"
                    >
                      <img
                        class="h-[90px] w-[90px] rounded-full"
                        src="../assets/home/faculty/Hymavathi.webp"
                        width="117px"
                        height="147px"
                        alt="Hymavathi"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[335px] md:w-[350px] h-[180px] flex flex-col justify-end py-6 bg-white"
                  >
                    <p class="font-semibold">Mr. M.S.Kishan Varma</p>
                    <p>MBA, (Ph. D.)</p>
                    <p>Assistant Director - CDOE</p>

                    <div
                      class="rounded-full p-2 bg-white absolute top-4 left-[37%] shadow-lg"
                    >
                      <img
                        class="h-[90px] w-[90px] rounded-full"
                        src="../assets/home/faculty/Kishan-Varma.webp"
                        width="68px"
                        height="90px"
                        alt="Kishan Varma"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[335px] md:w-[350px] h-[180px] flex flex-col justify-end py-6 bg-white"
                  >
                    <p class="font-semibold">Mr. Shaik Nyamathulla</p>
                    <p>B.Tech, M. Tech, (Ph.D.)</p>
                    <p>Deputy controller of Examinations - CDOE</p>
                    
                    <div
                      class="rounded-full p-2 bg-white absolute top-4 left-[37%] shadow-lg"
                    >
                      <img
                        class="h-[90px] w-[90px] rounded-full"
                        src="../assets/home/faculty/Shaik-Nyamathulla.webp"
                        alt="Shaik Nyamathulla"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="testimonials">&nbsp;</div>
      </div>
    </section>

    <section class="w-full">
      <div class="w-full mx-auto max-w-7xl py-8">
        <div>
          <h2 class="text-lg md:text-2xl font-bold uppercase py-4">
            Our <span class="text-primary">Testimonials</span>
          </h2>
        </div>

        <div class="w-full relative justify-center m-auto">
          <div
            class="next-testimonials absolute bottom-0 right-32 md:top-[40%] md:-right-10 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-next.svg"
              class=""
              width="40px"
              height="40px"
              alt="slider-next"
            />
          </div>
          <div
            class="prev-testimonials absolute bottom-0 left-32 md:top-[40%] md:-left-9 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-prev.svg"
              width="40px"
              height="40px"
              class=""
              alt="slider prev"
            />
          </div>

          <div class="slick-testimonials flex lg:gap-10 gap-1">
            <div
              class="hover:bg-[#d02829] rounded-lg p-2 w-[70%] mx-auto text-black hover:text-white"
              @click="openmodal(video.src, video.name)"
              v-for="video in videos"
              :key="video"
            >
              <div class="flex justify-center">
                <div class="h-[325px]">
                  <div class="py-2 px-2 text-left">
                    <p class="font-semibold text-[16px]">{{ video.name }}</p>
                    <p class="font-[400]">(MBA-Online)</p>
                  </div>

                  <div class="">
                    <img
                      :alt="video.name"
                      :src="video.imgsrc"
                      width="327px"
                      height="184px"
                      class="h-[184px] w-[327px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="modal" id="myModal">
      <div class="modal-content1 mb-20">
        <span class="close" @click="closeModal">&times;</span>
        <iframe
          :src="selectedVideo"
          width="100%"
          height="100%"
          style="border: none"
        ></iframe>
        <p class="font-bold text-center pt-5 pb-2">{{ selectedVideoName }}</p>
        <p class="font-bold text-center">(MBA-Online)</p>
      </div>
    </div>

    <section class="bg-[#f7f9fb]">
      <div class="w-full mx-auto max-w-7xl pt-8 pb-12">
        <div>
          <h2 class="text-center text-lg md:text-2xl font-bold uppercase">
            News &<span class="text-primary"> Media</span>
          </h2>
        </div>
        <div class="w-full pt-10 relative justify-center items-center m-auto">
          <div class="">
            <div
              class="next-media absolute -bottom-8 right-32 md:top-[50%] md:-right-10 cursor-pointer"
            >
              <img
                src="../assets/icon-slider-next.svg"
                class=""
                width="40px"
                height="40px"
                alt="slider-next"
              />
            </div>
            <div
              class="prev-media absolute -bottom-8 left-32 md:top-[50%] md:-left-9 cursor-pointer"
            >
              <img
                src="../assets/icon-slider-prev.svg"
                width="40px"
                height="40px"
                class=""
                alt="slider prev"
              />
            </div>
          </div>

          <div
            class="slick-media flex justify-center p-4 sm: mr-2 items-center m-auto relative gap-10"
          >
            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/MBA-in-Information-Technology-on-Leadership-Proficiency.webp"
                    alt=" Unveiling the Influence of an MBA"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://businessnewsthisweek.com/education/unveiling-the-influence-of-an-mba-in-information-technology-on-leadership-proficiency/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Unveiling the Influence of an MBA in Information
                      Technology...
                    </p>
                    <p class="font-semibold">Business News | Feb 27, 2024</p></a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/Business-Analyst-as-Career-Path-for-2024.webp"
                    alt="Reasons to Choose Business"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                    defer
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://www.apnnews.com/reasons-to-choose-business-analyst-as-career-path-for-2024/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Reasons to Choose Business Analyst as Career Path for 2024
                    </p>
                    <p class="font-semibold">APN News | Jan 31, 2024</p></a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/Business-News-PR.webp"
                    alt="Business News PR"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                    defer
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://businessnewsthisweek.com/business/elevate-your-business-operations-with-google-cloud-join-vignan-onlines-informative-webinar/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Elevate Your Business Operations with Google Cloud ...
                    </p>
                    <p class="font-semibold">Business News | Oct 26, 2023</p></a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/pr-8.webp"
                    alt="PR APN News"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://www.apnnews.com/vignan-online-expands-its-mca-and-bca-programs-offering-to-meet-the-increasing-demand-for-computer-science/?__cf_chl_rt_tk=cYYsre7lfxZ9EgZ3h8vu7SnLP1kmqPHAlxbiodwyfyc-1695890638-0-gaNycGzNDTs"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Vignan Online expands its MCA and BCA Programs offering to
                      meet the...
                    </p>
                    <p class="font-semibold">APN News | Sep 27, 2023</p></a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/hindustan-times.webp"
                    alt="hindustan-times"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white py-5 pl-2 rounded-b-md">
                  <a
                    href="https://www.hindustantimes.com/education/news/what-skills-can-an-online-master-s-in-finance-teach-you-101689779563746.html"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      What skills can an online Master’s in finance teach you
                    </p>
                    <p class="font-semibold">Hindustan Times | July 19, 2023</p>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/india-education-diary.webp"
                    alt="india-education-diary"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                    defer
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://indiaeducationdiary.in/vignan-online-to-host-a-webinar-on-accounting-and-finance-the-journey-from-studying-in-a-classroom-to-working-in-the-corporate-sector/#google_vignette"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Vignan Online to host a webinar on Accounting and Finance
                    </p>
                    <p class="font-semibold">
                      India Education Diary | July 18, 2023
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/Go-earth-pr.webp"
                    alt="Go Earth PR"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://goearth.in/2023/06/13/vignan-online-witnesses-exponential-growth-in-online-program-enrollments/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Vignan Online Witnesses Exponential Growth in Online
                      Program Enrollments
                    </p>
                    <p class="font-semibold">Go Earth | June 13, 2023</p></a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/apn-news.webp"
                    alt="apn-news"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://www.apnnews.com/vignan-online-witnesses-exponential-growth-in-online-program-enrollments/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Vignan Online Witnesses Exponential Growth in Online
                      Program Enrollments
                    </p>
                    <p class="font-semibold">APN News | June 13, 2023</p>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/pr-5.webp"
                    alt="PR The Times of India"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://timesofindia.indiatimes.com/blogs/voices/does-data-analytics-require-coding/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Does data analytics require coding? Online Masters Degree
                      Programs
                    </p>
                    <p class="font-semibold">
                      The Times of India | May 9, 2023
                    </p></a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/the-times-of-india-data-analytics.webp"
                    alt="the-times-of-india-data-analytics"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://timesofindia.indiatimes.com/blogs/voices/does-data-analytics-require-coding/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Data analytics is the procedure of analyzing raw data only
                      to...
                    </p>
                    <p class="font-semibold">
                      The Times of India | May 9, 2023
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/India-Education-Diary-PR.webp"
                    alt="India Education Diary PR"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                    defer
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://indiaeducationdiary.in/vignan-online-launches-visa-vignans-integrated-support-assistance-platform-2/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Vignan Online Launches ‘VISA’ – Vignan’s Integrated
                      Support...
                    </p>
                    <p class="font-semibold">
                      India Education Diary | April 7, 2023
                    </p></a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/financial-express.webp"
                    alt="financial-express"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://www.financialexpress.com/jobs-career/education-vignan-online-launches-visa-vignans-integrated-support-amp-assistance-platform-3036745/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Data analytics is the procedure of analyzing raw data...
                    </p>
                    <p class="font-semibold">
                      Financial Express | April 6, 2023
                    </p></a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/ChatGPT-PR.webp"
                    alt="ChatGPT PR"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://www.apnnews.com/chatgpt-the-key-to-unlocking-million-dollar-potential-vignan-online-webinar-to-dive-deep-into-the-unlimited-potential-of-chatgpt/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      ChatGPT The Key to Unlocking Million-Dollar Potential
                    </p>
                    <p class="font-semibold">APN News | March 9, 2023</p></a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/VOX.webp"
                    alt="PR The Print VOX"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://theprint.in/ani-press-releases/vignan-online-celebrates-its-maiden-anniversary-with-the-launch-of-learners-engagement-platform-vox/1359596/"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      Vignan Online celebrates its Maiden Anniversary with the
                      launch of learners
                    </p>
                    <p class="font-semibold">The Print | Feb 7, 2023</p></a
                  >
                </div>
              </div>
            </div>
            <div>
              <div class="">
                <div class="rounded-t-md justify-center m-auto">
                  <img
                    src="../assets/home/n&m/pr-1.webp"
                    alt="PR India Today"
                    width="375px"
                    height="225px"
                    class="w-[100%]"
                  />
                </div>
                <div class="bg-[#CA2526] text-white p-5 rounded-b-md">
                  <a
                    href="https://www.indiatoday.in/education-today/jobs-and-careers/story/9-reasons-to-pursue-online-mba-in-finance-with-advanced-certificate-in-banking-2322202-2023-01-16"
                    target="_blank"
                  >
                    <p class="text-[15px]">
                      9 reasons to pursue online MBA in Finance with Advanced
                      Certificate...
                    </p>
                    <p class="font-semibold">India Today | Jan 6, 2023</p></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-8">
      <h2 class="uppercase text-lg md:text-2xl font-bold pt-8">
        HIRING <span class="text-primary">COMPANIES</span>
      </h2>
      <HiringComp />
    </section>

    <section>
      <FaQ />
    </section>

    <section>
      <FooterView />
    </section>

    <StickyView />
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import BannerView from "@/components/HomeBanner.vue";
import DegreeProgram from "@/components/DegreeProgram.vue";
import FaQ from "@/components/FaQ.vue";
import HiringComp from "@/components/HiringComp.vue";
import FooterView from "@/components/FooterView.vue";
import StickyView from "@/components/StickyView.vue";
import NavBar from "@/components/NavBar.vue";
import popupView from "@/components/popupView.vue"
export default {
  name: "HomeView",
  components: {
    BannerView,
    DegreeProgram,
    HiringComp,
    FooterView,
    FaQ,
    StickyView,
    NavBar,
    popupView,
  },

  data() {
    return {
      selectedVideo: "",
      selectedVideoName: "",
      videos: [
        {
          name: "Sowmya",
          src: "https://www.youtube.com/embed/2kvf3TQVr8E?si=Tz-pcFilpb2cMEWH",
          imgsrc: require("@/assets/home/media/Sowmya.webp"),
        },
        {
          name: "Mohammad Altaf Hussain",
          src: "https://www.youtube.com/embed/Gm4hoVxaMQQ?si=sjjs08pVy_SE5nob",
          imgsrc: require("@/assets/home/media/Mohammed-Altaf-Hussain.webp"),
        },
        {
          name: "Rajendra",
          src: "https://www.youtube.com/embed/IXjbDHNxBBM?si=y7JcdrTJ7fP3FMSC",
          imgsrc: require("@/assets/home/media/Rajendra.webp"),
        },
        {
          name: "Vijay",
          src: "https://www.youtube.com/embed/Tpyj8WURJ4M?si=-6KHsTCJ57TqAc_-",
          imgsrc: require("@/assets/home/media/Vijay.webp"),
        },
        {
          name: "K M Naidu",
          src: "https://www.youtube.com/embed/UnADDFbRYIQ?si=k4v1CrlhjSa1EhK9",
          imgsrc: require("@/assets/home/media/K-M-Naidu.webp"),
        },
        {
          name: "Swati",
          src: "https://www.youtube.com/embed/Vf-x-1nm0nI?si=_AYhfBPMCstJOAJi",
          imgsrc: require("@/assets/home/media/Swati.webp"),
        },
        {
          name: "Massudan",
          src: "https://www.youtube.com/embed/jCfTOo2sKD8?si=kkaEVMMwG7JWXDsH",
          imgsrc: require("@/assets/home/media/Massudan.webp"),
        },
        {
          name: "Ramya",
          src: "https://www.youtube.com/embed/jc5HNE3oans?si=e8M1Lo7-OHtIgr6L",
          imgsrc: require("@/assets/home/media/Ramya.webp"),
        },
      ],

      accordionUpdate1: [],
      accordionUpdate2: [],

      accordionItems1: [
        {
          image: require("@/assets/home/skills/time-management.svg"),
          title: "Problem-Solving and Critical Thinking Skills",
          alt: "Problem-Solving",

          open: false,
          subItems: [
            {
              title: "Problem-solving",
              open: false,
            },
            {
              title: "Critical thinking",
              open: false,
            },
            {
              title: "Decision making",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/Leadership.svg"),
          title: "Leadership and Management Skills",
          alt: "Leadership and Management",

          open: false,
          subItems: [
            {
              title: "Leadership",
              open: false,
            },
            {
              title: "Strategic thinking",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/emotional-intelligence.svg"),
          title: "Emotional Intelligence and Personal Skills",
          alt: "Emotional Intelligence",

          open: false,
          subItems: [
            {
              title: "Emotional Intelligence",
              open: false,
            },
            {
              title: "Empathy",
              open: false,
            },
            {
              title: "Positive attitude",
              open: false,
            },
            {
              title: "Self-management",
              open: false,
            },
            {
              title: "Resilience",
              open: false,
            },
            {
              title: "Adaptability",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/communication.svg"),
          title: " Communication and Interpersonal Skills",
          alt: "Communication",

          open: false,
          subItems: [
            {
              title: "Communication",
              open: false,
            },
            {
              title: "Interpersonal communication",
              open: false,
            },
            {
              title: "Active listening",
              open: false,
            },
            {
              title: "Public speaking",
              open: false,
            },
          ],
        },
      ],
      accordionItems2: [
        {
          image: require("@/assets/home/skills/Creativity.svg"),
          title: "Creativity and Innovation Skills",
          alt: "Creativity ",

          open: false,
          subItems: [
            {
              title: "Creativity",
              open: false,
            },
            {
              title: "Design thinking",
              open: false,
            },
            {
              title: "Curiosity",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/Computer-literacy.svg"),
          title: "Technical and Computer Skills",
          alt: "Technical Skills",

          open: false,
          subItems: [
            {
              title: "Digital literacy",
              open: false,
            },
            {
              title: "Data analysis",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/Self-management.svg"),
          title: "Industry-Specific Skills",
          alt: "Industry Specific Skills",

          open: false,
          subItems: [
            {
              title: "Writing",
              open: false,
            },
            {
              title: "Negotiation",
              open: false,
            },
            {
              title: "Personal Branding",
              open: false,
            },
            {
              title: "Work ethic",
              open: false,
            },
            {
              title: "Customer service",
              open: false,
            },
            {
              title: "Professionalism",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/Project Management.svg"),
          title: "Time and Project Management Skills",
          alt: "Time and Project Managment Skills",
          open: false,
          subItems: [
            {
              title: "Project management",
              open: false,
            },
            {
              title: "Time management",
              open: false,
            },
            {
              title: "Teamwork",
              open: false,
            },
          ],
        },
      ],
    };
  },

  mounted() {
    $(".slick-faculty").slick({
      infinite: true,
      autoplay: false,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-faculty"),
      nextArrow: $(".next-faculty"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".slick-ranking").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-ranking"),
      nextArrow: $(".next-ranking"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".slick-testimonials").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-testimonials"),
      nextArrow: $(".next-testimonials"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".slick-media").slick({
      infinite: true,
      autoplay: false,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-media"),
      nextArrow: $(".next-media"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    this.updatedList();
    window.addEventListener("click", this.outsideClick);

    setTimeout(() => {
      this.$refs.popup.openPopup();
    }, 1000); 
  },

  methods: {
    openmodal(video, name) {
      const modal = document.getElementById("myModal");
      modal.style.display = "block";
      this.selectedVideo = video;
      this.selectedVideoName = name;
    },

    closeModal() {
      const modal = document.getElementById("myModal");
      modal.style.display = "none";
      this.selectedVideo = "";
    },

    toggleAccordion(index) {
      this.accordionItems1.forEach((item, i) => {
        if (i !== index) {
          item.open = false;
        }
      });
      this.accordionItems2.forEach((item) => {
        item.open = false;
      });
      this.accordionItems1[index].open = !this.accordionItems1[index].open;
    },
    toggleSubAccordion(accordionIndex, subAccordionIndex) {
      this.accordionItems1[accordionIndex].subItems[subAccordionIndex].open =
        !this.accordionItems1[accordionIndex].subItems[subAccordionIndex].open;
    },

    toggleAccordion2(index) {
      this.accordionItems2.forEach((item, i) => {
        if (i !== index) {
          item.open = false;
          this.accordionItems1[index].open = false;
        }
      });
      this.accordionItems1.forEach((item) => {
        item.open = false;
      });
      this.accordionItems2[index].open = !this.accordionItems2[index].open;
    },
    toggleSubAccordion2(accordionIndex, subAccordionIndex) {
      this.accordionItems2[accordionIndex].subItems[subAccordionIndex].open =
        !this.accordionItems2[accordionIndex].subItems[subAccordionIndex].open;
    },

    // showAllItems() {
    //   this.accordionUpdate1 = this.accordionItems1;
    //   this.accordionUpdate2 = this.accordionItems2;
    // },

    updatedList() {
      if (this.accordionItems1.length > 0) {
        this.accordionUpdate1 = this.accordionItems1.slice(0, 4);
      }

      if (this.accordionItems2.length > 0) {
        this.accordionUpdate2 = this.accordionItems2.slice(0, 4);
      }
    },
  },

  watch: {
    showUpdated() {
      this.updatedList();
    },
  },
};
</script>

<style scoped>
/* your modal and overlay styles here */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
}

/* Modal Content */
.modal-content1 {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

@media (max-width: 767.98px) {
  .modal-content1 {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }
}

/* The Close Button */
.close {
  /* color: #aaaaaa; */
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
