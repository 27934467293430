<template>
  <div>
    <!--desktop design-->
    <div
      v-if="isLargeScreenAlumini"
      class="py-12 bg-[#c82228] hidden xl:block mt-[2rem]"
    >
      <section class="max-w-7xl mx-auto w-full min-h-[600px]">
        <div class="p-5 lg:p-0 flex flex-col gap-2">
          <div class="flex justify-center lg:m-4 xl:m-0 items-center">
            <div class="text-center">
              <h2
                class="text-white pb-2 md:text-[35px] text-center text-[24px] font-semibold"
              >
                Alumni Speak
              </h2>
            </div>
          </div>

          <div
            class="flex flex-row flex-wrap gap-5 justify-between lg:m-4 xl:m-0"
          >
            <div
              class="lg:w-[290px] lg:h-[494px] w-[30%] relative"
              v-for="data in aluminaiData"
              :key="data.id"
            >
              <template v-if="data.id == is_active_video">
                <iframe
                  width="290"
                  height="494"
                  :src="data.link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </template>

              <template v-else>
                <div class="" @click="is_active_video = data.id">
                  <div
                    class="absolute bottom-0 left-0 w-full h-full"
                    style="
                      background: transparent
                        linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
                        0% no-repeat padding-box;
                      box-shadow: 0px 1px 6px #00000029;
                      border-radius: 14px;
                      opacity: 1;
                      height: 150px;
                    "
                  ></div>
                  <img
                    :src="data.bannerImg"
                    alt="alumini"
                    class="h-[494px] rounded-2xl"
                    loading="lazy"
                    decoding="async"
                  />
                  <!-- <div class="flex justify-center items-center">
                    <img
                      src="@/assets/alumni-lp/play.svg"
                      alt="alumini"
                      srcset=""
                      class="absolute top-[200px] md:top-[200px] w-[75px] h-[75px]"
                    />
                  </div> -->

                  <div class="flex justify-center">
                    <img
                      :src="data.smallImg"
                      alt="alumini"
                      class="rounded-full w-[120px] h-[120px] absolute bottom-[17%] border p-2 border-[#F8C300]"
                      loading="lazy"
                      decoding="async"
                    />

                    <p class="text-white absolute bottom-[20px]">
                      <span class="text-[24px] font-bold">
                        {{ data.name }}
                      </span>
                      <br />
                      {{ data.subject }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!--desktop design-->
    <!--mob design-->
    <div
      v-if="isSmallScreenAlumini"
      class="bg-[#c82228] py-5 md:hidden mt-[2rem]"
    >
      <section class="max-w-7xl mx-auto w-full min-h-[600px]">
        <div class="p-5 flex flex-col gap-2">
          <div class="flex justify-center items-center">
            <div class="text-center m-auto justify-center">
              <h1
                class="text-white text-center md:text-[35px] text-[24px] font-bold"
              >
                Alumni Speak
              </h1>
            </div>
          </div>

          <div class="aluminai-carousel">
            <div
              class="w-full relative !flex !justify-center !items-center"
              v-for="data in aluminaiData"
              :key="data.id"
            >
              <template v-if="data.id == is_active_video">
                <iframe
                  class="w-[320px] md:w-[290px]"
                  width="290"
                  height="493"
                  :src="data.link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </template>
              <template v-else>
                <div class="" @click="is_active_video = data.id">
                  <div
                    class="absolute bottom-0 left-0 w-full h-full"
                    style="
                      background: transparent
                        linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
                        0% no-repeat padding-box;
                      box-shadow: 0px 1px 6px #00000029;
                      border-radius: 14px;
                      opacity: 1;
                      height: 150px;
                    "
                  ></div>
                  <img
                    :src="data.bannerImg"
                    alt="alumini"
                    class="mx-auto w-[290px] h-[493px] rounded-2xl"
                    loading="lazy"
                    decoding="async"
                  />

                  <div class="flex justify-center">
                    <img
                      :src="data.smallImg"
                      alt="alumini"
                      class="rounded-full w-[120px] h-[120px] absolute bottom-[20%] border p-2 border-[#F8C300]"
                      loading="lazy"
                      decoding="async"
                    />

                    <p class="text-white absolute bottom-[20px]">
                      <span class="text-[18px] font-bold">
                        {{ data.name }}
                      </span>
                      <br />
                      {{ data.subject }}
                    </p>
                  </div>
                </div>
              </template>
             
            </div>
           
          </div>
          <a href="#applyBox" class="md:hidden">
      <div
        class="bg-white text-[#c82228] py-2 px-12 w-max m-auto rounded-full font-bold text-xl cursor-pointer"
      >
        Enquire Now
      </div>
    </a>
        </div>
      </section>
    </div>
    <!--mob design-->
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "AluminaiView",
  data() {
    return {
      is_active_video: "0",
      isSmallScreenAlumini: false,
      isLargeScreenAlumini: false,
      aluminaiData: [
        {
          id: 1,
          bannerImg: require("@/assets/alumni-lp/testimonials.webp"),
          smallImg: require("@/assets/alumni-lp/testimonials.webp"),
          name: "Name",
          subject: "MBA",
          link: "#",
        },
        {
          id: 2,
          bannerImg: require("@/assets/alumni-lp/testimonials.webp"),
          smallImg: require("@/assets/alumni-lp/testimonials.webp"),
          name: "Name",
          subject: "MBA",
          link: "#",
        },
        {
          id: 3,
          bannerImg: require("@/assets/alumni-lp/testimonials.webp"),
          smallImg: require("@/assets/alumni-lp/testimonials.webp"),
          name: "Name",
          subject: "MBA",
          link: "#",
        },
        {
          id: 4,
          bannerImg: require("@/assets/alumni-lp/testimonials.webp"),
          smallImg: require("@/assets/alumni-lp/testimonials.webp"),
          name: "Name",
          subject: "MBA",
          link: "#",
        },
      ],
    };
  },

  watch: {
    async isSmallScreenAlumini(newValue) {
      console.log("Watcher Activated when changed to mobile screen");
      console.log("Alumini newvalue for mobile ::::::::", newValue);
      if (newValue) {
        $(document).ready(function () {
          $(`.aluminai-carousel`).slick({
            infinite: true,
            autoplay: true,
            prevArrow: false,
            nextArrow: false,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            responsive: [
              {
                breakpoint: 950,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 650,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          });
        });
      }
    },
  },
  beforeMount() {
    const elementToRemoveMOb = document.querySelector(".alumni-carousel");
    if (elementToRemoveMOb) {
      elementToRemoveMOb.remove();
    }
  },

  async mounted() {
    if (window.innerWidth < 768) {
      this.isSmallScreenAlumini = true;
    } else {
      this.isLargeScreenAlumini = true;
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        this.isSmallScreenAlumini = true;
      } else {
        this.isLargeScreenAlumini = true;
      }
    });
  },
};
</script>

<style></style>
