<template>
  <div>
    <NavBar/>

      <!--Career Advancement Services-->
      <div class="w-full py-5 px-5 mb-5 bg-[#f7f9fb]">
        <div class="max-w-7xl mx-auto md:p-24 w-full">
          <div class="container flex flex-col items-center text-center m-auto justify-center align-middle md:w-9/12 mt-16"> 
      <h1 class="text-2xl mb-3"><span class="md:text-5xl pb-8 text-xl text-[#d02829]">Thank you</span><br>For inquiring about our {{Electives.name}}! If there's anything you'd like to know or if you need assistance, our counselor will be reaching out shortly. While you wait, feel free to check out our page.</h1>
      <router-link class="lg:text-lg font-semibold text-white bg-[#d02829] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl" :to="{name: 'home' }">Go back to Home</router-link>
      </div>
 </div>
      </div>
      <FooterView />
  </div>
</template>

<script>
import axios from "axios";
import FooterView from "@/components/FooterView.vue";
import NavBar from "@/components/NavBar.vue";

export default {
 components: {
    FooterView,
    NavBar,
  },
  data() {
    return {
      Electives: [],
    };
  },

  //   props: ["elective"],

  created() {
    this.electiveCall();
 
    console.log(this.Electives);
  },

  methods: {
    async electiveCall() {
      const slug = this.$route.params.slug; // Get the slug from the route
      const apiUrl = `${process.env.VUE_APP_API}/course/program-list/?slug=${slug}`;

      try {
        const resp = await axios.get(apiUrl);
        if (resp.data.status === 200) {
          this.Electives = resp.data.data[0];
          console.log(this.Electives);
        }
        else {
          console.log('else')
          this.$router.push({name :'PageNotFound',params:{pathMatch:'page-not-found'}})
        }
      } catch (error) {
        console.log(error);
      }
    },

  },
};
</script>

<style scoped>

</style>
