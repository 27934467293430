<template>
    <NavBar />
    <div class="w-full">
      <section>
        <div class="w-full px-5 pb-5">
          <div class="max-w-7xl mx-auto w-full">
            <ol
              class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]"
            >
              <li><a href="/">Home</a></li>
              <li>> Student Corner</li>
            </ol>
            <div
              class="relative my-8 overflow-x-auto shadow-md sm:rounded-lg border-collapse border border-slate-300"
            >
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
              <thead
                class="text-xs text-gray-700 uppercase bg-[#CA2526] text-white"
              >
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 border border-slate-300 w-12"
                    >
                      S.No.
                    </th>
                    <th scope="col" class="px-6 py-3 border border-slate-300">
                      Information
                    </th>
                    <th scope="col" class="px-6 py-3 border border-slate-300">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody>
              
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      1
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                      <p>
                        Programme details including brochures or programme guides
                        such as
                      </p>
                      <ul class="px-4 py-2 list-disc">
                        <li>Name of the programme</li>
                        <li>Duration</li>
                        <li>Eligibility for enrolment</li>
                        <li>Programme fee</li>
                      </ul>
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/2.1_66580.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Programmes Offered Under CDOE</span></a
                      >
                    </td>
                  </tr>
                  <!-- <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      2
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                      <p>Programme-wise information on:</p>
                      <ul class="px-4 py-2 list-disc">
                        <li>Syllabus</li>
                        <li>Programme structure with credit points</li>
                      </ul>
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://vignanonline.com/online-mba/marketing"
                        target="_blank"
                        ><span class="text-[#007bff]"
                          >https://vignanonline.com/online-mba/marketing</span
                        ></a
                      >
                    </td>
                  </tr> -->
  
                  <tr
                    class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      2
                    </th>
                    <td class="px-6 py-4 border border-slate-300 font-semibold">
                      Faculty and Non Teaching Staff
                    </td>
                    <!-- <td class="px-6 py-4 border border-slate-300">
                      <a href="aaa" target="_blank"
                        ><span class="text-[#007bff]">Link</span></a
                      >
                    </td> -->
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      &nbsp;
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                      <p>Programme-wise faculty details</p>
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/programme-wise-faculty-details_85024.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Programme-wise List of Faculties and Courses Delivered</span></a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      &nbsp;
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                      <p>List of Supporting staff</p>
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/List of Supporting staff _91518.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Details of Supporting Staff at CDOE</span></a
                      >
                    </td>
                  </tr>
  
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      3
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                    Sample Self Learning Materials
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <p>
                        <a
                          href="https://api.vignanonline.com/media/MCA_VIGNAN(8-May)_66730.pdf"
                          target="_blank"
                          ><span class="text-[#007bff]">MCA</span></a
                        >
                      </p>
                      <p>
                        <a
                          href="https://api.vignanonline.com/media/BCA_Syllabus(VIGNAN-8May)_61768.pdf"
                          target="_blank"
                          ><span class="text-[#007bff]">BCA</span></a
                        >
                      </p>
                      <p>
                        <a
                          href="https://api.vignanonline.com/media/MBA_Finance-Business-Environment-and-Stratergy-Website-Content_60585.pdf"
                          target="_blank"
                          ><span class="text-[#007bff]">MBA - Finance</span></a
                        >
                      </p>
                      <p>
                        <a
                          href="https://api.vignanonline.com/media/MBA_HR-Entreprenuership-Website-Content_12937.pdf"
                          target="_blank"
                          ><span class="text-[#007bff]">MBA - HRM</span></a
                        >
                      </p>
                      <p>
                        <a
                          href="https://api.vignanonline.com/media/MBA_General-Principles-of-Economics-and-Markets_89417.pdf"
                          target="_blank"
                          ><span class="text-[#007bff]">MBA - General</span></a
                        >
                      </p>
                      <p>
                        <a
                          href="https://api.vignanonline.com/media/BBA_FINANCIAL-ACCOUNTING-WEBSITE-CONTENT_64839.pdf"
                          target="_blank"
                          ><span class="text-[#007bff]">BBA</span></a
                        >
                      </p>
                    </td>
                  </tr>
                  <tr
                    class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      4
                    </th>
                    <td class="px-6 py-4">
                      Learner Support Centres (for ODL Mode)
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/LSC_for-ODL_56019.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Details of Learner Support Centre for Students Studying in ODL Mode</span></a
                      >
                    </td>
                  </tr>
                  <tr
                    class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      5
                    </th>
                    <td class="px-6 py-4 border border-slate-300 font-semibold">
                      Examination
                    </td>
                    <td class="px-6 py-4"></td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      &nbsp;
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                      List of the “Examination Centres” along with the number of
                      learners in each centre
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/Examination-Centres_12686.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Details of Examination Centre for Students Studying in ODL Mode</span></a
                      >
                    </td>
                  </tr>
  
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      &nbsp;
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                 Examination Infrastructure and Guidelines
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/Mettl_Examination_Manual_2024_82491.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Examination Guidelines for Students</span></a
                      >
                    </td>
                  </tr>
  
                  <tr
                    class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      6
                    </th>
                    <td class="px-6 py-4 border border-slate-300">Academic Calendar of Ongoing Semesters</td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/Calender-of-events_35034.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]"
                          >Calendar of events - Link</span
                        ></a
                      >
                    </td>
                  </tr>
               
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      
    </div>
    <FooterView />
  </template>
  
  <script>
  import NavBar from "@/components/NavBar.vue";
  import FooterView from "@/components/FooterView.vue";
  export default {
    name: "PrivacyPolicy",
    components: {
      FooterView,
      NavBar,
    },
  };
  </script>
  
  <style scoped></style>
  