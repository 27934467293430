<template>
  <div class="w-full px-5">
    <div class="max-w-7xl mx-auto w-full py-8 relative">
      <div
        class="prev-rank absolute bottom-0 left-24 md:top-[40%] md:-left-9 cursor-pointer"
      >
        <img src="../assets/icon-slider-prev.svg"  width="40px" height="40px" alt="slider-previous" />
      </div>

      <div
        class="flex justify-center m-auto items-center gap-8 py-5"
        :class="hiring_compo"
      >
        <div class="rounded-4xl flex justify-center m-auto items-center">
          <img
            src="../assets/hiring/EY_Logo.webp"
            alt="EY-Logo"
            class="w-[190px] justify-center m-auto p-10px shadow-md h-[60px] rounded-[60px]"
            defer
          />
        </div>

        <div class="rounded-4xl flex justify-center m-auto items-center">
          <img
            src="../assets/hiring/Facebook_Logo.webp"
            alt="Facebook-Logo"
            class="w-[190px] justify-center m-auto p-10px shadow-md h-[60px] rounded-[60px]"
            defer
          />
        </div>

        <div class="rounded-4xl flex justify-center m-auto items-center">
          <img
            src="../assets/hiring/Google_Logo.webp"
            alt="Google-Logo"
            class="w-[190px] justify-center m-auto p-10px shadow-md h-[60px] rounded-[60px]"
            defer
          />
        </div>

        <div class="rounded-4xl flex justify-center m-auto items-center">
          <img
            src="../assets/hiring/HDFC_Logo.webp"
            alt="HDFC-Logo"
            class="w-[190px] justify-center m-auto p-10px shadow-md h-[60px] rounded-[60px]"
            defer
          />
        </div>

        <div class="rounded-4xl flex justify-center m-auto items-center">
          <img
            src="../assets/hiring/Honeywell_Logo.webp"
            alt="Honeywell-Logo"
            class="w-[190px] justify-center m-auto p-10px shadow-md h-[60px] rounded-[60px]"
            defer
          />
        </div>

        <div class="rounded-4xl flex justify-center m-auto items-center">
          <img
            src="../assets/hiring/ICICI_Logo.webp"
            alt="ICICI-Logo"
            class="w-[190px] justify-center m-auto p-10px shadow-md h-[60px] rounded-[60px]"
            defer
          />
        </div>
      </div>

      <div
        class="next-rank absolute bottom-0 right-24 md:top-[40%] md:-right-10 cursor-pointer"
      >
        <img src="../assets/icon-slider-next.svg"  width="40px" height="40px"  alt="slider-next" />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "HiringComp",
  data() {
    return {
      hiring_compo: "hiring_components",
    };
  },

  async mounted() {
    await this.hiringCompo();

  },

  methods: {
    hiringCompo() {
      $(`.${this.hiring_compo}`).slick({
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: $(".next-rank"),
        prevArrow: $(".prev-rank"),
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
  },
};
</script>

<style>
.slick-slider .slick-track {
  display: flex;
  gap: 12px;
}
</style>