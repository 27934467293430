<template>
    <div>
        <section>

            <div class="pb-8">
              <div class="w-full mx-auto my-10 mb-2 justify-center">
        <div class="flex flex-col justify-center items-center pb-8 text-center">
          <h2 class="text-2xl font-bold">
            FAQ<span class="text-primary">s</span>
          </h2>
          <div class="p-4"><img src="@/assets/Heading-underline.png" /></div>
        </div>
        </div>
      <!-- Category dropdown -->
      <!-- <div v-if="selectedCategory" @change="fetchDataForCategory"  class="flex max-w-3xl justify-center items-center m-auto gap-1 mb-0 list-none border-b-4 border-gray-400">
 
        <div class="mb-px w-full mr-2 last:mr-0 text-center">
            <button
              class="text-[15px] font-bold uppercase px-5 py-3"
             v-for="category in faqCategoryList"
          :key="category.id"
          :value="category.title"
        >
        {{category.title}}
        </button>
        </div>
      </div> -->

      <div class="flex max-w-md justify-center items-center m-auto gap-1 mb-0 list-none border-b-4 border-gray-400">
    <div class="category-option mb-2 flex flex-row " :class="{ 'active-category': !selectedCategory}" @click="handleCategoryChange(null)">
    <div
      v-for="category in faqCategoryList"
      :key="category.id"
      :value="category.title"
      class="category-option flex p-2"
      :class="{ 'active-category text-red-600 font-semibold border-spacing-4 border-red-600': selectedCategory === category.title }"
      @click="handleCategoryChange(category.title)"
    >
      {{ category.title }}
    </div>
    </div>
  </div>

  <!-- <div class="mt-8 max-w-3xl justify-center items-center m-auto">
          <form>
            <div class="flex">
              <label
                for="search-dropdown"
                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >Your Email</label
              >
              <button
                id="dropdown-button"
                data-dropdown-toggle="dropdown"
                class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-white bg-[#d0393b] border-[#d0393b] rounded-s-lg hover:bg-[#d0393b] focus:ring-4 focus:outline-none focus:ring-[#d0393b] dark:bg-[#d0393b] dark:hover:bg-[#d0393b] dark:focus:ring-[#d0393b] dark:text-white dark:border-[#d0393b]"
                type="button"
              >
                Search
                <svg
                  class="w-2.5 h-2.5 ms-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <div
                class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
              >
                <ul
                  class="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdown-button"
                >
                  <li>
                    <button
                      type="button"
                      class="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Search
                    </button>
                  </li>
                </ul>
              </div>
              <div class="relative w-full">
                <input
                  type="search"
                  id="search-dropdown"
                  class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                  placeholder="Search Mockups, Logos, Design Templates..."
                  required
                />
                <button
                  type="submit"
                  class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-[#d0393b] rounded-e-lg border border-[#d0393b] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                  <span class="sr-only">Search</span>
                </button>
              </div>
            </div>
          </form>
        </div> -->


      <!-- Display FAQs -->
      <!-- <div v-if="faqList.length > 0">
        <div v-for="faq in faqList" :key="faq.id">
          <h3>{{ faq.title }}</h3>
          <p>{{ faq.content }}</p>
        </div>
      </div> -->

      <div class="max-w-7xl mx-auto">
          <div class="accordion mt-10 bg-trasparent w-full mx-auto" v-if="faqList.length > 0">
            <div
              v-for="(el, index) in this.faqList.slice(this.start, this.end)"
              :key="el"
              class="accordion-item border-2 rounded-lg shadow-lg m-3 text-left"
            >
              <h3 class="accordion-header mb-0">
                <button
                  @click="faqCollapse"
                  class="accordion-button font-semibold relative flex items-center justify-between w-full py-4 px-5 text-sm text-[#004271] hover:text-[#0a58ca] text-left border-0 rounded-none transition focus:outline-none"
                  type="button"
                  :data-target="'#text-' + index"
                >
                  {{ el.title }}
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-circle-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-[#e01b24] text-xl"
                  />
                </button>
              </h3>
              <div
                :id="'text-' + index"
                class="accordion-collapse border-0 hidden text-sm md:text-base text-black"
              >
                <div class="accordion-body pb-4 px-5 flex flex-col gap-3">
                  <div class="font-montserrat" v-html="el.content"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <button
            v-if="this.LandingFaq.length >= this.end"
            @click="this.end = this.end + 5"
            class="bg-[#CA2526] text-white font-semibold px-8 py-2 rounded-lg mt-5"
          >
            View More
          </button>
          <button
            v-if="this.LandingFaq.length <= this.end"
            @click="this.end = 5"
            class="bg-[#CA2526] text-white font-semibold px-8 py-2 rounded-lg mt-5"
          >
            View Less
          </button> -->
        </div>

      <!-- Display message if no FAQs found -->
      <!-- <div v-else>
        <p>No FAQs available for the selected category.</p>
      </div> -->
    </div>
        </section>
    </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
export default {


  data() {
    return {
      start: 0,
      end: 5,
      faqCategoryList: [],
      selectedCategory: null,
      faqList: [],
      LandingFaq:[]
    };
  },
created() {
//   this.electiveCall();
    this.fetchDataForCategory();
    this.fetchFAQCategoryList();
    setTimeout(() => {
      this.is_widget = true;
    }, 2000);
},

updated(){
   console.log(this.selectedCategory)
  },

  methods: {

    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass('rotate-180')) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass('rotate-180');
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },

    handleCategoryChange(category) {
      const electiveSlug = this.$route.params.slug;
      console.log("electiveSlug::::",electiveSlug)
      this.selectedCategory = category;
      this.currentPage = 1;
      this.fetchDataForCategory();
    },

    fetchFAQCategoryList() {
      axios
        .get(`${process.env.VUE_APP_API}/lp/electives/faq/category-list`)
        .then((response) => {
          this.faqCategoryList = response.data.data;
          this.handleCategoryChange(this.faqCategoryList[0].title)
          
        })
        .catch((error) =>
          console.error("Error fetching FAQ category list:", error)
        );
    },
    async fetchDataForCategory() {
      try {
        if (this.selectedCategory) {
          const response = await axios.get(
            `${process.env.VUE_APP_API}/lp/electives/faq/?slug=${this.$route.params.slug}&category__title=${this.selectedCategory}`
          );
          this.faqList = response.data.data;
        } else {
          this.faqList = [];
        }
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
      }
    },
  },
};
</script>

<style lang="">
/* .active-category{
border-bottom: 4px;
border-color: red;
color:red;
} */
</style>