<template>
  <!-- <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/brands.min.css"
    integrity="sha512-W/zrbCncQnky/EzL+/AYwTtosvrM+YG/V6piQLSe2HuKS6cmbw89kjYkp3tWFn1dkWV7L1ruvJyKbLz73Vlgfg=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  /> -->
  <div>
    <div
      ref="chatBox"
      class="chatBox bg-white w-full sm:w-96 h-[70vh] lg:h-[95vh] xl:h-[80vh] 2xl:h-[75vh] rounded-xl border shadow-2xl overflow-hidden fixed right-0 lg:right-5 bottom-0 lg:bottom-5 xl:bottom-5 sm:right-5 z-40 pb-[90px]"
      style="display: none"
    >
      <!-- chat header -->

      <div
        class="w-full flex flex-row justify-between items-center px-5 h-[80px] bg-gradient-to-r from-[#d02829] via-[#d02829] to-[#004271] shadow-lg"
      >
        <div>
          <img
            src="@/assets/logo-white.webp"
            alt="logo"
            class="w-[180px]"
            loading="lazy"
            decoding="async"
          />
        </div>
        <div class="flex items-center gap-5">
          <button
            class="rounded-full w-5 h-5 bg-[#fff] text-[#d3272a] cursor-pointer"
            type="button"
            @click="minimize"
          >
            <img src="./../assets/caret-down-solid.webp" class="-mt-1 p-0.5" />
          </button>
        </div>
      </div>
      <!-- chat header -->
      <div
        class="h-[calc(100%-50px)] px-2 pt-5 overflow-y-auto chatbackroundimage"
        ref="chatbox_messages"
      >
        <div
          class="max-w-[calc(100%-30px)] flex flex-row items-start justify-start gap-2 mb-3"
        >
          <img src="@/assets/vig-chatbot.gif" alt="chatbot" width="40" />
          <div>
            <div
              class="bg-white border-[#000] w-auto min-w-[50px] text-xs sm:text-sm text-left px-5 py-3 rounded-md"
            >
              Hello there, welcome to Vignan Online! <br />
              <br />
              I'm Niaa, how can I help you today?
            </div>
          </div>
        </div>

        <div
          v-if="!isFormSubmitted"
          class="flex flex-col items-center gap-2 mb-5"
        >
       
          <div class="w-full mx-5 flex flex-col gap-4">
            <p
              class="bg-white shadow-md text-black w-80 min-w-[50px] text-xs text-left px-5 py-3 rounded-md"
              v-if="item.title"
              v-html="item.title"
            ></p>
            <div
              :id="`form_container_${item.uniqueId}`"
              class="p-5 rounded-md bg-primary max-w-md flex flex-col justify-center bg-white shadow"
            >
              <form @submit.prevent="handleSubmit(item.uniqueId)">
                <div class="relative w-full mb-4">
                  <input
                    type="text"
                    @keydown="isLetter($event)"
                    class="w-full px-3 py-2 text-xs rounded-md bg-gray-100 border outline-none placeholder:text-xs"
                    :id="`name_${item.uniqueId}`"
                    name="name"
                    placeholder="Enter Name"
                    required
                  />
                </div>
                <div class="relative w-full mb-4">
                  <input
                    type="text"
                    @keydown="isNumeric($event)"
                    maxlength="10"
                    class="w-full px-3 py-2 text-xs rounded-md bg-gray-100 border outline-none placeholder:text-xs"
                    :id="`phone_${item.uniqueId}`"
                    name="phone"
                    placeholder="Enter Phone"
                    required
                  />
                </div>
                <div class="relative w-full mb-4">
                  <input
                    type="email"
                    class="w-full px-3 py-2 text-xs rounded-md bg-gray-100 border outline-none placeholder:text-xs"
                    :id="`email_${item.uniqueId}`"
                    name="email"
                    placeholder="Enter Email"
                    required
                  />
                </div>
                <button
                  :id="`btn_${item.uniqueId}`"
                  type="submit"
                  class="bg-[#d02829] text-white text-xs px-5 py-2 rounded-full font-medium mt-6"
                >
                  Submit
                </button>
                <div
                  v-if="submissionStatus === 'error'"
                  class="text-red-500 text-xs mt-2"
                >
                  {{ errorMessage }}
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          v-if="isFormSubmitted"
          class="flex flex-col items-center gap-2 mb-5 ml-5"
        >
          <input
            type="hidden"
            :id="`ProgramsOffered_${this.inputId}`"
            value="Programs offered"
          />
          <button
            type="submit"
            name="button"
            @click="callchatbot(`ProgramsOffered_${this.inputId}`)"
            class="w-72 border border-[#000] bg-white hover:text-white hover:bg-[#d3282a] hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md"
          >
            I want to know about the programs offered
          </button>

          <!-- <input type="hidden" :id="`Counselor_${this.inputId}`" value="Counselor" />
              <button type="submit" name="button" @click="callchatbot(`Counselor_${this.inputId}`)"
                class="w-72  border border-[#000] hover:text-white hover:bg-[#d3282a] hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md">
                I wish to speak with a counselor
              </button> -->

          <input
            type="hidden"
            :id="`AboutVignan_${this.inputId}`"
            value="About Vignan"
          />
          <button
            type="submit"
            name="button"
            @click="callchatbot(`AboutVignan_${this.inputId}`)"
            class="w-72 border border-[#000] bg-white hover:text-white hover:bg-[#d3282a] hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md"
          >
            Tell me a little bit about Vignan Online
          </button>

          <input
            type="hidden"
            :id="`ApplyNow_${this.inputId}`"
            value="Apply Now"
          />
          <button
            type="submit"
            name="button"
            @click="callchatbot(`ApplyNow_${this.inputId}`)"
            class="w-72 border border-[#000] bg-white hover:text-white hover:bg-[#d3282a] hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md"
          >
            I need help with applying to a program
          </button>

          <input
            type="hidden"
            :id="`Query_${this.inputId}`"
            value="Query not on this list"
          />
          <button
            type="submit"
            name="button"
            @click="callchatbot(`Query_${this.inputId}`)"
            class="w-72 border border-[#000] bg-white hover:text-white hover:bg-[#d3282a] hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md"
          >
            My query is not on this list
          </button>
        </div>

        <div
          class="flex flex-col absolute bottom-0 left-0 w-full"
          v-if="isFormSubmitted"
        >
        
          <div class="flex flex-row gap-2 px-2 chatbackroundimage">
            <input
              class="w-full flex p-1 mb-1 bg-white border border-black rounded-xl"
              placeholder="Type your message here..."
              type="text"
              id="chatbot_input"
              name="chatbot_input"
              @keyup.enter="callchatbot('chatbot_input')"
            />
            <button
              class="w-9 p-1 mb-1 h-8 bottom-[-2] rounded-md"
              type="button"
              @click="callchatbot('chatbot_input')"
            >
              <img src="./../assets/send.webp" />
            </button>
          </div>
          <a
            class="text-white font-semibold"
            href="https://apply.vignanonline.com/"
          >
            <div
              class="w-full flex flex-row justify-center items-center bg-gradient-to-r from-[#d02829] via-[#d02829] to-[#004271]"
            >
              Powered by
              <img
                class="w-5 h-5"
                target="_blank"
                title="Vignan ONLINE"
                src="./../assets/thunder.webp"
              />
              VIGNAN ONLINE
            </div>
          </a>
        </div>

        <template v-for="chat in allChats" :key="chat">
          <template v-if="chat.type == '1'">
            <div
              class="max-w-[calc(100%-30px)] flex flex-row items-start justify-end gap-2 ml-auto mb-5"
            >
              <div
                class="bg-[#d02829] w-auto min-w-[50px] text-white font-bold text-xs sm:text-sm text-right px-5 py-3 rounded-2xl"
              >
                {{ chat.data.userInput }}
              </div>
              <font-awesome-icon
                icon="fa-solid fa-user"
                class="bg-white p-2 border border-black rounded-full"
              />
            </div>
          </template>

          <template v-if="chat.type == '2'">
            <div
              class="max-w-[calc(100%-30px)] flex flex-row items-start justify-end gap-2 ml-auto mb-5"
            >
              <div
                class="bg-[#d02829] w-auto min-w-[50px] text-white font-bold text-xs sm:text-sm text-right px-5 py-3 rounded-2xl"
              >
                {{ chat.data.slang_response }}
              </div>
              <font-awesome-icon
                icon="fa-solid fa-user"
                class="bg-white p-2 border border-black rounded-full"
              />
            </div>
          </template>

          <template v-if="chat.type == '3'">
            <div
              class="max-w-[calc(100%-30px)] flex flex-row items-start justify-start gap-4 mb-5"
            >
              <img :src="chat.data.image" alt="CHATDATA" width="40" />
              <div>
                <div
                  class="bg-white border border-black rounded-xl w-auto min-w-[50px] text-xs sm:text-sm text-left px-5 py-3 mb-3"
                  v-html="chat.data.text"
                ></div>
                <div
                  v-if="chat.data.text2"
                  class="bg-white w-auto min-w-[50px] text-xs sm:text-sm text-left px-5 py-3 mb-3 border border-black rounded-xl"
                >
                  {{ chat.data.text1 }}
                </div>
                <div
                  v-if="chat.data.text2"
                  class="bg-white w-auto min-w-[50px] text-xs sm:text-sm text-left px-5 py-3 mb-3 border border-black rounded-xl"
                >
                  {{ chat.data.text2 }}
                </div>
              </div>
            </div>
          </template>

          <template v-if="chat.type == '4'">
            <div
              v-for="item in chat.data.text"
              :key="item"
              class="max-w-[calc(100%-30px)] flex flex-row items-start justify-start gap-2 mb-5"
            >
              <img :src="chat.data.image" alt="chatbot" width="50" />
              <div
                class="bg-white w-auto min-w-[50px] primary text-xs sm:text-sm text-left px-5 py-3 border border-black rounded-xl"
              >
                <span class="font-semibold"
                  >{{ Object.entries(item)[0][0] }}:</span
                >
                {{ Object.entries(item)[0][1] }}
              </div>
            </div>
          </template>

          <template v-if="chat.type == '5'">
            <div
              v-for="item in chat.data.urls"
              :key="item"
              class="w-full flex justify-center mb-5"
            >
              <a :href="Object.entries(item)[0][1]" target="_blank">
                <button
                  type="submit"
                  name="button"
                  class="w-80 border border-[#000] hover:bg-[#d3272a] hover:text-white hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md"
                >
                  {{ Object.entries(item)[0][0] }}
                </button>
              </a>
            </div>
          </template>
          <!-- for name formn -->
          <template v-if="chat.type == '6'">
            <div
              v-for="item in chat.data.buttons"
              :key="item"
              class="w-full flex justify-center mb-5"
            >
              <template v-if="item.payload == 'callme'">
                <div class="flex flex-col gap-4">
                  <p
                    class="bg-white w-80 min-w-[50px] text-xs sm:text-sm text-left px-5 py-3 border border-black rounded-xl"
                  >
                    You want to apply for Vignan Online Programs, but need help
                    with regard to payment or filling the application form, our
                    counselors will guide you with the same, you can reach out
                    to them at +91 8877661755.
                  </p>
                  <!-- <form @submit.prevent="form_Name(item.uniqueId)">
                    <div
                      class="p-5 mx-5 rounded-md bg-primary w-72 max-w-md flex flex-col justify-center bg-[#d02829]"
                    >
                      <div class="relative w-full mb-4">
                        <input
                          type="text"
                          @keydown="isLetter($event)"
                          class="w-full px-3 py-2 text-xs sm:text-sm rounded-md border-none bg-white outline-none placeholder:text-xs"
                          :id="`name_${item.uniqueId}`"
                          name="name"
                          placeholder="Enter Name"
                          required
                        />
                      </div>
                      <div class="relative w-full mb-4">
                        <input
                          type="text"
                          maxlength="10"
                          @keydown="isNumeric($event)"
                          minlength="10"
                          class="w-full px-3 py-2 text-xs sm:text-sm rounded-md border-none bg-white outline-none placeholder:text-xs"
                          :id="`phone_${item.uniqueId}`"
                          name="phone"
                          placeholder="Enter Phone"
                          required
                        />
                      </div>
                      <div class="relative w-full mb-4">
                        <input
                          type="email"
                          class="w-full px-3 py-2 text-xs sm:text-sm rounded-md border-none bg-white outline-none placeholder:text-xs"
                          :id="`email_${item.uniqueId}`"
                          name="email"
                          placeholder="Enter Email"
                          required
                        />
                      </div>

                      <button
                        :id="`btn_${item.uniqueId}`"
                        class="bg-[#000] text-white text-xs sm:text-sm px-5 py-2 rounded-full font-medium mt-6"
                      >
                        Submit
                      </button>
                    </div>
                  </form> -->
                </div>
              </template>

              <template v-else>
                <input
                  type="hidden"
                  :id="item.uniqueId"
                  :name="item.uniqueId"
                  :value="Object.entries(item)[1][1]"
                />
                <button
                  type="submit"
                  name="button"
                  @click="callchatbot(item.uniqueId)"
                  class="w-80 border border-[#000] hover:bg-[#d3272a] hover:text-white hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md"
                >
                  {{ Object.entries(item)[0][1] }}
                </button>
              </template>
            </div>
          </template>
          <!-- for Phone form -->
          <template v-if="chat.type == '7'">
            <div
              v-for="item in chat.data.text"
              :key="item"
              class="max-w-[calc(100%-30px)] flex flex-row items-start justify-start gap-2 mb-5"
            >
              <img
                src="@/assets/vig-chatbot.gif"
                alt="codinius_initial"
                width="50"
              />
              <div
                class="bg-white w-auto min-w-[50px] text-xs sm:text-sm text-left px-5 py-3 border border-black rounded-xl"
              >
                {{ item }}
              </div>
            </div>
            <input
              type="hidden"
              :id="`MainMenu_${this.inputId}`"
              value="Main Menu"
            />
            <button
              type="submit"
              name="button"
              @click="callchatbot(`MainMenu_${this.inputId}`)"
              class="w-72 mb-5 border border-[#000] hover:text-white hover:bg-[#d3282a] hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md"
            >
              Main Menu
            </button>
          </template>

          <!-- <template v-if="chat.type == '7'">
              <div v-for="item in chat.data.buttons" :key="item" class="w-full flex justify-center mb-5">
                <template v-if="item.payload == 'phoneform'">
                  <div class="p-5 mx-5 rounded-md bg-[#d02829] w-72 max-w-md flex flex-col justify-center">
                    <div class="relative w-full mb-4">
                      <input type="tel" @keypress="onlyNumber" maxlength="10"
                        class="w-full px-3 py-2 text-xs sm:text-sm rounded-md border-none bg-white outline-none placeholder:text-xs"
                        :id="`phoneNo_${chat.id}`" namse="phone" placeholder="Phone Number" />
                        <br>
                        <input type="tel"
                        class="w-full px-3 py-2 text-xs sm:text-sm rounded-md border-none mt-3 bg-white outline-none placeholder:text-xs"
                        :id="`email_${chat.id}`" name="email" placeholder="Email Address" />
                        
                    </div>
                    
                    <button :id="`btn_${chat.id}`" @click="form_Phone(chat.id)" class="bg-[#00aeff] text-white text-xs sm:text-sm px-5 py-2 rounded-full font-medium mt-6">
                      Send OTP
                    </button>
                  </div>
                </template>
    
    
    
                <template v-else>
                  <input type="hidden" :id="item.uniqueId" :name="item.uniqueId" :value="Object.entries(item)[1][1]" />
                  <button type="submit" name="button" @click="callchatbot(item.uniqueId)"
                    class="w-72 border border-[#000] hover:bg-[#d3272a] hover:text-white hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md">
                    {{ Object.entries(item)[0][1] }}
                  </button>
                </template>
              </div>
            </template> -->

          <template v-if="chat.type == '8'">
            <div v-for="item in chat.data.text" :key="item">
              <div
                class="max-w-[calc(100%-30px)] flex flex-row items-start justify-start gap-2 mb-5"
              >
                <img
                  src="@/assets/vig-chatbot.gif"
                  alt="chatBotimg"
                  width="50"
                />
                <div>
                  <div
                    class="bg-white w-auto min-w-[50px] text-xs sm:text-sm text-left px-5 py-3 border border-black rounded-xl"
                  >
                    {{ item }}
                  </div>
                  <br />
                </div>
              </div>
            </div>

            <div
              v-for="item in chat.data.buttons"
              :key="item"
              class="w-full flex justify-center mb-5"
            >
              <input
                type="hidden"
                :id="item.uniqueId"
                :name="item.uniqueId"
                :value="item.payload"
              />
              <button
                type="submit"
                name="button"
                @click="callchatbot(item.uniqueId)"
                class="w-72 border border-[#000] hover:bg-[#d3272a] hover:text-white hover:font-bold text-xs sm:text-sm px-2 py-1.5 rounded-md"
              >
                {{ item.payload }}
              </button>
            </div>
            <div
              class="p-5 mx-5 rounded-md bg-[#d02829] w-72 max-w-md flex flex-col justify-center"
            >
              <div class="relative w-full mb-4">
                <input
                  type="tel"
                  class="w-full px-3 py-2 text-xs sm:text-sm rounded-md border-none mt-3 bg-white outline-none placeholder:text-xs"
                  :id="`Otp_${chat.id}`"
                  name="otp"
                  placeholder="Enter The OTP"
                />
              </div>
              <button
                :id="`btn_${chat.id}`"
                @click="form_otp(chat.id)"
                class="bg-[#00aeff] text-white text-xs sm:text-sm px-5 py-2 rounded-full font-medium mt-6"
              >
                verify OTP
              </button>
            </div>
          </template>
        </template>
      </div>
    </div>

    <div class="chatImg z-50" @click="maximize" ref="chatIcon">
      <div class="group fixed bottom-5 right-5 cursor-pointer">
        <!-- <img
                class="border-2 rounded-full overflow-hidden bg-[#000] "
                src="@/assets/vig-chatbot.gif"
                width="60"
                alt="chatbot_icon"
                height="70"
              /> -->
        <div class="relative">
          <img
            @click="maximize"
            class="chatImg z-50 min-w-[40px] min-h-[40px] xl:w-12 xl:h-12 rounded-full p-1 bg-[#fff] shadow-2xl border-2 border-[#d02829] overflow-hidden cursor-pointer fixed bottom-28 md:bottom-5 md:right-5 right-1 z-50 h-[5%]"
            aria-label="Open chat with JOSH"
            src="@/assets/vig-chatbot.gif"
          />
          <div
            class="absolute md:-top-[39px] -top-[90px] left-0 px-5 py-2 bg-[#d02829] rounded-md text-[#000] font-bold text-xs sm:text-sm z-10 whitespace-nowrap opacity-0 group-hover:-left-[210px] group-hover:opacity-100 before:absolute before:-right-2 before:w-5 before:h-5 before:bg-[#d02829] before:rounded before:rotate-45 transition-all duration-500 ease-in-out"
          >
            Hello I'm Niaa.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import $ from "jquery";
//   import RatingStar from "@/components/RatingView.vue";

export default {
  data() {
    return {
      inputId: "",
      display_default: "",
      allChats: [],
      chatIcon: null,
      chatBox: null,
      maxStars: 5,
      hasCounter: true,
      grade: 0,
      phone: "",
      item: "",
      isFormSubmitted: false,
      errorMessage: null,
      submissionStatus: null,
      phonenumber: "",
      utmSourceCampaign: "",
    };
  },
  components: {},

  mounted() {
    this.chatIcon = this.$refs.chatIcon;
    this.chatBox = this.$refs.chatBox;
    this.populate_chat_data();
  },
  created() {
    console.log(this.$route.params.slug, "router");
    this.utmSourceCampaign = this.$route.query.utm_campaign || "";
  },
  methods: {
    // updatePhoneNumber() {
    //   if (this.$route.path === "/lp/online-degree-program.html") {
    //     this.phonenumber = "+91 8877661755";
    //   } else {
    //     this.phonenumber = "+91 8877661139";
    //   }
    // },
    // updatePhoneNumber() {
    //   this.phonenumber = "+91 8877661755"; // Static phone number
    // },

    ...mapActions(["register", "userDetailsGet"]),

    form_Name(id) {
      var chatbody = this.$refs.chatbox_messages;
      var name = document.getElementById(`name_${id}`);
      var phone = document.getElementById(`phone_${id}`);
      var email = document.getElementById(`email_${id}`);
      var btnId = document.getElementById(`btn_${id}`);

      let formdata = new FormData();
      formdata.append("name", name.value);
      formdata.append("phone", phone.value);
      formdata.append("email", email.value);
      formdata.append("campaign", this.campaign);
      formdata.append("source", this.source);
      formdata.append("medium", this.medium);
      console.log(formdata, "formdata");
      console.log(this.$route.path);

      if (name && phone && email) {
        axios
          .post(process.env.VUE_APP_CHAT_API + "/formsubmitted/", formdata)
          // .post("http://127.0.0.1:8000/formsubmitted/", formdata)
          .then((resp) => {
            console.log("resp", resp, formdata, "Let see", resp.data.chat_data);
            if (resp.data.chat_data.text != "") {
              var chatDict = {
                type: "7",
                data: {
                  text: resp.data.chat_data.text,
                  buttons: resp.data.chat_data.buttons,
                },
              };
              console.log("asdugawduiasoidf", chatDict);

              this.allChats.push(chatDict);
              // btnId.style.display = 'none';
              name.disabled = true;
              phone.disabled = true;
              email.disabled = true;
              btnId.disabled = true;
              // var formContainer = document.getElementById(`form_container_${id}`);
              // formContainer.style.display = 'none'
            }
          })
          .catch((error) => {
            console.log(error);
            btnId.style.display = "block";
          })
          .finally(() => {
            chatbody.scrollTo(0, chatbody.scrollHeight);
          });
      }
    },
    async handleSubmit(uniqueId) {
      try {
        await this.submitForm(uniqueId);
        this.isFormSubmitted = true;
        this.errorMessage = null;
        this.submissionStatus = "success";
      } catch (error) {
        console.error("Error handled in submitForm:", error);
      }
    },
    async submitForm(id) {
      try {
        const lsq_name = document.getElementById(`name_${id}`).value;
        const lsq_phone = document.getElementById(`phone_${id}`).value;
        const lsq_email = document.getElementById(`email_${id}`).value;
        const formData = [
          { Attribute: "FirstName", Value: lsq_name },
          { Attribute: "EmailAddress", Value: lsq_email },
          { Attribute: "Phone", Value: lsq_phone },
          { Attribute: "Source", Value: "chat" },
          { Attribute: "SourceMedium", Value: "Paid-Marketing" },
          { Attribute: "SourceCampaign", Value: this.utmSourceCampaign },
        ];
        console.log("Enter data : ", formData);

        const response = await axios.post(
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r822806c3d4d4cb1c22e55fc36ca478bf&secretKey=95b5dad573299c9d675384a7e9afe0476d44e0bb",
          formData
        );

        if (response.data.data) {
          console.log("Form submission response:", response);
          var chatDict = {
            type: "7",
            data: {
              text: ["Your Enquiry has been submitted successfully"],
            },
          };
          this.allChats.push(chatDict);
          this.submissionStatus = "success";
        } else {
          throw new Error("Form submission error");
        }
      } catch (error) {
        console.error("Form submission error:", error);
        this.errorMessage =
          error.response?.data?.error?.data?.error_list?.common ||
          "There was an error submitting the form. Please try again.";
        this.submissionStatus = "error";
      }
    },

    lsq_push(id) {
      var lsq_name = document.getElementById(`name_${id}`).value;
      var lsq_phone = document.getElementById(`phone_${id}`).value;
      var lsq_email = document.getElementById(`email_${id}`).value;

      {
        const dataJson = [
          {
            Attribute: "FirstName",
            Value: lsq_name,
          },
          {
            Attribute: "EmailAddress",
            Value: lsq_email,
          },
          {
            Attribute: "Phone",
            Value: lsq_phone,
          },
          {
            Attribute: "Source",
            Value: "chat",
          },
          {
            Attribute: "SourceMedium",
            Value: "Paid Marketing",
          },
          {
            Attribute: "SourceCampaign",
            Value: this.utmSourceCampaign,
          },
        ];

        axios
          .post(
            "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r822806c3d4d4cb1c22e55fc36ca478bf&secretKey=95b5dad573299c9d675384a7e9afe0476d44e0bb",
            dataJson
          )
          .catch((error) => {
            console.log(error.response.data.ExceptionMessage);
            this.message = error.response.data.ExceptionMessage;
          });
      }
    },


    rate(star) {
      if (typeof star === "number" && star <= this.maxStars && star >= 0) {
        this.grade = this.grade === star ? star - 1 : star;
        console.log("ratingL ", this.grade);
        let formdata = new FormData();
        let data = {
          csrfmiddlewaretoken: "{{ csrf_token }}",
        };
        formdata.append("count", this.grade);

        axios
          .post(process.env.VUE_APP_API + "/formfeedback/", formdata, data)
          // .post("http://127.0.0.1:8000/formfeedback/", formdata, data)
          .then((resp) => {
            console.log("feedbackresp", resp);

            var chatDict = {
              type: "3",
              data: {
                text: resp.data.chat_data,
              },
            };
            console.log("asdugawduiasoidf", chatDict);

            this.allChats.push(chatDict);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    minimize() {
      $(this.chatBox).slideUp();
      $(this.chatIcon).fadeIn();
      document.getElementById("lpcall").style.display = "block";
    },
    maximize() {
      $(this.chatIcon).fadeOut();
      $(this.chatBox).slideDown();
      document.getElementById("lpcall").style.display = "none";
    },

    isNumeric(event) {
      if (event.key.length === 1 && isNaN(Number(event.key))) {
        event.preventDefault();
      }
    },
    isLetter(event) {
      var keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode > 47 && keyCode < 58) {
        event.preventDefault();
      }
    },

    callchatbot(id) {
      var chatbody = this.$refs.chatbox_messages;

      var user_input_element = document.getElementById(id);
      var user_querry = user_input_element.value;
      //user_input_element.value = "";
      var chatbot_input = document.getElementById("chatbot_input");
      chatbot_input.value = "";

      let newform = new FormData();
      newform.append("fname", user_querry);
      let data = {
        csrfmiddlewaretoken: "{{ csrf_token }}",
      };

      let final = chatbody.scrollHeight;
      axios
        .post(process.env.VUE_APP_API + "/ajax_chatbot_json/", newform, data)
        .then((resp) => {
          console.log(resp);
          let phoneNumberPattern = /\+91 8877661139/; // Original phone number
          let newPhoneNumber = "+91 8877661755"; // New phone number
          if (resp.data.chat_data.text) {
            resp.data.chat_data.text = resp.data.chat_data.text.replace(
              phoneNumberPattern,
              newPhoneNumber
            );
          }
          if (resp.data.chat_data.text1) {
            resp.data.chat_data.text1 = resp.data.chat_data.text1.replace(
              phoneNumberPattern,
              newPhoneNumber
            );
          }
          if (resp.data.chat_data.text2) {
            resp.data.chat_data.text2 = resp.data.chat_data.text2.replace(
              phoneNumberPattern,
              newPhoneNumber
            );
          }
          if (
            resp.data.chat_data.text !=
            "Slang Word Detected You will be blocked!!"
          ) {
            let position = user_querry.search("/");

            if (position != -1) {
              user_querry = user_querry.replace("/", "");
            }

            let chatDict = {
              type: "1",
              data: {
                userInput: user_querry,
              },
            };

            this.allChats.push(chatDict);
          } else {
            var slang_response = "*******************************";

            let chatDict = {
              type: "2",
              data: {
                slang_response: slang_response,
              },
            };

            this.allChats.push(chatDict);
          }

          if (resp.data.chat_data.text) {
            let text = resp.data.chat_data.text;
            var type_data = typeof text;

            let image = require("@/assets/vig-chatbot.gif");

            if (type_data == "string") {
              let chatDict = {
                type: "3",
                data: {
                  image: image,
                  text: resp.data.chat_data.text,
                  text1: resp.data.chat_data.text1,
                  text2: resp.data.chat_data.text2,
                },
              };

              this.allChats.push(chatDict);
            } else {
              let chatDict = {
                type: "4",
                data: {
                  image: image,
                  text: resp.data.chat_data.text,
                },
              };

              this.allChats.push(chatDict);
            }
          }

          if (resp.data.chat_data.urls != "None") {
            let chatDict = {
              type: "5",
              data: {
                urls: resp.data.chat_data.urls,
              },
              id: Math.random(),
            };

            this.allChats.push(chatDict);
          }
          console.log(resp.data.chat_data.buttons);
          if (
            resp.data.chat_data.buttons != undefined &&
            resp.data.chat_data.buttons != "None"
          ) {
            var result = resp.data.chat_data.buttons.map((item) => {
              item["uniqueId"] = Math.random();
              return item;
            });
            var chatDict = {
              type: "6",
              data: {
                buttons: result,
              },
            };

            this.allChats.push(chatDict);
          }
        })
        .finally(() => {
          // Scrolling logic after fetching data
          setTimeout(() => {
            // Scroll a little bit more than just the top of the next message
            chatbody.scrollTo(0, final);
          }, 0);
        });
    },
    populate_chat_data() {
      var chatbody = this.$refs.chatbox_messages;

      let data = {
        csrfmiddlewaretoken: "{{ csrf_token }}",
      };

      axios
        .get(process.env.VUE_APP_API + "/get_chatbot_json/", data)
        // .get("http://127.0.0.1:8000/get_chatbot_json/", data)

        .then((resp) => {
          this.inputId = Math.random();

          if (resp.data.response == "None") {
            this.display_default =
              "Kindly share your mobile number with us, and we'll be happy to reach out and provide you with the details you're looking for!Come'on drop in those digits!";
          } else {
            for (var i = 0; i < data.response.timestamp.length; i++) {
              var user_querry = data.response.user_request[i];
              let position = user_querry.search("/");
              if (position != -1) {
                user_querry = user_querry.replace("/", "");
              }
              if (data.response.save != 1) {
                chatbody.innerHTML += ` <div class="flex flex-row items-start justify-end gap-2 ">
                                             <div class="text-right rounded-br-full rounded-l-full px-3 py-1 bg-[#ff9c00] border shadow-xl text-sm w-auto !max-w-7/12 place-self-end text-right gap-2 break-all">
                                             ${user_querry}
                                             </div>
                                             <font-awesome-icon icon="fa-solid fa-user" class="bg-white  p-2 border border-black rounded-full" />
                                            </div>`;
              } else {
                var text = "Slang Words Detected";
                chatbody.innerHTML += ` <div class="flex flex-row items-start justify-end gap-2 ">
                                              <div class="text-right rounded-br-full rounded-l-full px-3 py-1 bg-[#ff9c00] border shadow-xl text-sm w-auto !max-w-7/12 place-self-end text-right gap-2 break-all">
                                             ${text}
                                              </div>
                                              <font-awesome-icon icon="fa-solid fa-user" class="bg-white  p-2 border border-black rounded-full" />
                                             </div>`;
              }

              var chatbot_response = data.response.josh_reply[i];

              let image = require("@/assets/vig-chatbot.gif");

              if (chatbot_response.text) {
                let text = chatbot_response.text;
                var type_data = typeof text;

                if (type_data == "string") {
                  chatbody.innerHTML += `<div class="flex flex-row items-start justify-start gap-2 ">
                                                       <img src="${image}" alt="chatbot" width="50" class="rounded-full">
                                                       <div class="rounded-bl-xl rounded-r-xl my-1 px-3 py-1.5 bg-white border shadow-xl text-xs min-w-auto w-64 text-left">
                                                       ${text}
                                                       </div>
                                                       </div>`;
                } else {
                  var text_display = " ";

                  text.forEach((element) => {
                    text_display += `<div class="flex flex-row items-start justify-start gap-2 ">
                                                                <img src="${image}" alt="chatbot" width="50" class="rounded-full">
        
                                                                 <div class="bg-gray-50 rounded-md p-1.5 my-2 border border-green-200 text-xs md:text-sm">
                                                                 <p class="py-1 text-[#110b7c] font-mono italic"><span class="font-semibold p-2">${
                                                                   Object.entries(
                                                                     element
                                                                   )[0][0]
                                                                 }:</span> ${
                      Object.entries(element)[0][1]
                    } <br></p>
                                                                 </div>
                                                              </div>`;
                  });

                  chatbody.innerHTML += text_display;
                }
              }

              if (chatbot_response.urls) {
                var urls = chatbot_response.urls;
                var url_buttons = " ";

                urls.forEach((element) => {
                  url_buttons += `<a href="${
                    Object.entries(element)[0][1]
                  }" class="py-1 text-[#110b7c] font-mono italic" target="_blank">
                                                        <button type="submit" name="button"
                                                        class=" w-72 w-40 border  hover:bg-gradient-to-r from-[#00aeff] via-[#00aeff] to-[#110b7c] text-[#110b7c] font-mono italic hover:text-black hover:bg-gradient-to-r from-[#ca8a04] via-[#fde047] to-[#ca8a04] hover:font-bold px-2  border-1 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100  hover:-translate-y-1 hover:scale-100 hover:bg-gradient-to-r from-[#00aeff] via-[#00aeff] to-[#110b7c] duration-300">
                                                        ${
                                                          Object.entries(
                                                            element
                                                          )[0][0]
                                                        } </button>
                                                        </a>`;
                });

                chatbody.innerHTML += url_buttons;
              }

              //for buttons
              if (chatbot_response.buttons) {
                var button_buttons = " ";
                var buttons = chatbot_response.buttons;

                buttons.forEach((element) => {
                  this.inputId = "f_name-" + String(Math.random());
                  button_buttons += `<input type="text" id="${
                    this.inputId
                  }" name="${this.inputId}" value="${
                    Object.entries(element)[1][1]
                  }" style="display:none">
                                                                 <button type="submit" name="button" onclick="callchatbot('${
                                                                   this.inputId
                                                                 }')"
                                                                 class="w-64  border  hover:bg-gradient-to-r from-[#00aeff] via-[#00aeff] to-[#110b7c] text-[#110b7c] font-mono italic hover:text-black hover:bg-gradient-to-r from-[#ca8a04] via-[#fde047] to-[#ca8a04] hover:font-bold px-2 my-2 border-1 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100  hover:-translate-y-1 hover:scale-100 hover:bg-gradient-to-r from-[#00aeff] via-[#00aeff] to-[#110b7c] duration-300">
                                                                 ${
                                                                   Object.entries(
                                                                     element
                                                                   )[0][1]
                                                                 } </button>`;
                });

                chatbody.innerHTML += button_buttons;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.chatbackroundimage {
  background: url("@/assets/bgpattern.webp") repeat center center;
}

/* .primary {
        color: #110b7c;
      } */

.chatbgprimary {
  color: #000;
}
</style>
