// import VueRouter from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
// import Vue from 'vue';
// import App from './App.vue';
import HomeView from "../views/HomeView.vue";
import DegreeProgramView from "../views/OnlineProgramView.vue";
import SkillsView from "../views/SkillsView.vue";
// import ElectiveView from '../views/ElectiveView.vue';
import MbaView from "../views/MbaView.vue";
import McaView from "../views/McaView.vue";
import BbaView from "../views/BbaView.vue";
import BcaView from "../views/BcaView.vue";
import MainBlogs from "../views/MainBlogs.vue";
import ProgramView from "../views/ProgramsView.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import RefundPolicy from "../components/RefundPolicy.vue";
import BlogView from "../views/BlogView";
import LP_MBA from "../views/LP_MBA.vue";
import LP_Online from "../views/LP_Online.vue";
import LP_Thankyou from "../views/LP_Thankyou.vue";
import LP_Onlinepg from "@/views/LP_Onlinepg";
import LP_Onlineug from "@/views/LP_Onlineug";
import LP_Onlinety from "@/views/thankyou/LP_Onlinety";
import LP_Onlineugty from "@/views/thankyou/LP_Onlineugty";
import LP_Onlinepgty from "@/views/thankyou/LP_Onlinepgty";
import OutreachView from "@/views/landingView.vue";
import OutreachViewty from "@/views/thankyou/landingViewty";
import PageNotFoundView from "@/views/PageNotFoundView";
import ThankYouBbaView from "@/views/thankyou/ThankYouBbaView.vue";
import ThankYouBcaView from "@/views/thankyou/ThankYouBcaView.vue";
import ThankYouMbaView from "@/views/thankyou/ThankYouMbaView.vue";
import ThankYouMcaView from "@/views/thankyou/ThankYouMcaView.vue";
import ThankYouOnlineView from "@/views/thankyou/ThankYouOnlineView.vue";
import ThankYouNewlp from "../views/ThankYouNewlp.vue"
import NewLpView from "@/views/NewLpView.vue";
import OdlView from "@/views/OdlView.vue";
import DisclosureView from "@/views/DisclosureView.vue";
import FgmView from "@/views/FgmView.vue";
import PenrolmentView from "@/views/PenrolmentView.vue";
import QualityView from "@/views/QualityView.vue";
import StudentCornerView from "@/views/StudentCornerView.vue";
import AlumniLp from "@/views/AlumniLp.vue"
// Vue.config.productionTip = false;

// Vue.use(VueRouter);

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFoundView,
  },
  {
    path: "/:slug",
    name: "ProgramView",
    component: ProgramView,
    props: true,
  
  },
 {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/online-degree-programs",
    name: "DegreeProgramView",
    component: DegreeProgramView,
    beforeEnter: (to, from, next) => {
      // Check if the path ends with a slash
      if (to.path.endsWith("/")) {
        // Remove the trailing slash and redirect
        const newPath = to.path.slice(0, -1);
        next({ path: newPath, replace: true });
      } else {
        // Continue with the navigation
        next();
      }
    },
  },

  {
    path: "/online-mba/:slug",
    name: "MbaView",
    component: MbaView,
    beforeEnter: (to, from, next) => {
      // Check if the path ends with a slash
      if (to.path.endsWith("/")) {
        // Remove the trailing slash and redirect
        const newPath = to.path.slice(0, -1);
        next({ path: newPath, replace: true });
      } else {
        // Continue with the navigation
        next();
      }
    },
  },
  {
    path: "/online-mca/:slug",
    name: "McaView",
    component: McaView,
    beforeEnter: (to, from, next) => {
      // Check if the path ends with a slash
      if (to.path.endsWith("/")) {
        // Remove the trailing slash and redirect
        const newPath = to.path.slice(0, -1);
        next({ path: newPath, replace: true });
      } else {
        // Continue with the navigation
        next();
      }
    },
  },
  {
    path: "/online-bba/:slug",
    name: "BbaView",
    component: BbaView,
    beforeEnter: (to, from, next) => {
      // Check if the path ends with a slash
      if (to.path.endsWith("/")) {
        // Remove the trailing slash and redirect
        const newPath = to.path.slice(0, -1);
        next({ path: newPath, replace: true });
      } else {
        // Continue with the navigation
        next();
      }
    },
  },
  {
    path: "/online-bca/:slug",
    name: "BcaView",
    component: BcaView,
    beforeEnter: (to, from, next) => {
      // Check if the path ends with a slash
      if (to.path.endsWith("/")) {
        // Remove the trailing slash and redirect
        const newPath = to.path.slice(0, -1);
        next({ path: newPath, replace: true });
      } else {
        // Continue with the navigation
        next();
      }
    },
  },
  {
    path: "/about-us",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      beforeEnter: (to, from, next) => {
        // Check if the path ends with a slash
        if (to.path.endsWith("/")) {
          // Remove the trailing slash and redirect
          const newPath = to.path.slice(0, -1);
          next({ path: newPath, replace: true });
        } else {
          // Continue with the navigation
          next();
        }
      },
  },
  {
    path: "/contact-us",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
      beforeEnter: (to, from, next) => {
        // Check if the path ends with a slash
        if (to.path.endsWith("/")) {
          // Remove the trailing slash and redirect
          const newPath = to.path.slice(0, -1);
          next({ path: newPath, replace: true });
        } else {
          // Continue with the navigation
          next();
        }
      },
  },
  {
    path: "/skills",
    name: "SkillsView",
    component: SkillsView,
  },
  {
    path: "/blogs",
    name: "MainBlogsView",
    component: MainBlogs,
    beforeEnter: (to, from, next) => {
      // Check if the path ends with a slash
      if (to.path.endsWith("/")) {
        // Remove the trailing slash and redirect
        const newPath = to.path.slice(0, -1);
        next({ path: newPath, replace: true });
      } else {
        // Continue with the navigation
        next();
      }
    },
  },
  {
    path: "/blogs/:blog/",
    name: "BlogsView",
    component: BlogView,
    props: true,
 
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/feedback-and-grievance-management",
    name: "FgmView",
    component: FgmView,
  },
  {
    path: "/past-enrolments",
    name: "PenrolmentView",
    component: PenrolmentView,
  },
  {
    path: "/quality-and-assurance",
    name: "QualityView",
    component: QualityView,
  },
  {
    path: "/student-corner",
    name: "StudentCornerView",
    component: StudentCornerView,
  },
  {
    path: "/statutory-disclosures",
    name: "DisclosureView",
    component: DisclosureView,
  },
  {
    path: "/refundpolicy",
    name: "RefundPolicy",
    component: RefundPolicy,
  },
  {
    path: "/lp/:slug",
    name: "NewLpView",
    component: NewLpView,
  },
  {
    path: "/lp/vignanodl",
    name: "vignanodl",
    component: OdlView,
  },
  {
    path: "/lp/thankyou/:slug",
    name: "ThankYouNewlp",
    component: ThankYouNewlp,
    props: true,
  },
  {
    path: "/lp/:slug.html",
    name: "LP_MBA",
    component: LP_MBA,
   
  },
  {
    path: "/lp/online-degree-program",
    name: "LpnewView",
    component: () =>
      import(/* webpackChunkName: "AllProgram" */ "@/views/LpnewView.vue"),
  },
  {
    path: "/lp/thank-you",
    name: "lpThankyou",
    component: () =>
      import(
        /* webpackChunkName: "AllProgram" */ "@/views/LpnewThankyouView.vue"
      ),
  },
  {
    path: "/online-mba/:slug/thank-you",
    name: "ThankYouMbaView",
    component: ThankYouMbaView,
    props: true,
  },
  {
    path: "/online-bba/:slug/thank-you",
    name: "ThankYouBbaView",
    component: ThankYouBbaView,
    props: true,
  },
  {
    path: "/online-bca/:slug/thank-you",
    name: "ThankYouBcaView",
    component: ThankYouBcaView,
    props: true,
  },
  {
    path: "/online-mca/:slug/thank-you",
    name: "ThankYouMcaView",
    component: ThankYouMcaView,
    props: true,
  },
  {
    path: "/:slug/thank-you",
    name: "ThankYouOnlineView",
    component: ThankYouOnlineView,
    props: true,
  },
  {
    path: "/lp/thankyou/:slug",
    name: "LP_Thankyou",
    component: LP_Thankyou,
    props: true,
  },
  {
    path: "/lp/online-degree-program.html",
    name: "LP_Online",
    component: LP_Online,
  },
  {
    path: "/lp/alumni",
    name: "AlumniLp",
    component: AlumniLp,
  },
  {
    path: "/lp/online-ug-degree-program.html",
    name: "LP_Onlineug",
    component: LP_Onlineug,
  },
  {
    path: "/lp/online-pg-degree-program.html",
    name: "LP_Onlinepg",
    component: LP_Onlinepg,
  },
  {
    path: "/lp/thankyou/online-pg-degree-program.html",
    name: "LP_Onlinepgty",
    component: LP_Onlinepgty,
  },
  {
    path: "/lp/thankyou/online-ug-degree-program.html",
    name: "LP_Onlineugty",
    component: LP_Onlineugty,
  },
  {
    path: "/lp/thankyou/online-degree-program.html",
    name: "LP_Onlinety",
    component: LP_Onlinety,
  },
  {
    path: "/lp/online-degree",
    name: "OutreachView",
    component: OutreachView,
  },
  {
    path: "/lp/thankyou/online-degree",
    name: "OutreachViewty",
    component: OutreachViewty,
  },



  // re-Direction

  {
    path: "/online-mca-degree/machine-learning-and-artificial-intelligence",
    redirect: "/masters-computer-applications-online-degree",
  },
  {
    path: "/online-bba-degree/general1",
    redirect: "/online-bba/general",
  },
  {
    path: "/blog/world-students-day-a-day-dedicated-to-the-future-leaders",
    redirect: "/blogs/world-students-day-a-day-dedicated-to-the-future-leaders",
  },
  {
    path: "/node/26",
    redirect: "/",
  },
  {
    path: "/online-mba/hospital-and-healthcare-management",
    redirect: "/online-mba/healthcare-and-hospital-management",
  },
  {
    path: "/blog/how-hard-is-a-business-administration-degree",
    redirect: "/blogs/how-hard-is-a-business-administration-degree",
  },
  {
    path: "/online-mba-degree-programs/online-mba-logistics-and-supply-chain-management",
    redirect: "/online-mba/logistics-and-supply-chain-management",
  },
  {
    path: "/blog/financial-management-essentials-mastering-the-fundamentals-for-success",
    redirect: "/blogs/financial-management-essentials-mastering-the-fundamentals-for-success",
  },
  {
    path: "/node/253",
    redirect: "/",
  },

  {
    path: "/online-bca-degree/cyber-security",
    redirect: "/bachelor-computer-application-online-degree",
  },
  {
    path: "/node/252",
    redirect: "/",
  },
  {
    path: "/node/247",
    redirect: "/",
  },
  {
    path: "/online-mca-degree/full-stack-development",
    redirect: "/masters-computer-applications-online-degree",
  },
  {
    path: "/node/251",
    redirect: "/",
  },
  {
    path: "/node/162",
    redirect: "/",
  },

  {
    path: "/online-mba-degree",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mba-degree/marketing-and-finance",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-degree-program",
    redirect: "/online-degree-programs",
  },
  {
    path: "/node/164",
    redirect: "/",
  },
  {
    path: "/node",
    redirect: "/",
  },
  {
    path: "/node/28",
    redirect: "/",
  },
  {
    path: "/node/217",
    redirect: "/",
  },
  {
    path: "/node/166",
    redirect: "/",
  },
  {
    path: "/main-test",
    redirect: "/",
  },
  {
    path: "/node/25",
    redirect: "/",
  },
  {
    path: "/node/201",
    redirect: "/",
  },
  {
    path: "/node/20",
    redirect: "/about-us",
  },
  {
    path: "/node/22",
    redirect: "/about-us",
  },
  {
    path: "/node/178",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/node/191",
    redirect: "/bachelor-computer-application-online-degree",
  },
  {
    path: "/node/179",
    redirect: "/masters-computer-applications-online-degree",
  },
  {
    path: "/online-mba-degree/finance",
    redirect: "/online-mba/finance",
  },
  {
    path: "/online-mba-degree/human-resource-management",
    redirect: "/online-mba/human-resource-management",
  },
  {
    path: "/online-mba-degree/marketing",
    redirect: "/online-mba/marketing",
  },
  {
    path: "/online-mba-degree/business-analytics",
    redirect: "/online-mba/business-analytics",
  },
  {
    path: "/online-mba-degree/finance-and-human-resource",
    redirect: "/online-mba/finance-and-human-resource",
  },
  {
    path: "/online-mba-degree/information-technology",
    redirect: "/online-mba/information-technology",
  },
  {
    path: "/online-mba-degree/logistics-and-supply-chain-management",
    redirect: "/online-mba/logistics-and-supply-chain-management",
  },
  {
    path: "/online-mba-degree/operations",
    redirect: "/online-mba/operations",
  },
  {
    path: "/online-mca-degree",
    redirect: "/masters-computer-applications-online-degree",
  },
  {
    path: "/online-mca-degree/computer-science-and-it",
    redirect: "/online-mca/computer-science-and-it",
  },
  {
    path: "/online-mca-degree/data-science",
    redirect: "/online-mca/data-science",
  },
  {
    path: "/online-bba-degree",
    redirect: "/bachelors-business-administration-online-degree",
  },
  {
    path: "/online-bba-degree/general",
    redirect: "/online-bba/general",
  },
  {
    path: "/online-bca-degree",
    redirect: "/bachelor-computer-application-online-degree",
  },
  {
    path: "/online-bca-degree/computer-science-and-it",
    redirect: "/online-bca/computer-science-it",
  },

  {
    path: "/online-mba/logistics-and-supply-chain-management",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mba/operations-management",
    redirect: "/masters-business-administration-online-degree",
  },
  
  { path: "/vignanonline.com", redirect: "https://vignanonline.com" },
  
];

// const router = new VueRouter({
//  mode:'history',
//  base: process.env.BASE_URL,
//  routes
// });

 const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
   routes,
 });




export default router;





