<template>
  <div>
    <!--header-->
    <div class="flex bg-white w-full fixed z-20 top-0 shadow-lg">
      <div class="w-full mx-auto max-w-screen-2xl py-5 flex justify-between">
        <div class="md:pl-20">
          <img src="@/assets/lpmba/logo.svg" class="w-[180px] md:w-[250px]" />
        </div>

        <div class="flex justify-center items-center gap-2">
          <!-- <a
        href="https://api.whatsapp.com/send?phone=918877661755&amp;text=Hi!%20I%27d%20like%20to%20know%20more%20details."
        target="_blank"
        tabindex="-1"
        class="md:block hidden"
        ><div>
          <img
            src="@/assets/lpmba/icon-whatsapp.png"
            class="w-[35px] lg:w-[50px]"
          /></div
      ></a> -->
          <a href="tel:+918877661755"
            ><div
              class="flex justify-center items-center bg-[#004271] text-white pr-5 rounded-l-full"
            >
              <img
                src="@/assets/lpmba/icon-call.png"
                class="w-[35px] lg:w-[50px]"
              />
              <span
                class="text-[16px] lg:text-[18px] xl:text-[26px] leading-5 font-normal"
                >+91 8877661755</span
              >
            </div></a
          >
        </div>
      </div>
    </div>
    <!--header-->
    <StickyView />
    <section class="bg-[#D02829]">
      <div
        class="max-w-7xl mx-auto py-28 flex flex-col lg:flex-row place-items-center"
      >
        <div
          class="text-white lg:w-[30%] p-4 w-full text-center lg:text-left mx-auto xl:w-auto"
        >
          <div>
            <h1
              class="lg:text-3xl uppercase text-xl lg:divide-y text-left pt-8 md:mt-20 py-4 mt-0 font-bold"
            >
              Earn more than just a degree,
              <span>earn a career!</span>
            </h1>
            <p class="lg:text-lg text-lg font-bold text-white text-left">
              Get access to:
            </p>
            <div
              class="flex flex-row lg:flex-col w-full text-sm md:text-base text-left lg:block hidden"
            >
              <ul class="text-left list-disc text-white text-lg pl-3">
                <li class>A Post Graduate Certificate Program</li>

                <li>3,000+ additional courses on SWAYAM and NPTEL</li>
                <li>Premium Career Advancement Services</li>
              </ul>
            </div>

            <div
              class="flex flex-row lg:flex-col w-full text-sm md:text-base text-left divide-x lg:hidden"
            >
              <ul class="text-left list-disc text-white text-lg">
                <li class>A Post Graduate Certificate Program</li>
                <li>3,000+ additional courses on SWAYAM and NPTEL</li>
                <li>Premium Career Advancement Services</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="hidden lg:block relative lg:w-[35%]">
          <div class="w-full bottom-0">
            <img src="@/assets/banner-1.webp" />
          </div>
        </div>
        <div
          class="bg-white py-3 xl:py-8 md:w-[60%] xl:w-[45%] 2xl:w-[36%] md:mr-[30px] text-left text-[15px] align-middle lg:mt-16 self-center p-5 mt-[-15%]"
        >
          <p class="p-5">
            "Thank you! Your Enquiry has been submitted successfully. Our
            admissions team will get in touch with you soon."
          </p>
        </div>
        <div class="lg:hidden flex justify-center">
          <img src="@/assets/banner-1.webp" class="object-contain w-[80%]" />
        </div>
      </div>
    </section>
    <section class="p-4">
      <!--Rankings and Recognitions-->
      <div class="w-full h-full">
        <div class="max-w-7xl mx-auto w-full">
          <h2
            class="font-bold text-[22px] uppercase md:text-2xl py-3 uppercase"
          >
            Rankings and
            <span class="text-[#e33237] font-bold">Recognitions</span>
          </h2>
          <div
            class="flex flex-wrap md:flex-nowrap justify-center items-center gap-8"
          >
            <div
              class="font-bold flex flex-col shadow-lg md:flex-row justify-center items-center gap-3 boxshadow h-[162px] lg:h-[100px] w-[40%] md:w-[250px] lg:w-[230px]"
            >
              <img src="@/assets/lpmba/home-icon-1.png" alt="" />
              <span class="text-center md:text-left"
                >Amongst top 100 <br />in NIRF ranking</span
              >
            </div>

            <div
              class="font-bold flex flex-col shadow-lg md:flex-row justify-center items-center gap-3 boxshadow h-[162px] lg:h-[100px] w-[40%] md:w-[250px] lg:w-[230px]"
            >
              <img src="@/assets/lpmba/home-icon-2.png" alt="" />
              <span class="text-center md:text-left"
                >NAAC A+,<br />UGC 12B</span
              >
            </div>

            <div
              class="font-bold flex flex-col shadow-lg md:flex-row justify-center items-center gap-3 boxshadow h-[162px] lg:h-[100px] w-[40%] md:w-[250px] lg:w-[230px]"
            >
              <img src="@/assets/lpmba/home-icon-3.png" alt="" />
              <span class="text-center md:text-left"
                >Approved<br />BY AICTE</span
              >
            </div>

            <div
              class="font-bold flex flex-col shadow-lg md:flex-row justify-center items-center gap-3 boxshadow h-[162px] lg:h-[100px] w-[40%] md:w-[250px] lg:w-[230px]"
            >
              <img src="@/assets/lpmba/home-icon-4.png" alt="" />
              <span class="text-center md:text-left">ISO<br />9001:2015</span>
            </div>

            <!-- <div
          class="font-bold flex flex-col md:flex-row justify-center items-center gap-3 boxshadow h-[162px] lg:h-[100px] w-[180px] md:w-[250px] lg:w-[230px]"
        >
          <img src="@/assets/lpmba/home-icon-5.png" alt="" />
          <span class="text-center md:text-left"
            >Multiple electives<br />to choose from</span
          >
        </div> -->
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="max-w-7xl mx-auto text-center p-4">
        <h2
          class="font-bold lg:text-2xl mt-8 uppercase text-[20px] px-8 py-4 text-gray-700 p-2 pt-4"
        >
          Online Degree Program
          <span class="text-[#D02829]">with PGC</span>
        </h2>

        <div class="flex flex-col">
          <div class="flex flex-col lg:flex-row gap-2">
            <ul
              class="flex lg:w-[40%] mb-0 list-none lg:flex-col flex-row gap-3 pt-3 pb-4 flex-wrap"
            >
              <li class="mr-2 text-center">
                <a
                  class="text-lg font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(1)"
                  v-bind:class="{
                    'text-white bg-gray-600': openTab !== 1,
                    'text-white bg-[#D02829]': openTab === 1,
                  }"
                  >MBA</a
                >
              </li>
              <li class="mr-2 text-center">
                <a
                  class="text-lg font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(2)"
                  v-bind:class="{
                    'text-white bg-gray-600': openTab !== 2,
                    'text-white bg-[#D02829]': openTab === 2,
                  }"
                  >MCA</a
                >
              </li>
            </ul>
            <div class="w-[100%]">
              <div class="px-4 py-5 lg:flex">
                <div class="tab-content tab-space justify-start text-left">
                  <div
                    v-bind:class="{
                      hidden: openTab !== 1,
                      block: openTab === 1,
                    }"
                  >
                    <div class="mb-8">
                      <div class="w-full">
                        <p>
                          This is a Master's level program, leading to the grant
                          of a degree of Master of Business Administration of
                          Vignan Online, an e-learning arm of Vignan Online.
                          This two-year program offers foundation courses, core
                          courses, electives, and a comprehensive master thesis
                          intermediary apart from an option to pursue a
                          cross-functional and open elective. The core and
                          electives incorporate the AICTE's model curriculum as
                          prescribed for a full-time online MBA program. The
                          electives have been designed keeping in mind the
                          industry's requirements. The offering of these
                          electives right from the 2nd semester of the program
                          ensures a deep dive into the electives. The program is
                          designed to provide in-depth knowledge of the chosen
                          electives and their applications in improving business
                          processes and decision-making. Our online MBA
                          curriculum is intended to increase a learner's
                          business knowledge and leadership skills thus
                          providing a way to accelerate one's career for
                          corporate roles in management.
                        </p>
                        <h3 class="text-gray-600 py-4 font-bold">
                          What is Post Graduate Certificate (PGC) and what to
                          expect from it?
                        </h3>
                        <p class="mt-2">
                          A Post Graduate Certificate (PGC) is an academic
                          credential typically awarded to individuals who have
                          successfully completed a specialized program of study
                          at the postgraduate level. A PGC from Vignan Online is
                          offered as a supplementary component to its Master's
                          program, meaning it is not a standalone course but an
                          additional certification that complements the degree.
                          The content of the Post Graduate Certificate typically
                          covers advanced and focused topics related to the
                          field of study. Our 10-week PGC program is designed to
                          provide students with comprehensive knowledge and
                          skills in specific areas offering them a 360-degree
                          understanding of the chosen field.
                        </p>
                        <h3 class="font-bold">Duration: 10 weeks</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    v-bind:class="{
                      hidden: openTab !== 2,
                      block: openTab === 2,
                    }"
                  >
                    <div class="mb-8 flex break-words">
                      <div class="w-full">
                        <p>
                          This is a master’s level program, leading to the grant
                          of the degree of Master of Computer Applications from
                          the JAIN Online e-learning platform. This two-year
                          online MCA program offers foundation courses, core
                          courses, and a comprehensive project apart from an
                          option to pursue a cross-functional and open elective.
                          The core programs incorporate the model curriculum as
                          prescribed for a full-time MCA program. The programs
                          have been designed keeping in mind the industry’s
                          requirements. This online MCA degree is designed to
                          provide in-depth knowledge of technologies and their
                          applications in designing software applications. The
                          program’s curriculum is intended to increase a
                          learner’s technical skills and abilities thus
                          providing a way to accelerate one’s career for
                          corporate roles in the software domain.
                        </p>
                        <h3 class="text-gray-600 py-4 font-bold">
                          What is Post Graduate Certificate (PGC) and what to
                          expect from it?
                        </h3>
                        <p class="mt-2">
                          A Post Graduate Certificate (PGC) is an academic
                          credential typically awarded to individuals who have
                          successfully completed a specialized program of study
                          at the postgraduate level. A PGC from Vignan Online is
                          offered as a supplementary component to its Master's
                          program, meaning it is not a standalone course but an
                          additional certification that complements the degree.
                          The content of the Post Graduate Certificate typically
                          covers advanced and focused topics related to the
                          field of study. Our 10-week PGC program is designed to
                          provide students with comprehensive knowledge and
                          skills in specific areas offering them a 360-degree
                          understanding of the chosen field.
                        </p>
                        <h3 class>Duration: 10 weeks</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-bind:class="{
            hidden: openTab !== 1,
            block: openTab === 1,
          }"
        >
          <div class="mx-auto border-t-2 flex flex-row justify-center">
            <div class="lg:w-[30%] p-3 hidden lg:block">
              <img class="object-contain" src="@/assets/mba-side.webp" alt />
            </div>
            <div class="lg:w-[70%]">
              <div class="flex justify-start -mt-5">
                <button
                  class="bg-[#D02829] border px-4 font-bold pt-3 pb-2 text-white"
                >
                  MBA
                </button>
              </div>

              <div class="pt-4 lg:ml-4">
                <p class="text-left">
                  <span class="font-bold">Duration:</span> 2 years
                </p>
                <h3 class="font-semibold pt-4 text-left">Electives Offerd</h3>

                <div
                  class="flex flex-col md:flex-row justify-between items-start ml-8 font-lg text-[15px] mt-4"
                >
                  <div class="lg:w-[50%] w-full">
                    <ul
                      class="red-dot-list text-black text-left leading-[35px]"
                    >
                      <li class="relative">Finance</li>
                      <li class="relative">Marketing</li>
                      <li class="relative">Human Resource Management</li>
                      <li class="relative">
                        Finance and Human Resource Management
                      </li>
                      <li class="relative">Operations Management</li>
                    </ul>
                  </div>

                  <div class="lg:w-[50%] w-full">
                    <ul
                      class="red-dot-list text-black text-left leading-[35px]"
                    >
                      <li class="relative">Information Technology</li>
                      <li class="relative">Business Analytics</li>
                      <li class="relative">
                        Healthcare &amp; Hospital Management
                      </li>
                    </ul>
                  </div>
                </div>

                <h3 class="font-semibold pt-4 text-left">Eligibility:</h3>
                <div
                  class="flex flex-col md:flex-row justify-between items-start font-lg text-[15px] mt-4"
                >
                  <div class>
                    <ul
                      class="red-dot-list text-black text-left leading-[35px]"
                    >
                      <li class="relative ml-[3%]">
                        Bachelor's Degree (Minimum of 3 Years Degree Program),
                        with at least 50% marks or equivalent CGPA (45% in case
                        of SC/ST ) from a recognized University.
                      </li>

                      <li class="relative ml-[3%]">
                        Students who are in the final year of the bachelor’s
                        degree are also eligible to apply.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="flex justify-start mb-5">
                  <a href="#enqu">
                    <button
                      class="bg-[#D02829] border rounded-md mt-3 px-4 pt-2 pb-2 text-white"
                    >
                      Apply Now
                    </button></a
                  >
                </div>
                <div class="w-full">
                  <h3
                    class="font-semibold text-[#D02829] py-4 lg;text-xl text-lg text-left"
                  >
                    What You'll Learn in PGC
                  </h3>

                  <ul class="red-dot-list text-black text-left leading-[35px]">
                    <li class="relative ml-[3%]">
                      Digital Marketing: Decode the algorithms that make or
                      break online campaigns.
                    </li>
                    <li class="relative ml-[3%]">
                      Quality Management: Master the frameworks that define
                      excellence.
                    </li>
                    <li class="relative ml-[3%]">
                      Human Resource Management: Navigate the intricacies of
                      talent dynamics.
                    </li>
                    <li class="relative ml-[3%]">
                      Finance: Crack the code of financial sustainability.
                    </li>
                    <li class="relative ml-[3%]">
                      Strategic Marketing: Create strategies that dominate the
                      market.
                    </li>
                    <li class="relative ml-[3%]">
                      21st Century Technologies for Personal Productivity:
                      Master tools like time management software, cloud
                      services, and digital assistants.
                    </li>
                    <li class="relative ml-[3%]">
                      Technological Drivers in Organizational Productivity:
                      Leverage AI, data analytics, and automation for business
                      efficiency.
                    </li>
                  </ul>
                </div>
                <div class="relative w-full gap-5 mx-auto py-4">
                  <h3 class="font-semibold text-[#D02829] pt-4 text-left">
                    Learning Outcomes
                  </h3>
                  <p class="text-left text-[#D02829]">
                    Our finely honed program aims to provide you with:
                  </p>
                  <div class="pt-4">
                    <div
                      class="flex flex-col lg:flex-row w-full text-sm md:text-base gap-6 text-center"
                    >
                      <div
                        class="lg:w-full flex px-2 flex-col justify-center border rounded-lg shadow-lg items-center lg:justify-start p-2"
                      >
                        <div class="p-2 w-[40%] mb-4">
                          <img
                            src="@/assets/learning-outcome/1.png"
                            class="object-contain"
                            width
                            alt
                          />
                        </div>
                        <div class="items-center px-4 text-center">
                          <p>
                            The ability to identify, discuss, and understand key
                            concepts in management and technology.
                          </p>
                        </div>
                      </div>

                      <div
                        class="lg:w-full flex px-2 flex-col justify-center border rounded-lg shadow-lg items-center lg:justify-start p-2"
                      >
                        <div class="p-2 w-[40%] mb-4">
                          <img
                            src="@/assets/learning-outcome/2.png"
                            class="object-contain"
                            alt
                          />
                        </div>
                        <div class="items-center px-4 text-center">
                          <p>
                            A pragmatic sense of ethical and social
                            responsibilities
                            <br />in the business arena.
                          </p>
                        </div>
                      </div>

                      <div
                        class="lg:w-full flex px-2 flex-col justify-center border rounded-lg shadow-lg items-center lg:justify-start p-2"
                      >
                        <div class="p-2 w-[40%] mb-4">
                          <img
                            src="@/assets/learning-outcome/3.png"
                            class="object-contain"
                            alt
                          />
                        </div>
                        <div class="items-center px-4 text-center">
                          <p>
                            Apply your knowledge to real-world challenges
                            through case studies, projects, and cutting-edge
                            tools.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lg:hidden">
                <img
                  class="lg:h-[350px] object-contain"
                  src="@/assets/mba-side.webp"
                  alt
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-bind:class="{
            hidden: openTab !== 2,
            block: openTab === 2,
          }"
        >
          <div class="mx-auto border-t-2 flex flex-row justify-center">
            <div class="lg:w-[30%] p-3 hidden lg:block">
              <img class="object-contain" src="@/assets/mba-side.webp" alt />
            </div>
            <div class="lg:w-[70%]">
              <div class="flex justify-start -mt-5">
                <button
                  class="bg-[#D02829] border px-4 font-bold pt-3 pb-2 text-white"
                >
                  MCA
                </button>
              </div>

              <div class="pt-8 lg:ml-4">
                <p class="text-left">
                  <span class="font-bold">Duration:</span> 2 years
                </p>
                <h3 class="font-semibold text-[#D02829] text-left">
                  Electives Offerd
                </h3>

                <div
                  class="flex flex-col md:flex-row justify-between items-start ml-8 font-lg text-[15px] mt-4"
                >
                  <div class="lg:w-[50%] w-full">
                    <ul
                      class="red-dot-list text-black text-left leading-[35px]"
                    >
                      <li class="relative">Computer Science & IT</li>
                    </ul>
                  </div>

                  <div class="lg:w-[50%] w-full">
                    <ul
                      class="red-dot-list text-black text-left leading-[35px]"
                    >
                      <li class="relative">Data Science</li>
                    </ul>
                  </div>
                </div>

                <h3 class="font-semibold text-[#D02829] pt-4 text-left">
                  Eligibility:
                </h3>
                <div
                  class="flex flex-col md:flex-row justify-between items-start font-lg text-[15px] mt-4"
                >
                  <div class>
                    <ul
                      class="red-dot-list text-black text-left leading-[35px]"
                    >
                      <li class="relative ml-[3%]">
                        Bachelor's Degree (Minimum of 3 Years Degree Program),
                        with at least 50% marks or equivalent CGPA (45% in case
                        of SC/ST ) from a recognized University.
                      </li>
                      <li class="relative ml-[3%]">
                        The candidate must have passed Mathematics at 10+2 level
                        or Graduation level securing 50% marks.
                      </li>
                      <li class="relative ml-[3%]">
                        Students who are in the final year of the bachelor’s
                        degree are also eligible to apply.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="flex justify-start mb-5">
                  <a href="#enqu">
                    <button
                      class="bg-[#D02829] border rounded-md mt-3 px-4 pt-2 pb-2 text-white"
                    >
                      Apply Now
                    </button></a
                  >
                </div>
                <div class="w-full">
                  <h3
                    class="font-semibold text-[#D02829] py-4 lg;text-xl text-lg text-left"
                  >
                    What You'll Learn in PGC
                  </h3>

                  <ul class="red-dot-list text-black text-left leading-[35px]">
                    <li class="relative ml-[3%]">
                      Digital Marketing: Decode the algorithms that make or
                      break online campaigns.
                    </li>
                    <li class="relative ml-[3%]">
                      Quality Management: Master the frameworks that define
                      excellence.
                    </li>
                    <li class="relative ml-[3%]">
                      Human Resource Management: Navigate the intricacies of
                      talent dynamics.
                    </li>
                    <li class="relative ml-[3%]">
                      Finance: Crack the code of financial sustainability.
                    </li>
                    <li class="relative ml-[3%]">
                      Strategic Marketing: Create strategies that dominate the
                      market.
                    </li>
                    <li class="relative ml-[3%]">
                      21st Century Technologies for Personal Productivity:
                      Master tools like time management software, cloud
                      services, and digital assistants.
                    </li>
                    <li class="relative ml-[3%]">
                      Technological Drivers in Organizational Productivity:
                      Leverage AI, data analytics, and automation for business
                      efficiency.
                    </li>
                  </ul>
                </div>
                <div class="relative w-full gap-5 mx-auto py-4">
                  <h3 class="font-semibold text-[#D02829] pt-4 text-left">
                    Learning Outcomes
                  </h3>
                  <p class="text-left text-[#D02829]">
                    Our finely honed program aims to provide you with:
                  </p>
                  <div class="pt-4">
                    <div
                      class="flex flex-col lg:flex-row w-full text-sm md:text-base gap-6 text-center"
                    >
                      <div
                        class="lg:w-full flex px-2 flex-col justify-center border rounded-lg shadow-lg items-center lg:justify-start p-2"
                      >
                        <div class="p-2 w-[40%] mb-4">
                          <img
                            src="@/assets/learning-outcome/1.png"
                            class="object-contain"
                            width
                            alt
                          />
                        </div>
                        <div class="items-center px-4 text-center">
                          <p>
                            The ability to identify, discuss, and understand key
                            concepts in management and technology.
                          </p>
                        </div>
                      </div>

                      <div
                        class="lg:w-full flex px-2 flex-col justify-center border rounded-lg shadow-lg items-center lg:justify-start p-2"
                      >
                        <div class="p-2 w-[40%] mb-4">
                          <img
                            src="@/assets/learning-outcome/2.png"
                            class="object-contain"
                            alt
                          />
                        </div>
                        <div class="items-center px-4 text-center">
                          <p>
                            A pragmatic sense of ethical and social
                            responsibilities
                            <br />in the business arena.
                          </p>
                        </div>
                      </div>

                      <div
                        class="lg:w-full flex px-2 flex-col justify-center border rounded-lg shadow-lg items-center lg:justify-start p-2"
                      >
                        <div class="p-2 w-[40%] mb-4">
                          <img
                            src="@/assets/learning-outcome/3.png"
                            class="object-contain"
                            alt
                          />
                        </div>
                        <div class="items-center px-4 text-center">
                          <p>
                            Apply your knowledge to real-world challenges
                            through case studies, projects, and cutting-edge
                            tools.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lg:hidden">
                <img
                  class="lg:h-[350px] object-contain"
                  src="@/assets/mba-side.webp"
                  alt
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-[#D02829;]">
      <div
        class="max-w-8xl mx-auto flex flex-col lg:flex-row place-items-center"
      ></div>
    </section>

    <section class="w-full p-5 bg-[#D02829]">
      <div class="max-w-7xl justify-center items-center m-auto pb-8">
        <div class="text-center py-8">
          <h2
            class="text-white lg:text-2xl text-xl uppercase font-semibold text-xl"
            st
          >
            Why Online degree programs with us?
          </h2>
        </div>
        <div class="flex flex-wrap justify-start items-center gap-8">
          <div
            class="flex flex-col justify-center text-white text-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/programs-with-us/1.png" alt class />
            </div>
            <span class="text-3xl font-bold py-2 text-white text-center"
              >10+</span
            >
            <p class="text-center">In-Demand Electives</p>
          </div>

          <div
            class="flex flex-col justify-center text-white text-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/programs-with-us/2.png" alt class />
            </div>
            <span class="text-3xl font-bold py-2 text-white text-center"
              >10+</span
            >
            <p class="text-center">
              10+ Advanced Certifications
              <br />Global Accreditations
            </p>
          </div>

          <div
            class="flex flex-col justify-center text-white text-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/programs-with-us/4.png" alt class />
            </div>
            <span class="text-3xl font-bold py-2 text-white text-center"
              >2+</span
            >
            <p class="text-center">
              Value Added
              <br />Certificates
            </p>
          </div>

          <div
            class="flex flex-col justify-center text-white text-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/programs-with-us/5.png" alt class />
            </div>
            <span class="text-3xl font-bold py-2 text-white text-center"
              >120+</span
            >
            <p class="text-center">
              Hours of Learning
              <br />Through LMS
            </p>
          </div>
          <!-- <div class="border-t-2 bg-white"></div> -->
          <!-- <div class="border-t-2 w-64 border-white"></div> -->
          <div
            class="flex flex-col justify-center text-white text-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/programs-with-us/7.png" alt class />
            </div>
            <span class="text-3xl font-bold py-2 text-white text-center"
              >24x7</span
            >
            <p class="text-center">Student Support</p>
          </div>

          <div
            class="flex flex-col justify-center text-white text-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/programs-with-us/9.png" alt class />
            </div>
            <span class="text-3xl font-bold py-2 text-white text-center"
              >3000+</span
            >
            <p class="text-center">
              Additional courses on
              <br />SWAYAM and NPTEL
            </p>
          </div>

          <div
            class="flex flex-col justify-center text-white text-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/programs-with-us/10.png" alt class />
            </div>
            <span class="text-3xl font-bold py-2 text-white text-center"
              >365</span
            >
            <p class="text-center">Days of VOX</p>
          </div>
        </div>
      </div>
    </section>

    <section class="p-4">
      <div class="max-w-7xl mx-auto text-center">
        <h2
          class="font-bold uppercase lg:text-2xl mt-8 px-8 py-4 text-lg text-gray-700 p-2 pt-4"
        >
          Career
          <span class="text-[#D02829]">Advancement Services</span>
        </h2>
        <div class="relative w-full gap-5 mx-auto py-4">
          <div class>
            <div
              class="flex flex-col lg:flex-row w-full text-sm md:text-base text-center"
            >
              <div
                class="lg:w-full flex px-2 flex-col justify-center items-center lg:justify-start py-2"
              >
                <div
                  class="justify-center border rounded-lg shadow-lg items-center lg:justify-start p-4 px-8 my-6"
                >
                  <img
                    src="@/assets/Career-Advancement-Services/1.png"
                    class="object-contain"
                    alt
                  />
                </div>
                <div class="items-center text-center">
                  <p>
                    Access to 5,00,000+
                    <br />jobs opportunities
                  </p>
                </div>
              </div>

              <div
                class="lg:w-full flex px-2 flex-col justify-center items-center lg:justify-start py-2"
              >
                <div
                  class="justify-center border rounded-lg shadow-lg items-center lg:justify-start p-4 px-8 my-6"
                >
                  <img
                    src="@/assets/Career-Advancement-Services/2.png"
                    class="object-contain"
                    alt
                  />
                </div>
                <div class="items-center text-center">
                  <p>
                    Resume builder and
                    <br />interview training
                  </p>
                </div>
              </div>

              <div
                class="lg:w-full flex px-2 flex-col justify-center items-center lg:justify-start py-2"
              >
                <div
                  class="justify-center border rounded-lg shadow-lg items-center lg:justify-start p-4 px-8 my-6"
                >
                  <img
                    src="@/assets/Career-Advancement-Services/3.png"
                    class="object-contain"
                    alt
                  />
                </div>
                <div class="items-center text-center">
                  <p>
                    Access to virtual
                    <br />job fair
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="w-full p-5 bg-gray-200">
      <div class="max-w-7xl justify-center items-center m-auto pb-4">
        <div class="text-center py-4">
          <h2
            class="font-bold lg:text-3xl mt-8 text-[20px] px-8 sm:text-[20px] text-gray-700 p-2 pt-4"
          >
            Advantages of
            <span class="text-[#D02829]">Learning Online</span>
          </h2>
        </div>
        <div class="flex flex-wrap justify-center items-center gap-6">
          <div
            class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[15%]"
          >
            <div class="methodology-box-icon w-[30%]">
              <img
                src="@/assets/Advantages-of-learning-online/1.png"
                alt
                class
              />
            </div>
            <p class="text-center p-4">Accessibility</p>
          </div>

          <div
            class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[15%]"
          >
            <div class="methodology-box-icon w-[30%]">
              <img
                src="@/assets/Advantages-of-learning-online/2.png"
                alt
                class
              />
            </div>
            <p class="text-center p-4">Flexibility</p>
          </div>

          <div
            class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[15%]"
          >
            <div class="methodology-box-icon w-[30%]">
              <img
                src="@/assets/Advantages-of-learning-online/3.png"
                alt
                class
              />
            </div>
            <p class="text-center p-4">Tailored learning schedules</p>
          </div>

          <div
            class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[15%]"
          >
            <div class="methodology-box-icon w-[30%]">
              <img
                src="@/assets/Advantages-of-learning-online/4.png"
                alt
                class
              />
            </div>
            <p class="text-center p-4">Cost-effective</p>
          </div>

          <div
            class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[15%]"
          >
            <div class="methodology-box-icon w-[30%]">
              <img
                src="@/assets/Advantages-of-learning-online/5.png"
                alt
                class
              />
            </div>
            <p class="text-center p-4">Learn and earn at the same time.</p>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-[#D02829]">
      <div class="lg:w-1/2 float-left">
        <div class="flex justify-center">
          <img src="@/assets/university.webp" class="lg:h-[500px]" />
        </div>
      </div>

      <div class="lg:w-1/2 bg-[#D02829] text-left float-left text-white">
        <div class="about-content lg:h-[500px]" style="padding: 20px">
          <h3 class="text-white font-semibold py-2 lg:text-2xl text-xl">
            ABOUT VIGNAN ONLINE
          </h3>
          <p class="py-1">
            With Vignan Online, we have raised the bar higher for how education
            is delivered and received by students. We not only focus on ethical
            values & social consciousness with multiple online MBA programs but
            also deliver quality education with innovation, a global curriculum,
            and experienced faculty.
          </p>
          <p class="py-1">
            With 45 years of glorious legacy in the education sector, we are
            ready to teach learners how to take on leadership roles across
            industries and help them shape their future with our online MBA
            programs. We are focused on helping the young, powerful minds train
            effectively to rule the corporate sector across the world.
          </p>
          <p class="py-1">
            We aim to provide high-quality educational programs and facilitate
            training & research activities with our multiple online MBA
            electives. We also focus on increasing industrial interactions &
            promote entrepreneurial and leadership skills among the students.
            This is how we contribute to the economic and technological
            development of society through multiple electives in online MBA. Our
            affordable Online MBA program entails the right exposure for the
            learners to take on leadership roles in the business and corporate
            world.
          </p>
        </div>
      </div>
    </section>

    <div class="pb-0">&nbsp;</div>

    <section>
      <div class="w-full px-5 py-1">
        <h2 class="font-bold text-black text-[22px] md:text-[28px]">
          HIRING<span class="text-[#d02829]"> COMPANIES</span>
        </h2>
        <HiringComp />
      </div>
    </section>

    <section>
      <div class="w-full mx-auto max-w-7xl py-8">
        <div>
          <h2 class="lg:text-2xl text-xl font-bold uppercase py-8">
            our <span class="text-primary">testimonials</span>
          </h2>
        </div>

        <div class="w-full pt-10 relative">
          <!-- <div
            class="next-testimonials absolute top-[40%] -right-10 z-50 cursor-pointer"
          >
            <img src="@/assets/icon-slider-next.svg" alt />
          </div>

          <div
            class="prev-testimonials absolute top-[40%] -left-16 z-50 cursor-pointer"
          >
            <img src="@/assets/icon-slider-prev.svg" alt />
          </div> -->

          <div
            class="next-testimonials absolute bottom-0 right-32 md:top-[40%] md:-right-10 z-50 cursor-pointer"
          >
            <img src="@/assets/icon-slider-next.svg" alt />
          </div>
          <div
            class="prev-testimonials absolute bottom-0 left-32 md:top-[40%] md:-left-16 z-50 cursor-pointer"
          >
            <img src="@/assets/icon-slider-prev.svg" alt />
          </div>

          <div class="slick-testimonials flex lg:gap-10 gap-1">
            <div
              class="hover:bg-[#d02829] rounded-lg p-2 w-[70%] mx-auto text-black hover:text-white"
              @click="openmodal(video.src, video.name)"
              v-for="video in videos"
              :key="video"
            >
              <div class="flex justify-center">
                <div class="h-[325px]">
                  <div class="py-2 pl-2 text-left">
                    <p class="font-semibold text-[16px]">{{ video.name }}</p>
                    <p class="font-[400]">(MBA-Online)</p>
                  </div>

                  <div class="">
                    <img
                      :alt="video.name"
                      :src="video.imgsrc"
                      class="h-[184px] w-[328px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="myModal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>
          <iframe
            :src="selectedVideo"
            width="100%"
            height="auto"
            style="border: none"
          ></iframe>
          <p class="font-bold text-center pt-5 pb-2">{{ selectedVideoName }}</p>
          <p class="font-bold text-center">(MBA-Online)</p>
        </div>
      </div>
    </section>

    <div class="w-full p-5 bg-[#d02829]">
      <div
        class="max-w-7xl mx-auto w-full text-white font-semibold text-sm flex flex-col md:flex-row justify-center items-center gap-5"
      >
        <div
          class="flex flex-col justify-center items-center md:w-[250px] gap-3 py-5"
        >
          <a href="tel:+918877661755">
            <img src="@/assets/lpmba/icon-call.svg" alt=""
          /></a>
          <div class="min-h-[10px]">
            <a href="tel:+918877661755">
              <p class="mb-0">+91 8877661755</p>
            </a>
          </div>
        </div>

        <!-- <div
          class="flex flex-col justify-center items-center md:w-[250px] gap-3"
        >
          <img
            src="@/assets/lpmba/icon-whatsapp.png"
            style="width: 30px"
            alt=""
          />
          <div class="min-h-[90px]">
            <a
              href="https://api.whatsapp.com/send?phone=918877661755&amp;text=Hi!%20I%27d%20like%20to%20know%20more%20details."
              target="_blank"
              tabindex="-1"
            >
              <p class="mb-0">+91 8877661755</p>
            </a>
          </div>
        </div> -->

        <div
          class="flex flex-col justify-center items-center md:w-[250px] gap-3"
        >
          <a
            href="https://www.google.com/maps/place/Vignan's+Foundation+for+Science,+Technology+%26+Research+(Deemed+to+be+University)/@16.2333746,80.5509079,17z/data=!3m1!4b1!4m6!3m5!1s0x3a4a0953a362f945:0x11aa0de9063844ab!8m2!3d16.2333746!4d80.5509079!16s%2Fm%2F05q97tw?entry=ttu"
            target="_blank"
          >
            <img src="@/assets/lpmba/icon-map.svg" alt="Vignan Online"
          /></a>
          <div class="min-h-[10px]">
            <p class="mb-0">
              Vignan's Foundation for Science, Technology and Research (Deemed
              to be University), Vadlamudi, Guntur-522213
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="md:hidden">
      <div class="w-full bg-[#c82228]">
        <div class="flex justify-center items-center py-3 px-20">
          <a href="tel:+918877661755"
            ><div class="flex flex-col justify-center items-center gap-3">
              <img src="@/assets/home/sticky/call-icon.webp" />
              <p class="text-white font-semibold text-[11px]">CALL NOW</p>
            </div></a
          >
          <!-- <div class="border border-r-2 border-white h-14"></div>
          <a
            href="https://api.whatsapp.com/send?phone=918877661755&amp;text=Hi!%20I%27d%20like%20to%20know%20more%20details."
            target="_blank"
            tabindex="-1"
            ><div class="flex flex-col justify-center items-center gap-3">
              <img
                src="@/assets/home/sticky/icon-whatsapp-white.svg"
                class="w-7"
              />
              <p class="text-white font-semibold text-[11px]">WHATSAPP</p>
            </div></a
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import StickyView from "@/components/StickyView.vue";
import HiringComp from "@/components/HiringComp.vue";
export default {
  name: "OutreachViewTy",
  components: {
    StickyView,
    HiringComp,
  },

  props: ["widget_prop_id"],
  created() {
    this.widget_id = this.widget_prop_id;
  },
  data() {
    return {
      is_widget: false,
      showMenu: false,
      openTab: 1,
      hiring_compo: "hiring_components",
      selectedVideo: "",
      selectedVideoName: "",
      videos: [
        {
          name: "Sowmya",
          src: "https://www.youtube.com/embed/2kvf3TQVr8E?si=Tz-pcFilpb2cMEWH",
          imgsrc: require("@/assets/home/media/Sowmya.webp"),
        },
        {
          name: "Mohammed Altaf Hussain",
          src: "https://www.youtube.com/embed/Gm4hoVxaMQQ?si=sjjs08pVy_SE5nob",
          imgsrc: require("@/assets/home/media/Mohammed-Altaf-Hussain.webp"),
        },
        {
          name: "Rajendra",
          src: "https://www.youtube.com/embed/IXjbDHNxBBM?si=y7JcdrTJ7fP3FMSC",
          imgsrc: require("@/assets/home/media/Rajendra.webp"),
        },
        {
          name: "Vijay",
          src: "https://www.youtube.com/embed/Tpyj8WURJ4M?si=-6KHsTCJ57TqAc_-",
          imgsrc: require("@/assets/home/media/Vijay.webp"),
        },
        {
          name: "K M Naidu",
          src: "https://www.youtube.com/embed/UnADDFbRYIQ?si=k4v1CrlhjSa1EhK9",
          imgsrc: require("@/assets/home/media/K-M-Naidu.webp"),
        },
        {
          name: "Swati",
          src: "https://www.youtube.com/embed/Vf-x-1nm0nI?si=_AYhfBPMCstJOAJi",
          imgsrc: require("@/assets/home/media/Swati.webp"),
        },
        {
          name: "Massudan",
          src: "https://www.youtube.com/embed/jCfTOo2sKD8?si=kkaEVMMwG7JWXDsH",
          imgsrc: require("@/assets/home/media/Massudan.webp"),
        },
        {
          name: "Ramya",
          src: "https://www.youtube.com/embed/jc5HNE3oans?si=e8M1Lo7-OHtIgr6L",
          imgsrc: require("@/assets/home/media/Ramya.webp"),
        },
      ],
    };
  },

  async mounted() {
    await this.hiringCompo();
    $(".slick-testimonials").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-testimonials"),
      nextArrow: $(".next-testimonials"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },

  methods: {
    openmodal(video, name) {
      const modal = document.getElementById("myModal");
      modal.style.display = "block";
      this.selectedVideo = video;
      this.selectedVideoName = name;
    },

    closeModal() {
      const modal = document.getElementById("myModal");
      modal.style.display = "none";
      this.selectedVideo = "";
    },

    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },

    hiringCompo() {
      $(`.${this.hiring_compo}`).slick({
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: $(".next-rank"),
        prevArrow: $(".prev-rank"),
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
  },

  // name: "pink-tabs",
};
</script>

<style>
.red-dot-list li::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 15px;
  left: -20px;
  background-color: #d02829;
}
.slick-slider .slick-track {
  display: flex;
  gap: 12px;
}
/* your modal and overlay styles here */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

@media (max-width: 767.98px) {
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
