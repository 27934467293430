<template>
    <NavBar />
    <div class="w-full">
      <section>
        <div class="w-full px-5 pb-5">
          <div class="max-w-7xl mx-auto w-full">
            <ol
              class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]"
            >
              <li><a href="/">Home</a></li>
              <li>> Past Enrolments</li>
            </ol>
            <div
              class="relative my-8 overflow-x-auto shadow-md sm:rounded-lg border-collapse border border-slate-300"
            >
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
              <thead
                class="text-xs text-gray-700 uppercase bg-[#CA2526] text-white"
              >
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 border border-slate-300 w-12"
                    >
                      S.No.
                    </th>
                    <th scope="col" class="px-6 py-3 border border-slate-300">
                      Information
                    </th>
                    <th scope="col" class="px-6 py-3 border border-slate-300">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                 
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                    >
                      1
                    </th>
                    <td class="px-6 py-4 border border-slate-300">
                      Past Enrolments Data
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/Batch_wise_enrollements_77812.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]"
                          >Programme Wise Information of Enrolments</span
                        ></a
                      >
                    </td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
            <div class="mb-[300px]"></div>
          </div>
        </div>
      </section>
      
    </div>
    <FooterView />
  </template>
  
  <script>
  import NavBar from "@/components/NavBar.vue";
  import FooterView from "@/components/FooterView.vue";
  export default {
    name: "PrivacyPolicy",
    components: {
      FooterView,
      NavBar,
    },
  };
  </script>
  
  <style scoped></style>
  