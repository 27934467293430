//
<template>
  <div v-if="this.Programs.length > 0">
    <NavBar />
    <!--banner part-->
    <div v-if="this.Programs" class="w-full mx-auto relative">
      <div class="object-cover bg-center bg-no-repeat">
        <img
          :src="Programs[0].banner"
          class="hidden md:block w-full mt-24"
          alt="desktop-banner"
        />
        <img
          :src="Programs[0].mobile_banner"
          class="md:hidden pt-20"
          alt="mobile-banner"
        />
      </div>
      <div class="w-full max-w-screen-2xl">
        <div class="absolute md:top-2 top-24 left-3">
          <p><a href="/">Home</a> > {{ Programs[0].name }}</p>
        </div>
        <div
          class="absolute text-xl md:text-[28px] lg:leading-10 leading-7 text-left font-[700] top-32 md:top-[14%] left-6 lg:left-[8%] xl:left-[12%]"
        >
          <div v-html="Programs[0].focus"></div>
        </div>
        <div
          class="text-black md:text-left absolute left-3 bottom-2 lg:bottom-[12%] xl:bottom-[12%] md:left-[5%] lg:left-[8%] xl:left-[12%]"
        >
          <div v-if="imageToggle">
            <img
              src="@/assets/electivepage/ban-logos-mob.webp"
              class="md:hidden w-[55%]"
              alt="UGC&AICTE logo"
            />
            <img
              src="@/assets/electivepage/ban-logos.webp"
              class="hidden md:block w-[50%]"
              alt="UGC&AICTE logo"
            />
          </div>

          <div v-else>
            <img
              src="@/assets/electivepage/ban-logos1-mob.png"
              class="md:hidden w-[35%]"
              alt="UGC&AICTE logo"
            />
            <img
              src="@/assets/electivepage/ban-logos1.png"
              class="hidden md:block w-[50%]"
              alt="UGC&AICTE logo"
            />
          </div>

          <div class="flex lg:flex-col flex-row gap-5">
            <button
              class="bg-[#cf4030] text-white font-[600] text-[16px] px-3 py-2 w-36 mt-5 rounded-2xl"
              @click="openModal"
            >
              Enroll Now
            </button>
            <h6
              class="text-md font-[600] lg:text-left text-right md:text-lg align-middle lg:self-start self-center lg:text-2xl mt-4 xl:mt-6"
            >
              #Driving<span class="text-[#cf4030]">YourFuture</span>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <!--banner part completed-->

    <div class="grad w-full p-3 md:py-[45px] md:px-[30px]">
      <div class="w-full max-w-7xl mx-auto justify-center">
        <div
          class="flex flex-wrap justify-center items-center gap-3 xl:gap-5 mx-auto my-0"
        >
          <div
            v-if="this.Programs"
            class="text-[16px] leading-5 bg-[#e3dade] flex flex-col md:flex-row justify-center items-center gap-3 min-h-[120px] md:w-[200px] md:min-h-[100px] lg:w-[230px] lg:min-h-[120px] rounded-md gradbox"
          >
            <img src="@/assets/Program/icon-time-red.png" alt="time" />
            <span class="text-center md:text-left"
              >Duration <br />
              <span class="font-bold text-black text-md lg:text-lg"
                >{{ Programs[0].durations }} YEARS</span
              >
            </span>
          </div>

          <div
            class="text-[16px] leading-5 bg-[#e3dade] flex flex-col md:flex-row justify-center items-center gap-3 min-h-[120px] md:w-[200px] md:min-h-[100px] lg:w-[230px] lg:min-h-[120px] rounded-md gradbox"
          >
            <img src="@/assets/Program/icon-calendar-red.svg" alt="calender" />
            <span class="text-center md:text-left"
              >Live Online Sessions <br />
              <span class="font-bold text-black text-md lg:text-lg"
                >WEEKEND</span
              >
            </span>
          </div>

          <div
            class="text-[16px] leading-5 bg-[#e3dade] flex flex-col md:flex-row justify-center items-center gap-3 min-h-[120px] md:w-[200px] md:min-h-[100px] lg:w-[230px] lg:min-h-[120px] rounded-md gradbox"
          >
            <img src="@/assets/Program/icon-precent.svg" alt="percentage" />
            <span class="font-bold text-black text-md lg:text-lg md:text-left"
              >FLEXIBLE EMI<br />OPTIONS</span
            >
          </div>
        </div>
      </div>
    </div>

    <!--About the elective-->
    <div class="w-full px-5 py-5">
      <div
        v-if="this.Programs"
        class="max-w-7xl mx-auto w-full text-left"
        v-html="Programs[0].description"
      ></div>
      <!-- <p class="md:ml-6 text-[#212121] text-left">Program credits: 120</p> -->
    </div>
    <!--About the elective end-->

    <!--Program highlights-->
    <div class="w-full px-5 py-5">
      <div class="max-w-7xl mx-auto w-full" v-if="this.Programs">
        <h2 class="font-bold text-black text-lg lg:text-2xl pb-5 text-left">
          PROGRAM <span class="text-[#d02829]">HIGHLIGHTS</span>
        </h2>
        <div
          class="flex flex-col md:flex-row justify-start lg:gap-[80px] w-full lg:ml-5"
        >
          <div
            class="text-left font-medium red-dot-list"
            v-html="Programs[0].Highlights"
          ></div>
        </div>
      </div>
    </div>
    <!--Program highlights end-->

    <!--Learning Methodology-->
    <div class="w-full py-5 px-5">
      <div class="max-w-7xl mx-auto w-full">
        <h2 class="font-bold text-black text-lg lg:text-2xl pb-5 text-left">
          LEARNING <span class="text-[#d02829]">METHODOLOGY</span>
        </h2>
        <div
          class="flex flex-wrap justify-around items-center gap-3 sm:gap-5 xl:gap-10"
        >
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-clock.svg"
                class="w-[70px] h-[83px]"
                alt="clock-icon"
              />
            </div>
            <p>120 study hours in each course</p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-projetor.svg"
                class="w-[70px] h-[83px]"
                alt="projector-icon"
              />
            </div>
            <p>Interactive audio-video lectures</p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-id.svg"
                class="w-[70px] h-[83px]"
                alt="id-icon"
              />
            </div>
            <p>Pre-recorded video lectures</p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-monitor.svg"
                class="w-[70px] h-[83px]"
                alt="monitor-icon"
              />
            </div>
            <p>Discussion forum</p>
          </div>

          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-printer.svg"
                class="w-[70px] h-[83px]"
                alt="printer-icon"
              />
            </div>
            <p>Self e-learning material</p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-edit.svg"
                class="w-[70px] h-[83px]"
                alt="edit-icon"
              />
            </div>
            <p>Assignments, quizzes, MCQ, etc. for reinforcement</p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/icon-tub.svg"
                class="w-[70px] h-[83px]"
                alt="tub-icon"
              />
            </div>
            <p>Independent and group projects</p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/live-online.png"
                class="w-[70px] h-[83px]"
                alt="online-live-icon"
              />
            </div>
            <p>8 to 12 hours of live online sessions on weekends</p>
          </div>

          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/lm9.png"
                class="w-[70px] h-[83px]"
                alt="Q&A-icon"
              />
            </div>
            <p>
              Live Q&A sessions with faculty using tech-enabled tools for an
              optimum learning experience
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/lm10.png"
                class="w-[70px] h-[83px]"
                alt="Video-icon"
              />
            </div>
            <p>
              Short videos in the form of learning chunks for comprehensive
              understanding
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/lm11.png"
                class="w-[70px] h-[83px]"
                alt="technology-icon"
              />
            </div>
            <p>
              Technology Enabled Online Proctored Examinations, twice a year
            </p>
          </div>
          <div
            class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
          >
            <div>
              <img
                src="@/assets/Program/lm12.png"
                class="w-[70px] h-[83px]"
                alt="practical-icon"
              />
            </div>
            <p>
              Hands-on practical and lab exercises to supplement theory for tech
              based courses
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--Learning Methodology end-->

    <!--How to Enroll-->
    <div class="w-full py-5 px-5">
      <div class="max-w-7xl mx-auto w-full">
        <h2 class="font-bold text-black text-lg lg:text-2xl pb-5 text-left">
          HOW <span class="text-[#d02829]">TO ENROLL</span>
        </h2>
        <div
          class="w-full flex flex-col md:flex-row justify-center items-center gap-5"
        >
          <div class="shadow-[0_0px_5px_3px_rgba(227,224,224,0.3)]">
            <div class="text-left py-2 bg-[#d02829] text-white pl-5 font-bold">
              <h3 class="text-sm">Step</h3>
              <h2 class="pl-2 text-lg">1</h2>
            </div>
            <div class="px-3 h-[100px] pt-[20px] text-left">
              <a
                href="https://apply.vignanonline.com/"
                class="underline"
                target="_blank"
                >Click here</a
              >
              <Span class=""> to visit the Vignan Online Enrollment Form</Span>
            </div>
          </div>

          <div class="shadow-[0_0px_5px_3px_rgba(227,224,224,0.3)]">
            <div class="text-left py-2 bg-[#d0393b] text-white pl-5 font-bold">
              <h3 class="text-sm">Step</h3>
              <h2 class="pl-2 text-lg">2</h2>
            </div>
            <div class="px-3 h-[100px] pt-[20px] text-[14px] text-left">
                <Span
                  >Sign up, complete the 4 essential steps to enroll for the
                  Program of your choice</Span
                >
              </div>
          </div>

          <div class="shadow-[0_0px_5px_3px_rgba(227,224,224,0.3)]">
            <div class="text-left py-2 bg-[#d02829] text-white pl-5 font-bold">
              <h3 class="text-sm">Step</h3>
              <h2 class="pl-2 text-lg">3</h2>
            </div>
            <div class="px-3 h-[100px] pt-[20px] text-[14px] text-left">
                <Span
                  >Pay fee through Net banking/ Debit card/ Credit card or using
                  easy EMI with 0% interest</Span
                >
              </div>
          </div>
        </div>
      </div>
    </div>
    <!--How to Enroll end-->

    <!--Career Advancement Services-->
    <div class="w-full py-5 px-5 mb-5 bg-[#f7f9fb]">
      <div class="max-w-7xl mx-auto w-full">
        <h2 class="font-bold text-black text-lg lg:text-2xl pb-5 text-left">
          CAREER <span class="text-[#d02829]">ADVANCEMENT SERVICES</span>
        </h2>
        <p class="text-justify px-5 pb-5">
          The career advancement services team works tirelessly to connect our
          students with the right job opportunities. Our years of experience in
          training, networking, and human resourcing skills help our students
          find exciting and challenging job opportunities. Our students get
          placements in reputed companies through a structured framework.
        </p>

        <CAS />
      </div>
    </div>
    <!--Career Advancement Services end-->

    <TechNology />

    <section>
      <div class="max-w-7xl mx-auto w-full py-5">
            <h2 class="font-bold text-black text-lg lg:text-2xl pb-5 text-left">
        HIRING <span class="text-primary">COMPANIES</span>
      </h2>
      </div>
      <HiringComp />
    </section>

    <div class="w-full">
      <!-- FAQ -->
      <div id="faq-content" class="px-4 py-8">
        <div class="max-w-7xl mx-auto w-full">
          <div class="flex flex-wrap py-5 px-5">
            <h2 class="font-bold text-black text-lg lg:text-2xl pb-5 text-left">
              FAQ<span class="text-primary">s</span>
            </h2>
          </div>
          <div class="accordion bg-trasparent max-w-6xl mx-auto" id="faqs">
            <div
              v-for="(el, index) in this.ProFaq.slice(this.start, this.end)"
              :key="el"
              class="accordion-item border-t-0 border-x-0 text-left"
            >
              <h2 class="accordion-header mb-0">
                <button
                  @click="faqCollapse"
                  class="accordion-button font-semibold relative flex items-center justify-between w-full py-4 px-5 text-[15px] text-[#004271] hover:text-[#0a58ca] text-left border-0 rounded-none transition focus:outline-none"
                  type="button"
                  :data-target="'#text-' + index"
                >
                  {{ el.title }}
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-circle-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-[#e01b24] text-xl"
                  />
                  <!-- <img
                    src="../assets/home/Untitled.png"
                    alt="down-arrow"
                    class="down-arrow"
                    />  -->
                </button>
              </h2>
              <div
                :id="'text-' + index"
                class="accordion-collapse border-0 hidden text-sm md:text-base text-black"
              >
                <div class="accordion-body pb-4 px-10 flex flex-col gap-3">
                  <!-- <p v-html="el.answer_text"></p> -->
                  <pre
                    style="white-space: pre-line"
                    class="font-montserrat text-[13px]"
                    v-html="el.content"
                  ></pre>
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="font-medium">Load more >></button> -->
          <button
            v-if="this.ProFaq.length >= this.end"
            @click="this.end = this.end + 3"
            class="bg-[#CA2526] text-white font-semibold px-8 py-2 rounded-[50px] mt-7"
          >
            View More
          </button>
          <button
            v-if="this.ProFaq.length <= this.end"
            @click="this.end = 4"
            class="bg-[#CA2526] text-white font-semibold px-8 py-2 rounded-[50px] mt-5"
          >
            View Less
          </button>
        </div>
      </div>
    </div>
    <FooterView />
    <StickyView />
    <!-- Modal -->
    <div class="modal3" v-if="isModalOpen">
      <div
        class="modal-content3 rounded-md relative bottom-24 md:w-[35%] 2xl:w-[20%]"
      >
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
import CAS from "@/components/CAServices.vue";
import TechNology from "@/components/TechNology.vue";
import HiringComp from "@/components/HiringComp.vue";
import FooterView from "@/components/FooterView.vue";
import NavBar from "@/components/NavBar.vue";
import StickyView from "@/components/StickyView.vue";
import EnquireView from "@/components/EnquireForm.vue";
export default {
  name: "ProgramView",
  components: {
    CAS,
    TechNology,
    FooterView,
    HiringComp,
    NavBar,
    StickyView,
    EnquireView,
  },
  data() {
    return {
      start: 0,
      end: 5,
      ProFaq: [],
      Programs: [],
      isModalOpen: false,
      imageToggle: false,
    };
  },

  props: ["program"],

  mounted() {
    $("#Electiveslick").slick({
      rows: 2,
      // dots: true,
      infinite: true,
      autoplay: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: false,
      nextArrow: false,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },

  created() {
    this.programCall();
    this.programFaq();
    console.log(this.Programs);

    if (
      this.$route.path == "/bachelors-business-administration-online-degree" ||
      this.$route.path == "/bachelor-computer-application-online-degree"
    ) {
      this.imageToggle = true;
    }
  },

  methods: {
    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass("rotate-180")) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass(
          "rotate-180"
        );
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },

    async programCall() {
      const slug = this.$route.params.slug;
      const apiUrl = `${process.env.VUE_APP_API}/course/program-list/?slug=${slug}`;

      try {
        const resp = await axios.get(apiUrl);

        if (resp.data.status === 200) {
          this.Programs = resp.data.data;
          console.log(this.Programs.length, "this.Programsthis.Programs");
        } else {
          console.log("else");
          this.$router.push({
            name: "PageNotFound",
            params: { pathMatch: "page-not-found" },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async programFaq() {
      const programSlug = this.$route.params.slug; // Get the slug from the route or define it
      const apiUrl = `${process.env.VUE_APP_API}/faq/program-faq-list/?program__slug=${programSlug}`;

      if (!programSlug) {
        // Handle the case when the slug is not available
        console.error("Program Slug is not defined.");
        return;
      }

      try {
        const response = await axios.get(apiUrl);

        if (response.data) {
          this.ProFaq = response.data.data;
          console.log(this.ProFaq);
        }
      } catch (error) {
        console.error(error); // Handle any errors that may occur during the request
      }
    },

    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
@media (max-width: 425px) {
  .gradbox {
    width: 180px;
  }
}
@media (max-width: 375px) {
  .gradbox {
    width: 158px;
  }
}
@media (max-width: 320px) {
  .gradbox {
    width: 133px;
  }
}
.grad {
  background-color: #364c70;
  background-image: linear-gradient(to right, #364c70, #d0393b);
  /* padding: 45px 30px; */
}

/* ul {
 list-style: none;
}


ul li::before {
  content: "\2022";
  color: #d0393b;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 1em;
  margin-left: 1em;
} */

.modal3 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content3 {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
}

@media (max-width: 767.98px) {
  .modal-content3 {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}

.red-dot-list li::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 18px;
  left: -30px;
  background-color: #d02829;
}

.abt li::before {
    content: '';
    display: inline-block;
    height: 22px;
    width: 17px;
    background-size: cover;
    list-style-image: url('https://api.vignanonline.com/media/uploads/2023/12/04/icon-bulb.svg');
    background-repeat: no-repeat;
    margin-right: 10px;
    background-position: center center;
    vertical-align: middle;
}

/* ol,
ul,
menu {
  list-style: disc !important;
  margin: 0;
  padding: 0;
} */
</style>
