<template>
     <NavBar />
<div class="w-full">
    <ol class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]">
        <li><a href="/">Home</a></li>
        <li>> Privacy Policy</li>
    </ol>
   

    <div class="w-full text-left pl-5 py-5">      
        <h4 class="mt-5 text-[18px] font-bold text-black ">Privacy <span>Policy</span></h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">Your privacy is of utmost priority and we are committed to respecting it. Rest assured, we DO NOT give, share, sell, or transfer any personal information to a third party.</p>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">This privacy statement applies to the user information that we gather when you visit Vignan (Deemed-to-be University)’s and its other sub-sites that are owned, managed and controlled by the University team. This page contains information and the description of how this information is processed and protected</p>

        <h4 class="text-[18px] font-bold text-black">Browsing Behaviour</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">We track the browsing patterns of the visitors to understand how our sites are being used. The websites deploy Google Analytics to gather anonymous statistics which are used to improve user experience.</p>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]"><strong>The web-operating system will help us identify the following:</strong></p>

        <ul class="py-1 text-start leading-7 font-normal text-black text-[14px]">
            <li>The Internet domain for your internet services, such as “xyz.com” or “xyz.net” if you use a private internet access account, or “yourcollege.edu” if you connect from a college or university domain.</li>
            <li>Type of browser being used (“Google Chrome version X” or “Internet Explorer version X”).</li>
            <li>Type of operating system being used (Macintosh, UNIX, or Windows).</li>
            <li>Time and date of your prior visits to the website, what you had viewed or clicked and how you found us</li>
            <li>Personal information is NOT recorded</li>
        </ul>

        <h4 class="mt-5 text-[18px] font-bold text-black">The information asked on the website</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">The University websites and its sub-sites may ask you for personally-identifiable information like name, email ID, and/or phone number. The purpose of requesting this information is to communicate effectively with our visitors. Vignan (Deemed-to-be University) will never sell, share, or disclose the information provided on its websites.</p>

        <h4 class="mt-5 text-[18px] font-bold text-black">Information received from an e-mail</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">If in case you decide to send us an electronic mail message (email), it will usually contain your return email address. If you include personally-identifying information in your email because you want us to address issues specific to your circumstances, we may use that information in responding to your request. Also, e-mail is not necessarily secure against interception. Please send only the information necessary to help us process your request.</p>

        <h4 class="mt-5 text-[18px] font-bold text-black">Information collected from interactive forms</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">On some of our web pages, we offer interactive forms that let you voluntarily submit personal information such as your email address, name, or organization. This occurs when you are registering for online counseling, various tests, quizzes, seminars, workshops, or training sessions offered by Vignan (Deemed-to-be University) , downloading admission forms. In such cases, all submitted information is used only for the purposes for which it is intended and is not made available to any third party.</p>

        <h4 class="mt-5 text-[18px] font-bold text-black">Cookies</h4>

        <ul>
            <li class="py-1 text-start leading-7 font-normal text-black text-[14px]"><strong>Necessary Cookies</strong> - We use this type of cookie on all the Vignan (Deemed-to-be University) webpages to help you use our websites interactively and regularly. A cookie is a small file that a website transfers to your computer’s hard disk, usually to keep track of you while you are connected to that website.</li>
            <li class="py-1 text-start leading-7 font-normal text-black text-[14px]"><strong>Performance Enhancing Cookies</strong> - This doesn’t collect any personal information about individuals, but only about your browser “session.” The cookie makes it easier for you to use the dynamic features of these web pages, without providing the same information again as you move from one page to another.</li>
            <li class="py-1 text-start leading-7 font-normal text-black text-[14px]"><strong>Functional Cookies</strong> - This allows the websites to remember the choices you made during your previous. It identifies user behavior and enables us to serve content based on the user profile.</li>
        </ul>

        <h4 class="mt-5 text-[18px] font-bold text-black">Third-party links</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">The University and its other sub-sites may contain links to Third-Party Sites. Vignan (Deemed-to-be University) is not accountable for the privacy practices followed by these websites. Hence, we encourage you to read through the privacy policy published by these Third-Party websites.</p>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">For more information regarding the University’s privacy policy or if you have any queries, please write to: <strong><a href="mailto:info@vignan.ac.in" class="text-[#2c4983]">info@vignan.ac.in</a></strong></p>            
      
    </div>
</div>
<FooterView />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterView from "@/components/FooterView.vue";
    export default {
    name: "PrivacyPolicy",   
    components: { 
        FooterView,
        NavBar 
    }
}
</script>

<style scoped>

</style>