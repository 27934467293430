<template>
  <NavBar />
  <div class="w-full">
    <section>
      <div class="w-full px-5 pb-5">
        <div class="max-w-7xl mx-auto w-full">
          <ol
            class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]"
          >
            <li><a href="/">Home</a></li>
            <li>> Statutory Disclosures</li>
          </ol>
          <div
            class="relative my-8 overflow-x-auto shadow-md sm:rounded-lg border-collapse border border-slate-300"
          >
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-xs text-gray-700 uppercase bg-[#CA2526] text-white"
              >
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 border border-slate-300 w-12"
                  >
                    S.No.
                  </th>
                  <th scope="col" class="px-6 py-3 border border-slate-300">
                    Information
                  </th>
                  <th scope="col" class="px-6 py-3 border border-slate-300">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
   
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    1
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Establishing Act and Statutes
                  </td>
                  <td class="py-2 border border-slate-300">
                    <p class="px-6 py-2 border-b border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/Establishing-act-and-status_2008_58111.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Conferment of Status of Deemed to Be University (2008)</span></a
                      >
                    </p>
                    <p class="px-6 py-2 border-b border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/Establishing-act-and-Statutes_2019_94332.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Extension of Deemed to Be University Status (2019)</span></a
                      >
                    </p>
                    <p class="px-6 py-2 border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/Establishing-act-and-statutes_2023_56243.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Extension of Deemed to Be University Status (2023)</span></a
                      >
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    2
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Application submitted to UGC-DEB for offering ODL/Online programmes
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <p>
                      <a
                        href="https://api.vignanonline.com/media/Application_OLODL_31394.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">View Application </span></a
                      >
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    3
                  </th>
                  <td
                    class="px-6 py-4 border border-t-0 border-b-0 border-slate-300"
                  >
                  UGC Notifications

                  </td>
                  <td class="py-2 border border-slate-300">
                   <p class="px-6 py-2 border-b border-slate-300"> <a
                      href="https://api.vignanonline.com/media/UGC_Public_Notice_44038.pdf"
                      target="_blank"
                      ><span class="text-[#007bff]">Precaution to be taken by the students before enroliing in ODL and Online Programmes (September, 2023)</span></a
                    ></p>
                    <p class="px-6 py-2  border-slate-300"><a
                        href="https://api.vignanonline.com/media/UGC_34472.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Precaution to be taken by the students before enroliing in ODL and Online Programmes (March 2024)</span></a
                      ></p>
                  </td>
                </tr>
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    4
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Letters of recognition from UGC
                  </td>
                  <td class="py-4 border border-slate-300">
                    <p class="px-6 py-2 border-b border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/UGC-Approval-2021_87649.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Approval to Start Online Programmes (2021)</span>
                      </a>
                    </p>
                    <p class="px-6 py-2  border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/UGC-Approval-2024_54563.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]">Approval to Start Online Programmes (2024)</span></a
                      >
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    5
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                   AICTE Approvals
                  </td>
                  <td class="py-4 border border-slate-300">
                    <p class="px-6 py-2 border-b border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/AICTE_Approval_Report-2021-22_19564.pdf"
                        target="_blank"
                        >
                        <span class="text-[#007bff]">AICTE Approval to Offer Online Programmes for Academic Year 2021-22</span></a
                      >
                    </p>
                    <p class="px-6 py-2 border-b border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/AICTE_Approval_Report-2022-23_66315.pdf"
                        target="_blank"
                        >
                        <span class="text-[#007bff]">AICTE Approval to Offer Online Programmes for Academic Year 2022-23</span></a
                      >
                    </p>
                    <p class="px-6 py-2 border-slate-300">
                      <a
                        href="https://api.vignanonline.com/media/AICTE_approval_Report-2023-24_21401.pdf"
                        target="_blank"
                        >
                        <span class="text-[#007bff]">AICTE Approval to Offer Online Programmes for Academic Year 2023-24</span></a
                      >
                    </p>
                  </td>
                </tr>

               

                <tr class="odd:bg-white">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    6
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    National Institutional Ranking Framework (NIRF) Certificate, 2023
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <a
                      href="https://api.vignanonline.com/media/NIRF-2023(University-Level)_25833.pdf"
                      target="_blank"
                      ><span class="text-[#007bff]"> View Certificate</span></a
                    >
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    7
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    National Assessment and Accreditation Council (NAAC) Certificate, 2021
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <a
                      href="https://api.vignanonline.com/media/NAAC-Cycle-II_49462.pdf"
                      target="_blank"
                      ><span class="text-[#007bff]"> View Certificate</span></a
                    >
                  </td>
                </tr>
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
  <FooterView />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterView from "@/components/FooterView.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    FooterView,
    NavBar,
  },
};
</script>

<style scoped></style>
