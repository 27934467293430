<template lang="">
  <div>
    <NavBar/>
    <section class="">
      <div class="w-full mx-auto max-w-5xl p-5">
        <div v-if="this.blog_value">
        <div class="text-2xl text-left mt-5 font-semibold pt-20 pb-2.5 " >
          <h1>{{ this.blog_value.title }}</h1>
        </div>
        <div class="flex justify-center items-center w-full pt-10">
          <img :src="this.blog_value.image" alt="" class="w-[100%]" />
        </div>
        <div class="pt-10 text-left text-black">
          <p v-html="this.blog_value.content"></p>
        </div>
        </div>
      </div>
      <div class="pt-16">
        <TechNology />
      </div>
      <div>
        <FooterView />  
      </div>
    </section>
    <StickyView/>
  </div>
</template>
<script>
import axios from "axios";
import TechNology from "@/components/TechNology.vue";
import FooterView from "@/components/FooterView.vue";
import NavBar from "@/components/NavBar.vue";
import StickyView from "@/components/StickyView.vue";
export default {
  name: "BlogView",
  components: {
    TechNology,
    FooterView,
    StickyView,
    NavBar
  },
  data() {
return{
  blog_value: [],
}
},

created() {
  this.blogsCall()
  console.log(this.blog)
},

props:["blog"],

methods: {
        async blogsCall() {
          console.log(this.blog)
        await axios
          .get(`${process.env.VUE_APP_API}/blog/blog-list/?slug=${this.blog}`)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.blog_value = resp.data.data[0];
              console.log(this.blog_value,'this.blog_value')
            }
            else {
          console.log('else')
          this.$router.push({name :'PageNotFound',params:{pathMatch:'page-not-found'}})
        }
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
    },
};
</script>
<style lang=""></style>
