<template>
  <div class="w-full px-5 py-8 mt-2">
    <div
          class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
        >
          <h2
            class="font-bold uppercase lg:text-2xl mt-8 px-8 py-4 text-lg text-gray-700 p-2 pt-4"
          >
            Our Alumni 
            <span class="text-[#D02829]">Working at</span>
          </h2>
          <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
        </div>
    <div class="max-w-7xl mx-auto w-full p-2 relative">
      <div class="prev-rank absolute top-[40%] cursor-pointer">
        <img src="../assets/icon-slider-prev.svg" width="40px" height="40px" class="" alt="slider-prev" />
      </div>

      <div class="flex gap-8 p-3" :class="hiring_compo">
        <div class="rounded-4xl py-4 hover:border-[1px] flex hover:shadow-2xl hover:scale-75 ease-out duration-500 hover:after:border-[3px] after:border-red-600 after:h-8 after:items-top after:justify-start after:float-right after:mt-0 ">
           <img src="../assets/hiring/EY_Logo.webp" alt="" class=""/>
        </div>

        <div class="rounded-4xl py-4 hover:border-[1px] flex hover:shadow-2xl hover:scale-75 ease-out duration-500 hover:after:border-[3px] after:border-red-600 after:h-8 after:items-top after:justify-start after:float-right after:mt-0 ">
          <img src="../assets/hiring/Facebook_Logo.webp" alt="" class=""/>
        </div>

        <div class="rounded-4xl py-4 hover:border-[1px] flex hover:shadow-2xl hover:scale-75 ease-out duration-500 hover:after:border-[3px] after:border-red-600 after:h-8 after:items-top after:justify-start after:float-right after:mt-0 ">
           <img src="../assets/hiring/Google_Logo.webp" alt="" class=""/>
        </div>

        <div class="rounded-4xl py-4 hover:border-[1px] flex hover:shadow-2xl hover:scale-75 ease-out duration-500 hover:after:border-[3px] after:border-red-600 after:h-8 after:items-top after:justify-start after:float-right after:mt-0 ">
          <img src="../assets/hiring/HDFC_Logo.webp" alt="" class=""/>
        </div>

        <div class="rounded-4xl py-4 hover:border-[1px] flex hover:shadow-2xl hover:scale-75 ease-out duration-500 hover:after:border-[3px] after:border-red-600 after:h-8 after:items-top after:justify-start after:float-right after:mt-0 ">
          <img src="../assets/hiring/Honeywell_Logo.webp" alt="" class="" />
        </div>

        <div class="rounded-4xl py-4 hover:border-[1px] flex hover:shadow-2xl hover:scale-75 ease-out duration-500 hover:after:border-[3px] after:border-red-600 after:h-8 after:items-top after:justify-start after:float-right after:mt-0 ">
           <img src="../assets/hiring/ICICI_Logo.webp" alt=""  class=""/>
        </div>
      </div>

      <div class="next-rank absolute top-[40%] -right-3 xl:-right-4  cursor-pointer">
        <img src="../assets/icon-slider-next.svg" width="40px" height="40px" class="" alt />
      </div>

    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "HiringComp",
  data() {
    return {
      hiring_compo: "hiring_components",
    }
  },

  async mounted() {
    await this.hiringCompo();
  },

  methods: {
    hiringCompo() {
      $(`.${this.hiring_compo}`).slick({
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll:1,
        nextArrow: $(".next-rank"),
        prevArrow: $(".prev-rank"),
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
                    {
            breakpoint: 1280,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll:1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      })
    },

  }
}
</script>

<style>
.slick-slider .slick-track {
        display: flex;
        gap:12px;
    }
</style>