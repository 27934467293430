<template>
  <div class="w-full py-4">
    <section class="bg-red-800 pb-10 w-full">
      <div
        class="max-w-7xl flex flex-col items-center pb-4 justify-center mx-auto text-center"
      >
        <h2
          class="font-bold uppercase lg:text-2xl mt-8 px-8 py-4 text-lg text-white p-2 pt-4"
        >
          Our
          <span class="text-white">Testimonials</span>
        </h2>
        <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
      </div>
      <div class="max-w-7xl justify-center m-auto align-middle pt-4 relative">
        <!-- desktop Testimonial -->
        <div class="hidden md:block">
          <div
            class="next-testimonials absolute bottom-0 right-32 md:top-[40%] md:-right-10 z-10 cursor-pointer"
          >
            <img src="../assets/icon-slider-next.svg" width="40px" height="40px" class="" alt />
          </div>
          <div
            class="prev-testimonials absolute bottom-0 left-32 md:top-[40%] md:-left-9 z-10 cursor-pointer"
          >
            <img src="../assets/icon-slider-prev.svg" width="40px" height="40px" class="" alt />
          </div>
          <div class="slick_praise">
            <div
              v-for="card in cards"
              :key="card.id"
              @click="active = card.name"
              :class="{
                '': active === card.name,
              }"
            >
              <div
                @click="openmodal(card.src, card.name)"
                v-if="active === card.name"
                class="flex-col w-[250px]"
              >
                <div class="bg-blue-900 text-white -pt-12 container w-full">
                  <div class="flex-col gap-2">
                    <h3 class="text-left text-md text-white font-semibold p-4">
                      {{ card.name }}
                    </h3>
                    <hr />
                    <p class="px-3 py-2 text-left font-semibold">
                      {{ card.job }}
                    </p>
                    <div class="flex flex-row p-3">
                      <img src="@/assets/Testmonial-Icon.png" class="" alt="" />
                      <h5 class="flex text-left pl-4 text-[14px]">
                        {{ card.degree }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="rounded-lg mx-auto text-black hover:text-white">
                  <div class="w-full h-full">
                    <img :src="card.videoimage" alt="" class="w-full h-full" />
                  </div>
                </div>
              </div>

      

              <div v-else class="h-full w-full">
                <img :src="card.image" alt="" class="w-full h-full" />
              </div>

              <div v-if="active === card.name" class="flex">
                <div class="md:-ml-[500px] md:-mt-[170px] md: min-h-[155px]">
                  <p class="text-left text-base text-white md:w-[400px]">
                    {{ card.details }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- mobile Testimonial -->
        <div class="md:hidden">
          <div class="slick_praise_mob">
            <div
            @click="openmodal(card.src, card.name)"
             v-for="card in cards" :key="card.id">
              <div class="flex flex-col gap-4 w-[380px] p-4">
                <div class="w-[150px] mx-auto border-4 border-white">
                  <img :src="card.videoimage" alt="" class="w-full h-full" />
                </div>
                <div class="flex flex-col gap-5 py-5 px-5">
                  <div class="flex flex-col gap-2">
                    <h3
                      class="text-center text-md text-[#f8c301] font-semibold"
                    >
                      {{ card.name }}
                    </h3>
                    <p class="text-center text-md text-[#f8c301] font-semibold">
                      {{ card.job }}
                    </p>
                    <h3 class="text-center text-md text-[#f8c301] font-medium">
                      ({{ card.degree }})
                    </h3>
                  </div>
                  <p class="text-center text-white text-sm line-clamp-3">
                    {{ card.details }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="modal" id="myModal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <iframe
          :src="selectedVideo"
          width="100%"
          height="100%"
          style="border: none"
        ></iframe>
        <p class="font-bold text-center pt-5 pb-2">{{ cards.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "PraiseView",
  data() {
    return {
      active: "",
      selectedVideo: "",
      cards: [
        {
          id: 1,
          name: "Mohammad Altaf Hussain",
          src: "https://www.youtube.com/embed/Gm4hoVxaMQQ?si=sjjs08pVy_SE5nob",
          image: require("@/assets/landing/Altaf.webp"),
          videoimage: require("@/assets/landing/Altaf-video.webp"),
          job: "Delloite",
          degree: "MBA",
          details:
            "In Vignan, everything seems possible. Not only that, I have enrolled not only myself but also my elder sister, my friends, and their families because I believe in the advanced curriculum of Vignan and the promising future it offers.",
        },
        {
          id: 2,
          name: "KM Naidu",
          src: "https://www.youtube.com/embed/UnADDFbRYIQ?si=k4v1CrlhjSa1EhK9",
          image: require("@/assets/landing/K-M-Naidu.webp"),
          videoimage: require("@/assets/landing/K-M-Naidu-video.webp"),
          job: "Engineer",
          degree: "MBA",
          details:
            "I am a professional working, and I wanted to build my career, grow, and develop my skills. By joining Vignan Online, I have gained a lot of information to enhance my skills. ",
        },
        {
          id: 3,
          name: "Leena and Lekha",
          src: "https://www.youtube.com/embed/3J-STzx2ZhQ?si=TbAPY_CIB9V4Da3F",
          image: require("@/assets/landing/Leena-&-lekha.webp"),
          videoimage: require("@/assets/landing/Leena-&-lekha-video.webp"),
          job: "Student",
          degree: "BCA",
          details:
            "We have found Vignan to be our best option, as it offers flexible learning options",
        },
        {
          id: 4,
          name: "Massudan",
          src: "https://www.youtube.com/watch?v=jCfTOo2sKD8",
          image: require("@/assets/landing/Massudin.webp"),
          videoimage: require("@/assets/landing/Massudin-video.webp"),
          job: "Engineer",
          degree: "MBA",
          details:
            "For promotions and to achieve my career goals, obtaining a Postgraduate certification is mandatory. I discovered that Vignan Online is the only provider offering good quality education online, with experienced staff. I highly recommend choosing Vignan Online for your career growth.",
        },
        {
          id: 5,
          name: "Rajendra",
          src: "https://www.youtube.com/embed/IXjbDHNxBBM?si=y7JcdrTJ7fP3FMSC",
          image: require("@/assets/landing/Rajendra.webp"),
          videoimage: require("@/assets/landing/Rajendra-video.webp"),
          job: "Engineer",
          degree: "MBA",
          details:
            "I became a part of the Vignan family. It has proven to be the right platform for me, and I highly recommend it to everyone.",
        },
        {
          id: 6,
          name: "Ramya",
          src: "https://www.youtube.com/embed/jc5HNE3oans?si=e8M1Lo7-OHtIgr6L",
          image: require("@/assets/landing/Ramya.webp"),
          videoimage: require("@/assets/landing/Ramya-video.webp"),
          job: "Student",
          degree: "MBA",
          details:
            "I had a three-year gap in my education due to restrictions and responsibilities. However, I didn't want to halt my studies after marriage. I aimed to continue growing in my career, so I chose Vignan Online. It is an excellent platform for those looking to establish a successful career and achieve growth. Therefore, I highly recommend Vignan to everyone.",
        },
        {
          id: 7,
          name: "Vijay",
          src: "https://www.youtube.com/watch?v=Tpyj8WURJ4M",
          image: require("@/assets/landing/Vijay.webp"),
          videoimage: require("@/assets/landing/Vijay-video.webp"),
          job: "Working professional",
          degree: "MBA",
          details:
            "The way they organize their courses is excellent, including their assessments and the structure of their online sessions and weekend classes. I believe that everyone who joins can benefit greatly in their career and gain valuable knowledge through their MBA program.",
        },
        // {
        //   id: 8,
        //   name: "Yuvraj",
        //   image: require("@/assets/landing/Yuvraj.webp"),
        //   videoimage: require("@/assets/landing/Yuvraj-video.webp"),
        //   job: "Working professional ",
        //   degree: "MBA",
        //   details:
        //     "I completed my bachelor's degree in 2008 but couldn't continue my education due to personal commitments. Now, I am grateful for the opportunity to continue my studies with Vignan Online. Thank you for providing me with this opportunity.",
        // },
      ],
    };
  },

  mounted() {
    $(`.slick_praise_mob`).slick({
      infinite: true,
      autoplay: true,
      prevArrow: false,
      nextArrow: false,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      dotsClass: "slick-praise-dot",
    });

    $(`.slick_praise`).slick({
      centerMode: true,
      centerPadding: "10px",
      slidesToShow: 5,
      prevArrow: $(".prev-testimonials"),
      nextArrow: $(".next-testimonials"),
      speed: 200,
      index: 2,
      focusOnSelect: true,
      autoplay: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            centerMode: true,
            slidesToShow: 1,
            centerPadding: "10px",
          },
        },
        {
          breakpoint: 640,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "10px",
          },
        },
      ],
    });

    

    $(".slick_praise").on("afterChange", function () {
      $(".card").removeClass("active");
      $(".slick-center .card").addClass("active");
    });
  },

  methods: {
    openmodal(cards) {
      const modal = document.getElementById("myModal");
      modal.style.display = "block";
      this.selectedVideo = cards;
    },

    closeModal() {
      const modal = document.getElementById("myModal");
      modal.style.display = "none";
      this.selectedVideo = "";
    },
  },
};
</script>

<style>
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px 20px 70px 20px;
  border: 1px solid #888;
  width: 50%;
  height: 70%;
}

@media (max-width: 767.98px) {
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 10px 10px 50px 10px;
    border: 1px solid #888;
    width: 100%;
  }
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.slick_praise {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.slick_praise div {
  margin-right: 5px;
}

.card {
  display: none;
}

.card.active {
  display: block;
}
.slick-praise-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick-praise-dot li {
  margin: 0 0.25rem;
}
.slick-praise-dot button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  border: none;
  border-radius: 15px;
  background-color: silver;
  text-indent: -9999px;
}
.slick-praise-dot li.slick-active button {
  background-color: #f8c300;
  width: 2.5rem;
  height: 0.5rem;
}
.slick-center {
  display: block;
  max-width: 10% !important;
  max-height: 20% !important;
  opacity: 1;
}
</style>
