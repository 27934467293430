<template>
  <div>
    <!--header-->
    <sticky-view />
    <section class="fixed w-full top-0 bg-white z-50 shadow-lg">
      <div class="bg-white shadow-lg py-4">
        <div
          class="max-w-7xl mx-auto px-4 flex gap-5 justify-between place-items-center"
        >
          <div class="flex gap-6 items-center text-center">
            <a href="/" aria-label="redirect to homepage">
              <img src="../assets/logo.svg" class="w-[250px]" alt="logo" />
            </a>
          </div>
          <div class="flex items-center">
            <div>
              <div class="flex justify-center items-center gap-2">
                <div
                  class="bg-[#CA2526] my-4 text-white text-lg hover:text-red-500 py-2 rounded-lg shadow-lg w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] justify-center items-center"
                  data-v-920e5a82=""
                >
                  <a href="#enq" tabindex="-1"
                    ><p class="uppercase font-semibold">Apply Now</p></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--header-->
    <section>
      <div
        v-if="this.Electives"
        id="enq"
        class="ww-full mx-auto relative ban-img bg-cover bg-no-repeat"
      >
        <div class="md:pt-4 pt-20">
          <div
            class="absolite max-w-7xl mx-auto py-8 flex flex-col lg:flex-row place-items-center"
          >
            <div
              class="md:w-[30%] p-4 w-full md:mt-3 mx-auto xl:w-auto lg:text-left text-center"
            >
              <div>
                <div class="font-bold text-3xl lg:text-left text-center">
                  <h2 class="leading-[50px]">
                    VIGNAN <br />
                    <span class="text-[#CA2526]">Online Education</span>
                  </h2>
                </div>
                <div class="mt-5 md:w-[90%] w-full">
                  <h1
                    class="text-xl list5 bg-[#CA2526] text-white font-semibold p-3 rounded-lg"
                    v-html="Electives.title"
                  ></h1>
                </div>

                <div class="lg:hidden pt-4 flex justify-center">
                  <img
                    :src="Electives.mobile_banner"
                    class="h-full w-[70%] md:hidden"
                    alt="mobile-banner"
                  />
                </div>

                <div
                  class="flex flex-row lg:flex-col w-full text-sm md:text-[15px] pt-4 lg:text-left text-center lg:justify-start justify-center md:m-auto"
                >
                  <!-- <ul class="text-left list-disc font-semibold list-inside text-gray-600 text-lg pl-3" v-html="Electives.focus">
              </ul> -->
                  <div
                    class="text-gray-600 font-semibold float-left lg:text-left text-center leading-8"
                    v-html="Electives.focus"
                  ></div>
                </div>
              </div>
            </div>
            <div class="hidden lg:block relative w-[35%]">
              <div class="w-[80%] bottom-0">
                <img
                  :src="Electives.banner"
                  class="h-full w-full md:block hidden"
                  alt="desktop-banner"
                />
              </div>
            </div>

            <div class="lg:w-[35%] md:pr-5 md:mt-24">
              <div
                class="w-[100%] border-2 border-gray-400 shadow-lg rounded-lg"
              >
                <EnquireView :Electives="Electives.title" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="w-full p-5 bg-[#f7f9fb]">
      <div class="max-w-7xl justify-center items-center m-auto">
        <div class="flex flex-wrap justify-center items-left gap-8">
          <div
            class="flex flex-col justify-center text-left items-left w-[120px] md:w-[280px] md:min-h-[10px] min-h-[120px] lg:border-r-2 lg:border-[#ccc] border-[#ccc] lg:shadow-none shadow-lg lg:bg-[#f7f9fb] bg-white sm:rounded-lg"
          >
            <span
              class="lg:text-4xl text-xl font-bold py-2 text-[#CA2526] lg:text-left text-center"
              >45</span
            >
            <p class="lg:text-left text-center">years of glorious legacy</p>
          </div>

          <div
            class="flex flex-col justify-center text-left items-left w-[120px] md:w-[280px] md:min-h-[10px] min-h-[120px] lg:border-r-2 lg:border-[#ccc] border-[#ccc] lg:shadow-none shadow-lg lg:bg-[#f7f9fb] bg-white sm:rounded-lg"
          >
            <span
              class="lg:text-4xl text-xl font-bold py-2 text-[#CA2526] lg:text-left text-center"
              >3,000+</span
            >
            <p class="lg:text-left text-center">
              additional courses on SWAYAM & NPTEL
            </p>
          </div>

          <div
            class="flex flex-col justify-center text-left items-left w-[120px] md:w-[280px] md:min-h-[10px] min-h-[120px] lg:border-r-2 lg:border-[#ccc] border-[#ccc] lg:shadow-none shadow-lg lg:bg-[#f7f9fb] bg-white sm:rounded-lg"
          >
            <span
              class="lg:text-4xl text-xl font-bold py-2 text-[#CA2526] lg:text-left text-center"
              >360°</span
            >
            <p class="lg:text-left text-center">career support</p>
          </div>

          <div
            class="flex flex-col justify-center text-left items-left w-[120px] md:w-[280px] md:min-h-[10px] min-h-[120px] l lg:shadow-none shadow-lg lg:bg-[#f7f9fb] bg-white sm:rounded-lg"
          >
            <span
              class="lg:text-4xl text-xl font-bold py-2 text-[#CA2526] lg:text-left text-center"
              >3000+</span
            >
            <p class="lg:text-left text-center">learners worldwide</p>
          </div>
          <!-- <div class="border-t-2 bg-white"></div> -->
          <!-- <div class="border-t-2 w-64 border-white"></div> -->
        </div>
      </div>
    </section>
    <section>
      <div class="py-8">
        <SecMenu />
      </div>
    </section>
    <section id="sec1">
      <RankingComp />
    </section>

    <section class="bg-[#f7f9fb] pb-5" id="sec2">
      <div class="w-full mx-auto max-w-7xl pt-10 flex flex-col p-5">
        <div
          class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
        >
          <h2
            class="uppercase text-2xl font-bold"
            v-html="Electives.abt_title"
          ></h2>
          <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
        </div>
        <div class="flex flex-col md:flex-row gap-6">
          <div
            class="md:w-[70%] text-lg text-left pt-10"
            v-html="Electives.about_elective"
          ></div>
          <div class="md:w-[30%] pt-10">
            <img
              class="w-[50%j lg:-mt-24 Justify-center align-middle"
              src="../assets/online-mba-in-finance.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <section v-if="shouldShowSection" class="pb-5">
      <div class="w-full mx-auto max-w-7xl pt-10 flex flex-col p-5">
        <div
          class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
        >
          <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
            Advanced<span class="text-[#d02829]"> Certifications</span>
          </h2>

          <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
        </div>
        <div class="flex flex-col md:flex-row gap-6">
          <div class="md:w-1/2 text-left md:pt-10">
            <div
              class="flex flex-col md:flex-row justify-between items-start font-semibold text-[15px] mt-4 md:gap-5"
            >
              <div class="md:w-[70%] w-full ml-4">
                <ul class="red-dot-list text-left leading-[45px]">
                  <li
                    class="relative"
                    v-for="(elective, index) in Electives.advance_certification"
                    :key="index"
                  >
                    {{ elective }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="md:w-[50%] w-[70%] justify-center align-middle pt-4">
            <img
              class="md:w-2/3 justify-center align-middle m-auto"
              src="../assets/advanced-certifications.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <section class="bg-[#f7f9fb] pb-5" id="sec4">
      <div class="max-w-7xl justify-center items-center m-auto pb-4">
        <div
          class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
        >
          <h2
            class="font-bold uppercase lg:text-2xl mt-8 px-8 py-2 text-lg text-gray-700 p-2 pt-4"
          >
            Why
            <span class="text-[#D02829]" v-html="Electives.abt_title"></span>
          </h2>
          <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
        </div>
        <div class="flex flex-wrap justify-center items-center gap-2">
          <div
            class="flex flex-col justify-center m-auto items-center w-[40%] md:w-[21%]"
            v-for="data in this.Electives.landing_image"
            :key="data.id"
          >
            <div class="">
              <img :src="data.image" alt class="w-[80%]" />
            </div>
            <p class="text-center p-4" v-html="data.description"></p>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white" id="sec5">
      <div
        class="max-w-7xl mx-auto flex flex-col lg:flex-row place-items-center"
      >
        <div
          class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
        >
          <div class="flex flex-col text-center py-4">
            <h2
              class="font-bold uppercase lg:text-2xl mt-8 px-8 py-4 text-lg text-gray-700 p-2 pt-4"
            >
              Who can
              <span class="text-[#D02829]">Apply</span>
            </h2>
            <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
          </div>
          <div class="flex flex-row">
            <div
              class="flex lg:flex-row flex-col lg:gap-8 gap-4 w-full justify-center py-4"
            >
              <div class="flex flex-col justify-center items-center">
                <img
                  src="../assets/eligility.webp"
                  alt=""
                  class="ml-2 w-[50%] md:w-full"
                />
              </div>
              <div class="flex flex-col p-4">
                <h4
                  class="font-semibold text-[#ca2526] text-left pt-4 cursor-pointer"
                >
                  Eligibility
                </h4>
                <p class="text-left" v-html="Electives.eligibility"></p>
              </div>

              <div class="flex flex-col">
                <div class="flex-col flex justify-center align-middle m-auto">
                  <div
                    class="bg-[#CA2526] my-4 text-white text-lg hover:text-red-500 py-3 rounded-lg shadow-xl hover:bg-white hover:border-2 hover:border-[#CA2526] justify-center items-center"
                  >
                    <a href="#enq" tabindex="-1"
                      ><p class="uppercase font-semibold">Apply Now</p></a
                    >
                  </div>
                  <div class="flex justify-center items-center gap-2">
                    <a href="tel:+918877661755">
                      <div
                        class="flex justify-center items-center bg-[#2a2a2a] text-white w-[180px] py-2 rounded-lg"
                      >
                        <img
                          src="../assets/talk-to-councellor.png"
                          class="w-8 px-2"
                          alt="call"
                        /><span class="text-[13px] leading-2 font-bold"
                          >Talk to a Councellor</span
                        >
                      </div></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="sec6" class="bg-red-800">
      <div>
        <VideoCard />
      </div>
    </section>

    <section id="sec7">
      <div class="w-full px-5 py-1">
        <HiringLP />
      </div>
    </section>

    <section class="bg-[#f7f9fb] pb-5" id="sec8">
      <div class="max-w-7xl justify-center items-center m-auto pb-4">
        <div
          class="text-center items-center justify-center m-auto flex flex-col pt-2"
        >
          <h2
            class="font-bold uppercase lg:text-2xl mt-8 px-8 py-2 text-lg text-gray-700 p-2 pt-4"
          >
            Career
            <span class="text-[#D02829]">Opportunities</span>
          </h2>
          <div class="p-4"><img src="@/assets/Heading-underline.png" /></div>
        </div>
        <div class="flex flex-wrap justify-left gap-2">
          <div
            class="flex flex-col justify-left m-auto w-[40%] md:w-[25%]"
            v-for="data in this.Electives.carrer_image"
            :key="data.id"
          >
            <div class="">
              <img :src="data.image" />
            </div>

            <p class="text-left p-4" v-html="data.description"></p>
          </div>
        </div>
      </div>
    </section>

    <section id="sec9">
      <div>
        <div
          class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
        >
          <h2
            class="font-bold uppercase lg:text-2xl mt-8 px-8 py-4 text-lg text-gray-700 p-2 pt-4"
          >
            Career
            <span class="text-[#D02829]">Advancement Services</span>
          </h2>
          <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
        </div>
        <CasLp />
      </div>
    </section>

    <section class="bg-[#f7f9fb] pb-5" id="sec10">
      <div
        class="w-full mx-auto max-w-7xl align-middle py-0 pt-10 flex flex-col p-5"
      >
        <div class="flex flex-col justify-center items-center pb-8 text-center">
          <h2 class="uppercase text-2xl font-bold">
            Completion <span class="text-primary">Certificate</span>
          </h2>
          <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
        </div>
        <div class="flex flex-col md:flex-row gap-6 align-middle">
          <div
            class="md:w-[70%] text-left md:mt-[50px] align-middle self-center"
          >
            <h3
              class="font-semibold text-[#ca2526] text-xl lg:text-left text-center pt-4 pb-5"
            >
              Secure a Globally Recognized Degree
            </h3>

            <p>
              Earn a degree that is not only acknowledged but widely respected
              in your chosen field. The online degrees offered by Vignan
              (Deemed-to-be University) are at par with on-campus degrees,
              unlocking numerous opportunities for your future endeavors.
            </p>
          </div>
          <div class="md:w-[30%] pt-10">
            <img
              class="w-[50%j mt-2 Justify-center align-middle"
              src="../assets/certificate.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div>
        <LlpFaQ />
      </div>
    </section>

    <div class="w-full p-5 bg-[#2a2a2a]">
      <div
        class="max-w-7xl mx-auto w-full text-white font-semibold text-sm flex flex-col md:flex-row justify-center items-center gap-5"
      >
        <!-- <div
            class="flex flex-col justify-center items-center md:w-[250px] gap-3"
          >
            <img
              src="@/assets/lpmba/icon-whatsapp.png"
              style="width: 30px"
              alt=""
            />
            <div class="min-h-[90px]">
              <a
                href="https://api.whatsapp.com/send?phone=918877661755&amp;text=Hi!%20I%27d%20like%20to%20know%20more%20details."
                target="_blank"
                tabindex="-1"
              >
                <p class="mb-0">+91 8877661755</p>
              </a>
            </div>
          </div> -->

        <div class="flex flex-col justify-center items-center gap-3">
          <div class="">
            <p class="">&copy; 2023 VIGNAN Online All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="md:hidden">
      <div class="w-full bg-[#c82228]">
        <div class="flex justify-center items-center py-3 px-20">
          <a href="tel:+918877661755"
            ><div class="flex flex-col justify-center items-center gap-3">
              <img src="@/assets/home/sticky/call-icon.webp" />
              <p class="text-white font-semibold text-[11px]">CALL NOW</p>
            </div></a
          >
        
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import $ from "jquery";
import EnquireView from "@/components/EnquireFormNew.vue";
import axios from "axios";
import RankingComp from "@/components/RankingComp.vue";
import CasLp from "@/components/CasLp.vue";
// import LpFaQ from "@/components/LpFaQ.vue";
import HiringLP from "@/components/HiringLP.vue";
import SecMenu from "@/components/SecMenu.vue";
import StickyView from "@/components/StickyView.vue";
import LlpFaQ from "@/components/LlpFaQ.vue";
import VideoCard from "@/components/VideoCard.vue";

export default {
  name: "NewLpView",
  components: {
    EnquireView,
    HiringLP,
    RankingComp,
    CasLp,
    // LpFaQ,
    SecMenu,
    StickyView,
    LlpFaQ,
    VideoCard,
  },
  data() {
    return {
      Electives: [],
      ElecFaqs: [],
      isModalOpen: false,
      is_widget: false,
      showMenu: false,
      openTab: 6,
      currentUrl: window.location.pathname,
      countdown: "Loading...", // Initial value before the timer starts
    };
  },

  created() {
    this.electiveCall();
    setTimeout(() => {
      this.is_widget = true;
    }, 2000);
  },

  mounted() {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = this.countDownDate - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = `${hours}h ${minutes}m ${seconds}s`;

      if (distance < 0) {
        this.countDownDate = this.getNeverEndingDate();
      }
    };

    this.countDownDate = this.getNeverEndingDate();
    updateCountdown();
    setInterval(updateCountdown, 1000);

    $(document).ready(function () {
      $(".slider").slick({
        centerMode: true,
        centerPadding: "10px",
        slidesToShow: 5,
        speed: 1500,
        index: 2,
        autoplay: false,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: "10px",
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: "10px",
              slidesToShow: 1,
            },
          },
        ],
      });

      $(".slider").on("afterChange", function () {
        // Reset all hidden divs to hidden
        $(".bg-blue-900").removeClass("active");
        $(".content12").removeClass("active");

        // Add active class to the hidden div of the center slide
        $(".slick-center .bg-blue-900").addClass("active");
        $(".slick-center .content12").addClass("active");
      });
    });
  },

  methods: {
    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass("rotate-180")) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass(
          "rotate-180"
        );
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },

    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },

    getNeverEndingDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.setHours(0, 0, 0, 0);
    },

    faq(id) {
      let faq_list = document.querySelectorAll(".answer-list");
      faq_list.forEach((el) => {
        if (!el.classList.contains("hidden")) {
          el.classList.add("hidden");
        }
      });
      document.getElementById(`answer-${id}`).classList.toggle("hidden");
    },

    async electiveCall() {
      const slug = this.$route.params.slug;
      const apiUrl = `${process.env.VUE_APP_API}/lp/electives/?slug=${slug}`;

      try {
        const resp = await axios.get(apiUrl);
        if (resp.data.status === 200) {
          this.Electives = resp.data.data[0];
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    shouldShowSection() {
      const hiddenUrls = [
        "/lp/online-bca-computer-science-and-information-technology",
        "/lp/online-bba-general",
      ];
      return !hiddenUrls.includes(this.currentUrl);
    },
  },
};
</script>

<style scoped>
.slick-center .slide-h3 {
  color: #fff;
}

.slider {
  height: 150px;
  margin: 20px auto;
  text-align: center;
}

.slider div {
  margin-right: 5px;
}

.slick-slide {
  opacity: 0.6;
  width: 10px;
}

.slick-center {
  display: block;
  max-width: 10% !important;
  max-height: 20% !important;
  opacity: 1;
}

.slick-content {
  font-size: 15px;
  color: #fff;
}

.bg-blue-900 {
  display: none; /* Initially hide all hidden divs */
}

.bg-blue-900 .active {
  display: block; /* Show the hidden div of the center slide when it's active */
}

.content12 {
  display: none; /* Initially hide all hidden divs */
}

.content12 .active {
  display: block; /* Initially hide all hidden divs */
}

.list5 li {
  list-style-image: url(https://api.vignanonline.com//media/uploads/2024/01/22/red-arrow.png);
  width: 15px;
  height: 15px;
  margin-right: 15px;
  position: absolute;
  display: inline-block;
}

.content12 {
  text-align: left;
  left: 1%;
}

@media (max-width: 767.98px) {
  .question {
    margin-bottom: 8px;
    width: 83%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .content12 {
    text-align: center;
  }

  .faq-qus-arrow {
    position: absolute;
    float: right;
    top: 10px;
    right: -30px !important;
    width: 30px;
    height: 30px;
    border: 1px solid #c9c9c9;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    margin-right: -25px;
  }
  .ban-img {
    background-image: none !important;
    width: 100%;
  }
}
.question {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.faq-qus-arrow {
  position: absolute;
  float: right;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  margin-right: -25px;
}

.faq-qus-arrow:after {
  content: "";
  border: solid #c9c9c9;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 2px);
  transition: all 0.2s ease;
}
.enquire-s {
  height: 0px;
  width: 65px;
  position: fixed;
  right: 0;
  top: 55%;
  z-index: 1000;
  transform: rotate(-90deg);
  color: black;
  background-color: #d02829;
}
.enquire-s a {
  display: block;
  background: #d02829;
  height: 30px;
  width: 140px;
  border-radius: 6px 6px 0 0;
  padding: 8px 16px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
  border-bottom: solid 1px #333;
  border-left: solid 1px #333;
  border-right: solid 1px #fff;
}
.methodology-box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.banner-feature-box {
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  margin: 0 5px;
  border-radius: 5px;
  line-height: 18px;
  margin-bottom: 30px;
  font-size: 14px;
}

.course-features-box {
  min-width: 22%;
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  line-height: 18px;
  text-align: left;
  font-size: 14px;
}
.red-dot-list li::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 18px;
  left: -40px;
  background-color: #d02829;
  margin-left: 10px;
}
.boxshadow {
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  margin: 0 5px;
  padding: 10px;
  border-radius: 5px;
  line-height: 18px;
  margin-bottom: 30px;
}
.arrowmark111 ul li {
  background-image: url(https://api.vignanonline.com//media/uploads/2023/11/10/Arrow.svg);
  color: #fff;
  border-radius: 0;
  background-position: left top 7px;
  padding-left: 24px;
  height: 10px;
  display: flex;
  font-size: 16px;
  color: #fff !important;
  margin-top: 15px;
}

.red-dot-list li::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 18px;
  left: -40px;
  background-color: #d02829;
  margin-left: 10px;
}

.ban-img {
  background-image: url(../assets/banner-bg.webp);
  width: 100%;
}

.bor-element {
  width: 250px;
  height: 50px;
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border-radius: 10px;
  box-shadow: -13px -25px 20px -20px rgba(0, 0, 0, 0.45),
    0px 25px 20px -20px rgba(0, 0, 0, 0.45);
}
</style>
