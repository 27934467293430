<template>
  <div class="w-full py-5">
    <div class="max-w-7xl mx-auto w-full px-5">
      <div class="alltabs flex justify-center gap-[10px] lg:gap-[20px] w-full">
        <div class="button font-bold text-sm md:text-[16px]" id="tab1">
          SYLLABUS
        </div>
        <div class="button font-bold text-sm md:text-[16px]" id="tab2">
          FEES STRUCTURE
        </div>
        <div class="button font-bold text-sm md:text-[16px]" id="tab3">
          STUDENT ELIGIBILITY
        </div>
      </div>
      <div class="button-content mt-5">
        <div class="flex flex-col" id="content1">
          <div class="flex flex-wrap lg:gap-8 gap-4 justify-between w-[100%]">
            <div
              class="lg:w-[48%] w-full"
              v-for="data in this.Electives.syllabus"
              :key="data.id"
            >
              <div class="flex">
                <div class="text-left w-[40%]">
                  <span class="font-bold mr-5 text-black lg:text-5xl text-lg">{{
                    data.semester
                  }}</span
                  ><br />
                  <span class="text-[#d0393b] font-bold">Semester</span>
                </div>

                <div
                  class="text-left w-[65%] text-black border-[#d0393b] border-l-4 pl-5 text-[14px]"
                >
                  <p class="font-semibold" v-html="data.content"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:py-5 py-2" id="content2">
          <div
            class="flex flex-col md:flex-row gap-3 justify-center items-center"
          >
            <div
              class="md:w-1/2 lg:w-[40%] w-full xl:w-[35%] border-2 border-gray-200 lg:p-5 p-2 rounded-md md:h-[420px]"
            >
              <h2 class="font-bold text-black text-lg lg:text-2xl text-left">
                INDIAN <span class="text-[#d0393b]">STUDENTS</span>
              </h2>
              <p class="text-left text-[14px] font-semibold">
                For Indian National & SAARC Nations
              </p>

              <div class="flex justify-between items-center font-semibold mt-4">
                <div class="text-black text-[14px] lg:text-[14px]">
                  Semester Fee Plan
                </div>
                <div
                  class="text-[#d0393b] text-[14px] lg:text-[14px] font-semibold"
                >
                  ₹ {{ formatPrice(Electives.indian_semester_fee) }}
                </div>
              </div>
              <div class="flex justify-between items-center font-semibold">
                <div class="text-black text-[14px] lg:text-[14px]">
                  Annual Fee Plan
                </div>
                <div
                  class="text-[#d0393b] text-[14px] lg:text-[14px] font-semibold"
                >
                  ₹ {{ formatPrice(Electives.indian_annual_fee) }}
                </div>
              </div>
              <div
                class="flex justify-between items-center font-semibold border-b-2 border-gray-200 pb-3"
              >
                <div class="text-black text-[14px] lg:text-[14px]">
                  Total Fee Plan
                </div>
                <div
                  class="text-[#d0393b] text-[14px] lg:text-[14px] font-semibold"
                >
                  ₹ {{ formatPrice(Electives.indian_total_fee) }}
                </div>
              </div>

              <div class="text-left text-black text-[14px] font-normal">
                <p class="py-2"><strong>Note</strong></p>
                <p class="py-2">
                  University Registration Fee:
                  <strong
                    >Rs.
                    {{
                      formatPrice(Electives.indian_university_registration_fee)
                    }}
                  </strong>
                </p>
                <p class="py-2">
                  Examination Fee per year:
                  <strong
                    >Rs. {{ formatPrice(Electives.indian_exam_fee) }}
                  </strong>
                </p>
                <p class="py-2">
                  Registration Fee for SAARC countries:
                  <strong
                    >₹
                    {{
                      formatPrice(Electives.indian_registration_fee_for_saarc)
                    }}
                  </strong>
                </p>
                <p class="py-2">
                  Exam Fee Per Year for SAARC countries:
                  <strong
                    >₹ {{ formatPrice(Electives.indian_exam_fee_for_saarc) }}
                  </strong>
                </p>
              </div>
            </div>

            <div
              class="md:w-1/2 lg:w-[40%] xl:w-[35%] border-2 border-gray-200 lg:p-5 p-2 rounded-md md:h-[420px]"
            >
              <h2 class="font-bold text-black text-lg lg:text-2xl text-left">
                FOREIGN <span class="text-[#d0393b]">STUDENTS </span>
              </h2>
              <p class="text-left text-[14px] font-semibold">
                For Foreign Students
              </p>

              <div class="flex justify-between items-center font-semibold mt-4">
                <div class="text-black text-[14px] lg:text-[14px]">
                  Semester Fee Plan
                </div>
                <div
                  class="text-[#d0393b] text-[14px] lg:text-[14px] font-semibold"
                >
                  $ {{ formatPrice(Electives.foregin_semester_fee) }}
                </div>
              </div>
              <div class="flex justify-between items-center font-semibold">
                <div class="text-black text-[14px] lg:text-[14px]">
                  Annual Fee Plan
                </div>
                <div
                  class="text-[#d0393b] text-[14px] lg:text-[14px] font-semibold"
                >
                  $ {{ formatPrice(Electives.foregin_annual_fee) }}
                </div>
              </div>
              <div
                class="flex justify-between items-center font-semibold border-b-2 border-gray-200 pb-3"
              >
                <div class="text-black text-[14px] lg:text-[14px]">
                  Total Fee Plan
                </div>
                <div
                  class="text-[#d0393b] text-[14px] lg:text-[14px] font-semibold"
                >
                  $ {{ formatPrice(Electives.foregin_total_fee) }}
                </div>
              </div>

              <div class="text-left text-black text-[14px] font-normal">
                <p class="py-2"><strong>Note</strong></p>
                <p class="py-2">
                  University Registration Fee:
                  <strong
                    >$
                    {{
                      formatPrice(Electives.foregin_university_registration_fee)
                    }}
                  </strong>
                </p>
                <p class="py-2">
                  Examination Fee per year:
                  <strong
                    >$ {{ formatPrice(Electives.foregin_exam_fee) }}
                  </strong>
                  is applicable
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="py-5" id="content3">
          <div
            class="flex flex-col md:flex-row gap-[10%] justify-center items-center"
          >
            <div class="md:w-1/2 xl:w-[40%]">
              <img src="@/assets/electivepage/eligibility.jpg" />
            </div>

            <div v-if="this.Electives" class="md:w-1/2 xl:w-[40%] text-justify">
              <p class="text-[14px]">{{ Electives.eligibility }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "syllaBus",
  data() {
    return {
      activetabbutton1: 0,
      Elegibility: [],
    };
  },

  mounted() {
    this.initializeTabs();
  },

  props: ["Electives"],

  methods: {
    changeTab(index) {
      this.activetabbutton = index;
    },
    initializeTabs() {
      const tabs = document.querySelectorAll(".button");
      const tabContents = document.querySelectorAll(".button-content > div");

      tabs.forEach((tabbutton, index) => {
        tabbutton.addEventListener("click", () => {
          tabContents.forEach((content) => {
            content.classList.remove("show");
          });

          tabContents[index].classList.add("show");

          tabs.forEach((t) => {
            t.classList.remove("active");
          });

          tabbutton.classList.add("active");
        });
      });

      tabs[0].click();
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button {
  cursor: pointer;
  user-select: none;
  /* box-shadow: 0px 0px 5px 1px rgba(227, 224, 227, 1);
    border-radius: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 2px;
}
.button:hover {
  border-bottom: 5px solid #d0393b;
  transition: 0.5s;
}

.button-content > div {
  display: none;
  padding: 20px 20px;
}

.button-content > div.show {
  display: block;
  /* box-shadow: 0px 0px 5px 1px rgba(227,224,227,1); */
  border-radius: 10px;
}

.active {
  /* color: white; */
  border-bottom: 5px solid #d0393b;
}
</style>
