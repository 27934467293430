<template>
  <div>
    <section id="call" >
      <div v-if="is_counselor"
      class="fixed top-4 z-50 h-full w-full flex m-auto items-center justify-center bg-black bg-opacity-60"
      @click="is_counselor = false">

      <iframe src="https://zipme.at/vignanonline-vignan-online?utm_source=ZipTeams/Website/video_call" name="onlinejain" width="352px" height="500px"
        style="border: 2px solid #f6f5f4; border-radius: 10px" allow="camera *;microphone *;display-capture *"></iframe>

    </div>
    <!-- floating contact elements -->
    <div class="hidden lg:block">
      <div class="fixed top-[50%] right-5 flex flex-col gap-3">
        <button @click="is_counselor = true">
          <div
            class="hover-tooltip sticky-hover bg-black w-[42px] h-[42px] rounded-full flex items-center justify-center">
            <img src="@/assets/home/cam-recorder.png" alt="whatsapp" class="p-2.5 w-full h-full object-contain" />
            <span class="cbs-tooltip top-[10%] text-start font-bold">
              Hey there!
              <br />
              Do you have any questions? Let's talk!
            </span>
          </div>
        </button>
        <a href="tel:+91 9294969497">
          <div
            class="hover-tooltip sticky-hover w-[42px] h-[42px] flex items-center justify-center">
            <img src="../assets/icon-call.png" alt="phone" class="object-contain" />
            <span class="cbs-tooltip top-[50%] text-start font-bold flex items-center gap-2">
              +918877661139
            </span>
          </div>
        </a>
        <a href="https://api.whatsapp.com/send/?phone=918877661139&text&app_absent=0" target="_blank">
          <div class="w-[42px] h-[42px] flex items-center justify-center">
            <img src="../assets/icon-whap.png" alt="whatsapp" class="object-contain" />
          </div>
        </a>
      </div>
    </div>
    </section>
    <section>
      <div class="fixed bottom-0 w-full z-50">
      
      <div class="w-full md:hidden bg-[#d02829]">
        <!-- <div class="bg-[#d02829] md:hidden z-20 w-full h-8">
          <span
            class="text-white font-bold z-50 text-center"
            style=""
            >Admission closing soon
            <span
              id="countdown1"
              class="text-[#2a2a2a] font-semibold text-xl"
              > {{ countdown1 }}</span
            >
            </span
          >
        </div> -->
        <div class="flex flex-row justify-between px-1 py-3">
          <div class="blinking-bg rounded-lg">
            <div
              @click="openModal"
              class="flex flex-col justify-center items-center gap-3"
            >
              <img src="@/assets/home/sticky/icon-enquire.webp" class="w-[25px] h-[25px]" title="Enquire Now Mobile" alt="Enquire Now Mobile" />
              <p class="text-white font-semibold text-[11px]">ENQUIRE NOW</p>
            </div>
          
            
          </div>
          <div class="border-r border-white"></div>
          <a
            href="https://api.whatsapp.com/send/?phone=918877661139&amp;text&amp;app_absent=0"
            target="_blank">
            <div class="flex flex-col justify-center items-center gap-3">
              <img
                src="@/assets/home/sticky/icon-whatsapp-white.svg"
                class="w-8" width="41px" height="41px" alt="whatsapp-icon"
              />
              <p class="text-white font-semibold text-[11px] p-2">WHATSAPP</p>
            </div></a>
            <div class="border-r border-white"></div>
          <a>
            <div class="flex flex-col justify-center items-center gap-3">
              <button @click="is_counselor = true">
              <div
                class="w-[25px] h-[25px] flex items-center justify-center"
              >
                <img
                  src="@/assets/home/cam-recorder.png"
                  alt="Video Counselling"
                  class="w-[40px] h-[40px] object-contain"
                />
              </div>
            </button>
              <p class="text-white font-semibold text-[11px] pt-1">VIDEO COUNSELLING</p>
            </div></a>
          <div class="border-r border-white"></div>
          <a href="tel:+918877661139">
            <div class="flex flex-col justify-center items-center gap-3">
              <img src="@/assets/home/sticky/call-icon.webp" class="w-[25px] h-[25px]" title="Call Icon Mobile" alt="Call Icon Mobile" />
              <p class="text-white font-semibold text-[11px] pt-1">CALL NOW</p>
            </div></a>
        </div>
      </div>
      </div>
    </section>
    <!-- Modal -->
    <div class="Emodal" v-if="isModalOpen">
      <div
        class="Emodal-content rounded-md relative bottom-24 md:w-[35%] 2xl:w-[20%]"
      >
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
    <ChatBot />
  </div>
</template>
<script>
import EnquireView from "@/components/EnquireForm.vue";
// import ChatBot from "@/components/ChatBot.vue";

export default {
  components: {
    EnquireView,
    // ChatBot,
  },
  data() {
    return {
      countdown: "Loading...", // Initial value before the timer starts
      isModalOpen: false,
      is_counselor: false,
    };
  },
  methods: {
    getNeverEndingDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.setHours(0, 0, 0, 0);
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
  mounted() {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = this.countDownDate - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = `${hours}h ${minutes}m ${seconds}s`;
      this.countdown1 = `${hours}h ${minutes}m ${seconds}s`;

      if (distance < 0) {
        this.countDownDate = this.getNeverEndingDate();
      }
    };

    this.countDownDate = this.getNeverEndingDate();
    updateCountdown();
    setInterval(updateCountdown, 1000);
  },
};
</script>

<style scoped>

.hover-tooltip .cbs-tooltip {
  background: #d02829;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  right: 0;
  /* top: 11%; */
  transform: translateY(-50%);
  padding: 12px 15px;
  color: #fff;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: -10;
  font-weight: 600;
  letter-spacing: 0px;
  visibility: hidden;
  width: 170px;
}

.hover-tooltip .cbs-tooltip::before {
  content: "";
  position: absolute;
  right: -10px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  z-index: 9;
  width: 10px;
  border-left: 10px solid #d02829;
}
.hover-tooltip:hover .cbs-tooltip {
  right: calc(100% + 10px);
  opacity: 1;
  transition: all 0.3s ease-in-out;
  z-index: 0;
  visibility: visible;
}
.sidebar-call-holder {
  position: fixed;
  top: 80%;
  right: 0;
  transform: translateY(-50%);
  z-index: 99;
  width: 60px;
}

.sidebar-call-holder1 {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 99;
  width: 60px;
}

.sidebar-call-holder a {
  margin-bottom: 5px;
  margin-left: 0;
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.sidebar-call-holder1 a {
  margin-bottom: 5px;
  margin-right: 0;
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  position: relative;
}

span.custom-tooltip {
  padding: 8px 15px;
  white-space: nowrap;
  background: #e4e7ed;
  color: #7d7d7d;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  position: absolute;
  z-index: -1;
  left: -185px;
  top: 15px;
  display: flex;
  align-items: center;
  transform: translateX(162px);
  opacity: 0;
  transition: all 0.3s ease;
}
.sidebar-call-holder a:hover .custom-tooltip {
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.3s ease;
}

.sidebar-call-holder1 a:hover .custom-tooltip {
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.3s ease;
}
.custom-tooltip:before {
  content: "";
  position: absolute;
  right: -10px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  z-index: 9;
  width: 10px;
  border-left: 10px solid #e4e7ed;
  top: 11px;
}
.custom-tooltip .fa {
  margin-right: 5px;
  font-size: 20px;
}
a.fixed-call-btn {
  display: none;
  position: fixed;
  right: 5px;
  z-index: 99;
  bottom: 0;
}

.Emodal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Emodal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  top: 50px;
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}

@keyframes blink {
  0% {
    background-color: #004271;
  }
  50% {
    background-color: transparent; /* or any other color you want during the blink */
  }
  100% {
    background-color: #004271;
  }
}

.blinking-bg {
  animation: blink 1s infinite;
  background-color: #004271; /* Set the initial background color */
  padding: 5px;
}
</style>
