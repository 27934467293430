<template>
  <div>
    <NavBar />
    <!--banner part-->
    <div
      v-if="this.Electives"
      class="w-full mx-auto relative object-cover bg-center bg-no-repeat"
    >
      <img :src="Electives.banner" class="hidden md:block w-full mt-24" />
      <img :src="Electives.mobile_banner" class="md:hidden pt-20" />
      <div class="w-full max-w-screen-2xl mx-auto">
        <div class="absolute md:top-5 top-24 left-3">
          <p>
            <router-link to="/">Home</router-link> >
            <router-link to="/masters-computer-applications-online-degree"
              >MCA</router-link
            >
            > {{ Electives.name }}
          </p>
        </div>
        <div
          class="absolute text-xl lg:w-full w-[60%] md:text-[28px] lg:leading-10 leading-7 text-left font-[700] top-32 md:top-[14%] left-4 lg:left-[6%] xl:left-[12%]"
        >
          <div v-html="Electives.focus"></div>
        </div>
        <div
          class="text-black md:text-left absolute left-3 bottom-2 lg:bottom-[20%] xl:bottom-[20%] 2xl:bottom-[35%] md:left-[5%] lg:left-[8%] xl:left-[12%]"
        >
          <img
            src="@/assets/electivepage/ban-logos1-mob.png"
            class="md:hidden w-[35%]"
          />
          <img
            src="@/assets/electivepage/ban-logos1.png"
            class="hidden md:block w-[50%]"
          />
          <div class="flex lg:flex-col flex-row gap-5">
            <button
              @click="openModal"
              class="bg-[#cf4030] text-white font-[600] text-[16px] px-3 py-2 w-36 mt-5 rounded-2xl"
            >
              Enquire Now
            </button>
            <h6
              class="text-md font-[600] lg:text-left text-right md:text-lg align-middle lg:self-start self-center lg:text-2xl mt-4 xl:mt-6"
            >
              #Driving<span class="text-[#cf4030]">YourFuture</span>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <!--banner part completed-->

    <div class="grad w-full p-3 md:py-[45px] md:px-[30px]">
      <div class="w-full max-w-7xl mx-auto justify-center">
        <div
          class="flex flex-wrap justify-center items-center gap-3 xl:gap-5 mx-auto my-0"
        >
          <div
            v-if="this.Electives"
            class="text-[16px] leading-5 bg-[#e3dade] flex flex-col md:flex-row justify-center items-center gap-3 min-h-[120px] md:w-[200px] md:min-h-[100px] lg:w-[230px] lg:min-h-[120px] rounded-md gradbox"
          >
            <img src="@/assets/Program/icon-time-red.png" />
            <span class="text-center md:text-left"
              >Duration <br />
              <span class="font-bold text-black text-md lg:text-lg"
                >{{ Electives.durations }} YEARS</span
              >
            </span>
          </div>

          <div
            class="text-[16px] leading-5 bg-[#e3dade] flex flex-col md:flex-row justify-center items-center gap-3 min-h-[120px] md:w-[200px] md:min-h-[100px] lg:w-[230px] lg:min-h-[120px] rounded-md gradbox"
          >
            <img src="@/assets/Program/icon-calendar-red.svg" />
            <span class="text-center md:text-left"
              >Live Online Sessions <br />
              <span class="font-bold text-black text-md lg:text-lg"
                >WEEKEND</span
              >
            </span>
          </div>

          <div
            class="text-[16px] leading-5 bg-[#e3dade] flex flex-col md:flex-row justify-center items-center gap-3 min-h-[120px] md:w-[200px] md:min-h-[100px] lg:w-[230px] lg:min-h-[120px] rounded-md gradbox"
          >
            <img src="@/assets/Program/icon-precent.svg" />
            <span class="font-bold text-black text-md lg:text-lg md:text-left"
              >FLEXIBLE EMI<br />OPTIONS</span
            >
          </div>
        </div>
      </div>
    </div>

    <!--About the elective-->
    <div class="w-full px-5 pb-5">
      <div class="max-w-7xl mx-auto w-full">
        <div
          v-if="Electives.description"
          v-html="Electives.description"
          class="py-3 text-left md:px-5 font-[400] text-[14px]"
        ></div>
        <p class="md:ml-6 text-[#212121] text-left">Program credits: 90</p>
      </div>
    </div>
    <!--About the elective end-->

    <!--Program highlights-->
    <div class="w-full px-5 pb-5">
      <div class="max-w-7xl mx-auto w-full">
        <h2
          class="font-bold text-black text-lg md:text-2xl pb-5 text-left md:px-5"
        >
          ELECTIVE <span class="text-[#d0393b]">HIGHLIGHTS</span>
        </h2>
        <div
          class="flex flex-col md:flex-row justify-start lg:gap-[80px] w-full md:px-5"
          v-if="Electives.Highlights"
        >
          <div class="flex flex-col justify-center items-start">
            <div
              class="flex justify-center gap-4 md:gap-8 items-center px-3 py-3"
            >
              <!-- <img src="@/assets/Program/icon-bulb.svg" /> -->
              <span
                class="text-left text-[14px] highlights"
                v-html="Electives.Highlights"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Program highlights end-->

    <!--About the advanced certification-->
    <div class="w-full px-5 py-5 bg-[#f7f9fb]">
      <div class="w-full mx-auto max-w-7xl pt-10 flex flex-col p-5">
        <div class="max-w-7xl flex flex-col text-left">
          <h2
            class="font-bold uppercase text-black text-lg md:text-2xl pb-5 text-left md:px-5"
          >
            Advanced <span class="text-[#d0393b]">Certifications</span>
          </h2>
          <!-- <div class="p-2" >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPYAAAAFCAYAAAB2FBF/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMi1jMDAwIDc5LjFiNjVhNzliNCwgMjAyMi8wNi8xMy0yMjowMTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkUzNjUzQjI1OUU1MTExRUU5QTMxRTM1NEMwQjFDQkIwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkUzNjUzQjI2OUU1MTExRUU5QTMxRTM1NEMwQjFDQkIwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTM2NTNCMjM5RTUxMTFFRTlBMzFFMzU0QzBCMUNCQjAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTM2NTNCMjQ5RTUxMTFFRTlBMzFFMzU0QzBCMUNCQjAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7QHtWEAAAASklEQVR42uzToQ0AIRBE0Rk6QdEIZdMIilIWD3LFJdx/csWKSb4lhRIiwudt1Jb6CfxJX/NqyHaqocKswHsIGyBsAIQN4BNbgAEAcYQJCaAKsE0AAAAASUVORK5CYII="
                
              />
            </div> -->
        </div>
        <div class="flex flex-col md:flex-row gap-6">
          <div class="md:w-1/2 text-left md:pt-2">
            <div
              class="flex flex-col md:flex-row justify-between items-start font-semibold text-[15px] mt-4 md:gap-5"
            >
              <div class="md:w-[70%] w-full ml-4">
                <ul
                  data-v-49c22dea=""
                  class="red-dot-list text-left leading-[45px]"
                >
                  <li>Cloud Computing</li>
                  <li>Cyber Security</li>
                  <li>Full stack Development</li>
                  <li>ML &amp; AI</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="md:w-[50%] w-[70%] justify-center align-middle pt-4">
            <img
              class="md:w-2/3 justify-center align-middle m-auto"
              src="/img/advanced-certifications.303c15f9.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- <div class="max-w-7xl mx-auto w-full">
        <h2
          class="font-bold text-black text-lg md:text-2xl pb-3 text-left md:px-5"
        >
          ABOUT THE <span class="text-[#d02829]"> ADVANCED CERTIFICATION</span>
        </h2>
        <p class="text-left md:px-6 pb-2 text-[14px]">
          There is an opportunity to earn an advance certification while you
          pursue this program
        </p>

        <div class="w-full md:px-5 flex gap-5 overflow-auto">
          <button
            v-for="(data, index) in this.Electives.advance_cartification"
            :key="data.id"
            @click="selectTab(index)"
            class="tabbutton text-[13px] leading-5 relative my-4 font-semibold px-2 py-2 rounded-[5px] w-[200px]] text-left md:text-center md:w-[250px] h-[65px]"
            :class="[
              selectedTab == index
                ? 'bg-[#d02829] text-white Arrow'
                : 'bg-white text-black',
            ]"
          >
            {{ data.semester }}
          </button>
        </div>
        <template
          v-for="(data, index) in this.Electives.advance_cartification"
          :key="index"
        >
          <div
            v-if="selectedTab == index"
            class="rounded-md bg-white md:bg-[#f7f9fb] w-full text-justify shadow-[0_0px_12px_10px_rgba(227,224,224,0.3)] p-10 mt-1"
          >
            <div class="text-[14px] font-normal" v-html="data.content"></div>
          </div>
        </template>
        <p class="mt-4 text-left px-5 text-[14px]">
          <span class="text-[#d0393b]">Note: </span>For the duration of your
          program you can only choose and study one certificate course from the
          given list
        </p>
      </div> -->
      <!--About the advanced certification-->

      <!--syllabus Fees structure tab-->
      <syllaBus :Electives="Electives" />
      <!--syllabus Fees structure tab-->

      <!--Learning Methodology-->
      <div class="w-full py-5 px-5 bg-[#f7f9fb]">
        <div class="max-w-7xl mx-auto w-full">
          <h2
            class="font-bold text-black text-lg md:text-2xl pb-5 text-left md:px-5"
          >
            LEARNING <span class="text-[#d0393b]">METHODOLOGY</span>
          </h2>
          <div
            class="flex flex-wrap justify-around items-center gap-3 sm:gap-5 xl:gap-10"
          >
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/icon-clock.svg"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">
                120 study hours in each course
              </p>
            </div>
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/icon-projetor.svg"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">
                Interactive audio-video lectures
              </p>
            </div>
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/icon-id.svg"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">
                Pre-recorded video lectures
              </p>
            </div>
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/icon-monitor.svg"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">Discussion forum</p>
            </div>

            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/icon-printer.svg"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">Self e-learning material</p>
            </div>
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/icon-edit.svg"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">
                Assignments, quizzes, MCQ, etc. for reinforcement
              </p>
            </div>
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/icon-tub.svg"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">
                Independent and group projects
              </p>
            </div>
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/live-online.png"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">
                8 to 12 hours of live online sessions on weekends
              </p>
            </div>

            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img src="@/assets/Program/lm9.png" class="w-[70px] h-[83px]" />
              </div>
              <p class="text-[14px] font-semibold">
                Live Q&A sessions with faculty using tech-enabled tools for an
                optimum learning experience
              </p>
            </div>
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/lm10.png"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">
                Short videos in the form of learning chunks for comprehensive
                understanding
              </p>
            </div>
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/lm11.png"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">
                Technology Enabled Online Proctored Examinations, twice a year
              </p>
            </div>
            <div
              class="flex flex-col justify-center items-center gap-3 min-h-[130px] w-[130px] md:w-[185px] lg:w-[220px] xl:w-[280px]"
            >
              <div>
                <img
                  src="@/assets/Program/lm12.png"
                  class="w-[70px] h-[83px]"
                />
              </div>
              <p class="text-[14px] font-semibold">
                Hands-on practical and lab exercises to supplement theory for
                tech based courses
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--Learning Methodology end-->

      <!--How to Enroll-->
      <div class="w-full py-5 px-5">
        <div class="max-w-7xl mx-auto w-full">
          <h2
            class="font-bold text-black text-lg md:text-2xl pb-5 text-left md:px-5"
          >
            HOW <span class="text-[#d0393b]">TO ENROLL</span>
          </h2>
          <div
            class="w-full flex flex-col md:flex-row justify-center items-center gap-5 md:px-5"
          >
            <div class="shadow-[0_0px_5px_3px_rgba(227,224,224,0.3)]">
              <div
                class="text-left py-2 bg-[#d0393b] text-white pl-5 font-bold"
              >
                <h3 class="text-sm">Step</h3>
                <h2 class="pl-2 text-lg">1</h2>
              </div>
              <div class="px-3 h-[100px] pt-[20px] text-[14px] text-left">
                <a
                  href="https://apply.vignanonline.com/"
                  class="underline text-[14px] text-[#1a2f50] font-normal"
                  target="_blank"
                  >Click here</a
                >
                <Span class="">
                  to visit the Vignan Online Enrollment Form</Span
                >
              </div>
            </div>

            <div class="shadow-[0_0px_5px_3px_rgba(227,224,224,0.3)]">
              <div
                class="text-left py-2 bg-[#d0393b] text-white pl-5 font-bold"
              >
                <h3 class="text-sm">Step</h3>
                <h2 class="pl-2 text-lg">2</h2>
              </div>
              <div class="px-3 h-[100px] pt-[20px] text-[14px] text-left">
                <Span
                  >Sign up, complete the 4 essential steps to enroll for the
                  Program of your choice</Span
                >
              </div>
            </div>

            <div class="shadow-[0_0px_5px_3px_rgba(227,224,224,0.3)]">
              <div
                class="text-left py-2 bg-[#d0393b] text-white pl-5 font-bold"
              >
                <h3 class="text-sm">Step</h3>
                <h2 class="pl-2 text-lg">3</h2>
              </div>
              <div class="px-3 h-[100px] pt-[20px] text-[14px] text-left">
                <Span
                  >Pay fee through Net banking/ Debit card/ Credit card or using
                  easy EMI with 0% interest</Span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--How to Enroll end-->

      <!--Career Advancement Services-->
      <div class="w-full py-5 px-5 mb-5 bg-[#f7f9fb]">
        <div class="max-w-7xl mx-auto w-full">
          <h2
            class="font-bold text-black text-lg md:text-2xl pb-5 text-left md:px-5"
          >
            CAREER <span class="text-[#d0393b]">ADVANCEMENT SERVICES</span>
          </h2>
          <p class="text-justify px-5 pb-5 text-[14px]">
            The career advancement services team works tirelessly to connect our
            students with the right job opportunities. Our years of experience
            in training, networking, and human resourcing skills help our
            students find exciting and challenging job opportunities. Our
            students get placements in reputed companies through a structured
            framework.
          </p>

          <CAS />
        </div>
      </div>
      <!--Career Advancement Services end-->

      <TechNology />

      <div class="w-full py-5 px-5 bg-[#f7f9fb]">
        <div class="max-w-7xl mx-auto w-full">
          <h2
            class="font-bold text-black text-lg pt-5 md:text-2xl pb-5 text-left md:px-5"
          >
            HIRING <span class="text-primary">COMPANIES</span>
          </h2>
          <HiringComp />
        </div>
      </div>

      <div class="w-full">
        <!-- FAQ -->
        <div id="faq-content" class="px-4">
          <div class="max-w-screen-2xl mx-auto w-full">
            <div class="flex flex-wrap py-5 px-5">
              <h2
                class="font-bold text-black text-lg md:text-2xl pb-5 text-left md:px-5"
              >
                FAQ<span class="text-primary">s</span>
              </h2>
            </div>
            <div class="accordion bg-trasparent max-w-6xl mx-auto" id="faqs">
              <div
                v-for="(el, index) in this.ElecFaq.slice(this.start, this.end)"
                :key="el"
                class="accordion-item border-t-0 border-x-0 text-left"
              >
                <h2 class="accordion-header mb-0">
                  <button
                    @click="faqCollapse"
                    class="accordion-button font-semibold relative flex items-center justify-between w-full py-4 px-5 text-[15px] text-[#004271] hover:text-[#0a58ca] text-left border-0 rounded-none transition focus:outline-none"
                    type="button"
                    :data-target="'#text-' + index"
                  >
                    {{ el.title }}
                    <font-awesome-icon
                      :id="'text-' + index + '-img'"
                      icon="fa-solid fa-circle-chevron-down"
                      alt="down-arrow"
                      class="down-arrow text-[#e01b24] text-xl"
                    />
                    <!-- <img
                    src="../assets/home/Untitled.png"
                    alt="down-arrow"
                    class="down-arrow"
                    />  -->
                  </button>
                </h2>
                <div
                  :id="'text-' + index"
                  class="accordion-collapse border-0 hidden text-sm md:text-base text-black"
                >
                  <div class="accordion-body pb-4 px-10 flex flex-col gap-3">
                    <!-- <p v-html="el.answer_text"></p> -->
                    <pre
                      style="white-space: pre-line"
                      class="font-montserrat text-[13px]"
                      v-html="el.content"
                    ></pre>
                  </div>
                </div>
              </div>
            </div>
            <!-- <button class="font-medium">Load more >></button> -->
            <button
              v-if="this.ElecFaq.length >= this.end"
              @click="this.end = this.end + 3"
              class="bg-[#CA2526] text-white font-semibold px-8 py-2 rounded-[50px] mt-7"
            >
              View More
            </button>
            <button
              v-if="this.ElecFaq.length <= this.end"
              @click="this.end = 4"
              class="bg-[#CA2526] text-white font-semibold px-8 py-2 rounded-[50px] mt-5"
            >
              View Less
            </button>
          </div>
        </div>
      </div>
      <FooterView />
    </div>
    <StickyView />
    <!-- Modal -->
    <div class="Bannermodal1" v-if="isModalOpen">
      <div class="Bannermodal-content1 rounded-md">
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
import syllaBus from "@/components/SyllaBus.vue";
import CAS from "@/components/CAServices.vue";
import TechNology from "@/components/TechNology.vue";
import HiringComp from "@/components/HiringComp.vue";
import FooterView from "@/components/FooterView.vue";
import NavBar from "@/components/NavBar.vue";
import StickyView from "@/components/StickyView.vue";
import EnquireView from "@/components/EnquireForm.vue";
export default {
  name: "McaView",
  components: {
    syllaBus,
    CAS,
    TechNology,
    FooterView,
    HiringComp,
    NavBar,
    StickyView,
    EnquireView,
  },
  data() {
    return {
      selectedTab: 0,
      Electives: [],
      start: 0,
      end: 4,
      ElecFaq: [],
      isModalOpen: false,
    };
  },

  props: ["elective"],

  created() {
    this.electiveCall();
    this.electiveFaq();
    console.log(this.Electives);
  },

  methods: {
    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass("rotate-180")) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass(
          "rotate-180"
        );
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },

    async electiveCall() {
      const slug = this.$route.params.slug; // Get the slug from the route
      const apiUrl = `${process.env.VUE_APP_API}/course/elective-list/?slug=${slug}`;
      console.log(apiUrl, "mcaapi");
      try {
        const resp = await axios.get(apiUrl);
        if (resp.data.status === 200) {
          this.Electives = resp.data.data[0];
          console.log(this.Electives, "mca electives");
        } else {
          console.log("else");
          this.$router.push({
            name: "PageNotFound",
            params: { pathMatch: "page-not-found" },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async electiveFaq() {
      const electiveSlug = this.$route.params.slug;
      const apiUrl = `${process.env.VUE_APP_API}/faq/elective-faq-list/?elective__slug=${electiveSlug}`;

      if (!electiveSlug) {
        console.error("elective Slug is not defined.");
        return;
      }

      try {
        const response = await axios.get(apiUrl);

        if (response.data) {
          this.ElecFaq = response.data.data;
          console.log(this.ElecFaq, "hello");
        }
      } catch (error) {
        console.error(error);
      }
    },

    selectTab(tabId) {
      this.selectedTab = tabId;
    },

    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
@media (max-width: 425px) {
  .gradbox {
    width: 180px;
  }
}
@media (max-width: 375px) {
  .gradbox {
    width: 158px;
  }
}
@media (max-width: 320px) {
  .gradbox {
    width: 133px;
  }
}
.grad {
  background-color: #364c70;
  background-image: linear-gradient(to right, #364c70, #d0393b);
  /* padding: 45px 30px; */
}

/* ul {
  list-style: none;
} */

ul li::before {
  content: "\2022";
  color: #d0393b;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 1em;
  margin-left: 1em;
}

@media (min-width: 768px) {
  .Arrow::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #d0393b;
    bottom: -10px;
    left: 50%;
    transform: rotate(45deg);
  }
}

.Bannermodal1 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Bannermodal-content1 {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  bottom: 7%;
}

@media (max-width: 767.98px) {
  .Bannermodal-content1 {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}
</style>
