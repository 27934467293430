<template>
<div class="w-full">
    <ol class="flex gap-2 items-center pl-3 pt-3 font-normal text-[#001c54] text-[15px]">
        <li><a href="/">Home</a></li>
        <li>> Refund Policy</li>
    </ol>
       
        <div class="max-w-7xl mx-auto w-full text-left">
            <h2 class="mt-5 text-black font-bold text-lg md:text-lg md:text-[20px] text-left ">Refund <span class="text-[#dc3545]"> Policy</span></h2>

            <h4 class="mt-6 text-black font-bold text-left text-lg md:text-[20px]">Scope</h4>

            <p class="py-3 text-normal text-[12px] md:text-[15px] text-black">The Categories of students covered by this document include all Under Graduate (UG), Post Graduate (PG) students studying/applied (current students and applicants) for various online programs/courses offered by Vignan Online (CDOE), VFSTR’S (Deemed-to-be University).</p>

            <h4 class="text-black font-bold text-left text-lg md:text-[20px] pb-2">Components of<span class="text-[#dc3545]"> fees:</span></h4>

            <div class="hscroll">
            <table cellpadding="10px" cellspacing="10px" style="border-spacing: 8px;" width="100%">
            <tbody>
            <tr>
                <th class="bg-[#dc3545] text-white w-[40%];">Fee Components</th>
                <th class="bg-[#dc3545] text-white w-[40%];">Collection Time</th>
            </tr>
            <tr>
                <td class="bg-[#f7f9fb]" style="">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Program / Course Fee</p>
                </td>
                <td class="bg-[#f7f9fb]" style=" ">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Semester / Per Annum / Full Fees — Refundable (Note: Registration fee — Non Refundable)</p>
                </td>
            </tr>
            <tr>
                <td class="bg-[#f7f9fb]" style="">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Exam Fee</p>
                </td>
                <td class="bg-[#f7f9fb]" style="">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Per Annum / Full Fees — Refundable</p>
                </td>
            </tr>
            </tbody>
            </table>
            </div>

            <div class="bg-[#f7f9fb] my-2 p-2">
                <h4 class="text-black font-bold text-left text-lg md:text-[20px] ">Grounds for a refund and the <span class="text-[#dc3545]"> Amount to be refunded:</span></h4>
                <p class="text-normal text-[12px] md:text-[15px] text-black">The student may cancel admission by submitting an application for cancellation and may request for refund of the fee. The student entitlement of refund, amount of refund and the circumstances are as follows:</p>
            </div>
            <div class="hscroll">
            <table cellpadding="10px" cellspacing="" width="100%" style="padding-left:5px">
            <tbody>
            <tr>
                <th class="bg-[#dc3545] text-white w-[35%];">Reason</th>
                <th class="bg-[#dc3545] text-white w-[20%];">Cancelled by</th>
                <th class="bg-[#dc3545] text-white">Present Refund Practice</th>
            </tr>
            <tr>
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Student application is cancelled</p>
                </td>
                <td class="bg-[#f7f9fb]" style=" padding:28px; border-spacing: 3px;text-align:center">
                <p class="text-normal text-[12px] md:text-[15px] text-black">University</p>
                </td>
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Full refund of program/course fee and exam fees.</p>
                </td>
            </tr>
            <tr style="padding:8px">
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Program has been cancelled</p>
                </td>
                <td class="bg-[#f7f9fb]" style=" padding:28px; border-spacing: 3px;text-align:center">
                <p class="text-normal text-[12px] md:text-[15px] text-black">University</p>
                </td>
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Full refund of program/course fee and exam fees.</p>
                </td>
            </tr>
            <tr style="padding:8px">
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Student request for change in Specialization / Batch</p>
                </td>
                <td class="bg-[#f7f9fb]" style=" padding:28px; border-spacing: 3px;text-align:center">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Student</p>
                </td>
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">No refund, INR 2500/- deducted as processing fee and balance fee adjusted towards the new course fee. As per specific rules.</p>
                </td>
            </tr>
            <tr style="padding:8px">
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Student chooses to withdraw from the program of study</p>
                </td>
                <td class="bg-[#f7f9fb]" style=" padding:28px; border-spacing: 3px;text-align:center">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Student</p>
                </td>
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">As mentioned in the table below***</p>
                </td>
            </tr>
            <tr style="padding:8px">
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Student detained because of backlogs</p>
                </td>
                <td class="bg-[#f7f9fb]" style=" padding:28px; border-spacing: 3px;text-align:center">
                <p class="text-normal text-[12px] md:text-[15px] text-black">University</p>
                </td>
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Full refund of program/course fee and exam fees.</p>
                </td>
            </tr>
            </tbody>
            </table>
            </div>

            <h4 class="mt-5 mb-3 text-black font-bold text-left text-lg md:text-[20px]">***Student chooses to withdraw from the <span class="text-[#dc3545]"> program of study</span></h4>

            <div class="hscroll">
            <table cellpadding="10px" cellspacing="" style="border-spacing: 8px;" width="100%">
            <tbody>
            <tr class="p-[8px] w-1/2 text-white">
                <th class="bg-[#dc3545]">Situation</th>
                <th class="bg-[#dc3545]">Amount of refund</th>
            </tr>
            <tr style="padding:8px">
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Refund request received before the class start date</p>
                </td>
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Full refund of program/course Fee and exam fee excluding the processing fee of INR 2500/-</p>
                </td>
            </tr>
            <tr style="padding:8px" width="50%">
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">Refund request received after the class start date</p>
                </td>
                <td class="bg-[#f7f9fb]">
                <p class="text-normal text-[12px] md:text-[15px] text-black">No Refund</p>
                </td>
            </tr>
            </tbody>
            </table>
            </div>

            <p class="text-normal text-[12px] md:text-[15px] text-black mt-3 pl-1">Refund requests shall be processed within 30 working days post the confirmation of academics team.</p>

            <p class="text-normal text-[12px] md:text-[15px] text-black mb-[10%] py-3 pl-1"><strong>NOTE: For any refund-related enquiries, you may contact the Email ID <a href="mailto:learnersupport@vignanonline.com" class="text-[#254483]">learnersupport@vignanonline.com </a></strong></p>
        </div>    
   
<FooterView/>
</div>
</template>

<script>
import FooterView from './FooterView.vue';
    export default {
        name:"RefundPolicy",
        components:{FooterView}
    }
</script>

<style scoped>
table, th, td {
border: 5px solid #fff;
border-collapse: collapse;
padding: 10px;

}
.hscroll {
overflow-x: auto;
}
</style>