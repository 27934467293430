<template lang="">
  <div>
    <!-- Modal -->
    <div class="text-[13px] z-50" v-if="showForm">
      <div class="popup" v-if="visible">
        <div class="popup-content" v-if="showForm1">
          <h2 class="text-black font-bold text-2xl mb-3">Enquire Now</h2>
          <div>
            <form
              class="space-y-3 md:p-4 font-normal"
              @submit="$emit('closeWidget')"
              @submit.prevent="enquireSubmit"
            >
              <input
                type="text"
                placeholder="Enter Name *"
                id="name"
                v-on:input="validateInput"
                class="p-2 rounded-md w-full border border-[#ccc]"
                required
              />
              <input
                type="email"
                placeholder="Enter Email Address *"
                id="email"
                class="p-2 rounded-md w-full border border-[#ccc]"
                required
              />
              <div class="flex flex-col gap-2">
                <div class="flex items-center justify-center">
                  <select
                    v-model="selectedCountryCode"
                    @change="handleChange"
                    class="text-sm w-[63px] bg-white p-2 border border-[#ccc] rounded-md"
                    required
                  >
                    <option
                      v-for="country in countryCodes"
                      :key="country.code"
                      :value="country.code"
                    >
                      {{
                        selectedCountryCode === country.code
                          ? country.code
                          : `${country.code} ${country.name}`
                      }}
                    </option>
                  </select>
                  <input
                    type="tel"
                    placeholder="Mobile Number *"
                    id="phone"
                    maxlength="10"
                    @input="onPhoneNumberChange"
                    v-model="phoneNumber"
                    class="p-2 rounded-md w-full border border-[#ccc]"
                    ref="phone"
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                class="w-full text-white font-semibold bg-[#d02829] hover:bg-[#ca9216] rounded-lg px-4 py-2 text-center"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showForm">
      <div class="popup" v-if="visible">
        <div class="popup-content">
          <div
            class="flex flex-col justify-center m-auto items-center gap-20 text-center z-50"
          >
            <div class="relative flex">
              <span class="close" @click="closePopup">&times;</span>
              <p class="mt-4">
                Thank you! Your Enquiry has been submitted successfully. Our
                admissions team will get in touch with you soon.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showForm1">
      <div class="popup" v-if="visible">
        <div class="popup-content1">
          <div
            class="flex flex-col justify-center m-auto items-center gap-20  text-center z-50"
          >
            <div class="relative flex">
              <span class="close" @click="closePopup">&times;</span>
              <p class="mt-4">
                Thank you! <br /> Your data already exited
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import stateMixins from "@/mixins/stateMixins";
import countryCodes from "@/mixins/countryCodeMixins";
export default {
  name: "EnquireView",
  mixins: [stateMixins, countryCodes],
  props: ["widget_prop_id"],

  data: () => ({
    showForm: true,
    showForm1: true,
    showPopup: false,
    selectedProgram: "",
    utmSource: "",
    utmSourceCampaign: "",
    utmSourceMedium: "",
    utmAdgroupid: "",
    utmCampaignid: "",
    utmCreativeid: "",
    utmDevice: "",
    utmKeyword: "",
    utmMatchtype: "",
    utmNetwork: "",
    utmPlacement: "",
    utmGclid: "",
    widget_id: "",
    activeTab: "tab-1",
    selectedState: "",
    selectedCity: "",
    cities: [],
    enteredOtp: "",
    otpChecking: false,
    errorStatus: false,
    message: "",
    phoneNumber: "",
    selectedCountryCode: "+91-",
    // countryCodes: [],
    visible: false,
  }),

  created() {
    console.log(this.$route.path);
    this.widget_id = this.widget_prop_id;
    this.utmSource = this.$route.query.utm_source || "Organic Search";
    this.utmSourceCampaign = this.$route.query.utm_campaign || "website";
    this.utmSourceMedium = this.$route.query.utm_medium || "";
    this.utmAdgroupid = this.$route.query.utm_adgroupid || "";
    this.utmCampaignid = this.$route.query.utm_campaignid || "";
    this.utmCreativeid = this.$route.query.utm_creativeid || "";
    this.utmDevice = this.$route.query.utm_device || "";
    this.utmKeyword = this.$route.query.utm_keyword || "";
    this.utmMatchtype = this.$route.query.utm_matchtype || "";
    this.utmNetwork = this.$route.query.utm_network || "";
    this.utmPlacement = this.$route.query.utm_placement || "";
    this.Gclid = this.$route.query.gclid || "";
  },
  updated() {
    console.log(this.selectedProgram);
  },

  methods: {
    onStateChange() {
      // console.log(el.target.value);
      // let select_state = el.target.value;
      // this.state_list.forEach((el) => {
      //   if (el.state == select_state) {
      //     this.selectedCity = el;
      //   }
      // });
      const state = this.stateList.find((s) => s.state === this.selectedState);
      this.cities = state ? state.districts : [];
      this.selectedCity = "";
    },

    validateInput(event) {
      const regex = /^[A-Za-z\s]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^A-Za-z\s]/g, "");
      }
      this.name = event.target.value;
    },

    sanitizeInput() {
      // Remove non-numeric characters
      this.enteredOtp = this.enteredOtp.replace(/[^0-9]/g, "");
    },

    onPhoneNumberChange() {
      // Reset the error message when the phone number changes
      this.message = "";

      // Reset otpSent when the phone number changes
      this.otpSent = false;

      // Remove non-numeric characters from the phone number
      this.phoneNumber = this.phoneNumber.replace(/\D/g, "");

      // Limit the phone number to a maximum length of 10 digits
      if (this.phoneNumber.length > 10) {
        this.phoneNumber = this.phoneNumber.slice(0, 10);
      }
    },

    handleKeyPress(event) {
      // Allow only numeric input and backspace
      if (
        !(event.charCode >= 48 && event.charCode <= 57) &&
        event.charCode !== 8
      ) {
        event.preventDefault();
      }
    },

    // async getOtp() {
    //   try {
    //     let json_data = {
    //       phone: this.$refs.phone.value,
    //     };
    //     axios
    //       .post(`${process.env.VUE_APP_API}/mob_otp_send/`, json_data)
    //       .then((resp) => {
    //         console.log(resp);
    //       });
    //     this.otpSent = true;
    //   } catch (error) {
    //     console.error("Error getting OTP:", error);
    //   }
    // },

    // async getOtp() {
    //   try {
    //     const json_data = {
    //       phone: this.phoneNumber,
    //     };
    //     const response = await axios.post(
    //       `${process.env.VUE_APP_API}/mob_otp_send/`,
    //       json_data
    //     );
    //     console.log(response);
    //     this.otpSent = true;
    //   } catch (error) {
    //     console.error("Error getting OTP:", error);
    //   }
    // },

    async enquireSubmit(el) {
      try {
        // Call the API to verify OTP

        const dataJson = [
          {
            Attribute: "FirstName",
            Value: el.target.elements.name.value,
          },
          {
            Attribute: "EmailAddress",
            Value: el.target.elements.email.value,
          },
          {
            Attribute: "Phone",
            Value: this.selectedCountryCode + this.phoneNumber,
          },
          {
            Attribute: "mx_utm_Source",
            Value: this.utmSource,
          },
          {
            Attribute: "SourceCampaign",
            Value: this.utmSourceCampaign,
          },
          {
            Attribute: "SourceMedium",
            Value: this.utmSourceMedium,
          },
          {
            Attribute: "mx_utm_Adgroupid",
            Value: this.utmAdgroupid,
          },
          {
            Attribute: "mx_utm_Campaignid",
            Value: this.utmCampaignid,
          },
          {
            Attribute: "mx_utm_Creativeid",
            Value: this.utmCreativeid,
          },
          {
            Attribute: "mx_utm_Device",
            Value: this.utmDevice,
          },
          {
            Attribute: "mx_utm_Keyword",
            Value: this.utmKeyword,
          },
          {
            Attribute: "mx_utm_Matchtype",
            Value: this.utmMatchtype,
          },
          {
            Attribute: "mx_utm_Network",
            Value: this.utmNetwork,
          },
          {
            Attribute: "mx_utm_Placement",
            Value: this.utmPlacement,
          },
          {
            Attribute: "mx_Gclid",
            Value: this.Gclid,
          },
        ];

        // Call the API to capture lead
        await axios
          .post(
            "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r822806c3d4d4cb1c22e55fc36ca478bf&secretKey=95b5dad573299c9d675384a7e9afe0476d44e0bb",
            dataJson
          )
          .then((resp) => {
            console.log(resp, "this is resp");
            if (resp.data.Status == "Success") {
              el.target.reset();
              this.showForm = false;
            }

            // close the popup

            //   window.location.href = `${this.$route.path}/thank-you?utm_source=website&utm_medium=organic+seo&utm_campaign=organic+traffic`;
          })
          .catch((error) => {
            console.log(error.response.data.ExceptionMessage);
            this.message = error.response.data.ExceptionMessage;
            if (this.message) {
              this.showForm1 = false;
            }
          });
      } catch (error) {
        // Handle error, for example, show a generic error message
        console.error("Error:", error);
        this.errorMessage = "An error occurred. Please try again later.";
      }
    },
    openPopup() {
      this.visible = true;
    },
    closePopup() {
      this.visible = false;
    },
    closeModal() {
      this.showForm = true;
      this.showForm1 = true;
    },
  },
};
</script>
<style scoped>
.popup {
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
}

.popup-content {
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  width: 30%;
}

.popup-content1 {
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  width: 20%;
}

.Emodal3 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Emodal-content3 {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 450px;
}

@media (max-width: 767.98px) {
  .popup-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }
  .popup-content1 {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }
}

.close {
  position: absolute;
  top: -15px;
  right: -5px;
  cursor: pointer;
}
</style>
