<template>
  <div class="max-w-7xl m-auto md:my-6 my-3">
    <a href="#applyBox" class="hidden md:block">
      <div
        class="bg-[#c82228] text-white py-2 px-12 w-max m-auto rounded-full font-bold text-xl cursor-pointer"
      >
        Enquire Now
      </div>
    </a>
    <div class="text-xs pt-4 m-auto font-semibold">
      ©️ Copyright Vignan University. All Rights Reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
};
</script>

<style scoped></style>
