<template>
  <div>
    <NavBar />
<section>
      <div class="bg-[#f0f0f0]">
        <div class="flex w-full h-full mx-auto place-items-center justify-center text-white justify-between">
          <!-- <div class="text-left lg:text-left pt-6">
            <span class="lg:text-[2rem] text-[28px] font-bold lg:leading-[60px] sm:text-[30px] leading-10" >
              Blogs 
          
            </span>
 
          </div> -->
          <div class="lg:block hidden pt-24 justify-center mx-auto">
            <img src="../assets/Blog-Banner.webp" class="" alt />
          </div>
          <div class="lg:hidden pt-8">
            <img src="../assets/Blog-mob.webp" class="" alt />
          </div>
        </div>
      </div>
    </section>

  <div class="mx-auto pt-16 px-4 justify-center">
  
    <!-- Category dropdown -->
  <div class="flex flex-row gap-6 bg-[#f0f0f0] overflow-scroll font-bold mx-auto lg:justify-center justify-start">
    <div class="category-option mb-8 " :class="{ 'active-category': !selectedCategory }" @click="handleCategoryChange(null)">All Categories</div>
    <div
      v-for="category in BlogsPost"
      :key="category.id"
      class="category-option"
      :class="{ 'active-category': selectedCategory === category.title }"
      @click="handleCategoryChange(category.title)"
    >
      {{ category.title }}
    </div>
  </div>
    <!-- Display blogs in a responsive grid -->
    <div
      v-for="(row, rowIndex) in chunkedBlogs"
      :key="rowIndex"
      class="flex flex-wrap my-8 container justify-start align-middle mx-auto"
    >
      <div
        v-for="item in row"
        :key="item.id"
        class="w-full sm:w-1/2 md:w-1/3 px-4 mb-4"
      >
        <div class="bg-white h-[500px]  rounded shadow-md p-4">
          <a :href="`/blogs/${item.slug}`">
            <h2
              class="text-left font-semibold text-lg text-[#004271] hover:text-[#0d6efd] pb-2"
            >
              {{ item.title }}
            </h2>
          </a>
          <a :href="`/blogs/${item.slug}`">
            <img :src="item.image" alt="" class="w-full h-auto mb-2 rounded" />
          </a>
          <p class="text-left text-black text-sm mb-2">
            {{ formatDate(item.publish_at) }}
          </p>
          <p class="text-left text-black text-sm mb-4">
            {{ item.description }}
          </p>
          <p
            class="text-left font-semibold text-sm text-[#004271] hover:text-[#0d6efd]"
          >
            <a :href="`/blogs/${item.slug}`">Read more</a>
          </p>
        </div>
      </div>
    </div>
    <!-- Pagination -->
    <div class="flex items-center justify-center mt-4">
      <div>
        <!-- First button -->
        <button
          @click="changePage(1)"
          :disabled="currentPage === 1"
          class="mr-2"
        >
          First 
        </button>

        <!-- Prev button -->
        <button
          @click="changePage('prev')"
          :disabled="currentPage === 1"
          class="mr-2"
        >
     
          Prev
        </button>

        <!-- Page numbers -->
        <template v-for="page in displayedPages" :key="page">
          <button
            @click="changePage(page)"
            :class="{
              'font-bold': currentPage === page,
              'active-page': currentPage === page,
            }"
            class="mr-2"
          >
            {{ page }}
          </button>
        </template>

        <!-- Next button -->
        <button
          @click="changePage('next')"
          :disabled="currentPage === totalPages"
          class="mr-2"
        >
        
          Next 
        </button>

        <!-- Last button -->
        <button
          @click="changePage(totalPages)"
          :disabled="currentPage === totalPages"
          class="ml-2"
        >
       
          Last
        </button>
      </div>
    </div>
  </div>
  <div class="pt-8">
    <FooterView />
  </div>
 
</div>
</template>

<script>
import axios from "axios";
import FooterView from "@/components/FooterView.vue";
import NavBar from "@/components/NavBar.vue";
export default {
  components: {
    FooterView,
    NavBar,
  },

  data() {
    return {
      originalBlogs: [], // Store the original data
      Blogs: [],
      BlogsPost: [],
      currentPage: 1,
      totalPages: 1,
      pagesToShow: 4,
      selectedCategory: null,
      blogsPerPage: 6,
    };
  },
  methods: {
    async fetchData() {
      try {
        const [blogResponse, categoryResponse] = await Promise.all([
          this.getForumPost(),
          this.getBlogPost(),
        ]);

        if (blogResponse.data) {
          this.originalBlogs = blogResponse.data.data; // Save the original data
          this.Blogs = this.originalBlogs;
          this.totalPages = Math.ceil(this.Blogs.length / this.blogsPerPage);
        }

        if (categoryResponse.data && categoryResponse.data.data) {
          this.BlogsPost = categoryResponse.data.data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    getForumPost() {
      return axios.get(`${process.env.VUE_APP_API}/blog/blog-list/`);
    },

    getBlogPost() {
      return axios.get(`${process.env.VUE_APP_API}/blog/category-list/`);
    },

    changePage(newPage) {
      // Handle page change logic here
      if (newPage === "prev" && this.currentPage > 1) {
        this.currentPage--;
      } else if (newPage === "next" && this.currentPage < this.totalPages) {
        this.currentPage++;
      } else if (
        typeof newPage === "number" &&
        newPage >= 1 &&
        newPage <= this.totalPages
      ) {
        this.currentPage = newPage;
      }
    },

    handleCategoryChange(category) {
      this.selectedCategory = category;
      this.currentPage = 1;
      this.fetchDataForCategory();
    },

    async 'fetchDataForCategory'() {
      try {
        if (this.selectedCategory) {
          const response = await axios.get(
            `${process.env.VUE_APP_API}/blog/blog-list/`,
            {
              params: { category__title: this.selectedCategory },
            }
          );
          this.Blogs = response.data.data;
        } else {
          this.Blogs = this.originalBlogs; // Reset to original data when selecting "All Categories"
        }

        this.totalPages = Math.ceil(this.Blogs.length / this.blogsPerPage);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    formatDate(dateString) {
      const options = { month: "short", year: "numeric" };
      const formattedDate = new Date(dateString).toLocaleDateString(
        "en-IN",
        options
      );

      // Extract day with appropriate suffix (e.g., 1st, 2nd, 3rd, 4th)
      const dayWithSuffix = this.getDayWithSuffix(
        new Date(dateString).getDate()
      );

      // Combine the formatted date and day with suffix
      return `${dayWithSuffix} ${formattedDate}`;
    },
    getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    },
  },
  computed: {
    paginatedBlogs() {
      const startIndex = (this.currentPage - 1) * this.blogsPerPage;
      const endIndex = startIndex + this.blogsPerPage;
      return this.Blogs.slice(startIndex, endIndex);
    },

    chunkedBlogs() {
      // Chunk the blogs into rows with three items each
      const chunkSize = 3;
      return this.paginatedBlogs.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    },

    displayedPages() {
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(this.pagesToShow / 2)
      );
      const endPage = Math.min(
        this.totalPages,
        startPage + this.pagesToShow - 1
      );

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.active-category {
  color: red;
}
.category-option {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.active-page {
  color: red;
}
</style>