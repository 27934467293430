<template lang="">
  <div class="relative w-full bg-white z-8">
    <section class="shadow-md py-3 lg:py-2 shadow-[#00000029] hidden md:block">
      <div class="fixed top-0 bg-white w-full shadow-md">
        <div class="scroll-text">
          Precautions to be taken by the students before enrolling in programmes
          offered under Open & Distance Learning (ODL) and/or Online Learning
          mode -
          <a
            href="https://api.vignanonline.com/media/UGC_20240319152222_1.pdf"
            target="blank"
            class="text-[#d02829]"
          >
            Link
          </a>
        </div>
        <div class="w-full mx-auto max-w-6xl relative p-3">
          <div class="flex justify-between items-center gap-20">
            <div
              class="w-[20%] md:w-[30%] lg:w-[35%] flex justify-center items-center"
            >
              <router-link to="/">
                <img
                  src="../assets/Vignan-Online-Logo.webp"
                  class=""
                  width="339px"
                  height="70px"
                  alt="Vignan-Logo"
                  aria-label="Re-direct to home page"
                />
              </router-link>
            </div>

            <div
              class="flex justify-center gap-2 md:gap-10 items-center w-[80%]"
            >
              <div
                class="font-semibold text-black text-[10px] md:text-[14px] lg:text-[14px]"
              >
                <router-link to="/about-us">ABOUT US</router-link>
              </div>
              <div
                class="cursor-pointer font-semibold text-black text-[10px] md:text-[14px] lg:text-[14px]"
              >
                <p @click="toggleDiv">
                  PROGRAMS
                  <font-awesome-icon icon="fa-solid fa-angle-down" size="lg" />
                </p>
                <div
                  v-if="showDiv"
                  class="w-full mx-auto max-w-5xl h-[530px] top-20 left-0 p-4 bg-white absolute"
                >
                  <div
                    class="flex justify-start gap-12 w-full mx-auto max-w-5xl border-b-2 border-[#d02829]"
                  >
                    <div
                      @click="setActiveTab('bachelors')"
                      :class="{
                        'active-tab border-b-4 border-b-[#d02829]':
                          activeTab === 'bachelors',
                      }"
                      class="[w-[150px] shadow-md text-[15px] border px-8 py-4 rounded-t-md border-gray-300 hover:border-b-4 hover:border-b-[#d02829]"
                    >
                      BACHELORS
                    </div>
                    <div
                      @click="setActiveTab('masters')"
                      :class="{
                        'active-tab border-b-4 border-b-[#d02829]':
                          activeTab === 'masters',
                      }"
                      class="[w-[150px] shadow-md text-[15px] border px-8 py-4 rounded-t-md border-gray-300 hover:border-b-4 hover:border-b-[#d02829]"
                    >
                      MASTERS
                    </div>

                    <div
                      class="flex justify-end items-center w-full"
                      @click="closeDiv"
                    >
                      <img src="@/assets/home/close-btn.png" />
                    </div>
                  </div>
                  <div class="w-full mx-auto max-w-5xl pt-1 flex">
                    <div class="w-1/2">
                      <div class="flex flex-col justify-start h-[400px]">
                        <div v-if="activeTab === 'bachelors'">
                          <!-- Content for BACHELORS tab -->
                          <div class="flex">
                            <div
                              class="font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#CA2526]"
                              @click="selectedDegree = 'BBA'"
                              :class="[
                                selectedDegree == 'BBA'
                                  ? 'bg-[#E5EBF0] text-[#CA2526]'
                                  : 'bg-white text-black',
                              ]"
                            >
                              BBA
                            </div>
                            <div
                              class="font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#CA2526]"
                              @click="selectedDegree = 'BCA'"
                              :class="[
                                selectedDegree == 'BCA'
                                  ? 'bg-[#E5EBF0] text-[#CA2526]'
                                  : 'bg-white text-black',
                              ]"
                            >
                              BCA
                            </div>
                          </div>
                          <div class="bg-[#d02829] relative">
                            <img
                              src="../assets/bachelors.webp"
                              alt=""
                              class="w-[276px]"
                            />
                            <p class="text-white absolute right-2 top-56">
                              #DrivingYourFuture
                            </p>
                          </div>
                        </div>
                        <div v-else-if="activeTab === 'masters'">
                          <!-- Content for MASTERS tab -->
                          <div class="flex flex-col justify-start">
                            <div class="flex">
                              <div
                                class="bg-[#E5EBF0] font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#CA2526]"
                                @click="selectedDegree = 'MBA'"
                                :class="[
                                  selectedDegree == 'MBA'
                                    ? 'bg-[#E5EBF0] text-[#CA2526]'
                                    : 'bg-white text-black',
                                ]"
                              >
                                MBA
                              </div>
                              <div
                                class="bg-[#E5EBF0] font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#CA2526]"
                                @click="selectedDegree = 'MCA'"
                                :class="[
                                  selectedDegree == 'MCA'
                                    ? 'bg-[#E5EBF0] text-[#CA2526]'
                                    : 'bg-white text-black',
                                ]"
                              >
                                MCA
                              </div>
                            </div>
                            <div class="bg-[#d02829] relative">
                              <img
                                src="../assets/Master-Programs.webp"
                                alt="Masters Program"
                                class="w-[335px]"
                              />
                              <p class="text-white absolute right-2 top-56">
                                #DrivingYourFuture
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-1/2">
                      <!-- Display the selected degree content -->
                      <div
                        class="flex flex-col"
                        v-if="
                          activeTab === 'bachelors' && selectedDegree === 'BBA'
                        "
                      >
                        <div class="text-left p-4 w-full">
                          <a
                            href="/bachelors-business-administration-online-degree"
                            class="text-[13px] w-full font-semibold hover:bg-[#d02829] hover:text-white hover:py-1 hover:px-2 block"
                            >Online BBA Degree</a
                          >
                        </div>
                        <div class="electives text-left p-2">
                          <ul>
                            <p class="text-[12px] font-normal">Electives</p>
                            <a href="/online-bba/general">
                              <li
                                class="uppercase ele bg-[#d02829] text-white hover:px-2 block text-xs font-medium"
                              >
                                General
                              </li></a
                            >
                          </ul>
                        </div>
                      </div>
                      <div
                        v-else-if="
                          activeTab === 'bachelors' && selectedDegree === 'BCA'
                        "
                      >
                        <div class="text-left p-4 w-full">
                          <a
                            href="/bachelor-computer-application-online-degree"
                            class="text-[13px] w-full font-semibold hover:bg-[#d02829] hover:text-white hover:py-1 hover:px-2 block"
                            >Online BCA Degree</a
                          >
                        </div>
                        <div class="electives text-left p-2">
                          <ul>
                            <p class="text-[12px] font-normal">Electives</p>
                            <a href="/online-bca/computer-science-it">
                              <li
                                class="uppercase ele bg-[#d02829] text-white hover:px-2 block text-xs font-medium"
                              >
                                Computer science and it
                              </li></a
                            >
                          </ul>
                        </div>
                      </div>
                      <div
                        v-else-if="
                          activeTab === 'masters' && selectedDegree === 'MBA'
                        "
                      >
                        <div class="text-left p-4 w-full">
                          <a
                            href="/masters-business-administration-online-degree"
                            class="text-[13px] w-full font-semibold hover:bg-[#d02829] hover:text-white hover:py-1 hover:px-2 block"
                            >Online MBA Degree</a
                          >
                        </div>
                        <div class="electives text-left p-2">
                          <ul>
                            <p class="text-[12px] font-normal">
                              Electives and Advanced Certifications
                            </p>

                            <a href="/online-mba/marketing">
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Marketing
                              </li></a
                            >

                            <a href="/online-mba/finance">
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Finance
                              </li>
                            </a>

                            <a href="/online-mba/human-resource-management">
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Human Resource Management
                              </li>
                            </a>
                            <a href="/online-mba/business-analytics">
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Business Analytics
                              </li>
                            </a>

                          <a href="/online-mba/finance-and-human-resource">
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Finance and human resource management
                              </li>
                            </a>
                            <a href="/online-mba/information-technology">
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Information TechNology
                              </li>
                            </a>
                            
                            <a
                              href="/online-mba/healthcare-and-hospital-management"
                            >
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Healthcare and hospital management
                              </li></a
                            >
                           <!--    <a
                              href="/online-mba/logistics-and-supply-chain-management"
                            >
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Logistics and supply chain management
                              </li></a
                            >
                            <a href="/online-mba/operations-management">
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Operation management
                              </li></a
                            > -->
                          </ul>
                        </div>
                      </div>
                      <div
                        v-else-if="
                          activeTab === 'masters' && selectedDegree === 'MCA'
                        "
                      >
                        <div class="text-left p-4 w-full">
                          <a
                            href="/masters-computer-applications-online-degree"
                            class="text-[13px] w-full font-semibold hover:bg-[#d02829] hover:text-white hover:py-1 hover:px-2 block"
                            >Online MCA Degree</a
                          >
                        </div>
                        <div class="electives text-left p-2">
                          <ul>
                            <p class="text-[12px] font-normal">
                              Electives and Advanced Certifications
                            </p>
                            <a href="/online-mca/computer-science-and-it">
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Computer science and it
                              </li></a
                            >
                            <a href="/online-mca/data-science">
                              <li
                                class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase text-xs font-medium"
                              >
                                Data science
                              </li>
                            </a>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
               @click="openModal"
                class="font-semibold cursor-pointer text-white p-3 bg-[#d02829] text-[14px] hover:bg-white hover:text-[#D02829] hover:border-2 hover:border-[#d02829] rounded-3xl"
              >
               ENQUIRE NOW
              </div>
              <div
                class="font-semibold text-black cursor-pointer text-[8px] md:text-[13px] lg:text-[14px]"
              >
                <a href="https://apply.vignanonline.com/"><p>APPLY NOW</p></a>
              </div>
              <div class="hamburger-menu">
                <button
                  @click="toggleMenu"
                  :class="{ active: isOpen }"
                  id="dhm"
                  aria-label="hamburger"
                >
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </button>
                <ul :class="{ show: isOpen }" class="formup">
                  <a href="/blogs"
                    ><li
                      class="hover:bg-[#CA2526] hover:text-white text-[13px] font-medium"
                    >
                      BLOGS
                    </li></a
                  >
                  <a href="/contact-us"
                    ><li
                      class="hover:bg-[#CA2526] hover:text-white text-[13px] font-medium"
                    >
                      CONTACT US
                    </li></a
                  >
                  <a href="https://support.vignanonline.com" target="blank"
                    ><li
                      class="hover:bg-[#CA2526] hover:text-white text-[13px] font-medium"
                    >
                      STUDENT SUPPORT
                    </li></a
                  >
                  <a
                    href="https://refer.vignanonline.com/?utm_source=reference&utm_medium=Website&utm_campaign=Button"
                    target="blank"
                    ><li
                      class="hover:bg-[#CA2526] hover:text-white text-[13px] font-medium"
                    >
                    REFER A FRIEND
                    </li></a
                  >

                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- MobileView -->
    <section class="md:hidden fixed bg-white shadow-md">
      <div class="flex justify-between p-5">
        <div class="w-[60%]">
          <router-link to="/">
            <img
              src="../assets/Vignan-Online-Logo.webp"
              alt="Vignan Online Logo"
              width="340px"
              height="70px"
              title="Vignan Online Logo"
            />
          </router-link>
        </div>

        <div class="hamburger-menu">
          <button
            @click="toggleMenu"
            :class="{ active: isOpen }"
            id="mhm"
            aria-label="hamburger"
          >
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </button>
          <div
            :class="{ show: isOpen }"
            class="drop w-full bg-white h-screen cursor-pointer fixed top-20 z-50"
          >
            <!-- Tabbed Content Section -->
            <div class="w-full">
              <div
                class="flex justify-center gap-5 w-full border-b-2 border-[#d02829]"
              >
                <div
                  @click="setActiveTab('bachelors')"
                  :class="{
                    'active-tab border-b-4 border-b-[#d02829]':
                      activeTab === 'bachelors',
                  }"
                  class="w-[150px] shadow-md text-[13px] font-bold border px-8 py-4 rounded-t-md border-gray-300 hover:border-b-4 hover:border-b-[#d02829]"
                >
                  BACHELORS
                </div>
                <div
                  @click="setActiveTab('masters')"
                  :class="{
                    'active-tab border-b-4 border-b-[#d02829]':
                      activeTab === 'masters',
                  }"
                  class="w-[150px] shadow-md text-[13px] font-bold border px-8 py-4 rounded-t-md border-gray-300 hover:border-b-4 hover:border-b-[#d02829]"
                >
                  MASTERS
                </div>
              </div>
              <div class="w-full mx-auto max-w-5xl pt-1 flex flex-col">
                <div>
                  <div class="flex flex-col justify-center">
                    <div v-if="activeTab === 'bachelors'">
                      <!-- Content for BACHELORS tab -->
                      <div class="flex">
                        <div
                          class="font-normal px-10 py-2"
                          @click="selectedDegree = 'BBA'"
                          :class="[
                            selectedDegree == 'BBA'
                              ? 'bg-[#E5EBF0] text-[#CA2526]'
                              : 'bg-white text-black',
                          ]"
                        >
                          BBA
                        </div>
                        <div
                          class="font-normal px-10 py-2"
                          @click="selectedDegree = 'BCA'"
                          :class="[
                            selectedDegree == 'BCA'
                              ? 'bg-[#E5EBF0] text-[#CA2526]'
                              : 'bg-white text-black',
                          ]"
                        >
                          BCA
                        </div>
                      </div>
                    </div>
                    <div v-else-if="activeTab === 'masters'">
                      <!-- Content for MASTERS tab -->
                      <div class="flex flex-col justify-start">
                        <div class="flex">
                          <div
                            class="bg-[#E5EBF0] font-normal px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#CA2526]"
                            @click="selectedDegree = 'MBA'"
                            :class="[
                              selectedDegree == 'MBA'
                                ? 'bg-[#E5EBF0] text-[#CA2526]'
                                : 'bg-white text-black',
                            ]"
                          >
                            MBA
                          </div>
                          <div
                            class="bg-[#E5EBF0] font-normal px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#CA2526]"
                            @click="selectedDegree = 'MCA'"
                            :class="[
                              selectedDegree == 'MCA'
                                ? 'bg-[#E5EBF0] text-[#CA2526]'
                                : 'bg-white text-black',
                            ]"
                          >
                            MCA
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- Display the selected degree content -->
                  <div
                    v-if="activeTab === 'bachelors' && selectedDegree === 'BBA'"
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/bachelors-business-administration-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#d02829] hover:text-white hover:px-2 block"
                        >Online BBA Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <ul>
                        <p class="text-[12px]">Electives</p>
                        <a href="/online-bba/general"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            General
                          </li></a
                        >
                      </ul>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      activeTab === 'bachelors' && selectedDegree === 'BCA'
                    "
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/bachelor-computer-application-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#d02829] hover:text-white hover:px-2 block"
                        >Online BCA Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <ul>
                        <p class="text-[12px]">Electives</p>
                        <a href="/online-bca/computer-science-it"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Computer science and it
                          </li></a
                        >
                      </ul>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      activeTab === 'masters' && selectedDegree === 'MBA'
                    "
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/masters-business-administration-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#d02829] hover:text-white hover:px-2 block"
                        >Online MBA Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <ul>
                        <p class="text-[12px]">
                          Electives and Advanced Certifications
                        </p>

                        <a href="/online-mba/marketing"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Marketing
                          </li></a
                        >
                        <a href="/online-mba/finance"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Finance
                          </li></a
                        >

                        <a href="/online-mba/human-resource-management"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Human resource management
                          </li></a
                        >

                        <a href="/online-mba/business-analytics"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Business Analytics
                          </li></a
                        >

                        <!--                        
                        <a href="/online-mba/finance-and-human-resource"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Finance and Human resource management
                          </li></a
                        >
                        <a href="/online-mba/information-technology"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Information Technology
                          </li></a
                        >
                       
                        <a href="/online-mba/hospital-and-healthcare-management"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Healthcare and hospital management
                          </li></a
                        >
                        <a
                          href="/online-mba/logistics-and-supply-chain-management"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Logistics and supply management
                          </li></a
                        >
                        <a href="/online-mba/operations-management"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Operation management
                          </li></a
                        > -->
                      </ul>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      activeTab === 'masters' && selectedDegree === 'MCA'
                    "
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/masters-computer-applications-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#d02829] hover:text-white hover:px-2 block"
                        >Online MCA Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <ul>
                        <p class="text-[12px]">
                          Electives and Advanced Certifications
                        </p>
                        <a href="/online-mca/computer-science-and-it"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Computer science and it
                          </li></a
                        >
                        <a href="/online-mca/data-science"
                          ><li
                            class="ele hover:bg-[#d02829] hover:text-white hover:px-2 block uppercase"
                          >
                            Data science
                          </li></a
                        >
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Links Section -->
            <div
              class="bg-[#d8d7d7] text-xs font-medium w-full p-4 flex flex-col gap-4"
            >
              <a href="/blogs"
                ><div class="border-b border-b-[#ccc]">BLOGS</div></a
              >
              <a href="/contact-us"
                ><div class="border-b border-b-[#ccc]">CONTACT US</div></a
              >
              <a href="https://support.vignanonline.com" target="blank"
                ><div class="border-b border-b-[#ccc]">STUDENT SUPPORT</div></a
              >
              <a href="https://refer.vignanonline.com/?utm_source=reference&utm_medium=Website&utm_campaign=Button"
                ><div class=""> REFER A FRIEND</div></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <chatView /> 

    <!-- Modal -->
    <div class="Emodal" v-if="isModalOpen">
      <div
        class="Emodal-content z-10 rounded-md relative bottom-24 md:w-[35%] 2xl:w-[20%]"
      >
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
  </div>
</template>
<script>
import EnquireView from "@/components/EnquireForm.vue";
import { useHead } from "@unhead/vue";
import { ref, onMounted } from 'vue';
import axios from "axios";
import chatView from "./chatView.vue";

export default {
  components: {
    EnquireView,
    chatView,
  },
  setup() {
    const pageTitle = ref('');
    const pageDescription = ref('');
    const metaTags = ref([]);

    // Function to set the page metadata
    const setMeta = (title, description, schema) => {
      pageTitle.value = title;
      pageDescription.value = description;
      useHead({
        title,
        meta: [
          { name: 'description', content: description },
          ...metaTags.value
        ],
        script: [
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(schema),
            crossorigin: 'anonymous'
          }
        ]
      });
    };

    const initializeMeta = () => {
      const currentUrl = window.location.href;

      if (currentUrl === 'https://vignanonline.com/masters-business-administration-online-degree') {
        const schema = {
          "@context": "https://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Homepage",
              "item": "https://vignanonline.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "MBA",
              "item": "https://vignanonline.com/masters-business-administration-online-degree"
            }
          ]
        };
        setMeta('MBA Program - Vignan Online', 'Learn more about our online MBA program and its features.', schema);
      } else if (currentUrl === 'https://vignanonline.com/online-mba/marketing') {
        const schema = {
          "@context": "https://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Homepage",
              "item": "https://vignanonline.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "MBA",
              "item": "https://vignanonline.com/masters-business-administration-online-degree"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "MBA Marketing",
              "item": "https://vignanonline.com/online-mba/marketing"
            }
          ]
        };
        const faqSchema = {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Does an Online MBA in Marketing hold validity in India?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, an Online MBA in Marketing degree is recognized in India when the e-learning platforms providing the program are accredited by NAAC and recognized by UGC."
              }
            },
            {
              "@type": "Question",
              "name": "Which advanced certifications are offered as part of an Online MBA in Marketing Degree?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "In the Online MBA program specializing in Marketing, learners have the opportunity to pursue advanced certifications."
              }
            }
          ]
        };
        setMeta('MBA Marketing Program - Vignan Online', 'Explore our MBA Marketing program and its offerings.', [schema, faqSchema]);
      } else {
        const schema = {
          "@context": "https://schema.org",
          "@type": "CollegeOrUniversity",
          "name": "Vignan Online",
          "alternateName": "Vignan Online",
          "url": "https://vignanonline.com/",
          "logo": "https://vignanonline.com/img/vignan-logo.2ee047d1.svg",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "8877661139",
            "contactType": "customer service",
            "areaServed": "IN",
            "availableLanguage": "en"
          },
          "sameAs": [
            "https://www.facebook.com/vignanonline/",
            "https://x.com/vignanonline",
            "https://www.instagram.com/vignanonline/",
            "https://www.youtube.com/channel/UC9xOL6RM5DShuroZMecmEvg",
            "https://www.linkedin.com/company/vignan-online"
          ]
        };
        setMeta('Vignan Online', 'Explore our diverse programs and offerings.', schema);
      }
    };

    const fetchSeoData = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API}/seo-list/?url=${window.location.pathname}`);
        if (response.data.status === 200 && response.data.data.length > 0) {
          const result = response.data.data[0];
          metaTags.value = [
            { name: "keywords", content: result.keyword.join(",") },
            { name: "og:title", content: result.og_title },
            { name: "og:image", content: result.og_img },
            { name: "og:description", content: result.og_description },
            { name: "twitter:title", content: result.twitter_title },
            { name: "twitter:image", content: result.twitter_img },
            { name: "twitter:card", content: result.twitter_card },
          ];
        }
      } catch (error) {
        console.error("Error fetching SEO data:", error);
      }
    };

    onMounted(async () => {
      initializeMeta(); // Call the function to set static metadata
      await fetchSeoData(); // Fetch additional SEO data
      useHead({ meta: metaTags.value }); // Update head with dynamic meta tags
    });

    return {
      // Return any other reactive properties and methods if needed
    };
  },

  data() {
    return {
      isOpen: false,
      showDiv: false,
      activeTab: "bachelors",
      selectedDegree: "BBA",
      isModalOpen: false,
    };
  },

  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    toggleDiv() {
      this.showDiv = !this.showDiv;
    },
    closeDiv() {
      this.showDiv = false;
    },
    closeDivOnClickOutside(event) {
      if (this.showDiv && !this.$el.contains(event.target)) {
        this.showDiv = false;
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.selectedDegree = tab === "bachelors" ? "BBA" : "MBA";
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeDivOnClickOutside);
  },
};
</script>

<style scoped>
.hamburger-menu {
  position: relative;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  outline: none;
  position: relative;
}

.bar {
  background-color: #333;
  height: 2px;
  margin: 4px 0;
  transition: 0.4s;
  width: 25px;
}

.active .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.active .bar:nth-child(2) {
  opacity: 0;
}

.active .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.formup {
  list-style-type: none;
  padding: 10px;
  position: absolute;
  top: 66px;
  right: 0;
  background-color: #fff;
  border-top: none;
  display: none;
  min-width: 350px;
  text-align: left;
}

ul.show {
  display: block;
}

ul li {
  padding: 10px;
  border-bottom: #ccc 1px solid;
}

.drop {
  padding: 10px;
  right: 0;
  display: none;
  min-width: 350px;
  text-align: left;
}

.drop.show {
  display: block;
}

#scroll-container {
  overflow: hidden;
}
.scroll-text {
  color: #001c50;
  font-size: 15px;
  font-weight: bolder;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 480px) {
  .scroll-text {
    color: #001c50;
    font-size: small;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
  }
}

.scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 10s linear infinite;
  -webkit-animation: my-animation 10s linear infinite;
  animation: my-animation 30s linear infinite;
}

.scroll-text:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.Emodal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Emodal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 750px;
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}

.electives {
  display: flex;
  flex-direction: column;
  background: none;
}

.ele {
  border: none;
  font-size: 13px;
  font-weight: 500;
}
</style>
