<template lang="">
  <div>
    <section
      class="w-full mt-[65%] md:mt-[45%] lg:mt-[10%] xl:mt-[8%] 2xl:mt-[8%]"
    >
      <div class="w-full mx-auto max-w-7xl relative flex justify-center">
        <div
          class="absolute bottom-[-150px] md:bottom-[-80px] w-full lg:max-w-4xl xl:max-w-5xl mx-auto"
        >
          <div
            class="w-full mx-auto bg-[#d02829] rounded-md font-normal text-sm text-white"
          >
            <div
              class="flex flex-col lg:flex-row justify-between items-center py-8 px-5"
            >
              <div class="flex flex-col items-center gap-4 pb-4">
                <img
                  src="../assets/icon-call.svg"
                  width="19px"
                  height="22px"
                  alt=""
                />
                <a href="tel:+918877661139"
                  ><p class="hover:text-[#004271]">+91 8877661139</p></a
                >
              </div>
              <a
                href="https://api.whatsapp.com/send/?phone=918877661139"
                target="_blank"
                ><div class="flex flex-col items-center gap-4 pb-4">
                  <img
                    src="../assets/icon-whatsapp--sml1.png"
                    width="21px"
                    height="21px"
                    alt=""
                  />
                  <p class="hover:text-[#004271]">+91 8877661139</p>
                </div></a
              >
              <div class="flex flex-col items-center gap-4 pb-4">
                <img
                  src="../assets/icon-message.svg"
                  width="23px"
                  height="17px"
                  alt=""
                />
                <a href="mailto:info@vignanonline.com"
                  ><p class="hover:text-[#004271]">info@vignanonline.com</p></a
                >
              </div>
              <div class="flex flex-col items-center gap-4 pb-4">
                <img
                  src="../assets/icon-map.svg"
                  width="21px"
                  height="24px"
                  alt=""
                />
                <a href="https://goo.gl/maps/s8iXvnoigUmbQWW5A" target="_blank"
                  ><p class="hover:text-[#004271]">
                    Vignan's Foundation for Science, <br />Technology and
                    Research (Deemed to be University),<br />
                    Vadlamudi, Guntur-522213
                  </p></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="w-full bg-[#004271] px-12">
      <div class="w-full mx-auto max-w-7xl pt-32 md:pt-14">
        <div
          class="flex flex-col md:flex-row justify-center gap-5 md:gap-0 md:justify-between py-16 lg:px-5"
        >
          <div class="flex flex-col gap-5 md:border-r md:border-[#596c77] pr-4">
            <div>
              <a href="/" aria-label="Re-direct to home page">
                <img
                  src="../assets/Vignan-Online-White-Logo.webp"
                  alt="Vignan Online White Logo"
                  class="w-[280px] h-[59px]"
                />
              </a>
            </div>
            <div class="flex justify-center items-center gap-8">
              <a
                href="https://www.instagram.com/vignanonline/"
                target="_blank"
                aria-label="instagram"
              >
                <font-awesome-icon
                  icon="fa-brands fa-instagram"
                  size="xl"
                  class="text-white hover:text-[#d02829]"
              /></a>
              <a
                href="https://www.youtube.com/channel/UC9xOL6RM5DShuroZMecmEvg"
                target="_blank"
                aria-label="youtube"
              >
                <font-awesome-icon
                  icon="fa-brands fa-youtube"
                  size="xl"
                  class="text-white hover:text-[#d02829]"
              /></a>
              <a
                href="https://www.facebook.com/vignanonline/"
                target="_blank"
                aria-label="facebook"
              >
                <font-awesome-icon
                  icon="fa-brands fa-square-facebook"
                  size="xl"
                  class="text-white hover:text-[#d02829]"
              /></a>
              <a
                href="https://www.linkedin.com/company/vignan-online"
                target="_blank"
                aria-label="linkedin"
              >
                <font-awesome-icon
                  icon="fa-brands fa-linkedin"
                  size="xl"
                  class="text-white hover:text-[#d02829]"
              /></a>
              <a
                href="https://twitter.com/vignanonline"
                target="_blank"
                aria-label="twitter"
              >
                <font-awesome-icon
                  icon="fa-brands fa-x-twitter"
                  size="xl"
                  class="text-white hover:text-[#d02829]"
              /></a>
            </div>
          </div>
          <!-- <div class="border-r border-[#596c77]"></div> -->
          <div class="flex flex-col gap-5 text-left hidden md:block">
            <div class="text-white font-bold text-sm uppercase">
              Vignan online - CDOE
            </div>
            <div class="text-[15px] leading-7 pt-3">
              <a href="/about-us"
                ><p class="text-[#ffffff80] hover:text-white">About us</p></a
              >
              <a href="/online-degree-programs" target=""
                ><p class="text-[#ffffff80] hover:text-white">Programs</p></a
              >
              <a href="/blogs"
                ><p class="text-[#ffffff80] hover:text-white">Blogs</p></a
              >
              <a href="https://refer.vignanonline.com/?utm_source=reference&utm_medium=Website&utm_campaign=Button"
                ><p class="text-[#ffffff80] hover:text-white">Refer a friend</p></a
              >
              <a href="https://apply.vignanonline.com/"
                ><p class="text-[#ffffff80] hover:text-white">Apply Now</p></a
              >
            </div>
          </div>
          <div class="border-r border-[#596c77]"></div>
          <div class="flex flex-col gap-5 text-left hidden md:block">
            <div class="text-white font-bold text-sm uppercase">Programs</div>
            <div class="text-[15px] leading-7 pt-3">
              <a href="/masters-business-administration-online-degree"
                ><p class="text-[#ffffff80] hover:text-white">MBA</p></a
              >
              <a href="/masters-computer-applications-online-degree"
                ><p class="text-[#ffffff80] hover:text-white">MCA</p></a
              >
              <a href="/bachelors-business-administration-online-degree"
                ><p class="text-[#ffffff80] hover:text-white">BBA</p></a
              >
              <a href="/bachelor-computer-application-online-degree"
                ><p class="text-[#ffffff80] hover:text-white">BCA</p></a
              >
            </div>
          </div>
          <div class="border-r border-[#596c77]"></div>
          <div class="flex flex-col gap-5 text-left md:block hidden">
            <div class="text-white font-bold text-sm uppercase">Support</div>
            <div class="text-[15px] leading-7 pt-3">
              <a href="/contact-us"
                ><p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Contact us
                </p></a
              >
              <p
                class="text-[#ffffff80] hover:text-white text-[15px] cursor-pointer"
                @click="openModal"
              >
                Download brochure
              </p>
              <a href="https://support.vignanonline.com/login/"
                ><p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Student Support
                </p></a
              >

              <a href="/privacypolicy">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Privacy policy
                </p>
              </a>

              <a href="/refundpolicy">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Refund policy
                </p>
              </a>
            </div>
          </div>
          <div class="border-r border-[#596c77]"></div>
          <div class="flex flex-col gap-5 text-left md:block hidden">
            <div class="text-white font-bold text-sm uppercase">Quick Links</div>
            <div class="text-[15px] leading-7 pt-3">
              <a href="/statutory-disclosures"
                ><p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Statutory Disclosures
                </p></a
              >
              <!-- <p
                class="text-[#ffffff80] hover:text-white text-[15px] cursor-pointer"
                @click="openModal"
              >
                Download brochure
              </p> -->
              <a href="/student-corner"
                ><p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Student Corner
                </p></a
              >

              <a href="/feedback-and-grievance-management">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Feedback and Grievance Management
                </p>
              </a>

              <a href="/quality-and-assurance">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Quality and Assurance
                </p>
              </a>

              <a href="/past-enrolments">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Past Enrolments
                </p>
              </a>
            </div>
          </div>
          <div class="md:hidden mb-14 pb-4">
            <div class="flex flex-col gap-4">
              <!-- First Dropdown -->
              <div class="dropdown flex flex-col gap-4">
                <button
                  @click="toggleDropdown(1)"
                  class="text-center text-white font-bold text-md w-full flex justify-between"
                >
                  VIGNAN ONLINE - CDOE
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-white text-md"
                  />
                </button>
                <ul v-show="isDropdownOpen[1]">
                  <a href="/about"
                    ><li class="text-[#ffffff80] text-[15px]">About Us</li></a
                  >
                  <a href="/online-degree-programs"
                    ><li class="text-[#ffffff80] text-[15px]">Programs</li></a
                  >
                  <a href="/blogs"
                ><li class="text-[#ffffff80] text-[15px]">Blogs</li></a
              >
              <a href="https://refer.vignanonline.com/?utm_source=reference&utm_medium=Website&utm_campaign=Button"
                ><li class="text-[#ffffff80] text-[15px]">Refer a friend</li></a
              >
                  <a href="https://apply.vignanonline.com/"
                    ><li class="text-[#ffffff80] text-[15px]">Apply Now</li></a
                  >
                </ul>
              </div>

              <!-- Second Dropdown -->
              <div class="dropdown flex flex-col gap-4">
                <button
                  @click="toggleDropdown(2)"
                  class="text-center text-white font-bold text-md w-full flex justify-between"
                >
                  PROGRAMS
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-white text-md"
                  />
                </button>
                <ul v-show="isDropdownOpen[2]">
                  <a href="/masters-business-administration-online-degree"
                    ><li class="text-[#ffffff80] text-[15px]">MBA</li></a
                  >
                  <a href="/masters-computer-applications-online-degree"
                    ><li class="text-[#ffffff80] text-[15px]">MCA</li></a
                  >
                  <a href="/bachelors-business-administration-online-degree"
                    ><li class="text-[#ffffff80] text-[15px]">BBA</li></a
                  >
                  <a href="/bachelor-computer-application-online-degree"
                    ><li class="text-[#ffffff80] text-[15px]">BCA</li></a
                  >
                </ul>
              </div>

              <div class="dropdown flex flex-col gap-4">
                <button
                  @click="toggleDropdown(3)"
                  class="text-center text-white font-bold text-md w-full flex justify-between"
                >
                  SUPPORT
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-white text-md"
                  />
                </button>
                <ul v-show="isDropdownOpen[3]">
                  <a href="/contact-us"
                    ><li class="text-[#ffffff80] text-[15px]">Contact us</li></a
                  >
                  <p
                    class="text-[#ffffff80] hover:text-white text-[15px] cursor-pointer"
                    @click="openModal"
                  >
                    Download brochure
                  </p>
                  <a href="https://support.vignanonline.com/login/"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Student Support
                    </li></a
                  >
                  <a href="/privacypolicy"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Privacy policy
                    </li></a
                  >
                  <a href="/refundpolicy"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Refund policy
                    </li></a
                  >
                </ul>
              </div>

              <div class="dropdown flex flex-col gap-4">
                <button
                  @click="toggleDropdown(3)"
                  class="text-center text-white font-bold text-md w-full flex justify-between"
                >
                  QUICK LINKS
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-white text-md"
                  />
                </button>
                <ul v-show="isDropdownOpen[3]">
                 
                  <a href="/statutory-disclosures"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Statutory Disclosures
                    </li></a
                  >
                  <a href="/student-corner"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Student Corner
                    </li></a
                  >
                  <a href="/feedback-and-grievance-management"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Feedback and Grievance Management
                    </li></a
                  >
                  <a href="/quality-and-assurance"
                    ><li class="text-[#ffffff80] text-[15px]">Quality and Assurance</li></a
                  >
                  <a href="/past-enrolments"
                    ><li class="text-[#ffffff80] text-[15px]">Past Enrolments</li></a
                  >
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- Modal -->
  <div class="Dmodal" v-if="isModalOpen">
    <div
      class="Dmodal-content rounded-md relative bottom-24 md:w-[35%] 2xl:w-[20%]"
    >
      <span class="close" @click="closeModal">&times;</span>
      <BrochureView />
    </div>
  </div>
</template>
<script>
import BrochureView from "@/components/DownloadBrochure.vue";
export default {
  components: {
    BrochureView,
  },
  data() {
    return {
      isDropdownOpen: { 1: false, 2: false, 3: false },
      isModalOpen: false,
    };
  },
  methods: {
    toggleDropdown(dropdownNumber) {
      // Close all dropdowns first
      for (const key in this.isDropdownOpen) {
        if (key !== dropdownNumber) {
          this.isDropdownOpen[key] = false;
        }
      }

      // Toggle the selected dropdown
      this.isDropdownOpen[dropdownNumber] =
        !this.isDropdownOpen[dropdownNumber];
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>
<style scoped>
.Dmodal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Dmodal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 750px;
}

@media (max-width: 760px) {
  .Dmodal-content {
    position: relative;
    background-color: white;
    margin: 55% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}
</style>
