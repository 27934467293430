<template>
  <div>
    <!--header-->
    <section class="fixed w-full top-0 bg-white z-50 shadow-lg">
      <div class="bg-white shadow-lg py-4">
        <div
          class="max-w-7xl mx-auto px-4 flex gap-5 justify-between place-items-center"
        >
          <div class="flex gap-6 items-center text-center">
            <a href="/" aria-label="redirect to homepage">
              <img src="../assets/logo.svg" class="w-[250px]" alt="logo" />
            </a>
          </div>
          <div class="flex items-center">
            <div>
              <div class="flex justify-center items-center gap-2">
                <a href="tel:+918877661755">
                  <div
                    class="flex justify-center items-center bg-[#004271] text-white lg:w-full w-[150px] lg:pr-5 rounded-l-full"
                  >
                    <img
                      src="@/assets/lpmba/icon-call.png"
                      class="w-[35px] lg:w-[50px]"
                      alt="call"
                    />
                    <span
                      class="text-[13px] lg:text-[18px] xl:text-[26px] leading-5"
                      >+91 8877661755</span
                    >
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--header-->

    <div
      class="w-full h-[80vh] flex flex-col md:flex-row md:justify-between relative mt-20"
    >
      <div class="w-full bg-cover absolute">
        <img
          :src="Electives.banner"
          class="h-full w-full md:block hidden"
          alt="desktop-banner"
        />
        <img
          :src="Electives.mobile_banner"
          class="h-full w-full md:hidden"
          alt="mobile-banner"
        />
      </div>

      <div
        class="md:mt-0 pt-3 w-full flex-col md:flex-row justify-center md:block hidden"
      >
        <div
          class="text-center md:text-left left-[37%] md:top-24 md:h-[500px] absolute"
        >
          <h1
            class="font-bold md:text-lg lg:text-3xl xl:text-4xl"
            v-html="Electives.focus"
          ></h1>
          <!-- <h2 class="font-bold text-white text-left">
            Batch Starts on <span
              class="font-semibold text-[#2a2a2a] text-xl" > 24<sup>th</sup> December</span><br>
         <span
            class="text-white" >Application closes in : <span
              id="countdown"
              class="font-semibold text-[#2a2a2a] text-xl"
              >{{ countdown }}</span
            ></span
          >
      
        </h2> -->
        </div>
        <div class="bg-white absolute md:w-[30%] xl:top-5 2xl:top-14 right-10">
          <div
          class="bg-white shadow-md py-3 xl:py-8 md:mt-20 md:w-[60%] xl:w-[40%] 2xl:w-[35%]"
        >
          <p class="p-5">
            Thank you! Your Enquiry has been submitted successfully. Our
            admissions team will get in touch with you soon. A verification
            email has been sent to your registered email ID.<br />
            Please ignore if already verified.
          </p>
        </div>
        </div>
      </div>

      <div class="pt-3 w-full flex justify-center md:hidden">
        <div class="text-left top-60 absolute">
          <div
            class="text-xl font-bold md:text-sm lg:text-2xl xl:text-2xl"
            v-html="Electives.focus"
          ></div>
          <!-- <h2 class="font-bold text-white text-left">
            Batch Starts on <span
              class="font-semibold text-[#2a2a2a] text-lg"> 24<sup>th</sup> December</span><br>
         <span
            class="text-white" >Application closes in : <span
              id="countdown"
              class="font-semibold text-[#2a2a2a] text-lg"
              >{{ countdown }}</span
            ></span
          >
        </h2> -->
        </div>

        <div class="bg-white absolute top-[80%]">
          <EnquireView :Electives="Electives.title" />
        </div>
      </div>
    </div>

    <!--Section 2-->
    <div class="w-full pt-[330px] md:pt-[5rem] bg-white">
      <div class="max-w-7xl mx-auto w-full px-8 pb-5">
        <div class="col-lg-10 lg:offset-lg-1 wow fadeInLeft text-center">
          <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
            ABOUT <span class="text-[#e33237] font-bold">VIGNAN ONLINE</span>
          </h2>

          <p
            class="pt-6 px-2 text-black text-justify"
            v-html="Electives.about_us"
          ></p>
        </div>
      </div>
      <img
        src="@/assets/lpmba/partner-main.webp"
        class="w-full"
        alt="partner"
      />
    </div>

    <!--YEARS OF GLORIOUS LEGACY-->
    <div class="w-full pb-10 bg-[#f7f9fb]">
      <div
        class="xl:max-w-3xl mx-auto w-full bg-[#d02829] p-5 flex flex-col md:flex-row justify-center md:items-start gap-5 text-white"
      >
        <div class="md:w-[37%] justify-center">
          <h2 class="font-bold text-[100px] md:text-[150px]">45</h2>
          <p class="text-[26px] font-bold">YEARS OF GLORIOUS LEGACY</p>
        </div>
        <div
          class="md:w-[63%] w-full text-white py-5 text-sm px-5 text-justify"
        >
          <p v-html="Electives.glory"></p>
        </div>
      </div>
    </div>

    <!--Rankings and Recognitions-->
    <!--Rankings and Recognitions-->
    <section>
          <div class="w-full p-5">
      <div class="max-w-6xl mx-auto w-full">
        <h2 class="font-bold text-[22px] md:text-[28px] py-3 uppercase">
          Rankings and
          <span class="text-[#e33237] font-bold">Recognitions</span>
        </h2>
        <div
          class="flex flex-wrap justify-center items-center gap-3 xl:gap-5 mt-5"
        >
          <div class="">
            <div
              class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
            >
              <img src="@/assets/lpmba/home-icon-1.png" alt="" />
              <span class="text-center md:text-left"
                >Amongst top 100 <br />in NIRF ranking</span
              >
            </div>
          </div>
          <div class="">
            <div
              class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
            >
              <img src="@/assets/lpmba/home-icon-2.png" alt="" />
              <span class="text-center md:text-left"
                >NAAC A+,<br />UGC 12B</span
              >
            </div>
          </div>
          <div class="">
            <div
              class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
            >
              <img src="@/assets/lpmba/home-icon-3.png" alt="" />
              <span class="text-center md:text-left"
                >Approved<br />BY AICTE</span
              >
            </div>
          </div>
          <div class="">
            <div
              class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
            >
              <img src="@/assets/lpmba/home-icon-4.png" alt="" />
              <span class="text-center md:text-left">ISO<br />9001:2015</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>

    <!-- ONLINE MBA PROGRAM-->
    <div class="w-full p-5 bg-[#f7f9fb]">
      <div class="max-w-5xl mx-auto w-full">
        <h1
          class="font-bold text-black uppercase text-[22px] md:text-[28px] pt-5"
        >
          Online {{ Electives.title }}
          <span class="text-[#d02829]">Program</span>
        </h1>
        <div class="text-left py-5 text-black">
          <h4 class="font-bold text-black text-[14px] md:text-[18px]">
            PROGRAM OVERVIEW
          </h4>
          <p
            class="mt-5 text-[15px] font-normal"
            v-html="Electives.overview"
          ></p>
        </div>
      </div>
    </div>

    <!--ELECTIVES OFFERED-->
    <div class="w-full p-2 bg-[#f7f9fb]">
      <div class="max-w-5xl mx-auto w-full">
        <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
          ELECTIVES<span class="text-[#d02829]"> OFFERED</span>
        </h2>
        <div
          class="flex flex-col md:flex-row justify-between items-start font-semibold text-[15px] mt-4 md:gap-5"
        >
          <div class="md:w-[50%] w-full ml-[5%]">
            <ul
              class="red-dot-list two-col-list text-black text-left leading-[45px]"
            >
              <li
                class="relative ml-[5%] flex"
                v-for="(elective, index) in this.Electives.program_electives"
                :key="index"
              >
                {{ elective }}
              </li>
            </ul>
          </div>

          <div class="md:w-[50%] w-full ml-[5%]">
            <ul class="red-dot-list text-black text-left leading-[45px]">
              <!-- <li class="relative ml-[5%]">Information Technology</li>
                        <li class="relative ml-[5%]">Business Analytics</li>
                        <li class="relative ml-[5%]">Healthcare &amp; Hospital Management</li>
                        <li class="relative ml-[5%]">Logistics and Supply Chain Management</li> -->
            </ul>
          </div>
        </div>

        <div
          class="flex justify-center gap-[10px] items-center uppercase mt-10 font-bold pb-5"
        >
          <div
            class="course-features-box w-[180px] md:w-[250px] min-h-[130px] p-[5px] gap-[6px] md:gap-[15px]"
          >
            <img src="@/assets/lpmba/icon-tie-red.svg" alt="years" />
            <span class="leading-5">2 Year <br />Program</span>
          </div>

          <div
            class="course-features-box w-[180px] md:w-[250px] min-h-[130px] p-[5px] gap-[6px] md:gap-[15px]"
          >
            <img src="@/assets/lpmba/icon-goal-red.svg" alt="Online-sessions" />
            <span class="leading-5">Live Online<br />Sessions</span>
          </div>
        </div>
      </div>
    </div>

  

    <!--ADVANCED CERTIFICATIONS-->
    <!-- <div class="w-full p-5 bg-[#f7f9fb]">
      <div class="max-w-4xl w-full ml-[20px] md:mx-auto">
        <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
          MINOR<span class="text-[#d02829]"> ELECTIVES</span>
        </h2>
        <div
          class="flex flex-col md:flex-row justify-between items-center font-semibold text-[15px] mt-5 px-5"
        >
          <div class="w-full md:w-[55%]">
            <ul class="red-dot-list text-black text-left leading-[45px]">
              <li
                class="relative"
                v-for="(elective, index) in this.Electives
                  .advance_certification"
                :key="index"
              >
                {{ elective }}
              </li>
            </ul>
          </div>
        
        </div>
      </div>
    </div> -->

    <section class="pb-5">
      <div class="w-full mx-auto max-w-7xl pt-10 flex flex-col p-5">
        <div
          class="max-w-7xl flex flex-col items-center justify-center mx-auto text-center"
        >
          <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
            Advanced<span class="text-[#d02829]"> Certifications</span>
          </h2>

          <div class="p-2"><img src="@/assets/Heading-underline.png" /></div>
        </div>
        <div class="flex flex-col md:flex-row gap-6">
          <div class="md:w-1/2 text-left md:pt-10">
            <div
              class="flex flex-col md:flex-row justify-between items-start font-semibold text-[15px] mt-4 md:gap-5"
            >
              <div class="md:w-[70%] w-full ml-4">
                <ul class="red-dot-list text-left leading-[45px]">
                  <li
                    class="relative"
                    v-for="(elective, index) in Electives.advance_certification"
                    :key="index"
                  >
                    {{ elective }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="md:w-[50%] w-[70%] justify-center align-middle pt-4">
            <img
              class="md:w-2/3 justify-center align-middle m-auto"
              src="../assets/advanced-certifications.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

      <!--PROGRAM HIGHLIGHTS-->
      <section class=" bg-[#f7f9fb] pb-5">
      <div class="w-full p-5">
        <div class="max-w-7xl mx-auto w-full">
          <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
            PROGRAM<span class="text-[#d02829]"> HIGHLIGHTS</span>
          </h2>
          <div
            class="flex flex-wrap justify-center items-center gap-3 xl:gap-5 mt-5"
          >
            <div class=" ">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img
                  src="@/assets/lpmba/Updated_Industry_Relevant_Syllabus.svg"
                  alt="Syllabus"
                />
                <span class="text-center md:text-left"
                  >Updated Industry <br />Relevant Syllabus</span
                >
              </div>
            </div>
            <div class=" ">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img
                  src="@/assets/lpmba/Experienced_Faculty-02.svg"
                  alt="Faculty"
                />
                <span class="text-center md:text-left"
                  >Experienced &amp;<br />Professional Faculty</span
                >
              </div>
            </div>
            <div class="">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img
                  src="@/assets/lpmba/Flexible-Learning.svg"
                  alt="Learning"
                />
                <span class="text-center md:text-left"
                  >Flexible Learning<br />
                  Environment</span
                >
              </div>
            </div>
            <!-- <div class=" ">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Flexible-Learning.svg" alt="" />
                <span class="text-center md:text-left"
                  >Multiple electives<br />to choose from</span
                >
              </div>
            </div> -->
            <div class=" ">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Management_Thesis.svg" alt="Thesis" />
                <span class="text-center md:text-left"
                  >Management <br />Thesis</span
                >
              </div>
            </div>
            <div class=" ">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img
                  src="@/assets/lpmba/Live_Online_Session.svg"
                  alt="Online-Session"
                />
                <span class="text-center md:text-left"
                  >Live Online<br />
                  Session</span
                >
              </div>
            </div>
            <div class=" ">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img
                  src="@/assets/lpmba/Practical_Exposure.svg"
                  alt="Practical"
                />
                <span class="text-center md:text-left"
                  >Practical<br />
                  Exposure</span
                >
              </div>
            </div>

            <div class=" ">
              <div
                class="banner-feature-box flex flex-col md:flex-row justify-center gap-2 items-center font-extrabold w-[140px] md:w-[250px] min-h-[100px] p-[2px] md:p-[10px]"
              >
                <img src="@/assets/lpmba/Project_Reports.svg" alt="Reports" />
                <span class="text-center md:text-left"
                  >Project<br />
                  Reports</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--LEARNING METHODOLOGY-->
    <div class="w-full p-5">
      <div class="max-w-7xl mx-auto w-full">
        <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
          LEARNING<span class="text-[#d02829]"> METHODOLOGY</span>
        </h2>
        <div class="flex flex-wrap justify-center pt-8 items-center gap-4">
          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img
                src="@/assets/lpmba/icon-clock.svg"
                alt="clock"
                class="w-[50px]"
              />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>120 learning hours in each program</strong>
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img
                src="@/assets/lpmba/icon-projetor.svg"
                alt="projetor"
                class="w-[50px]"
              />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>Interactive audio-video lectures</strong>
            </p>
          </div>
        
          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img src="@/assets/lpmba/icon-id.svg" alt="id" class="w-[50px]" />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>Pre-recorded video lectures</strong>
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img
                src="@/assets/lpmba/icon-monitor.svg"
                alt="monitor"
                class="w-[50px]"
              />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>Discussion forum</strong>
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img
                src="@/assets/lpmba/icon-edit.svg"
                alt="edit"
                class="w-[50px]"
              />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>Assignments, quizzes, MCQ, etc.for reinforcement</strong>
            </p>
          </div>

          <div
            class="flex flex-col justify-center items-center w-[120px] md:w-[280px] min-h-[100px]"
          >
            <div class="methodology-box-icon">
              <img
                src="@/assets/lpmba/icon-tub.svg"
                alt="tub"
                class="w-[50px]"
              />
            </div>
            <p class="text-[#d02829] min-h-[40px]">
              <strong>Independent and<br />group projects</strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--HIRING COMPANIES-->
    <div class="w-full p-5 mt-5">
      <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
        HIRING<span class="text-[#d02829]"> COMPANIES</span>
      </h2>
      <HiringComp />
    </div>

    <!--FAQ's-->
    <div class="w-full p-5 mt-5">
      <div class="max-w-5xl mx-auto w-full">
        <h2 class="font-bold text-[20px] md:text-2xl py-3 uppercase">
          FAQ<span class="text-[#d02829]">s</span>
        </h2>
        <div>
          <!-- <div
            class="border-b-2 border-gray-300 pb-3 text-left text-black font-medium text-[14px]"
            v-for="(faq, index) in Electivesfaq.slice(start, end)"
            :key="index"
          >
            <div class="question my-4" @click="this.faq(faq.id)">
              <h3>
                {{ faq.title }}
              </h3>
              <span class="faq-qus-arrow"></span>
            </div>
            <p
              :id="`answer-${faq.id}`"
              class="hidden answer-list mr-[50px]"
              v-html="faq.content"
            ></p>
          </div> -->
          <div class="accordion bg-trasparent max-w-6xl mx-auto" id="faqs">
            <div
              v-for="(el, index) in this.ElecFaqs.slice(this.start, this.end)"
              :key="el"
              class="accordion-item border-t-0 border-x-0 text-left"
            >
              <h2 class="accordion-header mb-0">
                <button
                  @click="faqCollapse"
                  class="accordion-button font-semibold relative flex items-center justify-between w-full py-4 px-5 text-sm text-[#004271] hover:text-[#0a58ca] text-left border-0 rounded-none transition focus:outline-none"
                  type="button"
                  :data-target="'#text-' + index"
                >
                  {{ el.title }}
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-circle-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-[#e01b24] text-xl"
                  />
                  <!-- <img
                    src="../assets/home/Untitled.png"
                    alt="down-arrow"
                    class="down-arrow"
                    />  -->
                </button>
              </h2>
              <div
                :id="'text-' + index"
                class="accordion-collapse border-0 hidden text-sm md:text-base text-black"
              >
                <div class="accordion-body pb-4 px-5 flex flex-col gap-3">
                  <!-- <p v-html="el.answer_text"></p> -->
                  <p class="answer-list" v-html="el.content"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Footer-->
    <div class="w-full p-5 bg-[#d02829]">
      <div
        class="max-w-7xl mx-auto w-full text-white font-semibold text-sm flex flex-col md:flex-row justify-center items-center gap-5"
      >
        <div
          class="flex flex-col justify-center items-center md:w-[250px] gap-3 py-5"
        >
          <a href="tel:+918877661755">
            <img src="@/assets/lpmba/icon-call.svg" alt=""
          /></a>
          <div class="min-h-[10px]">
            <a href="tel:+918877661755">
              <p class="mb-0">+91 8877661755</p>
            </a>
          </div>
        </div>

        <!-- <div
          class="flex flex-col justify-center items-center md:w-[250px] gap-3"
        >
          <img
            src="@/assets/lpmba/icon-whatsapp.png"
            style="width: 30px"
            alt=""
          />
          <div class="min-h-[90px]">
            <a
              href="https://api.whatsapp.com/send?phone=918877661755&amp;text=Hi!%20I%27d%20like%20to%20know%20more%20details."
              target="_blank"
              tabindex="-1"
            >
              <p class="mb-0">+91 8877661755</p>
            </a>
          </div>
        </div> -->

        <div
          class="flex flex-col justify-center items-center mb-20 md:mb-0 md:w-[250px] gap-3"
        >
          <a
            href="https://www.google.com/maps/place/Vignan's+Foundation+for+Science,+Technology+%26+Research+(Deemed+to+be+University)/@16.2333746,80.5509079,17z/data=!3m1!4b1!4m6!3m5!1s0x3a4a0953a362f945:0x11aa0de9063844ab!8m2!3d16.2333746!4d80.5509079!16s%2Fm%2F05q97tw?entry=ttu"
            target="_blank"
          >
            <img src="@/assets/lpmba/icon-map.svg" alt="Vignan Online"
          /></a>
          <div class="min-h-[10px]">
            <p class="mb-0">
              Vignan's Foundation for Science, Technology and Research (Deemed
              to be University), Vadlamudi, Guntur-522213
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="md:hidden fixed z-50 bottom-0 w-full">
      <div class="w-full bg-[#c82228]">
        <div class="flex justify-center items-center py-3 px-20">
          <a href="tel:+918877661755"
            ><div class="flex flex-col justify-center items-center gap-3">
              <img src="@/assets/home/sticky/call-icon.webp" />
              <p class="text-white font-semibold text-[11px]">CALL NOW</p>
            </div></a
          >
          <!-- <div class="border border-r-2 border-white h-14"></div>
          <a
            href="https://api.whatsapp.com/send?phone=918877661755&amp;text=Hi!%20I%27d%20like%20to%20know%20more%20details."
            target="_blank"
            tabindex="-1"
            ><div class="flex flex-col justify-center items-center gap-3">
              <img
                src="@/assets/home/sticky/icon-whatsapp-white.svg"
                class="w-7"
              />
              <p class="text-white font-semibold text-[11px]">WHATSAPP</p>
            </div></a
          > -->
        </div>
      </div>
    </div>

    <div class="enquire-s md:block" id="enqure">
      <a href="#">Enquire Now</a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import HiringComp from "@/components/HiringComp.vue";
import axios from "axios";
export default {
  name: "LP_MBA",
  components: {
    HiringComp,
  },
  data() {
    return {
      Electives: [],
      ElecFaqs: [],
      isModalOpen: false,
      countdown: "Loading...", // Initial value before the timer starts
    };
  },

  created() {
    this.electiveCall();
    this.electivefaqs();
  },

  mounted() {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = this.countDownDate - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = `${hours}h ${minutes}m ${seconds}s`;

      if (distance < 0) {
        this.countDownDate = this.getNeverEndingDate();
      }
    };

    this.countDownDate = this.getNeverEndingDate();
    updateCountdown();
    setInterval(updateCountdown, 1000);
  },

  methods: {
    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass("rotate-180")) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass(
          "rotate-180"
        );
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },
    // faq(id) {
    //   let faq_list = document.querySelectorAll(".answer-list");
    //   console.log(faq_list);
    //   faq_list.forEach((el) => {
    //     if (!el.classList.contains("hidden")) {
    //       el.classList.add("hidden");
    //     }
    //   });
    //   document.getElementById(`answer-${id}`).classList.toggle("hidden");
    // },

    async electivefaqs() {
      const electiveSlug = this.$route.params.slug; // Get the slug from the route or define it
      const apiUrl = `${process.env.VUE_APP_API}/lp/faq/elective-faq-list/?elective__slug=${electiveSlug}`;
      if (!electiveSlug) {
        // Handle the case when the slug is not available
        console.error("elective Slug is not defined.");
        return;
      }

      try {
        const response = await axios.get(apiUrl);

        if (response.data) {
          this.ElecFaqs = response.data.data;
          console.log(this.ElecFaqs, "hello");
        }
      } catch (error) {
        console.error(error); // Handle any errors that may occur during the request
      }
    },

    getNeverEndingDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.setHours(0, 0, 0, 0);
    },

    // faq(id) {
    //   let faq_list = document.querySelectorAll(".answer-list");
    //   console.log(faq_list);
    //   faq_list.forEach((el) => {
    //     if (!el.classList.contains("hidden")) {
    //       el.classList.add("hidden");
    //     }
    //   });
    //   document.getElementById(`answer-${id}`).classList.toggle("hidden");
    // },

    async electiveCall() {
      const slug = this.$route.params.slug;
      const apiUrl = `${process.env.VUE_APP_API}/lp/course/elective-list/?slug=${slug}`;

      try {
        const resp = await axios.get(apiUrl);
        if (resp.data.status === 200) {
          this.Electives = resp.data.data[0];
          console.log(this.Electives);
        }
      } catch (error) {
        console.log(error);
      }
    },

    // async electivefaqsCall() {
    //   const electiveSlug = this.$route.params.slug;
    //   const apiUrl = `${process.env.VUE_APP_API}/lp/faq/elective-faq-list/?elective__slug=${electiveSlug}`;
    //   try {
    //     const resp = await axios.get(apiUrl);
    //     console.log(resp);
    //     if (resp.data.status === 200) {
    //       this.Electivesfaq = resp.data.data;
    //       console.log(this.Electivesfaq);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
};
</script>

<style scoped>
@media (max-width: 767.98px) {
  .question {
    margin-bottom: 8px;
    width: 83%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .faq-qus-arrow {
    position: absolute;
    float: right;
    top: 10px;
    right: -30px !important;
    width: 30px;
    height: 30px;
    border: 1px solid #c9c9c9;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    margin-right: -25px;
  }
}
.question {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.faq-qus-arrow {
  position: absolute;
  float: right;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  margin-right: -25px;
}

.faq-qus-arrow:after {
  content: "";
  border: solid #c9c9c9;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 2px);
  transition: all 0.2s ease;
}
.enquire-s {
  height: 0px;
  width: 65px;
  position: fixed;
  right: 0;
  top: 55%;
  z-index: 1000;
  transform: rotate(-90deg);
  color: black;
  background-color: #d02829;
}
.enquire-s a {
  display: block;
  background: #d02829;
  height: 30px;
  width: 140px;
  border-radius: 6px 6px 0 0;
  padding: 8px 16px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
  border-bottom: solid 1px #333;
  border-left: solid 1px #333;
  border-right: solid 1px #fff;
}
.methodology-box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.banner-feature-box {
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  margin: 0 5px;
  border-radius: 5px;
  line-height: 18px;
  margin-bottom: 10px;
  font-size: 14px;
}

.course-features-box {
  min-width: 22%;
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  line-height: 18px;
  text-align: left;
  font-size: 14px;
}
.red-dot-list li::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 18px;
  left: -40px;
  background-color: #d02829;
  margin-left: 10px;
}
.boxshadow {
  box-shadow: 0 2px 5px 0 rgba(17, 121, 239, 0.15);
  border: 1px solid #e6ecef;
  background: #fff;
  margin: 0 5px;
  padding: 10px;
  border-radius: 5px;
  line-height: 18px;
  margin-bottom: 30px;
}
.arrowmark111 ul li {
  background-image: url(https://api.vignanonline.com//media/uploads/2023/11/10/Arrow.svg);
  color: #fff;
  border-radius: 0;
  background-position: left top 7px;
  padding-left: 24px;
  height: 10px;
  display: flex;
  font-size: 16px;
  color: #fff !important;
  margin-top: 15px;
}
</style>
