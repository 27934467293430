<template lang="">
  <div>
    <section class="bg-[#D02829]">
      <div class="mx-auto max-w-7xl p-10">
        <div class="text-left">
          <div class="font-bold text-[22px]">
            <h2 class="text-black text-lg lg:text-2xl">OUR <span class="text-white">TECHNOLOGY</span></h2>
            <p class="text-white font-semibold text-[16px]">
              Get the added advantage of kickstarting your career
            </p>
          </div>
          <div class="pt-10 flex flex-col md:flex-row gap-10 lg:gap-[10%] text-[14px]">
            <div>
              <ul class="flex flex-col gap-4">
                <li>
                  <div class="flex items-center justify-start gap-4">
                    <div class="flex items-center justify-center p-2 min-h-[60px] min-w-[60px] bg-white rounded-md">
                      <img
                        class="h-[40px] w-[40px]"
                        src="../assets/technology/ott1.png"
                        alt=""
                      />
                    </div>
                    <div class="text-white font-normal">
                      <p>
                        State-of-the-art Learning Management System (LMS) for a
                        near face-to-face learning experience
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="flex items-center justify-start gap-4">
                    <div class="flex items-center justify-center p-2 min-h-[60px] min-w-[60px] bg-white rounded-md">
                      <img
                        class="h-[40px] w-[40px]"
                        src="../assets/technology/ott2.png"
                        alt=""
                      />
                    </div>
                    <div class="text-white font-normal">
                      <p>
                        Clarify all your queries with ease using our chat and
                        message feature
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="flex items-center justify-start gap-4">
                    <div class="flex items-center justify-center p-2 min-h-[60px] min-w-[60px] bg-white rounded-md">
                      <img
                        class="h-[40px] w-[40px]"
                        src="../assets/technology/ott3.png"
                        alt=""
                      />
                    </div>
                    <div class="text-white font-normal">
                      <p>
                        Join online forums and peer groups to engage in
                        discussions on a variety of topics
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="flex items-center justify-start gap-4">
                    <div class="flex items-center justify-center p-2 min-h-[60px] min-w-[60px] bg-white rounded-md">
                      <img
                        class="h-[40px] w-[40px]"
                        src="../assets/technology/ott4.png"
                        alt=""
                      />
                    </div>
                    <div class="text-white font-normal">
                      <p>Instant and anytime access to the learning material</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="flex items-center justify-start gap-4">
                    <div class="flex items-center justify-center p-2 min-h-[60px] min-w-[60px] bg-white rounded-md">
                      <img
                        class="h-[40px] w-[40px]"
                        src="../assets/technology/ott5.png"
                        alt=""
                      />
                    </div>
                    <div class="text-white font-normal">
                      <p>
                        Continuous feedback and analytics to monitor progress
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="w-full md:w-1/2 lg:w-[60%]">
              <div class="bg-white p-5">
                <h2 class="font-semibold text-center pb-4">
                  Know Our <span class="text-red-600">LMS</span>
                </h2>
                <iframe
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                  src="https://www.youtube.com/embed/8REjWKLJRAY"
                  title="YouTube video player"
                  width="100%"
                  height="300"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {};
</script>
<style scoped >

</style>
