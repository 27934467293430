<template>
  <div class="max-w-7xl m-auto">
    <div
      class="flex flex-col items-center lg:flex-row-reverse lg:justify-center lg:items-start lg:my-8"
    >
      <div class="my-4 lg:ml-4">
        <div
          class="text-black w-[20rem] m-auto text-xl md:text-2xl lg:text-start lg:pl-6 lg:w-[25rem] 2xl:w-[30rem] font-bold"
        >
        Chancellor on the Alumni Advancement Scholarship Program <br /> 
        A Scholarship Opportunity For You!
        </div>
      
        <a href="#applybox">
          <div
            class="lg:block hidden bg-[#c82228] text-white w-max my-4 ml-4 mt-4 xl:mt-10 2xl:mt-8 rounded-full py-2 px-12 font-bold cursor-pointer text-xl"
          >
           Apply Now
          </div>
        </a>
      </div>
      <div>
        <div
          class="ssm:w-[18rem] w-[22rem] md:w-[30rem] xl:w-[40rem] video-container"
        >
          <video
            v-if="!videoStarted"
            ref="videoPlayer"
            width="640"
            height="360"
            :src="videoSrc"
            controls
            @play="hideBanner"
          >
            Your browser does not support the video tag.
          </video>
          <video
            v-else
            ref="videoPlayer"
            width="640"
            height="360"
            :src="videoSrc"
            controls
            autoplay
          >
            Your browser does not support the video tag.
          </video>
          <img
            v-if="showBanner"
            :src="bannerImage"
            class="banner-image"
            @click="startVideo"
          />
        </div>
        <a href="#applybox">
          <div
            class="lg:hidden bg-[#c82228] text-white w-max my-4 m-auto rounded-full py-1 px-6 font-semibold cursor-pointer"
          >
          Apply Now
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "scholarShip",
  data() {
    return {
      videoSrc:
        "https://api.onlinejain.com/media/alumni/Dr.Chenraj-Roychand%28Chairman%29_12317.mp4",
      bannerImage: require("@/assets/alumni-lp/Video.webp"),
      showBanner: true,
    };
  },
  methods: {
    startVideo() {
      this.showBanner = false;
      this.videoStarted = true;
      this.$refs.videoPlayer.play();
    },
    hideBanner() {
      this.showBanner = false;
    },
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; /* Optional: change cursor to indicate it is clickable */
}
</style>
