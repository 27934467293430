<template>
   <iframe async style="bottom: 15px; right: 10px;" class="mob-chat" id="lsq-chatbot"
    src="https://botweb.converse.leadsquared.com/?botId=1117&tenantId=44223&type=WEB&channelId=efb4c0e8-ccde-4c5b-8dfe-3d8799ce3966"></iframe>

</template>

<script>
export default {

}
</script>

<style>
  #lsq-chatbot {
  position: fixed;
  z-index: 99999;
  border: none;
}
.chatbot-opened {
  height: min(85vh, 586px) !important;
}
@media only screen and (max-width: 768px) {
  .chatbot-opened {
    position: fixed;
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    top: 0 !important;
  }
  .mob-chat{
    bottom: 90px !important;
  }
  .chatbot-opened {
  height: min(85vh, 586px) !important;
  margin-top: 90px;
}
}


</style>