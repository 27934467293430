<template>
    <NavBar />
    <div class="w-full">
      <section>
        <div class="w-full px-5 pb-5">
          <div class="max-w-7xl mx-auto w-full">
            <ol
              class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]"
            >
              <li><a href="/">Home</a></li>
              <li>> Quality and Assurance</li>
            </ol>
            <div
              class="relative my-8 overflow-x-auto shadow-md sm:rounded-lg border-collapse border border-slate-300"
            >
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
              <thead
                class="text-xs text-gray-700 uppercase bg-[#CA2526] text-white"
              >
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 border border-slate-300 w-12"
                    >
                      S.No.
                    </th>
                    <th scope="col" class="px-6 py-3 border border-slate-300">
                      Information
                    </th>
                    <th scope="col" class="px-6 py-3 border border-slate-300">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  
  
                
  
                  <tr class="odd:bg-white">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                   
                    </th>
                    <td class="px-6 py-4 border border-b-0 border-slate-300">
                      &nbsp;
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a href="https://api.vignanonline.com/media/CIQA-ODL_2021-22_25945.pdf" target="_blank"
                        ><span class="text-[#007bff]">
                          Centre for Internal Quality and Assurance Annual Report (Open and Distance Learning Programs), 2021-22</span
                        ></a
                      >
                    </td>
                  </tr>
  
                  <tr class="odd:bg-white">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                    1
                    </th>
                    <td
                      class="px-6 py-4 border border-t-0 border-b-0 border-slate-300"
                    >
                      CIQA Reports
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a href="https://api.vignanonline.com/media/CIQA-ODL_2022-23_57191.pdf" target="_blank"
                        ><span class="text-[#007bff]">
                          Centre for Internal Quality and Assurance Annual Report (Open and Distance Learning Programs), 2022-23</span
                        ></a
                      >
                    </td>
                  </tr>
  
                  <tr class="odd:bg-white">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      &nbsp;
                    </th>
                    <td class="px-6 py-4 border-l border-t-0 border-slate-300">
                      &nbsp;
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a href="https://api.vignanonline.com/media/CIQA-Report-2021-2022(OL)_82552.pdf" target="_blank"
                        ><span class="text-[#007bff]">
                          Centre for Internal Quality and Assurance Annual Report (Online Programs), 2021-22</span
                        ></a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      &nbsp;
                    </th>
                    <td class="px-6 py-4 border border-t-0 border-slate-300">
                      &nbsp;
                    </td>
                    <td class="px-6 py-4 border border-slate-300">
                      <a href="https://api.vignanonline.com/media/CIQA-REPORT_OL_2022-23_43093.pdf" target="_blank"
                        ><span class="text-[#007bff]">
                          Centre for Internal Quality and Assurance Annual Report (Online Programs), 2022-23</span
                        ></a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      
    </div>
    <FooterView />
  </template>
  
  <script>
  import NavBar from "@/components/NavBar.vue";
  import FooterView from "@/components/FooterView.vue";
  export default {
    name: "PrivacyPolicy",
    components: {
      FooterView,
      NavBar,
    },
  };
  </script>
  
  <style scoped></style>
  