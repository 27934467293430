<template>
  <div class="w-full bg-[#f7f9fb] py-10">
    <div class="lg:max-w-7xl mx-auto justify-center px-3">
      <div class="flex lg:flex-row flex-col text-center justify-center">
        <h1
          class="lg:text-[22px] text-xl flex font-bold uppercase text-center justify-center"
        >
          Online Degree
        </h1>
        <h2
          class="text-[#CA2526] lg:text-[22px] text-xl flex font-bold uppercase pl-3 text-center justify-center"
        >Programs Offered</h2>
      </div>

      <div>
        <div
          class="alltabs flex flex-row justify-center gap-[10px] lg:gap-[20px] pt-10 pb-10"
        >
          <button
            :class="{ active: currentSlider === 1 }"
            class="tabbutton md:min-w-[165px] w-[150px] lg:w-[180px] xl:w-[100px] py-2 md:min-h-[60px] text-black"
            @click="showSlider(1)"
          >
            All
          </button>
          <button
            :class="{ active: currentSlider === 2 }"
            class="tabbutton md:min-w-[165px] w-[150px] lg:w-[180px] xl:w-[100px] py-2 md:min-h-[60px] text-black"
            @click="showSlider(2)"
          >
            MASTERS
          </button>
          <button
            :class="{ active: currentSlider === 3 }"
            class="tabbutton md:min-w-[165px] w-[150px] lg:w-[180px] xl:w-[100px] py-2 md:min-h-[60px] text-black"
            @click="showSlider(3)"
          >
            BACHELORS
          </button>
        </div>

        <div v-if="currentSlider === 1">
          <div ref="slider1" class="slider justify-center m-auto">
            <div v-for="item in items1" :key="item.id" class="slide-item">
              <!-- Your provided code goes here -->
              <div
                class="flex flex-col gap-5 mt-5 lg:w-[345px] lg:h-[463px] w-[100%] border-2 justify-center rounded-lg"
              >
                <div>
                  <img
                    :src="item.imageSrc"
                    :alt="item.alt"
                    width="345px"
                    height="210px"
                    class="w-[100%]"
                    defer
                  />
                </div>
                <div class="flex flex-col gap-5 p-4">
                  <p class="text-[16px] h-[60px] font-bold text-left">
                    {{ item.title }}
                    <span class="text-primary">{{ item.subtitle }}</span>
                  </p>
                  <div class="flex gap-4">
                    <div class="flex items-center gap-2">
                      <img
                        :src="item.iconLive"
                        alt=""
                        class="h-[23px] w-[20px]"
                      />
                      <p class="text-gray-400 text-[15px] text-left">
                        {{ item.liveText }}
                      </p>
                    </div>
                    <div class="flex items-center gap-2">
                      <img
                        :src="item.iconTime"
                        alt=""
                        class="h-[23px] w-[17px]"
                      />
                      <p class="text-gray-400 text-[15px]">
                        {{ item.duration }}
                      </p>
                    </div>
                  </div>
                  <a :href="item.link">
                    <div :class="item.buttonClass">
                      <p class="uppercase font-semibold">
                        {{ item.buttonText }}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <!-- End of provided code -->
            </div>
          </div>
        </div>

        <div v-else-if="currentSlider === 2">
          <div ref="slider2" class="slider">
            <div v-for="item in items2" :key="item.id" class="slide-item">
              <!-- Your provided code goes here -->
              <div
                class="flex flex-col gap-5 mt-5 lg:w-[345px] lg:h-[463px] w-[100%] border-2 justify-center rounded-lg"
              >
                <div>
                  <img
                    :src="item.imageSrc"
                    :alt="item.alt"
                    width="345px"
                    height="210px"
                    class="w-[100%]"
                    defer
                  />
                </div>
                <div class="flex flex-col gap-5 p-4">
                  <p class="text-[16px] h-[60px] font-bold text-left">
                    {{ item.title }}
                    <span class="text-primary">{{ item.subtitle }}</span>
                  </p>
                  <div class="flex gap-4">
                    <div class="flex items-center gap-2">
                      <img
                        :src="item.iconLive"
                        alt=""
                        class="h-[23px] w-[20px]"
                      />
                      <p class="text-gray-400 text-[15px] text-left">
                        {{ item.liveText }}
                      </p>
                    </div>
                    <div class="flex items-center gap-2">
                      <img
                        :src="item.iconTime"
                        alt=""
                        class="h-[23px] w-[17px]"
                      />
                      <p class="text-gray-400 text-[15px]">
                        {{ item.duration }}
                      </p>
                    </div>
                  </div>
                  <a :href="item.link">
                    <div :class="item.buttonClass">
                      <p class="uppercase font-semibold">
                        {{ item.buttonText }}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <!-- End of provided code -->
            </div>
          </div>
        </div>

        <div v-else>
          <!-- Static section with two cards -->
          <div class="lg:flex lg:flex-row gap-10">
            <div class="flex flex-col lg:gap-10">
              <!-- Card 1 -->
              <div class="slide-item">
                <div
                  class="flex flex-col gap-10 justify-center items-center m-auto"
                >
                  <div class="slide-item justify-center m-auto items-center">
                    <div
                      class="flex flex-col gap-5 mt-5 lg:w-[345px] lg:h-[463px] w-[100%] border-2 rounded-lg"
                    >
                      <div>
                        <img
                          src="@/assets/home/pro/online-bba-in-general-management.webp"
                          alt="BBA in General Management"
                          width="345px"
                          height="210px"
                          class="w-[100%]"
                          defer
                        />
                      </div>
                      <div class="flex flex-col gap-5 p-4">
                        <p class="text-[16px] h-[60px] font-bold text-left">
                          Online BBA in
                          <span class="text-primary">General Management</span>
                        </p>
                        <div class="flex gap-4">
                          <div class="flex items-center gap-2">
                            <img
                              src="@/assets/home/pro/icon-live.png"
                              alt=""
                              class="h-[23px] w-[20px]"
                            />
                            <p class="text-gray-400 text-[15px] text-left">
                              Live Online <br />Classes
                            </p>
                          </div>
                          <div class="flex items-center gap-2">
                            <img
                              src="@/assets/home/pro/icon-time.png"
                              alt=""
                              class="h-[23px] w-[17px]"
                            />
                            <p class="text-gray-400 text-[15px]">3 years</p>
                          </div>
                        </div>
                        <a href="/online-bba-degree/general">
                          <div
                            class="bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center"
                          >
                            <p class="uppercase font-semibold">get started</p>
                          </div></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="lg:flex flex-col gap-10">
              <!-- Card 2 -->
              <div class="slide-item">
                <div class="lg:flex flex-col gap-10">
                  <div class="slide-item">
                    <div
                      class="flex flex-col gap-5 mt-5 lg:w-[345px] lg:h-[463px] w-[100%] border-2 rounded-lg"
                    >
                      <div>
                        <img
                          src="@/assets/home/pro/Online-BCA-in-Computer-Science-and-IT.webp"
                          alt="Online BCA in Computer Science and IT"
                          width="345px"
                          height="210px"
                          class="w-[100%]"
                          defer
                        />
                      </div>
                      <div class="flex flex-col gap-5 p-4">
                        <p class="text-[16px] h-[60px] font-bold text-left">
                          Online BCA in
                          <span class="text-primary"
                            >Computer science and IT</span
                          >
                        </p>
                        <div class="flex gap-4">
                          <div class="flex items-center gap-2">
                            <img
                              src="@/assets/home/pro/icon-live.png"
                              alt=""
                              class="h-[23px] w-[20px]"
                            />
                            <p class="text-gray-400 text-[15px] text-left">
                              Live Online <br />Classes
                            </p>
                          </div>
                          <div class="flex items-center gap-2">
                            <img
                              src="@/assets/home/pro/icon-time.png"
                              alt=""
                              class="h-[23px] w-[17px]"
                            />
                            <p class="text-gray-400 text-[15px]">3 years</p>
                          </div>
                        </div>
                        <a href="/online-bca-degree/computer-science-and-it">
                          <div
                            class="bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center"
                          >
                            <p class="uppercase font-semibold">get started</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script defer>
import $ from "jquery";
// import axios from "axios";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
export default {
  name: "DegreeView",
  data() {
    return {
      activetabbutton: 0,
      currentSlider: 1,
      items1: [
        {
          id: 1,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Human-Resource-Management.webp"),
          alt: "Online MBA in Human Resource Management ",
          title: "Online MBA in",
          subtitle: "Human Resource Management",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/human-resource-management",
          buttonText: "Get Started",
        },
        {
          id: 2,
          imageSrc: require("@/assets/home/pro/Online-BCA-in-Computer-Science-and-IT.webp"),
          alt: "Online BCA in Computer Science and IT ",
          title: "Online BCA in",
          subtitle: "Computer science and IT",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "3 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-bca-degree/computer-science-and-it",
          buttonText: "Get Started",
        },
        {
          id: 3,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Marketing.webp"),
          alt: "Online MBA in Marketing ",
          title: "Online MBA in",
          subtitle: "Marketing",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/marketing",
          buttonText: "Get Started",
        },
        {
          id: 4,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Business-Analytics.webp"),
          alt: "Online-MBA-in-Business-Analytics ",
          title: "Online MBA in",
          subtitle: "Business Analytics",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/business-analytics",
          buttonText: "Get Started",
        },
        {
          id: 5,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Finance-and-Human-Resource.webp"),
          alt: "Online MBA in Finance and Human-Resource ",
          title: "Online MBA in",
          subtitle: "Finance and Human Resource",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/finance-and-human-resource",
          buttonText: "Get Started",
        },
        {
          id: 6,
          imageSrc: require("@/assets/home/pro/Online-MCA-in-Computer-Science-and-IT.webp"),
          alt: "Online MCA in Computer Science and IT ",
          title: "Online MCA in",
          subtitle: "Computer Science and IT",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mca-degree/computer-science-and-it",
          buttonText: "Get Started",
        },
        {
          id: 7,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Information-Technology.webp"),
          alt: "Online MBA in Information Technology",
          title: "Online MBA in ",
          subtitle: "Information Technology",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/information-technology",
          buttonText: "Get Started",
        },
        {
          id: 8,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Finance.webp"),
          alt: "Online MBA in Finance ",
          title: "Online MBA in",
          subtitle: "Finance",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/finance",
          buttonText: "Get Started",
        },
        {
          id: 9,
          imageSrc: require("@/assets/home/pro/Online-MCA-in-Data-Science.webp"),
          alt: "Online MCA in Data Science",
          title: "Online MCA in",
          subtitle: "Data Science",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mca-degree/data-science",
          buttonText: "Get Started",
        },
        {
          id: 10,
          imageSrc: require("@/assets/home/pro/Healthcare-and-Hospital-Management.webp"),
          alt: "Online ",
          title: "Online MBA in",
          subtitle: "Healthcare and Hospital Management",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/hospital-and-healthcare-management",
          buttonText: "Get Started",
        },
        // {
        //   id: 11,
        //   imageSrc: require("@/assets/home/pro/Online-MBA-in-Logistics-and-Supply-Chain-Management.webp"),
        //   alt: "Online ",
        //   title: "Online MBA in",
        //   subtitle: "Logistics and Supply Chain Management",
        //   iconLive: require("@/assets/home/pro/icon-live.png"),
        //   liveText: "Live Online Classes",
        //   iconTime: require("@/assets/home/pro/icon-time.png"),
        //   duration: "2 years",
        //   buttonClass:
        //     "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
        //   link: "/online-mba/logistics-and-supply-chain-management",
        //   buttonText: "Get Started",
        // },
        // {
        //   id: 12,
        //   imageSrc: require("@/assets/home/pro/Online-MBA-in-Operations.webp"),
        //   alt: "Online MBA in Operations ",
        //   title: "Online MBA in",
        //   subtitle: "Operations",
        //   iconLive: require("@/assets/home/pro/icon-live.png"),
        //   liveText: "Live Online Classes",
        //   iconTime: require("@/assets/home/pro/icon-time.png"),
        //   duration: "2 years",
        //   buttonClass:
        //     "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
        //   link: "/online-mba/operations",
        //   buttonText: "Get Started",
        // },
      ],

      items2: [
        {
          id: 1,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Human-Resource-Management.webp"),
          alt: "Online MBA in Human Resource Management ",
          title: "Online MBA in",
          subtitle: "Human Resource Management",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/human-resource-management",
          buttonText: "Get Started",
        },
        {
          id: 2,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Marketing.webp"),
          alt: "Online MBA in Marketing ",
          title: "Online MBA in",
          subtitle: "Marketing",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/marketing",
          buttonText: "Get Started",
        },
        {
          id: 3,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Business-Analytics.webp"),
          alt: "Online-MBA-in-Business-Analytics ",
          title: "Online MBA in",
          subtitle: "Business Analytics",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/business-analytics",
          buttonText: "Get Started",
        },
        {
          id: 4,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Finance-and-Human-Resource.webp"),
          alt: "Online MBA in Finance and Human-Resource ",
          title: "Online MBA in",
          subtitle: "Finance and Human Resource",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/finance-and-human-resource",
          buttonText: "Get Started",
        },
        {
          id: 5,
          imageSrc: require("@/assets/home/pro/Online-MCA-in-Computer-Science-and-IT.webp"),
          alt: "Online MCA in Computer Science and IT ",
          title: "Online MCA in",
          subtitle: "Computer Science and IT",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mca-degree/computer-science-and-it",
          buttonText: "Get Started",
        },
        {
          id: 6,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Information-Technology.webp"),
          alt: "Online MBA in Information Technology",
          title: "Online MBA in",
          subtitle: "Information Technology",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/information-technology",
          buttonText: "Get Started",
        },
        {
          id: 7,
          imageSrc: require("@/assets/home/pro/Online-MBA-in-Finance.webp"),
          alt: "Online MBA in Finance ",
          title: "Online MBA in",
          subtitle: "Finance",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/finance",
          buttonText: "Get Started",
        },
        {
          id: 8,
          imageSrc: require("@/assets/home/pro/Online-MCA-in-Data-Science.webp"),
          alt: "Online MCA in Data Science",
          title: "Online MCA in ",
          subtitle: "Data Science",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mca-degree/data-science",
          buttonText: "Get Started",
        },
        {
          id: 9,
          imageSrc: require("@/assets/home/pro/Healthcare-and-Hospital-Management.webp"),
          alt: "Online ",
          title: "Online MBA in",
          subtitle: "Healthcare and Hospital Management",
          iconLive: require("@/assets/home/pro/icon-live.png"),
          liveText: "Live Online Classes",
          iconTime: require("@/assets/home/pro/icon-time.png"),
          duration: "2 years",
          buttonClass:
            "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
          link: "/online-mba/hospital-and-healthcare-management",
          buttonText: "Get Started",
        },
        // {
        //   id: 10,
        //   imageSrc: require("@/assets/home/pro/Online-MBA-in-Logistics-and-Supply-Chain-Management.webp"),
        //   title: "Online MBA in",
        //   alt: "Online MBA in Logistics and Supply Chain Management",
        //   subtitle: "Logistics and Supply Chain Management",
        //   iconLive: require("@/assets/home/pro/icon-live.png"),
        //   liveText: "Live Online Classes",
        //   iconTime: require("@/assets/home/pro/icon-time.png"),
        //   duration: "2 years",
        //   buttonClass:
        //     "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
        //   link: "/online-mba/logistics-and-supply-chain-management",
        //   buttonText: "Get Started",
        // },
        // {
        //   id: 11,
        //   imageSrc: require("@/assets/home/pro/Online-MBA-in-Operations.webp"),
        //   alt: "Online MBA in Operations ",
        //   title: "Online MBA in",
        //   subtitle: "Operations",
        //   iconLive: require("@/assets/home/pro/icon-live.png"),
        //   liveText: "Live Online Classes",
        //   iconTime: require("@/assets/home/pro/icon-time.png"),
        //   duration: "2 years",
        //   buttonClass:
        //     "bg-[#CA2526] text-white text-[13px] hover:text-red-500 py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#CA2526] flex justify-center items-center",
        //   link: "/online-mba/operations",
        //   buttonText: "Get Started",
        // },
      ],

      slickInstances: {}, // Store slick instances for each slider
    };
  },

  watch: {
    currentSlider(newValue, oldValue) {
      // Destroy the old slick instance
      this.destroySlider(oldValue);

      // Reinitialize the slider
      this.$nextTick(() => {
        this.initializeSlider(newValue);
      });
    },
  },

  mounted() {
    this.initializeSlider(1);
    this.initializeSlider(2);
  },
  beforeUnmount() {
    // Clean up slick instances when the component is destroyed
    Object.values(this.slickInstances).forEach((slickInstance) => {
      this.destroySlider(slickInstance);
    });
  },

  props: ["elective"],

  methods: {
    showSlider(sliderNumber) {
      this.currentSlider = sliderNumber;
    },
    initializeSlider(sliderNumber) {
      const refName = `slider${sliderNumber}`;
      this.slickInstances[sliderNumber] = $(this.$refs[refName]).slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        rows: 2,
        dots: true,
        dotsClass: "slick-dots",
        prevArrow: false,
        nextArrow: false,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
    destroySlider(sliderNumber) {
      const slickInstance = this.slickInstances[sliderNumber];
      if (slickInstance) {
        slickInstance.slick("unslick");
        delete this.slickInstances[sliderNumber];
      }
    },
  },
};
</script>

<style lang="scss">
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tabbutton {
  cursor: pointer;
  user-select: none;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.tabbutton:hover {
  border-bottom: 3px solid #d0393b;
}

.tabbutton-content > div {
  display: none;
  padding: 20px 20px;
}

.tabbutton-content > div.show {
  display: block;
  border-radius: 10px;
}
.active {
  border-bottom: 3px solid #d0393b;
}

.slide {
  display: flex;
  justify-content: center;
}
.slick-wrapper {
  width: 100%;
}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick-dots li {
  margin: 0 0.25rem;
}
.slick-dots button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: silver;
  text-indent: -9999px;
}
.slick-dots li.slick-active button {
  background-color: #d0393b;
  width: 0.75rem;
  height: 0.75rem;
}
</style>
