<template>
  <NavBar />
  <div class="w-full">
    <section>
      <div class="w-full px-5 pb-5">
        <div class="max-w-7xl mx-auto w-full">
          <ol
            class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]"
          >
            <li><a href="/">Home</a></li>
            <li>> Feedback and Grievance Management</li>
          </ol>
          <div
            class="relative my-8 overflow-x-auto shadow-md sm:rounded-lg border-collapse border border-slate-300"
          >
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-xs text-gray-700 uppercase bg-[#CA2526] text-white"
              >
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 border border-slate-300 w-12"
                  >
                    S.No.
                  </th>
                  <th scope="col" class="px-6 py-3 border border-slate-300">
                    Information
                  </th>
                  <th scope="col" class="px-6 py-3 border border-slate-300">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    1
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Details of Students' Grievance Redressal Committee (SGRC)
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <p>
                      <a
                        href="https://api.vignanonline.com/media/Grievance-Redressal-Mechanism_64699.pdf"
                        target="_blank"
                        ><span class="text-[#007bff]"
                          >Students' Grievance Redressal Committee</span
                        ></a
                      >
                    </p>
                    <!-- <p>
                        <a
                          href="https://api.vignanonline.com/media/9.3_Ombudsperson-for-redressal-of-Grievances-of-Students_57158.pdf"
                          target="_blank"
                          ><span class="text-[#007bff]">Ombudsperson for Redressal of Grievance of Students</span></a
                        >
                      </p> -->
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    2
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Ombudsperson for redressal of Grievances of Students
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <a
                      href="https://api.vignanonline.com/media/9.3-Ombudsperson-for-redressal-of-Grievances-of-Students_27957.pdf"
                      target="_blank"
                      ><span class="text-[#007bff]"
                        >Ombudsperson for Redressal of Grievance of
                        Students</span
                      ></a
                    >
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    3
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    Feedback mechanism
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    <a
                      href="https://support.vignanonline.com/login/"
                      target="_blank"
                      ><span class="text-[#007bff]"
                        >For Feedback, Suggestions and Queries, please log in to
                        Vignan's Integrated Support and Assistance (VISA)</span
                      ></a
                    >
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border border-slate-300"
                  >
                    4
                  </th>
                  <td class="px-6 py-4 border border-slate-300">E-Samadhan</td>
                  <td class="px-6 py-4 border border-slate-300">
                    <p>
                      For e-samadhan: <br>Prof. K Kalpana <br>Additional Director, CDOE
                      and Chairperson, SGRC <br>EMail:drkk_mgmt@vignan.ac.in
                    </p>
                    <!-- <a href="https://samadhaan.ugc.ac.in/" target="_blank"
                      ><span class="text-[#007bff]"
                        >Click here for UGC e-samadhan portal</span
                      ></a
                    > -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
  <FooterView />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterView from "@/components/FooterView.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    FooterView,
    NavBar,
  },
};
</script>

<style scoped></style>
