<template>
    <div class="w-full px-5 py-6 mt-2 border-b-4 border-gray-400">

      <div class="max-w-7xl mx-auto w-full relative">
        <div class="prev-menu absolute top-0 -left-3 xl:-left-4 cursor-pointer">
          <img src="../assets/icon-slider-prev.svg" class="w-[40px] h-[40px]" alt="" />
        </div>
  
        <div class="flex justify-center items-center gap-2" :class="sec_menu">
  
          <div class="text-center">
            <a href="#sec1"
              class="text-[15px] font-bold  px-5 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
              Rankings
            </a>
            
          </div>
  
          <div class="text-center">
            <a href="#sec2"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            Online MBA in Finance
            </a>
            
          </div>
  
          <div class="text-center">
            <a href="#sec3"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            Advanced Certifications
            </a>
            
          </div>
  
          <div class="text-center">
            <a href="#sec4"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            Why Online MBA in Finance?
            </a>
            
          </div>
  
          <div class="text-center">
            <a href="#sec5"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            Who can Apply
            </a>
            
          </div>
  
          <div class="text-center">
            <a href="#sec6"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            Our Testimonials
            </a>
            
          </div>
          <div class="text-center">
            <a href="#sec7"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            Our Alumni Working at
            </a>
            
          </div>
          <div class="text-center">
            <a href="#sec8"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            Career Opportunities
            </a>
            
          </div>

          <div class="text-center">
            <a href="#sec9"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            Career Advancement
            </a>
            
          </div>

          <div class="text-center">
            <a href="#sec10"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            Completion Certificate
            </a>
            
          </div>

          <div class="text-center">
            <a href="#sec11"
              class="text-[15px] font-bold  px-1 py-3 hover:text-[#d0393b] hover:border-b-4 hover:border-[#d0393b]"
            >
            FAQs
            </a>
            
          </div>

        
        </div>
  
        <div class="next-menu absolute top-0 -right-3 xl:-right-4  cursor-pointer">
          <img src="../assets/icon-slider-next.svg" class="w-[40px] h-[40px]" alt />
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  export default {
    name: "SecMenu",
    data() {
      return {
        sec_menu: "Secmenu_components",
      }
    },
  
    async mounted() {
      await this.secmenu();
    },
  
    methods: {
      secmenu() {
        $(`.${this.sec_menu}`).slick({
          dots: false,
          infinite: true,
          slidesToShow: 5,
          slidesToScroll:1,
          nextArrow: $(".next-menu"),
          prevArrow: $(".prev-menu"),
          autoplay: true,
          autoplaySpeed: 3000,
          responsive: [
                      {
              breakpoint: 1280,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll:1,
              },
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        })
      },
  
    }
  }
  </script>
  
  <style>
.slick-slider .slick-track {
  display: flex;
  gap: 12px;
}
</style>